import React from "react";
import ReactEcharts from "echarts-for-react";
import { Stack } from "@mui/material";

/* const legendData = [
  "Email",
  "Union Ads",
  "Video Ads",
  "Direct",
  "Search Engine",
] */
/* const xAxisData = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]; */
/* const   seriesData1 =  [120, 132, 101, 134, 90, 230, 210]
const   seriesData2 =  [120, 132, 101, 134, 90, 230, 210] */

const StackedLineEChart = ({
  legendData,
  seriesData1,
  seriesData2,
  seriesName1,
  seriesName2,
  xAxisData,
  isShowXAxis,
}: {
  legendData: string[];
  seriesData1: number[];
  seriesData2: number[];
  seriesName1: string;
  seriesName2: string;
  xAxisData: string[];
  isShowXAxis?: boolean;
}) => {
  return (
    <Stack width={"100%"}>
      <ReactEcharts
        option={{
          /*    title: {
            text: "Stacked Line",
          }, */
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: legendData,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "10%",
            containLabel: true,
          },
          /*  toolbox: {
            feature: {
              saveAsImage: {},
            },
          }, */
          xAxis: {
            show: isShowXAxis,
            type: "category",
            boundaryGap: false,
            data: xAxisData,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              name: seriesName1,
              type: "line",
              stack: "Total",
              data: seriesData1,
              smooth: true,
            },
            {
              name: seriesName2,
              type: "line",
              stack: "Total",
              data: seriesData2,
              smooth: true,
            },
            /*  {
              name: "Video Ads",
              type: "line",
              stack: "Total",
              data: [150, 232, 201, 154, 190, 330, 410],
            },
            {
              name: "Direct",
              type: "line",
              stack: "Total",
              data: [320, 332, 301, 334, 390, 330, 320],
            },
            {
              name: "Search Engine",
              type: "line",
              stack: "Total",
              data: [820, 932, 901, 934, 1290, 1330, 1320],
            }, */
          ],
        }}
      />
    </Stack>
  );
};

export default StackedLineEChart;
