import React from "react";
import { Typography, alpha, styled } from "@mui/material";
import { Handle, Position } from "reactflow";

const NodesContainer = styled("div")(({ theme }) => ({
  width: "60px",
  height: "36px",
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid ${theme.palette.primary.main}`,
  padding: "4px 8px",
  borderRadius: "5px",
  color: theme.palette.primary.dark,
  backgroundColor: ` ${alpha(theme.palette.secondary.main, 0.2)}`,
}));

const NodesContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "4px ",
}));

const handleStyle = {
  height: "8px",
  borderRadius: "4px",
  border: "medium none",
  width: " 20px",
  backgroundColor: "#8E8E8E",
};

function BreakNode({ data, id }: any) {
  return (
    <NodesContainer key={id}>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable
        style={handleStyle}
      />
      <NodesContent>
        <Typography
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          align="center"
          width={"100%"}
          fontSize={"8px"}
          variant="subtitle2"
        >
          {data.data.display_name}
        </Typography>
      </NodesContent>

      <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable
        style={handleStyle}
      />
    </NodesContainer>
  );
}

export default BreakNode;
