import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Handle, Position } from "reactflow";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { INodeParameters } from "page/workflow/types/nodeTypes";
import { FuncContext } from "page/workflow/context/FuncContext";
import StrInput from "./FromTypes/StrInput";
import IntInput from "./FromTypes/IntInput";
import BoolInput from "./FromTypes/BoolInput";
import DropDownMenu from "./FromTypes/DropDownMenu";
import ReturnInput from "./FromTypes/RtnInput";
import ScriptInput from "./FromTypes/ ScriptInput";

const Content = styled(Stack)(({ theme }) => ({
  width: "100%",
  gap: "12px",
  height: "100%",
}));
const NodesContainer = styled("div")(({ theme }) => ({
  width: "120px",
  height: "36px",
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid ${theme.palette.primary.main}`,
  padding: "4px 8px",
  borderRadius: "5px",
  color: theme.palette.primary.dark,
  backgroundColor: "transparent",
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "42px",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.light,
  borderRadius: "30px",
  marginTop: "24px",

  "&:hover": {
    backgroundColor: "#E2633F",
  },
  "&:disabled": {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.text.disabled,
  },
}));

const NodesContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "4px ",
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "460px",
  padding: "24px",
  borderRadius: "16px",
  backgroundColor: theme.palette.background.default,
  overflowY: "auto",
  maxHeight: "640px",
  "&::-webkit-scrollbar": {
    width: 6,
  },

  "&::-webkit-scrollbar-thumb:vertical": {
    borderRadius: 4,
    background: theme.palette.primary.main,
  },

  "&::-webkit-scrollbar-track:vertical": {
    borderRadius: 4,
    background: "transparent",
  },
}));

const handleStyle = {
  height: "8px",
  borderRadius: "4px",
  border: "medium none",
  width: " 20px",
  backgroundColor: "#8E8E8E",
};

const GeneralNode = ({ data, id }: { data: any; id: any }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { nodes, setNodes } = useContext(FuncContext);

  const [returnData, setReturnData] = useState<string>("");
  const [formData, setFormData] = useState<INodeParameters[]>([]);

  useEffect(() => {
    if (data.data.parameters !== undefined) {
      if (data.data.parameters.length > 0) {
        setFormData(data.data.parameters);
      }
      setReturnData(data.data.return_variable);
    }
  }, []);

  const handleInputChange = (parameterName: string, newValue: any) => {
    if (formData) {
      setFormData((prevFormData) => {
        const updatedData = [...prevFormData];
        const elementIndex = updatedData.findIndex(
          (item) => item.parameter_name === parameterName
        );
        if (elementIndex !== -1) {
          updatedData[elementIndex] = {
            ...updatedData[elementIndex],
            parameter_value: newValue,
          };
        }
        return updatedData;
      });
    }
  };

  const handleSubmit = () => {
    setTimeout(() => {
      const nodeIndex = nodes.findIndex((item: any) => item.id === id);
      const updatedNode = {
        ...nodes[nodeIndex],
        data: {
          ...nodes[nodeIndex].data,
          data: {
            ...nodes[nodeIndex].data.data,
            parameters: formData,
            return_variable: returnData,
          },
        },
      };
      const newNodes = [...nodes];
      newNodes[nodeIndex] = updatedNode;
      setNodes(newNodes);
      setOpen(false);
    }, 150);
  };

  /*  const handleInputChange = (parameterName: string, newValue: any) => {
    if (formData) {
      const updatedData = [...formData];
      const elementIndex = updatedData.findIndex(
        (item) => item.parameter_name === parameterName
      );
      if (elementIndex !== -1) {
        console.log(updatedData[elementIndex].parameter_value, newValue);
        updatedData[elementIndex].parameter_value = newValue;
      }
      setFormData(updatedData);
    }
  };
 */
  /*   const handleSubmit = () => {
    setTimeout(() => {
      const nodeIndex = nodes.findIndex((item: any) => item.id === id);
      nodes[nodeIndex].data.data["parameters"] = formData;
      nodes[nodeIndex].data.data["return_variable"] = returnData;
      setNodes(nodes);
      setOpen(false);
    }, 50);
  }; */
  return (
    <NodesContainer key={id}>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable
        style={handleStyle}
      />
      <NodesContent>
        <Typography
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          align="center"
          fontSize={"8px"}
          variant="subtitle2"
        >
          {data.data.display_name}
        </Typography>

        {data.data.parameters && (
          <IconButton onClick={handleOpen}>
            <DocumentScannerIcon sx={{ fontSize: "12px" }} />
          </IconButton>
        )}
      </NodesContent>

      <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable
        style={handleStyle}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent>
          <Content>
            <Typography variant="body1" fontWeight={700} component="h2">
              {data.data.display_name}
            </Typography>
            <Divider sx={{ width: "40%", marginBottom: "20px" }} />

            {formData &&
              formData.map((parameter: INodeParameters, i) => {
                return (
                  <Stack key={`NodeFormkey${i}`}>
                    {parameter.parameter_type === "str" &&
                      parameter.is_hide === false && (
                        <StrInput
                          parameter={parameter}
                          onInputChange={handleInputChange}
                        />
                      )}
                    {parameter.parameter_type === "int" &&
                      parameter.is_hide === false && (
                        <IntInput
                          parameter={parameter}
                          onInputChange={handleInputChange}
                        />
                      )}
                    {parameter.parameter_type === "bool" &&
                      parameter.is_hide === false && (
                        <BoolInput
                          parameter={parameter}
                          onInputChange={handleInputChange}
                        />
                      )}
                    {parameter.parameter_type === "dropdown" &&
                      parameter.is_hide === false && (
                        <DropDownMenu
                          parameter={parameter}
                          onInputChange={handleInputChange}
                          extraParameters={data.data.extra_parameters}
                        />
                      )}
                    {parameter.parameter_type === "script" &&
                      parameter.is_hide === false && (
                        <ScriptInput
                          mode={data.data.extra_parameters.script_language}
                          parameter={parameter}
                          onInputChange={handleInputChange}
                        />
                      )}
                  </Stack>
                );
              })}
            {data.data.is_return === true && (
              <ReturnInput
                title={"return variable"}
                returnData={returnData}
                setReturnData={setReturnData}
              />
            )}

            <SubmitButton
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </SubmitButton>
          </Content>
        </ModalContent>
      </Modal>
    </NodesContainer>
  );
};

export default GeneralNode;
