import React from "react";
import { Stack } from "@mui/material";
import IntegrationList from "./components/IntegrationList";

const IntegrationMonitor = () => {
  return (
    <Stack width={"100%"} gap={"36px"}>
      <IntegrationList />
    </Stack>
  );
};

export default IntegrationMonitor;
