import React from "react";
import { Divider, Stack, Typography } from "@mui/material";
import NightingaleChartEcharts from "components/eCharts/NightingaleChartEcharts";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import GradientStackedAreaChartEChart from "components/eCharts/GradientStackedAreaChartEChart";
import { useTheme } from "layouts/theme/ThemeContext";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import SmsIcon from "@mui/icons-material/Sms";
import ChartWrapper from "components/eCharts/ChartWrapper";
import StackedLineEChart from "components/eCharts/StackedLineEChartCustom";
import DashTitle from "../DashTitle";

const legendData = ["Sent", "Open", "Spam"];
const seriesData = [
  { value: 40, name: "Sent" },
  { value: 33, name: "Open" },
  { value: 28, name: "Spam" },
];
const gradientSeriesData = [130, 170, 160, 200, 180, 220];
const optionColors = ["#80FFA5"];
const xAxisData = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const stackedLegendData = ["Domain Owner", "User Invited"];
const stackedXAxisData = ["Mon", "Tue", "Wed", "Thu", "Fri"];
const stackedSeriesData1 = [150, 232, 201, 154, 190, 330, 410];
const stackedSeriesData2 = [320, 332, 301, 334, 390, 330, 320];
const stackedSeriesName1 = "Domain Owner";
const stackedSeriesName2 = "User Invited";

const summaryArr = [
  { number: 219, name: "Email Sent " },
  { number: 24, name: "Skipped" },
  { number: 48, name: "Opened" },
  { number: 25, name: "Clicked" },
  { number: 34, name: "Hard Bounc" },
  { number: 27, name: "Soft Bounc" },
  { number: 4, name: "Unsunscribe" },
  { number: 16, name: "Spam" },
  { number: 49, name: "Order In" },
  { number: 20, name: "Rejected" },
  { number: 17, name: "Last Order" },
  { number: 3, name: "New Member" },
];

const DashCRM = () => {
  const date = new Date();
  const { theme } = useTheme();
  return (
    <Stack gap={"24px"}>
      <DashTitle title={"CRM Dashboard"} />
      <Stack direction={"row"} gap={"24px"}>
        <ChartWrapper
          children={
            <Stack
              sx={{
                height: "340px",
                maxHeight: "340px",
                width: "100%",
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography variant="subtitle2">Email Sent Total</Typography>{" "}
                <MoreHorizIcon />
              </Stack>
              <Typography variant="caption">
                {date.getMonth()}. {date.getFullYear()}{" "}
              </Typography>
              <NightingaleChartEcharts
                legendData={legendData}
                seriesData={seriesData}
              />
            </Stack>
          }
        />
        <ChartWrapper
          children={
            <Stack
              sx={{
                height: "340px",
                maxHeight: "340px",
                width: "100%",
              }}
            >
              <Stack gap={"24px"}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack direction={"row"} gap={"4px"} alignItems={"center"}>
                    <Typography variant="subtitle2">Revenue</Typography>
                    <Divider orientation="vertical" flexItem />
                    <Typography variant="caption">
                      {date.getMonth()}. {date.getFullYear()}{" "}
                    </Typography>
                  </Stack>
                  <MoreHorizIcon />
                </Stack>
                <Stack gap={"12px"}>
                  {" "}
                  <Typography variant="h4">$145,250.75</Typography>
                  <Typography variant="caption">
                    Won from 262 Details
                  </Typography>
                </Stack>
              </Stack>
              <GradientStackedAreaChartEChart
                legendData={legendData}
                seriesData={gradientSeriesData}
                optionColors={[theme.palette.warning.dark]}
                xAxisData={xAxisData}
              />
            </Stack>
          }
        />
        <Stack
          sx={{
            height: "340px",
            maxHeight: "340px",
            width: "100%",
            gap: "24px",
          }}
        >
          <ChartWrapper
            children={
              <Stack
                sx={{
                  alignItems: "center",
                  padding: "12px",
                  gap: "16px",
                  height: "140px",
                  maxHeight: "140px",
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                <LocalAtmIcon
                  sx={{
                    fontSize: "36px",
                    color: theme.palette.error.light,
                  }}
                />
                <Stack>
                  <Typography variant="h4">$145,250.75</Typography>
                  <Typography variant="caption">Won from 18 Details</Typography>
                </Stack>
              </Stack>
            }
          />
          <ChartWrapper
            children={
              <Stack
                sx={{
                  alignItems: "center",
                  padding: "12px",
                  gap: "16px",
                  height: "140px",
                  maxHeight: "140px",
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                <SmsIcon
                  sx={{
                    fontSize: "36px",
                    color: theme.palette.success.light,
                  }}
                />
                <Stack>
                  <Typography variant="h4">+126</Typography>
                  <Typography variant="caption">
                    New Deals worth $3000{" "}
                  </Typography>
                </Stack>
              </Stack>
            }
          />
        </Stack>
      </Stack>
      <Stack direction={"row"} gap={"24px"}>
        <ChartWrapper
          children={
            <Stack
              sx={{
                height: "340px",
                maxHeight: "340px",
                width: "100%",
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack direction={"row"} gap={"4px"} alignItems={"center"}>
                  <Typography variant="subtitle2">Tag Graph</Typography>
                  <Divider orientation="vertical" flexItem />
                  <Typography variant="caption">
                    {date.getMonth()}. {date.getFullYear()}{" "}
                  </Typography>
                </Stack>
                <MoreHorizIcon />
              </Stack>

              <StackedLineEChart
                legendData={stackedLegendData}
                seriesData1={stackedSeriesData1}
                seriesData2={stackedSeriesData2}
                seriesName1={stackedSeriesName1}
                seriesName2={stackedSeriesName2}
                xAxisData={stackedXAxisData}
                isShowXAxis={true}
              />
            </Stack>
          }
        />
        <ChartWrapper
          children={
            <Stack
              sx={{
                height: "340px",
                maxHeight: "340px",
                width: "100%",
                gap: "36px",
              }}
            >
              <Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography variant="subtitle2">Summary</Typography>{" "}
                  <MoreHorizIcon />
                </Stack>
                <Typography variant="caption">
                  {date.getMonth()}. {date.getFullYear()}{" "}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "48px",
                  flexWrap: "wrap",
                }}
              >
                {summaryArr.map((item) => {
                  return (
                    <Stack
                      sx={{
                        width: "72px",
                      }}
                      key={`summaryArr${item.name}`}
                    >
                      <Typography
                        align="center"
                        color="primary.dark"
                        variant="h4"
                      >
                        {item.number}
                      </Typography>
                      <Typography align="center" variant="caption">
                        {item.name}
                      </Typography>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          }
        />
      </Stack>
    </Stack>
  );
};

export default DashCRM;
