import { Stack, Typography, alpha, styled } from "@mui/material";
import { IGraphifyOs, INetworkInterfaces } from "page/graphify/type";
import React, { useEffect, useState } from "react";
import { SlimLinesCharts } from "components/eCharts/SlimLinesCharts";
import { SlimAreasCharts } from "./charts/SlimAreasCharts";
import { useTranslation } from "react-i18next";

const Container = styled(Stack)(({ theme }) => ({
  alignItems: "flex-start",
  padding: "16px",
  borderRadius: "16px",
  gap: "8px",
  boxShadow: ` ${alpha(theme.palette.primary.dark, 0.25)}  0px 5px 15px `,
}));

const Content = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderRadius: "16px  ",
  gap: "8px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    gap: "16px",
    flexDirection: "column-reverse",
  },
  /* flexDirection:"column-reverse" */
}));

export const ButtonContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  padding: "20px",

  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: 6,
  },
  maxHeight: "420px",
  "&::-webkit-scrollbar-thumb:vertical": {
    borderRadius: 4,
    background: theme.palette.primary.main,
  },

  "&::-webkit-scrollbar-track:vertical": {
    borderRadius: 4,
    background: "transparent",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
  },
}));

interface IChartData {
  data: INetworkInterfaces[];
  title: string;
}

const emptyChartData = {
  data: [],
  title: "",
};

const NetworkViewGroups = ({ data }: { data: IGraphifyOs }) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<IChartData>(emptyChartData);

  function findInterfacesNames() {
    const interfacesName = data?.network_interfaces
      .sort((a, b) => {
        return (
          b.data[b.data.length - 1].lastvalue -
          a.data[a.data.length - 1].lastvalue
        );
      })
      .map((item) => {
        return { name: item.name };
      });

    const uniqueData: string[] = [];
    interfacesName?.forEach((item) => {
      const parts = item.name.split(":");
      const interfaceName = parts[0].trim();

      if (!uniqueData.includes(interfaceName)) {
        uniqueData.push(interfaceName);
      }
    });
    return uniqueData.map((interfaceName) => {
      return { name: interfaceName };
    });
  }

  const interfacesName = findInterfacesNames();

  const createChartData = (name: string) => {
    let chartsData = data?.network_interfaces.filter((item) =>
      item.name.includes(name)
    );
    return chartsData ?? [];
  };

  useEffect(() => {
    setChartData({
      data: createChartData(interfacesName[0].name),
      title: interfacesName[0].name,
    });
  }, []);

  const createTime = data.network_interfaces[0].data.map(
    (item) => item.recivedTime
  );

  return (
    <Container>
      <Stack
        sx={{
          marginBottom: { xs: "24px", md: "0px" },
        }}
      >
        <Typography variant="body1" fontWeight={700}>
          {chartData.title}
        </Typography>
        <Typography variant="caption">{t("graphify.charDesc")}</Typography>
      </Stack>
      <Content>
        <Stack flex={6}>
          {createTime && (
            <SlimAreasCharts data={chartData.data} time={createTime} />
          )}
        </Stack>
        <ButtonContainer flex={2}>
          {interfacesName.map((item, i) => {
            return (
              <ChartsItem
                key={`interfacesName_${i}`}
                title={item.name}
                data={createChartData(item.name)}
                chartData={chartData}
                setChartData={setChartData}
              />
            );
          })}
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default NetworkViewGroups;

const ChartsItem = ({
  title,
  data,
  chartData,
  setChartData,
}: {
  title: string;
  data: INetworkInterfaces[];

  chartData: IChartData;
  setChartData: React.Dispatch<React.SetStateAction<IChartData>>;
}) => {
  return (
    <Stack
      sx={{
        cursor: "pointer",
        padding: "2px 8px",
        borderRadius: "8px",
        backgroundColor:
          chartData.title === title ? ` ${alpha("#ED2438", 0.05)}` : "none",
        "&:hover": {
          transition: "all 0.4s ease-in-out",
          transform: " scale(1.03); ",
          backgroundColor: ` ${alpha("#ED2438", 0.1)}`,
        },
      }}
      onClick={() => {
        setChartData({ data: data, title: title });
      }}
    >
      <Typography variant="subtitle2" marginBottom={"-20px"}>
        {title}
      </Typography>
      <SlimLinesCharts data={data} />
    </Stack>
  );
};
