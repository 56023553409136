import React, { useEffect, useState } from "react";
import { Modal, Stack, TextField, Typography } from "@mui/material";
import { AddButton, ModalContent } from "../Components";
import FilterValues from "./FilterValues";
import InfoValues from "./InfoValues";
import { CustomStyleButton } from "components/buttons/CustomButton";
import { IInfoAlarmTypes, IValueTypes } from "page/filterManagement/types/Type";
import { usePostMutation } from "query/PostQuery";
import { Notify } from "components/notify/notify";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const AlarmModal = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const postFilterData = usePostMutation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [filterName, setFilterName] = useState<string>("");
  const [filterValueArr, setValueArr] = useState<IValueTypes[]>([]);
  const [infoValueArr, setInfoArr] = useState<IInfoAlarmTypes>({
    mail: "",
    phone: "",
    action: "",
    agent: "",
  });

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ["filterManagementData"],
    });
  }, []);
  const user: string | null = sessionStorage.getItem("user");
  const userData = user && JSON.parse(user);
  const desibledValue =
    filterName === "" ||
    filterValueArr.length === 0 ||
    (infoValueArr.phone === "" &&
      infoValueArr.mail === "" &&
      Boolean(
        Boolean(infoValueArr.action === "0" || infoValueArr.action === "") ||
          Boolean(infoValueArr.agent === "0" || infoValueArr.agent === "")
      ));

  return (
    <Stack>
      <AddButton onClick={handleOpen}>
        <Typography variant="caption">
          {t("filterManagement.createFilter")}
        </Typography>
      </AddButton>

      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent>
          <Stack gap={"16px"}>
            <Stack gap={"4px"}>
              <Typography variant="subtitle2">
                {t("filterManagement.filterName")}
              </Typography>
              <TextField
                id="filterName_textField"
                placeholder=""
                variant="outlined"
                size="small"
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                }}
              />
            </Stack>
            <FilterValues
              filterValueArr={filterValueArr}
              setValueArr={setValueArr}
            />
            <InfoValues setInfoArr={setInfoArr} />

            <CustomStyleButton
              disabled={desibledValue}
              onClick={() => {
                postFilterData.mutate(
                  {
                    url: `/filter/`,
                    data: {
                      user: userData.id,
                      filter_name: filterName,
                      content: filterValueArr,
                      is_phone: infoValueArr.phone,
                      is_mail: infoValueArr.mail,
                      is_action: infoValueArr.action,
                      is_agent: infoValueArr.agent,
                    },
                  },
                  {
                    onSuccess() {
                      queryClient.invalidateQueries({
                        queryKey: ["filterManagementData"],
                      });
                      Notify.notifySuccess(
                        t("filterManagement.notifySuccessAdd")
                      );
                      handleClose();
                      setFilterName("");
                      setValueArr([]);
                      setInfoArr({
                        mail: "",
                        phone: "",
                        action: "",
                        agent: "",
                      });
                    },
                    onError() {
                      Notify.notifyError(t("filterManagement.notifyError"));
                    },
                  }
                );
              }}
            >
              <Typography variant="subtitle2">
                {t("filterManagement.createFilter")}
              </Typography>
            </CustomStyleButton>
          </Stack>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default AlarmModal;
