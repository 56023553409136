import React from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { BorderContainer } from "components/box/BorderContainer";
import { useTheme } from "layouts/theme/ThemeContext";
import RadialBarChartsSimple from "components/apexcharts/RadialBarChartsSimple";

const MonitoringView = () => {
  const { theme } = useTheme();
  const monitoringArr = [
    {
      name: "Last run",
      value: "2 minutes ago",
    },
    {
      name: "Next run",
      value: "in 3 minutes",
    },
    {
      name: "Email",
      value: "Configured",
    },
    {
      name: "Channel",
      value: "Not configured",
    },
  ];

  const statuArr = [
    {
      name: "HEALTHY",
      value: 6,
      color: theme.palette.success.dark,
    },
    {
      name: "WARNING",
      value: 4,
      color: theme.palette.warning.dark,
    },
    {
      name: "ERROR",
      value: 3,
      color: theme.palette.error.dark,
    },
    {
      name: "UNMONITORED",
      value: 1,
      color: theme.palette.background.default,
    },
  ];

  return (
    <BorderContainer>
      <Stack width={"100%"} gap={"8px"}>
        <Stack
          direction={"row"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Stack gap={"24px"}>
            <Typography variant="subtitle2">Monitoring</Typography>
            <Stack gap={"4px"}>
              {monitoringArr.map((item) => {
                return (
                  <Stack
                    key={`monitoringArr${item.name}`}
                    direction={"row"}
                    gap={"4px"}
                  >
                    <Typography width={"60px"} variant="caption">
                      {item.name}
                    </Typography>
                    <Typography variant="caption">:</Typography>
                    <Typography variant="caption">{item.value}</Typography>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
          <Stack direction={"row"}>
            <RadialBarChartsSimple
              seriesData={[80]}
              labelsName={["CPU"]}
              circleColors={[theme.palette.success.main]}
              height={"160px"}
              width="160px"
            />
            <RadialBarChartsSimple
              seriesData={[100]}
              labelsName={["Healthy"]}
              circleColors={[theme.palette.warning.main]}
              height={"160px"}
              width="160px"
            />
          </Stack>
        </Stack>

        <Stack width={"100%"} gap={"10px"}>
          <Divider />
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"space-between"}
          >
            {statuArr.map((item) => {
              return (
                <Stack
                  key={`statuArr${item.name}`}
                  sx={{
                    borderLeft: `3px solid  ${item.color}`,
                    paddingLeft: "8px",
                  }}
                >
                  <Typography variant="subtitle2">{item.name}</Typography>
                  <Typography variant="body2">{item.value}</Typography>
                  <Typography variant="caption">Resources</Typography>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </BorderContainer>
  );
};

export default MonitoringView;
