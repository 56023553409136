import { useMutation, useQuery } from "@tanstack/react-query";
import { Notify } from "components/notify/notify";

const fetchGet = async (url: string) => {
  const user: string | null = sessionStorage.getItem("user");
  try {
    if (user !== null) {
      const userData = JSON.parse(user);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Token ${userData.token}`);
      const data = await fetch(url, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      })
        .then((response) => response.json())
        .catch((error) => {
          console.log(error);
        });
      return data;
    }
  } catch (error) {}
};

const fetchPost = async (datas: any, url: string, notify: string) => {
  const user: string | null = sessionStorage.getItem("user");
  try {
    if (user !== null) {
      const userData = JSON.parse(user);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Token ${userData.token}`);
      const data = await fetch(url, {
        method: "GET",
        headers: myHeaders,
        body: JSON.stringify(datas),
        redirect: "follow",
      })
        .then((response) => response.json())
        .then(() => {
          Notify.notifySuccess(notify);
        })
        .catch((error) => {
          console.log(error);
        });
      return data;
    }
  } catch (error) {}
};

/**
 * @returns isScrollable, label and variant values ​​of events
 */
export const useGetEventsTitles = ({
  key,
  url,
}: {
  key: string;
  url: string;
}) => {
  const result = useQuery({
    queryKey: [key],
    queryFn: async () => await fetchGet(url),
  });

  return result;
};

/**
 * @returns useGetData, label and variant values ​​of events
 */
export const useGetData = function <T>({
  path,
  key,
  enabled,
}: {
  path: string;
  key: string;
  enabled: boolean;
}) {
  return useQuery({
    queryKey: [key],
    queryFn: async () => await fetchGet(path),
    select: (data: T[]) => data,
    enabled: enabled,
  });
};

/* How use useGetData 
  const { data, refetch, isRefetching, isRefetchError, error } =
    useGetData<IEventInfo>({
      path: eventsCollPath,
      key:`${baseUrl}/filter/` ,
      enabled: userPanelStore.currentTab === "myNotes",
    }); */

export const usePostMutation = function <T>() {
  const mutate = useMutation({
    mutationFn: ({
      url,
      notify,
      data,
    }: {
      url: string;
      notify: string;
      data: T;
    }) => fetchPost(data, url, notify),
    onSuccess: () => {
      Notify.notifySuccess("Notification added to job");
    },
  });
  return mutate;
};

/* 

how to use 

  const setDatelist = usePostMutation();
   setFbColl.mutate(
      {
        path: productNoteDbPath,
        data: {
          note: addNote,
          date: pickerDate,
          hour: currency,
          customTimestamp: formatDateToTimestamp(pickerDate, currency),
        },
      },
      {
        onSuccess() {
          onClose();
        },
        onError() {
          Notify.notifyError("Error");
        },
      }
    );
*/
