import { useMutation } from "@tanstack/react-query";
import { fetchPost } from "apis/Post";

export const usePostMutation = function <T>() {
  const mutate = useMutation({
    mutationFn: ({ url, data }: { url: string; data: T }) =>
      fetchPost(data, url),
  });
  return mutate;
};

/* How to use 
  
    const setDatelist = usePostMutation();
     setFbColl.mutate(
        {
          url: "",
          data: {
            note: addNote,
            date: pickerDate,
            hour: currency,
            customTimestamp: formatDateToTimestamp(pickerDate, currency),
          },
        },
        {
          onSuccess() {
            onClose();
          },
          onError() {
            Notify.notifyError("Error");
          },
        }
      );
  */
