import { Divider, Stack } from "@mui/material";
import { IGraphifyOs } from "page/graphify/type";
import { useEffect, useState } from "react";
import CustomLoading from "components/loading/CustomLoading";
import NetworkTopCharts from "./components/NetworkTopCharts";
import GeneralList from "../GeneralComponents/GeneralList";
import { useTranslation } from "react-i18next";
import EmptyDataView from "page/general/EmptyDataView";

const NetworkDashboard = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<IGraphifyOs[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch("/data/graphifyOsData.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const rawData: IGraphifyOs[] = await response.json();
        const filteredData = rawData.filter((item) =>
          item.host_groups.some((group) => group.name.includes("network"))
        );

        setData(filteredData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Stack>
      {loading === true ? (
        <CustomLoading />
      ) : data.length !== 0 ? (
        <Stack gap={"24px"}>
          {/* Charts */}
          <NetworkTopCharts data={data} />
          {/* Charts */}
          <Divider variant="middle" orientation="horizontal" />
          {/* list  */}
          <GeneralList data={data} title={t("graphify.listNetworkTitle")} />
        </Stack>
      ) : (
        <EmptyDataView text={t("graphify.networkEmptyData")} />
      )}
    </Stack>
  );
};

export default NetworkDashboard;
