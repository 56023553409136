import {
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import React from "react";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useTheme } from "layouts/theme/ThemeContext";
import { BorderContainer } from "components/box/BorderContainer";

const ButtonContainer = styled(Button)<{ isSelect: boolean }>(
  ({ theme, isSelect }) => ({
    textTransform: "none",
    borderRadius: "8px",
    padding: "12px 12px",
    gap: "12px",
    color: isSelect ? theme.palette.success.main : theme.palette.primary.dark,
    border: isSelect ? "none" : ` 1px solid ${theme.palette.primary.main}`,

    "&:hover": {
      color: isSelect
        ? theme.palette.success.dark
        : theme.palette.primary.contrastText,
      backgroundColor: isSelect
        ? ""
        : ` ${alpha(theme.palette.primary.contrastText, 0.1)}  `,
    },
  })
);

const IntegrationList = () => {
  const { theme } = useTheme();

  const listArr = [
    {
      name: "Jira",
      subName: "Your subtitle comes here",
      img: "https://static-00.iconduck.com/assets.00/jira-icon-512x512-kkop6eik.png",
      status: false,
    },
    {
      name: "ServiceNow",
      subName: "Your subtitle comes here",
      img: "https://companieslogo.com/img/orig/NOW-6d5a01eb.png?t=1647966832",
      status: false,
    },
    {
      name: "ManageEngine ServiceDesk",
      subName: "Your subtitle comes here",
      img: "https://e7.pngegg.com/pngimages/144/53/png-clipart-green-logo-design-text-logo-thumbnail.png",
      status: false,
    },
    {
      name: "Zoho",
      subName: "Your subtitle comes here",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj-7LOgOpuB05gc_EgfpuruLO-2tk829U2VA&usqp=CAU",
      status: false,
    },
    {
      name: "Ivanti",
      subName: "Your subtitle comes here",
      img: "https://leadgenapp.io/wp-content/uploads/2022/03/d0ffe020388a2ac212953831c71b54c2.png",
      status: false,
    },
    {
      name: "Solarwinds ITSM",
      subName: "Your subtitle comes here",
      img: "https://cdn.brighttalk.com/ams/california/images/channel/11431/image_926397.png?width=300&height=300",
      status: false,
    },
    {
      name: "IBM ServiceDesk",
      subName: "Your subtitle comes here",
      img: "https://play-lh.googleusercontent.com/NEQFc1Mw0N_x5x_NB_b-K4mNNB0o_RaaRPJIJHURWBuzRMR73NV_S-iUmiiZomXS7g",
      status: false,
    },
  ];
  return (
    <Grid container spacing={2}>
      {listArr.map((item) => {
        return (
          <Grid
            key={`integration${item.name}`}
            sx={{ backgroundColor: "transparent" }}
            item
            xs={12}
            md={4}
          >
            <BorderContainer>
              <Stack
                width={"100%"}
                justifyContent={"flex-end"}
                direction={"row"}
              >
                <IconButton>
                  <AutorenewIcon fontSize="small" />
                </IconButton>
                <IconButton>
                  <MoreHorizIcon fontSize="small" />
                </IconButton>
              </Stack>
              <Stack
                width={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
                direction={"row"}
              >
                <img src={item.img} alt="" width={"64px"} height={"64px"} />
                <Stack>
                  {!item.status && (
                    <ButtonContainer isSelect={false}>
                      + Install
                    </ButtonContainer>
                  )}
                  {item.status && (
                    <ButtonContainer isSelect={true}>
                      <TaskAltIcon /> Installed{" "}
                    </ButtonContainer>
                  )}
                </Stack>
              </Stack>
              <Stack alignItems={"flex-start"} width={"100%"}>
                <Typography variant="subtitle2">{item.name}</Typography>
                <Typography variant="caption">{item.subName}</Typography>
              </Stack>
            </BorderContainer>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default IntegrationList;
