import { Divider, Stack } from "@mui/material";
import { IGraphifyOs } from "page/graphify/type";
import OsTopCharts from "./OsTopCharts";
import { useEffect, useState } from "react";
import CustomLoading from "components/loading/CustomLoading";
import GeneralList from "../GeneralComponents/GeneralList";
import { useTranslation } from "react-i18next";

const OsHostDashboard = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<IGraphifyOs[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch("/data/graphifyOsData.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const rawData: IGraphifyOs[] = await response.json();
        const filteredData = rawData.filter((item) =>
          item.host_groups.some((group) => !group.name.includes("network"))
        );

        setData(filteredData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, []);

  /* useEffect(() => {
    fetch("/data/graphifyOsData.json")
      .then((response) => response.json())
      .then((rawData) => {
        setData(rawData);
      })
      .then(() => {});
  }, []); */

  return (
    <Stack>
      {loading === true ? (
        <CustomLoading />
      ) : data.length !== 0 ? (
        <Stack gap={"24px"}>
          {/* Charts */}
          <OsTopCharts data={data} />
          {/* Charts */}
          <Divider variant="middle" orientation="horizontal" />
          {/* List  */}
          <GeneralList data={data} title={t("graphify.hostList")} />
          {/* List  */}
        </Stack>
      ) : (
        <CustomLoading />
      )}
    </Stack>
  );
};

export default OsHostDashboard;
