import {
  Button,
  IconButton,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { listArr } from "../data/data";
import { toImageUrl } from "utils/helpers/AssetHelpers";
import ForumIcon from "@mui/icons-material/Forum";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { useTheme } from "layouts/theme/ThemeContext";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";

export const CustomStyleButton = styled(Button)(({ theme }) => ({
  ...theme.typography.subtitle2,
  textTransform: "capitalize",
  borderRadius: "8px",
  padding: "6px 24px",
  color: theme.palette.background.paper,

  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
    lineHeight: "16px",
  },
}));

const MarketDetail = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const { t } = useTranslation();

  const { theme } = useTheme();
  return (
    <Stack gap={"36px"}>
      <Stack width={"100%"} alignItems={"flex-start"}>
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      </Stack>
      {listArr
        .filter((item) => item.id === id)
        .map((item, i) => {
          return (
            <Stack key={`marketDetail${i}`}>
              <Stack gap={"64px"} direction={"row"}>
                <Stack flex={2}>
                  <img
                    src={toImageUrl(item.img)}
                    alt=""
                    width={"100%"}
                    style={{
                      opacity: 0.7,
                    }}
                    height={"auto"}
                  />
                </Stack>
                <Stack gap={"32px"} flex={5}>
                  <Stack gap={"4px"}>
                    <Typography fontWeight={700} variant="h6">
                      {t(`marketPlace.${item.title}`)}
                    </Typography>
                    <Typography variant="body2">
                      {t(`marketPlace.${item.desc}`)}
                    </Typography>
                  </Stack>

                  <Stack gap="16px" direction={"row"}>
                    <CustomStyleButton
                      sx={{
                        backgroundColor: ` ${alpha(
                          theme.palette.warning.dark,
                          0.5
                        )}`,
                        "&:hover": {
                          color: theme.palette.primary.light,
                          backgroundColor: theme.palette.warning.dark,
                        },
                      }}
                    >
                      <DownloadingIcon
                        sx={{ marginRight: "4px", fontSize: "20px" }}
                      />
                      {t(`marketPlace.install`)}
                    </CustomStyleButton>
                    <CustomStyleButton
                      sx={{
                        backgroundColor: ` ${alpha(
                          theme.palette.success.dark,
                          0.5
                        )}`,
                        "&:hover": {
                          color: theme.palette.primary.light,
                          backgroundColor: theme.palette.success.dark,
                        },
                      }}
                    >
                      <ForumIcon
                        sx={{ marginRight: "4px", fontSize: "20px" }}
                      />
                      {t(`marketPlace.contact`)}
                    </CustomStyleButton>
                  </Stack>

                  {t(`marketPlace.${item.what}`) !== "" && (
                    <Stack gap={"6px"}>
                      <Typography fontWeight={700} variant="h6">
                        {t(`marketPlace.whatIs`)}
                      </Typography>
                      <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{
                          __html: t(`marketPlace.${item.what}`),
                        }}
                      />
                    </Stack>
                  )}
                  {t(`marketPlace.${item.howDoesItDo}`) !== "" && (
                    <Stack gap={"6px"}>
                      <Typography fontWeight={700} variant="h6">
                        {t(`marketPlace.whatDo`)}
                      </Typography>
                      <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{
                          __html: t(`marketPlace.${item.howDoesItDo}`),
                        }}
                      />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack>
          );
        })}
    </Stack>
  );
};

export default MarketDetail;
