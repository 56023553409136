import React from "react";
import { Stack, Typography } from "@mui/material";
import { BorderContainer } from "components/box/BorderContainer";

const AssociatedResources = () => {
  const associatedArr = [
    { name: "Azure Functions", value: 2 },
    { name: "Cosmos SOL Containers", value: 1 },
    { name: "Cosmos SQL Databases", value: 1 },
    { name: "Logic", value: 3 },
    { name: "Service Bus Queues", value: 1 },
  ];
  return (
    <BorderContainer>
      <Stack gap={"28px"} marginBottom={"11px"} width={"100%"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack alignItems={"center"} direction={"row"} gap={"8px"}>
            <Typography variant="subtitle2">Associated Resources </Typography>
            <Typography variant="subtitle2">|</Typography>
            <Typography variant="caption">8</Typography>
          </Stack>
          <Typography variant="caption">View All</Typography>
        </Stack>
        <Stack gap={"8px"}>
          {associatedArr.map((item) => {
            return (
              <Stack
                key={`associatedArr${item.name}`}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="caption">{item.name}</Typography>
                <Typography variant="subtitle2">{item.value}</Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </BorderContainer>
  );
};

export default AssociatedResources;
