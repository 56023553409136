import React from "react";
import { Stack, Typography } from "@mui/material";
import ChartWrapper from "components/eCharts/ChartWrapper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ColumnPlotCornerRadius from "components/chartTypes/ColumnPlotCornerRadius";
import DoughnutChartwithRoundEChart from "components/eCharts/DoughnutChartwithRoundEChart";
import StackedLineEChart from "components/eCharts/StackedLineEChartCustom";

const stackedLegendData = ["Last Month", "This Month"];
const stackedXAxisData = ["Mon", "Tue", "Wed", "Thu", "Fri"];
const stackedSeriesData1 = [150, 232, 201, 154, 190, 330, 410];
const stackedSeriesData2 = [220, 132, 201, 234, 190, 230, 220];
const stackedSeriesName1 = "Last Month";
const stackedSeriesName2 = "This Month";

const doughnutChartSeriesData = [
  { value: 1048, name: "Search Engine" },
  { value: 735, name: "Direct" },
  { value: 580, name: "Email" },
  { value: 484, name: "Union Ads" },
  { value: 300, name: "Video Ads" },
];

const TopProducts = () => {
  return (
    <Stack width={"100%"} direction={"row"} gap={"24px"}>
      <ChartWrapper
        children={
          <Stack width={"100%"} gap={"12px"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant="subtitle2">Total Revenue</Typography>{" "}
              <MoreHorizIcon />
            </Stack>
            <ColumnPlotCornerRadius />
          </Stack>
        }
      />
      <ChartWrapper
        children={
          <Stack width={"100%"} gap={"12px"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant="subtitle2">Customer Satisfaction</Typography>{" "}
              <MoreHorizIcon />
            </Stack>
            <StackedLineEChart
              legendData={stackedLegendData}
              seriesData1={stackedSeriesData1}
              seriesData2={stackedSeriesData2}
              seriesName1={stackedSeriesName1}
              seriesName2={stackedSeriesName2}
              xAxisData={stackedXAxisData}
            />
          </Stack>
        }
      />

      <ChartWrapper
        children={
          <Stack width={"100%"} gap={"12px"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant="subtitle2">Target vs Reality</Typography>{" "}
              <MoreHorizIcon />
            </Stack>
            <DoughnutChartwithRoundEChart
              seriesData={doughnutChartSeriesData}
            />
          </Stack>
        }
      />
    </Stack>
  );
};

export default TopProducts;
