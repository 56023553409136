import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Stack, Typography } from "@mui/material";
import { TableContiner } from "components/tableCom/components";
import CustomLoading from "components/loading/CustomLoading";
import { IFilterAlarms } from "../types/Type";
import { formatDateTime } from "utils/helpers/TimeFunc";
import { useTheme } from "layouts/theme/ThemeContext";
import { useTranslation } from "react-i18next";

interface HeadCell {
  id: string;
  label: string;
  align: "right" | "left" | "center" | "inherit" | "justify" | undefined;
}

const headCells: readonly HeadCell[] = [
  {
    id: "severity",
    label: "Severity",
    align: "left",
  },
  {
    id: "customer",
    label: "Customer",
    align: "left",
  },
  {
    id: "host",
    label: "Host",
    align: "left",
  },
  {
    id: "alertContent",
    label: "Content",
    align: "right",
  },
  {
    id: "created",
    label: "Created",
    align: "center",
  },
  {
    id: "action",
    label: "Actions",
    align: "center",
  },
];

const FilterAlarmTable = ({
  data,
  loading,
}: {
  data: IFilterAlarms[];
  loading: boolean;
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%", marginTop: "32px" }}>
      {loading ? (
        <CustomLoading />
      ) : (
        <TableContiner>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={`TableCell${headCell.id}`}
                      align={headCell.align}
                    >
                      <Typography variant="subtitle2">
                        {t(`filterManagement.table${headCell.label}`)}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        id={`host-table-checkbox-${index}`}
                        role="checkbox"
                        tabIndex={-1}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell align="center">
                          <Stack
                            sx={{
                              ...theme.typography.overline,
                              fontWeight: 700,
                              padding: "2px",
                              borderRadius: "10px",
                              width: "80px",
                              alignItems: "center",
                              fontSize: 9,
                              color: theme.palette.background.paper,
                              backgroundColor:
                                row.severity.toLowerCase() === "critical"
                                  ? "#B80D0D"
                                  : row.severity.toLowerCase() === "major"
                                  ? "#E65D4E"
                                  : row.severity.toLowerCase() === "minor"
                                  ? "#F78F4A"
                                  : row.severity.toLowerCase() === "warning"
                                  ? "#F7CD3B"
                                  : theme.palette.info.main,
                            }}
                          >
                            {row.severity}
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.customer_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.host}
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            maxWidth={"350px"}
                            fontSize={"12px"}
                            variant="body2"
                          >
                            {row.alert_content}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {formatDateTime(row.created)}{" "}
                        </TableCell>
                        <TableCell align="center">
                          <Stack
                            width={"100%"}
                            justifyContent={"center"}
                            direction={"row"}
                          >
                            {/*  <DeleteModal
                              text="You are about to delete this alarm."
                              onClick={() => {
                               mainStore.deleteAgentManagement({
                                  id: row.alert_id,
                                }); 
                              }}
                            /> */}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContiner>
      )}
    </Box>
  );
};
export default FilterAlarmTable;
