import React from 'react';
import { Column } from '@ant-design/plots';


const data =[
  {
      "city": "Mon",
      "type": "Online Sales",
      "value": 14500
  },
  {
      "city": "Tue",
      "type": "Online Sales",
      "value": 9000
  },
  {
      "city": "Wed",
      "type": "Online Sales",
      "value": 16000
  },
  {
      "city": "Thu",
      "type": "Online Sales",
      "value": 14000
  },
  {
      "city": "Fri",
      "type": "Online Sales",
      "value": 14000
  },
  {
      "city": "Sat",
      "type": "Online Sales",
      "value": 9000
  },
  {
      "city": "Sun",
      "type": "Online Sales",
      "value": 17000
  },
]
const ColumnPlotCornerRadius = () => {
 
  const config = {
  
    data,
    xField: 'city',
    yField: 'value',
    seriesField: 'type',
    isGroup: true,
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
  };

  return <Column  height={300} {...config} />;
};

export default ColumnPlotCornerRadius


