import { Stack } from "@mui/material";
import { toImageUrl } from "utils/helpers/AssetHelpers";

const UnderConstruction = () => {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={toImageUrl("/media/underConstruction/underConstruction.png")}
        alt=""
        style={{ width: "70%", height: "auto" }}
      />
    </Stack>
  );
};

export default UnderConstruction;
