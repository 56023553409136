// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltipCss {
  background-color: rgba(214, 115, 89, 0.5843137255);
  color: #000000;
  max-height: 280px;
  overflow-y: scroll;
}
.tooltipCss::-webkit-scrollbar {
  width: 4;
}
.tooltipCss::-webkit-scrollbar-thumb {
  border-radius: 10;
  background-color: t #525252;
}
.tooltipCss::-webkit-scrollbar-track {
  border-radius: 10;
  background-color: transparent;
}

.tooltipScroll {
  background-color: #d67359;
  max-height: 300px;
  overflow-y: auto;
}
.tooltipScroll::-webkit-scrollbar {
  width: 4;
}
.tooltipScroll::-webkit-scrollbar-thumb {
  border-radius: 10;
  background-color: t #525252;
}
.tooltipScroll::-webkit-scrollbar-track {
  border-radius: 10;
  background-color: transparent;
}

.slimAreaTooltipCss {
  padding: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/apexcharts/tooltipCss.scss"],"names":[],"mappings":"AAAA;EACE,kDAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;AACF;AAAE;EACE,QAAA;AAEJ;AAAE;EACE,iBAAA;EACA,2BAAA;AAEJ;AAAE;EACE,iBAAA;EACA,6BAAA;AAEJ;;AAEA;EACE,yBAAA;EACA,iBAAA;EACA,gBAAA;AACF;AACE;EACE,QAAA;AACJ;AACE;EACE,iBAAA;EACA,2BAAA;AACJ;AACE;EACE,iBAAA;EACA,6BAAA;AACJ;;AAGA;EACE,aAAA;AAAF","sourcesContent":[".tooltipCss {\n  background-color: #d6735995;\n  color: #000000;\n  max-height: 280px;\n  overflow-y: scroll;\n  &::-webkit-scrollbar {\n    width: 4;\n  }\n  &::-webkit-scrollbar-thumb {\n    border-radius: 10;\n    background-color: t#525252;\n  }\n  &::-webkit-scrollbar-track {\n    border-radius: 10;\n    background-color: transparent;\n  }\n}\n\n.tooltipScroll {\n  background-color: #d67359;\n  max-height: 300px;\n  overflow-y: auto;\n\n  &::-webkit-scrollbar {\n    width: 4;\n  }\n  &::-webkit-scrollbar-thumb {\n    border-radius: 10;\n    background-color: t#525252;\n  }\n  &::-webkit-scrollbar-track {\n    border-radius: 10;\n    background-color: transparent;\n  }\n}\n\n.slimAreaTooltipCss {\n  padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
