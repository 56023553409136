import React from "react";
import { Stack, Modal, styled, Box, Typography } from "@mui/material";
import CustomTooltip from "components/tooltip/tooltip";
import { CustomIconButton } from "components/buttons/CustomIconButton";
import { CancelButtonSyled } from "components/buttons/CancelButtonSyled";
import { useTheme } from "layouts/theme/ThemeContext";
import { formatDateTime } from "utils/helpers/TimeFunc";
import { IAlertsModel } from "page/graphify/type";

const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "48px",
  borderRadius: "16px",
  maxWidth: "720px",
  backgroundColor: theme.palette.background.default,
}));

const AlartDescModal = ({
  data,
  buttonTitle,
}: {
  data: IAlertsModel;
  buttonTitle: string;
}) => {
  const { theme } = useTheme();
  const [open, setOpen] = React.useState(false);

  return (
    <Stack>
      <CustomTooltip title={"Delete"}>
        <CustomIconButton
          sx={{
            fontSize: "13px",
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          {buttonTitle}
        </CustomIconButton>
      </CustomTooltip>

      {data && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalContent>
            <Stack gap={"16px"}>
              <AlartDescItem
                title={"customer name"}
                desc={data.customer_name}
              />
              <AlartDescItem title={"description"} desc={data.description} />
              <AlartDescItem
                title={"alert content"}
                desc={data.alert_content}
              />
              <Stack
                direction={"row"}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <AlartDescItem
                  title={"created"}
                  desc={formatDateTime(data.created)}
                />
                {data.updated && (
                  <AlartDescItem
                    title={"updated"}
                    desc={formatDateTime(data.updated)}
                  />
                )}
              </Stack>
              <Stack
                direction={"row"}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack
                  direction={"row"}
                  sx={{
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Typography minWidth={"90px"} noWrap variant="subtitle2">
                    severity :
                  </Typography>
                  <Stack
                    sx={{
                      ...theme.typography.overline,
                      fontWeight: 700,
                      padding: "2px",
                      borderRadius: "10px",
                      width: "80px",
                      alignItems: "center",
                      fontSize: 9,
                      color: theme.palette.background.paper,
                      backgroundColor:
                        data.severity.toLowerCase() === "critical"
                          ? "#B80D0D"
                          : data.severity.toLowerCase() === "major"
                          ? "#E65D4E"
                          : data.severity.toLowerCase() === "minor"
                          ? "#F78F4A"
                          : data.severity.toLowerCase() === "warning"
                          ? "#F7CD3B"
                          : theme.palette.info.main,
                    }}
                  >
                    {data.severity}
                  </Stack>
                </Stack>{" "}
                <Stack
                  direction={"row"}
                  sx={{
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Typography minWidth={"90px"} noWrap variant="subtitle2">
                    status :
                  </Typography>
                  {data.status === 0 ? (
                    <StatuItem
                      title={"open"}
                      color={"#398E4C"}
                      /*    icon={<LockOpenIcon sx={{ fontSize: "14px" }} />} */
                    />
                  ) : (
                    <StatuItem
                      title={"close"}
                      color={"#B80D0D"}
                      /*  icon={<LockIcon sx={{ fontSize: "14px" }} />} */
                    />
                  )}
                </Stack>
              </Stack>

              <AlartDescItem title={"host name"} desc={data.host} />
            </Stack>
            <Stack
              direction={"row"}
              width={"100%"}
              gap={"16px"}
              justifyContent={"flex-end"}
            >
              <CancelButtonSyled
                onClick={() => {
                  setOpen(false);
                }}
              >
                CANCEL
              </CancelButtonSyled>
              {/*     <DeleteButtonStyled
              onClick={() => {
                onClick();
                setOpenDelete(false);
              }}
            >
              DELETE
            </DeleteButtonStyled> */}
            </Stack>
          </ModalContent>
        </Modal>
      )}
    </Stack>
  );
};

export default AlartDescModal;

const AlartDescItem = ({ title, desc }: { desc: string; title: string }) => {
  return (
    <Stack
      direction={"row"}
      sx={{
        alignItems: "flex-start",
        flexDirection: "flex-start",
        gap: "4px",
      }}
    >
      <Typography minWidth={"90px"} noWrap variant="subtitle2">
        {title}:
      </Typography>
      <Typography fontWeight={400} variant="subtitle2">
        {desc}
      </Typography>
    </Stack>
  );
};

const StatuItem = ({
  title,
  color,
  icon,
}: {
  title: string;

  color: string;
  icon?: any;
}) => {
  return (
    <CustomTooltip title={title}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={"4px"}
        sx={{ color: color }}
      >
        {icon}
        <Typography
          sx={{ fontSize: "12px" }}
          fontWeight={700}
          variant="overline"
        >
          {title}
        </Typography>
      </Stack>
    </CustomTooltip>
  );
};
