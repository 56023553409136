export const alarmData = [
  {
    created: "2024-02-20T08:14:26.662593+03:00",
    updated: "2024-02-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-20T08:14:26.662593+03:00",
    updated: "2024-02-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-20T08:14:26.662593+03:00",
    updated: "2024-02-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-20T08:14:26.662593+03:00",
    updated: "2024-02-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-20T08:14:26.662593+03:00",
    updated: "2024-02-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-20T08:14:26.662593+03:00",
    updated: "2024-02-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-03-20T08:14:26.662593+03:00",
    updated: "2024-03-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-03-20T08:14:26.662593+03:00",
    updated: "2024-03-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-04-20T08:14:26.662593+03:00",
    updated: "2024-04-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-04-20T08:14:26.662593+03:00",
    updated: "2024-04-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-04-20T08:14:26.662593+03:00",
    updated: "2024-04-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-04-20T08:14:26.662593+03:00",
    updated: "2024-04-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-05-20T08:14:26.662593+03:00",
    updated: "2024-05-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-05-20T08:14:26.662593+03:00",
    updated: "2024-05-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-05-20T08:14:26.662593+03:00",
    updated: "2024-05-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-05-20T08:14:26.662593+03:00",
    updated: "2024-05-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-05-20T08:14:26.662593+03:00",
    updated: "2024-05-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-05-20T08:14:26.662593+03:00",
    updated: "2024-05-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-05-20T08:14:26.662593+03:00",
    updated: "2024-05-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-05-20T08:14:26.662593+03:00",
    updated: "2024-05-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-05-20T08:14:26.662593+03:00",
    updated: "2024-05-20T08:14:26.663153+03:00",
    customer: 16,
    alert_id: 393,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10603",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 05:13:35 on 2024.03.20\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 66004",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-03-02T20:45:38.557960+03:00",
    updated: "2024-03-02T20:45:38.558368+03:00",
    customer: 16,
    alert_id: 392,
    alert_content:
      "Problem: Linux: İsbi Backup Server has been restarted (uptime < 10m)",
    other_id: "10602",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 17:45:13 on 2024.03.02\r\nProblem name: Linux: İsbi Backup Server has been restarted (uptime < 10m)\r\nHost: İsbi Backup Server\r\nSeverity: Warning\r\nOperational data: 00:04:26\r\nOriginal problem ID: 65989",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-22T16:38:11.591551+03:00",
    updated: "2024-02-22T16:38:11.595193+03:00",
    customer: 16,
    alert_id: 391,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 13:37:59 on 2024.02.22\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.546875 8.137207 6.208984), # of CPUs: 4\r\nOriginal problem ID: 65986",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-16T01:37:33.734032+03:00",
    updated: "2024-02-16T01:37:33.734137+03:00",
    customer: 16,
    alert_id: 390,
    alert_content:
      "Resolved in 4m 0s: Linux: Zabbix agent is not available (for 3m)",
    other_id: "10604",
    status: 0,
    severity: "Average",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem has been resolved at 22:37:30 on 2024.02.15\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nProblem duration: 4m 0s\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Average\r\nOriginal problem ID: 65958",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-16T01:36:57.709271+03:00",
    updated: "2024-02-16T01:36:57.709327+03:00",
    customer: 16,
    alert_id: 389,
    alert_content: "Problem: ICMP: High ICMP ping loss",
    other_id: "10604",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem started at 22:36:32 on 2024.02.15\r\nProblem name: ICMP: High ICMP ping loss\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Warning\r\nOperational data: Loss: 33.33 %\r\nOriginal problem ID: 65968",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-16T01:36:55.511338+03:00",
    updated: "2024-02-16T01:36:55.511420+03:00",
    customer: 16,
    alert_id: 388,
    alert_content: "Resolved in 4m 0s: ICMP: Unavailable by ICMP ping",
    other_id: "10604",
    status: 0,
    severity: "High",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem has been resolved at 22:36:32 on 2024.02.15\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nProblem duration: 4m 0s\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: High\r\nOriginal problem ID: 65956",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-16T01:36:53.268692+03:00",
    updated: "2024-02-16T01:36:53.268831+03:00",
    customer: 16,
    alert_id: 387,
    alert_content: "Resolved in 3m 59s: ICMP: Unavailable by ICMP ping",
    other_id: "65957",
    status: 0,
    severity: "High",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem has been resolved at 22:36:34 on 2024.02.15\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nProblem duration: 3m 59s\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOriginal problem ID: 65957",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-16T01:36:51.067935+03:00",
    updated: "2024-02-16T01:36:51.068056+03:00",
    customer: 16,
    alert_id: 386,
    alert_content: "Problem: Linux: Zabbix agent is not available (for 3m)",
    other_id: "10618",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 22:34:32 on 2024.02.15\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Average\r\nOperational data: not available (0)\r\nOriginal problem ID: 65961",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-16T01:36:48.566022+03:00",
    updated: "2024-02-16T01:36:48.566084+03:00",
    customer: 16,
    alert_id: 385,
    alert_content: "Problem: Linux: Zabbix agent is not available (for 3m)",
    other_id: "65959",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 22:33:40 on 2024.02.15\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: Average\r\nOperational data: not available (0)\r\nOriginal problem ID: 65959",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-16T01:36:45.857165+03:00",
    updated: "2024-02-16T01:36:45.857257+03:00",
    customer: 16,
    alert_id: 384,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10618",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 22:33:44 on 2024.02.15\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 65960",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-15T23:50:09.138419+03:00",
    updated: "2024-02-15T23:50:09.138661+03:00",
    customer: 16,
    alert_id: 383,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:49:59 on 2024.02.15\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (10.291016 7.345215 3.94043), # of CPUs: 4\r\nOriginal problem ID: 65954",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-15T18:50:08.887672+03:00",
    updated: "2024-02-15T18:50:08.889162+03:00",
    customer: 16,
    alert_id: 382,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:58 on 2024.02.15\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (8.95752 7.951172 5.850586), # of CPUs: 4\r\nOriginal problem ID: 65952",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-13T04:51:08.319316+03:00",
    updated: "2024-02-13T04:51:08.319433+03:00",
    customer: 16,
    alert_id: 381,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 01:50:59 on 2024.02.13\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (10.332031 7.903809 5.863281), # of CPUs: 4\r\nOriginal problem ID: 65950",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-12T23:50:06.383137+03:00",
    updated: "2024-02-12T23:50:06.383352+03:00",
    customer: 16,
    alert_id: 380,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:49:58 on 2024.02.12\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (10.383301 8.72168 6.601562), # of CPUs: 4\r\nOriginal problem ID: 65948",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-12T18:50:08.114607+03:00",
    updated: "2024-02-12T18:50:08.114667+03:00",
    customer: 16,
    alert_id: 379,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:59 on 2024.02.12\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (10.791504 8.609375 6.560547), # of CPUs: 4\r\nOriginal problem ID: 65946",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-12T06:48:07.021191+03:00",
    updated: "2024-02-12T06:48:07.021307+03:00",
    customer: 16,
    alert_id: 378,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 03:47:58 on 2024.02.12\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (6.742188 7.114258 7.594238), # of CPUs: 4\r\nOriginal problem ID: 65944",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-12T04:50:04.694412+03:00",
    updated: "2024-02-12T04:50:04.695583+03:00",
    customer: 16,
    alert_id: 377,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 01:49:58 on 2024.02.12\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (10.94873 7.20166 5.874512), # of CPUs: 4\r\nOriginal problem ID: 65942",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-11T23:50:08.432267+03:00",
    updated: "2024-02-11T23:50:08.432419+03:00",
    customer: 16,
    alert_id: 376,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:49:58 on 2024.02.11\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (12.56543 8.842285 6.424316), # of CPUs: 4\r\nOriginal problem ID: 65940",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-11T20:11:09.771701+03:00",
    updated: "2024-02-11T20:11:09.771888+03:00",
    customer: 16,
    alert_id: 375,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 17:10:58 on 2024.02.11\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (6.87793 6.737793 7.541016), # of CPUs: 4\r\nOriginal problem ID: 65938",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-11T18:50:06.609516+03:00",
    updated: "2024-02-11T18:50:06.609650+03:00",
    customer: 16,
    alert_id: 374,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:58 on 2024.02.11\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (8.670898 7.131348 5.76709), # of CPUs: 4\r\nOriginal problem ID: 65936",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-11T04:50:06.813878+03:00",
    updated: "2024-02-11T04:50:06.813947+03:00",
    customer: 16,
    alert_id: 373,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 01:49:58 on 2024.02.11\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (8.809082 7.66748 5.670898), # of CPUs: 4\r\nOriginal problem ID: 65934",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-10T23:50:11.164039+03:00",
    updated: "2024-02-10T23:50:11.165425+03:00",
    customer: 16,
    alert_id: 372,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:49:58 on 2024.02.10\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (8.951172 7.818848 5.928223), # of CPUs: 4\r\nOriginal problem ID: 65932",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-10T18:50:09.250491+03:00",
    updated: "2024-02-10T18:50:09.252205+03:00",
    customer: 16,
    alert_id: 371,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:59 on 2024.02.10\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.925293 8.129883 5.929199), # of CPUs: 4\r\nOriginal problem ID: 65930",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-09T23:49:07.364569+03:00",
    updated: "2024-02-09T23:49:07.364687+03:00",
    customer: 16,
    alert_id: 370,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "65858",
    status: 1,
    severity: "10602",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:48:59 on 2024.02.09\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (8.197754 7.413574 5.997559), # of CPUs: 4\r\nOriginal problem ID: 65858",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-09T23:10:18.032601+03:00",
    updated: "2024-02-09T23:10:18.034438+03:00",
    customer: 16,
    alert_id: 369,
    alert_content: "Problem: /: Disk space is critically low (used > 90%)",
    other_id: "10618",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 20:10:09 on 2024.02.09\r\nProblem name: /: Disk space is critically low (used > 90%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Average\r\nOperational data: Space used: 202.17 GB of 216.97 GB (97.62 %)\r\nOriginal problem ID: 65740",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-09T18:49:10.064727+03:00",
    updated: "2024-02-09T18:49:10.065854+03:00",
    customer: 16,
    alert_id: 368,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:48:59 on 2024.02.09\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (12.460938 9.094238 6.655762), # of CPUs: 4\r\nOriginal problem ID: 65738",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-09T17:18:17.320455+03:00",
    updated: "2024-02-09T17:18:17.321933+03:00",
    customer: 16,
    alert_id: 367,
    alert_content: "Problem: /: Disk space is critically low (used > 90%)",
    other_id: "10618",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 14:18:09 on 2024.02.09\r\nProblem name: /: Disk space is critically low (used > 90%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Average\r\nOperational data: Space used: 199.25 GB of 216.97 GB (96.21 %)\r\nOriginal problem ID: 65568",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-09T11:06:19.312885+03:00",
    updated: "2024-02-09T11:06:19.312993+03:00",
    customer: 16,
    alert_id: 366,
    alert_content: "Problem: /: Disk space is critically low (used > 90%)",
    other_id: "10618",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 08:06:09 on 2024.02.09\r\nProblem name: /: Disk space is critically low (used > 90%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Average\r\nOperational data: Space used: 194.01 GB of 216.97 GB (93.68 %)\r\nOriginal problem ID: 65337",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-08T01:46:16.905640+03:00",
    updated: "2024-02-08T01:46:16.906678+03:00",
    customer: 16,
    alert_id: 365,
    alert_content: "Problem: /: Disk space is critically low (used > 90%)",
    other_id: "10618",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 22:46:09 on 2024.02.07\r\nProblem name: /: Disk space is critically low (used > 90%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Average\r\nOperational data: Space used: 193.99 GB of 216.97 GB (93.67 %)\r\nOriginal problem ID: 65312",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-07T23:50:07.845908+03:00",
    updated: "2024-02-07T23:50:07.846659+03:00",
    customer: 16,
    alert_id: 364,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:49:59 on 2024.02.07\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (8.635254 8.094727 6.493652), # of CPUs: 4\r\nOriginal problem ID: 65310",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-07T18:50:11.430521+03:00",
    updated: "2024-02-07T18:50:11.432082+03:00",
    customer: 16,
    alert_id: 363,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:59 on 2024.02.07\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (10.845215 9.035645 6.881836), # of CPUs: 4\r\nOriginal problem ID: 65223",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-07T18:24:16.552373+03:00",
    updated: "2024-02-07T18:24:16.552945+03:00",
    customer: 16,
    alert_id: 362,
    alert_content: "Problem: /: Disk space is critically low (used > 90%)",
    other_id: "10618",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 15:24:09 on 2024.02.07\r\nProblem name: /: Disk space is critically low (used > 90%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Average\r\nOperational data: Space used: 190.97 GB of 216.97 GB (92.21 %)\r\nOriginal problem ID: 65146",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-07T04:49:38.575589+03:00",
    updated: "2024-02-07T04:49:38.576576+03:00",
    customer: 16,
    alert_id: 361,
    alert_content: "Problem: ICMP: High ICMP ping response time",
    other_id: "10604",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem started at 01:49:32 on 2024.02.07\r\nProblem name: ICMP: High ICMP ping response time\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Warning\r\nOperational data: Value: 151ms\r\nOriginal problem ID: 65144",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-07T01:49:38.392222+03:00",
    updated: "2024-02-07T01:49:38.392290+03:00",
    customer: 16,
    alert_id: 360,
    alert_content: "Problem: ICMP: High ICMP ping response time",
    other_id: "10604",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem started at 22:49:32 on 2024.02.06\r\nProblem name: ICMP: High ICMP ping response time\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Warning\r\nOperational data: Value: 150ms\r\nOriginal problem ID: 65142",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-07T00:19:37.909395+03:00",
    updated: "2024-02-07T00:19:37.909525+03:00",
    customer: 16,
    alert_id: 359,
    alert_content: "Problem: ICMP: High ICMP ping response time",
    other_id: "10604",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem started at 21:19:32 on 2024.02.06\r\nProblem name: ICMP: High ICMP ping response time\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Warning\r\nOperational data: Value: 150ms\r\nOriginal problem ID: 65134",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-07T00:15:40.507382+03:00",
    updated: "2024-02-07T00:15:40.507458+03:00",
    customer: 16,
    alert_id: 358,
    alert_content: "Problem: ICMP: High ICMP ping response time",
    other_id: "10604",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem started at 21:15:32 on 2024.02.06\r\nProblem name: ICMP: High ICMP ping response time\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Warning\r\nOperational data: Value: 150ms\r\nOriginal problem ID: 65131",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-07T00:15:37.569175+03:00",
    updated: "2024-02-07T00:15:37.569306+03:00",
    customer: 16,
    alert_id: 357,
    alert_content: "Problem: Linux: Zabbix agent is not available (for 3m)",
    other_id: "10618",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 21:15:32 on 2024.02.06\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Average\r\nOperational data: not available (0)\r\nOriginal problem ID: 65130",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-07T00:14:53.205246+03:00",
    updated: "2024-02-07T00:14:53.206551+03:00",
    customer: 16,
    alert_id: 356,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10618",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 21:14:44 on 2024.02.06\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 65129",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-06T23:51:05.832237+03:00",
    updated: "2024-02-06T23:51:05.833710+03:00",
    customer: 16,
    alert_id: 355,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:50:59 on 2024.02.06\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.218262 7.901855 5.963379), # of CPUs: 4\r\nOriginal problem ID: 65127",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-04T23:49:02.609705+03:00",
    updated: "2024-02-04T23:49:02.609850+03:00",
    customer: 16,
    alert_id: 354,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:48:58 on 2024.02.04\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (10.068359 8.295898 6.604492), # of CPUs: 4\r\nOriginal problem ID: 65123",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-04T17:02:41.002208+03:00",
    updated: "2024-02-04T17:02:41.002309+03:00",
    customer: 16,
    alert_id: 353,
    alert_content:
      "Problem: Linux: Eska Cloud EU-01 Server has been restarted (uptime < 10m)",
    other_id: "65120",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem started at 14:02:36 on 2024.02.04\r\nProblem name: Linux: Eska Cloud EU-01 Server has been restarted (uptime < 10m)\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: Warning\r\nOperational data: 00:00:58\r\nOriginal problem ID: 65120",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-04T16:50:59.734254+03:00",
    updated: "2024-02-04T16:50:59.734339+03:00",
    customer: 16,
    alert_id: 352,
    alert_content:
      "Problem: Linux: Eska Cloud USA-01 Server has been restarted (uptime < 10m)",
    other_id: "65116",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem started at 13:50:57 on 2024.02.04\r\nProblem name: Linux: Eska Cloud USA-01 Server has been restarted (uptime < 10m)\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Warning\r\nOperational data: 00:01:08\r\nOriginal problem ID: 65116",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-04T16:50:35.983575+03:00",
    updated: "2024-02-04T16:50:35.983647+03:00",
    customer: 16,
    alert_id: 351,
    alert_content: "Problem: Linux: Zabbix agent is not available (for 3m)",
    other_id: "65114",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem started at 13:50:30 on 2024.02.04\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Average\r\nOperational data: not available (0)\r\nOriginal problem ID: 65114",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-04T16:49:39.466668+03:00",
    updated: "2024-02-04T16:49:39.467911+03:00",
    customer: 16,
    alert_id: 350,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "65113",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem started at 13:49:32 on 2024.02.04\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 65113",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-03T18:50:04.506793+03:00",
    updated: "2024-02-03T18:50:04.506917+03:00",
    customer: 16,
    alert_id: 349,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:59 on 2024.02.03\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (10.200684 8.425781 6.170898), # of CPUs: 4\r\nOriginal problem ID: 65111",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-02T18:50:07.309953+03:00",
    updated: "2024-02-02T18:50:07.311308+03:00",
    customer: 16,
    alert_id: 348,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:58 on 2024.02.02\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (12.157227 7.794922 6.169922), # of CPUs: 4\r\nOriginal problem ID: 65109",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-01T10:41:14.499925+03:00",
    updated: "2024-02-01T10:41:14.500086+03:00",
    customer: 16,
    alert_id: 347,
    alert_content: "Problem: /: Disk space is critically low (used > 90%)",
    other_id: "10618",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 07:41:09 on 2024.02.01\r\nProblem name: /: Disk space is critically low (used > 90%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Average\r\nOperational data: Space used: 187.5 GB of 216.97 GB (90.54 %)\r\nOriginal problem ID: 65106",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-02-01T10:37:12.849896+03:00",
    updated: "2024-02-01T10:37:12.850015+03:00",
    customer: 16,
    alert_id: 346,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "10618",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 07:37:09 on 2024.02.01\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Warning\r\nOperational data: Space used: 185.09 GB of 216.97 GB (89.38 %)\r\nOriginal problem ID: 65105",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-31T18:50:14.622128+03:00",
    updated: "2024-01-31T18:50:14.623035+03:00",
    customer: 16,
    alert_id: 345,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:58 on 2024.01.31\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.103027 8.146484 6.136719), # of CPUs: 4\r\nOriginal problem ID: 65103",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-30T22:49:20.587929+03:00",
    updated: "2024-01-30T22:49:20.588072+03:00",
    customer: 16,
    alert_id: 344,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "10618",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 19:49:09 on 2024.01.30\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Warning\r\nOperational data: Space used: 178.8 GB of 216.97 GB (86.34 %)\r\nOriginal problem ID: 65101",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-30T21:52:18.530052+03:00",
    updated: "2024-01-30T21:52:18.530145+03:00",
    customer: 16,
    alert_id: 343,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "10618",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 18:52:09 on 2024.01.30\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Warning\r\nOperational data: Space used: 175.03 GB of 216.97 GB (84.52 %)\r\nOriginal problem ID: 65099",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-30T20:23:16.202484+03:00",
    updated: "2024-01-30T20:23:16.202651+03:00",
    customer: 16,
    alert_id: 342,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "10618",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 17:23:09 on 2024.01.30\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Warning\r\nOperational data: Space used: 169.76 GB of 216.97 GB (81.97 %)\r\nOriginal problem ID: 65097",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-30T19:21:07.656648+03:00",
    updated: "2024-01-30T19:21:07.658038+03:00",
    customer: 16,
    alert_id: 341,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 16:20:58 on 2024.01.30\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.465332 7.305176 5.337402), # of CPUs: 4\r\nOriginal problem ID: 65095",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-29T23:50:07.979404+03:00",
    updated: "2024-01-29T23:50:07.979537+03:00",
    customer: 16,
    alert_id: 340,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:49:58 on 2024.01.29\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.151367 7.564453 5.542969), # of CPUs: 4\r\nOriginal problem ID: 65093",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-28T04:51:09.251500+03:00",
    updated: "2024-01-28T04:51:09.252409+03:00",
    customer: 16,
    alert_id: 339,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 01:50:58 on 2024.01.28\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (8.678223 7.740234 5.864258), # of CPUs: 4\r\nOriginal problem ID: 65091",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-27T23:50:07.222481+03:00",
    updated: "2024-01-27T23:50:07.222599+03:00",
    customer: 16,
    alert_id: 338,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:49:58 on 2024.01.27\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.768555 7.823242 5.854004), # of CPUs: 4\r\nOriginal problem ID: 65089",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-27T18:50:07.219592+03:00",
    updated: "2024-01-27T18:50:07.219704+03:00",
    customer: 16,
    alert_id: 337,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:59 on 2024.01.27\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (10.124023 7.760254 5.775391), # of CPUs: 4\r\nOriginal problem ID: 65087",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-26T14:47:45.779412+03:00",
    updated: "2024-01-26T14:47:45.779531+03:00",
    customer: 16,
    alert_id: 336,
    alert_content: "Problem: ICMP: High ICMP ping response time",
    other_id: "10604",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem started at 11:47:32 on 2024.01.26\r\nProblem name: ICMP: High ICMP ping response time\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Warning\r\nOperational data: Value: 134ms\r\nOriginal problem ID: 65085",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-25T15:01:04.732080+03:00",
    updated: "2024-01-25T15:01:04.732188+03:00",
    customer: 16,
    alert_id: 335,
    alert_content:
      "Problem: Linux: Eska Cloud TR-01 DT Server has been restarted (uptime < 10m)",
    other_id: "10618",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 12:00:58 on 2024.01.25\r\nProblem name: Linux: Eska Cloud TR-01 DT Server has been restarted (uptime < 10m)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Warning\r\nOperational data: 00:03:19\r\nOriginal problem ID: 65080",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-25T14:57:37.229348+03:00",
    updated: "2024-01-25T14:57:37.229421+03:00",
    customer: 16,
    alert_id: 334,
    alert_content: "Problem: Linux: Zabbix agent is not available (for 3m)",
    other_id: "10618",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 11:57:32 on 2024.01.25\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Average\r\nOperational data: not available (0)\r\nOriginal problem ID: 65078",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-25T14:56:54.633397+03:00",
    updated: "2024-01-25T14:56:54.633500+03:00",
    customer: 16,
    alert_id: 333,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10618",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 11:56:44 on 2024.01.25\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 65077",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-24T23:50:05.627734+03:00",
    updated: "2024-01-24T23:50:05.628425+03:00",
    customer: 16,
    alert_id: 332,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:49:58 on 2024.01.24\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (8.596191 7.199707 5.480957), # of CPUs: 4\r\nOriginal problem ID: 65075",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-23T17:31:16.850581+03:00",
    updated: "2024-01-23T17:31:16.850664+03:00",
    customer: 16,
    alert_id: 331,
    alert_content: "Problem: /: Disk space is critically low (used > 90%)",
    other_id: "10618",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 14:31:09 on 2024.01.23\r\nProblem name: /: Disk space is critically low (used > 90%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Average\r\nOperational data: Space used: 192.96 GB of 216.97 GB (93.18 %)\r\nOriginal problem ID: 65065",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-23T12:27:18.338783+03:00",
    updated: "2024-01-23T12:27:18.338895+03:00",
    customer: 16,
    alert_id: 330,
    alert_content: "Problem: /: Disk space is critically low (used > 90%)",
    other_id: "10618",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 09:27:09 on 2024.01.23\r\nProblem name: /: Disk space is critically low (used > 90%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Average\r\nOperational data: Space used: 189.16 GB of 216.97 GB (91.34 %)\r\nOriginal problem ID: 64894",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-23T12:13:02.035942+03:00",
    updated: "2024-01-23T12:13:02.036065+03:00",
    customer: 16,
    alert_id: 329,
    alert_content:
      "Problem: Linux: Eska Cloud TR-01 DT Server has been restarted (uptime < 10m)",
    other_id: "10618",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 09:12:58 on 2024.01.23\r\nProblem name: Linux: Eska Cloud TR-01 DT Server has been restarted (uptime < 10m)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Warning\r\nOperational data: 00:03:00\r\nOriginal problem ID: 64887",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-23T12:10:38.535676+03:00",
    updated: "2024-01-23T12:10:38.535744+03:00",
    customer: 16,
    alert_id: 328,
    alert_content: "Problem: Linux: Zabbix agent is not available (for 3m)",
    other_id: "10618",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 09:10:32 on 2024.01.23\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Average\r\nOperational data: not available (0)\r\nOriginal problem ID: 64885",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-23T12:09:55.240087+03:00",
    updated: "2024-01-23T12:09:55.240555+03:00",
    customer: 16,
    alert_id: 327,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10618",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 09:09:44 on 2024.01.23\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 64884",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-23T04:50:07.000388+03:00",
    updated: "2024-01-23T04:50:07.000548+03:00",
    customer: 16,
    alert_id: 326,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 01:49:59 on 2024.01.23\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (8.799805 7.61377 5.747559), # of CPUs: 4\r\nOriginal problem ID: 64882",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-22T23:50:06.785634+03:00",
    updated: "2024-01-22T23:50:06.785749+03:00",
    customer: 16,
    alert_id: 325,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:49:59 on 2024.01.22\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.352051 7.810547 5.819336), # of CPUs: 4\r\nOriginal problem ID: 64880",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-22T21:58:14.950828+03:00",
    updated: "2024-01-22T21:58:14.950884+03:00",
    customer: 16,
    alert_id: 324,
    alert_content: "Problem: /: Disk space is critically low (used > 90%)",
    other_id: "10618",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 18:58:09 on 2024.01.22\r\nProblem name: /: Disk space is critically low (used > 90%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Average\r\nOperational data: Space used: 186.77 GB of 216.97 GB (90.19 %)\r\nOriginal problem ID: 64877",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-22T21:18:19.390268+03:00",
    updated: "2024-01-22T21:18:19.390343+03:00",
    customer: 16,
    alert_id: 323,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "10618",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 18:18:09 on 2024.01.22\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Warning\r\nOperational data: Space used: 183.66 GB of 216.97 GB (88.69 %)\r\nOriginal problem ID: 64876",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-22T18:50:07.397220+03:00",
    updated: "2024-01-22T18:50:07.397305+03:00",
    customer: 16,
    alert_id: 322,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "64874",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:58 on 2024.01.22\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (11.946777 8.520508 6.021484), # of CPUs: 4\r\nOriginal problem ID: 64874",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-21T18:51:07.360364+03:00",
    updated: "2024-01-21T18:51:07.360459+03:00",
    customer: 16,
    alert_id: 321,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:50:59 on 2024.01.21\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (10.794922 8.479492 6.186035), # of CPUs: 4\r\nOriginal problem ID: 64872",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-21T04:50:07.970596+03:00",
    updated: "2024-01-21T04:50:07.970677+03:00",
    customer: 16,
    alert_id: 320,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 01:49:59 on 2024.01.21\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (10.669922 7.925293 5.743652), # of CPUs: 4\r\nOriginal problem ID: 64870",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-20T23:51:07.560343+03:00",
    updated: "2024-01-20T23:51:07.562085+03:00",
    customer: 16,
    alert_id: 319,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:50:58 on 2024.01.20\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.546875 8.015137 5.981934), # of CPUs: 4\r\nOriginal problem ID: 64868",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-20T18:50:06.478168+03:00",
    updated: "2024-01-20T18:50:06.479205+03:00",
    customer: 16,
    alert_id: 318,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:59 on 2024.01.20\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (11.358887 8.358887 6.02002), # of CPUs: 4\r\nOriginal problem ID: 64866",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-19T23:51:10.820845+03:00",
    updated: "2024-01-19T23:51:10.821782+03:00",
    customer: 16,
    alert_id: 317,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:50:59 on 2024.01.19\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (8.779297 7.79834 5.869141), # of CPUs: 4\r\nOriginal problem ID: 64864",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-19T18:01:21.290016+03:00",
    updated: "2024-01-19T18:01:21.297475+03:00",
    customer: 16,
    alert_id: 316,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "10618",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 15:01:09 on 2024.01.19\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Warning\r\nOperational data: Space used: 183.98 GB of 216.97 GB (88.84 %)\r\nOriginal problem ID: 64862",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-18T22:18:17.234369+03:00",
    updated: "2024-01-18T22:18:17.234471+03:00",
    customer: 16,
    alert_id: 315,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "10618",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 19:18:09 on 2024.01.18\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Warning\r\nOperational data: Space used: 179.39 GB of 216.97 GB (86.62 %)\r\nOriginal problem ID: 64860",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-18T19:42:07.561369+03:00",
    updated: "2024-01-18T19:42:07.561500+03:00",
    customer: 16,
    alert_id: 314,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 16:41:58 on 2024.01.18\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (6.077637 5.834961 5.091797), # of CPUs: 4\r\nOriginal problem ID: 64858",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-18T18:01:17.173442+03:00",
    updated: "2024-01-18T18:01:17.173585+03:00",
    customer: 16,
    alert_id: 313,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "10618",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 15:01:09 on 2024.01.18\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Warning\r\nOperational data: Space used: 179.23 GB of 216.97 GB (86.54 %)\r\nOriginal problem ID: 64856",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-16T23:50:05.764697+03:00",
    updated: "2024-01-16T23:50:05.764792+03:00",
    customer: 16,
    alert_id: 312,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:49:58 on 2024.01.16\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (11.399902 7.736816 4.674316), # of CPUs: 4\r\nOriginal problem ID: 64853",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-16T23:49:17.929531+03:00",
    updated: "2024-01-16T23:49:17.930018+03:00",
    customer: 16,
    alert_id: 311,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "10618",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 20:49:09 on 2024.01.16\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Warning\r\nOperational data: Space used: 171.7 GB of 216.97 GB (82.91 %)\r\nOriginal problem ID: 64852",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-16T18:55:05.792234+03:00",
    updated: "2024-01-16T18:55:05.793698+03:00",
    customer: 16,
    alert_id: 310,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:54:58 on 2024.01.16\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (6.243164 5.318359 3.483887), # of CPUs: 4\r\nOriginal problem ID: 64850",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-16T17:12:18.500863+03:00",
    updated: "2024-01-16T17:12:18.501052+03:00",
    customer: 16,
    alert_id: 309,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "10618",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 14:12:09 on 2024.01.16\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Warning\r\nOperational data: Space used: 168.61 GB of 216.97 GB (81.42 %)\r\nOriginal problem ID: 64848",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-15T21:46:17.022366+03:00",
    updated: "2024-01-15T21:46:17.023814+03:00",
    customer: 16,
    alert_id: 308,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "10618",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 18:46:10 on 2024.01.15\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Warning\r\nOperational data: Space used: 167.75 GB of 216.97 GB (81 %)\r\nOriginal problem ID: 64846",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-15T12:22:44.254713+03:00",
    updated: "2024-01-15T12:22:44.254775+03:00",
    customer: 16,
    alert_id: 307,
    alert_content:
      "Resolved in 22h 43m 0s: Linux: Zabbix agent is not available (for 3m)",
    other_id: "64831",
    status: 0,
    severity: "Average",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem has been resolved at 09:22:40 on 2024.01.15\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nProblem duration: 22h 43m 0s\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: Average\r\nOriginal problem ID: 64831",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-15T12:22:38.140382+03:00",
    updated: "2024-01-15T12:22:38.140479+03:00",
    customer: 16,
    alert_id: 306,
    alert_content:
      "Resolved in 22h 43m 0s: Linux: Zabbix agent is not available (for 3m)",
    other_id: "10618",
    status: 0,
    severity: "Average",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem has been resolved at 09:22:32 on 2024.01.15\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nProblem duration: 22h 43m 0s\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Average\r\nOriginal problem ID: 64830",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-15T12:22:35.266855+03:00",
    updated: "2024-01-15T12:22:35.266932+03:00",
    customer: 16,
    alert_id: 305,
    alert_content:
      "Resolved in 22h 43m 0s: Linux: Zabbix agent is not available (for 3m)",
    other_id: "10604",
    status: 0,
    severity: "Average",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem has been resolved at 09:22:30 on 2024.01.15\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nProblem duration: 22h 43m 0s\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Average\r\nOriginal problem ID: 64829",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-15T12:22:31.352085+03:00",
    updated: "2024-01-15T12:22:31.352176+03:00",
    customer: 16,
    alert_id: 304,
    alert_content: "Resolved in 22h 44m 0s: ICMP: Unavailable by ICMP ping",
    other_id: "10618",
    status: 0,
    severity: "High",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem has been resolved at 09:21:44 on 2024.01.15\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nProblem duration: 22h 44m 0s\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: High\r\nOriginal problem ID: 64828",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-15T12:21:46.253496+03:00",
    updated: "2024-01-15T12:21:46.253625+03:00",
    customer: 16,
    alert_id: 303,
    alert_content: "Resolved in 22h 43m 59s: ICMP: Unavailable by ICMP ping",
    other_id: "64827",
    status: 0,
    severity: "High",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem has been resolved at 09:21:34 on 2024.01.15\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nProblem duration: 22h 43m 59s\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: High\r\nOriginal problem ID: 64827",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-15T12:21:43.415392+03:00",
    updated: "2024-01-15T12:21:43.416497+03:00",
    customer: 16,
    alert_id: 302,
    alert_content: "Resolved in 22h 44m 0s: ICMP: Unavailable by ICMP ping",
    other_id: "10604",
    status: 0,
    severity: "High",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem has been resolved at 09:21:32 on 2024.01.15\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nProblem duration: 22h 44m 0s\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: High\r\nOriginal problem ID: 64826",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-11T11:58:06.634645+03:00",
    updated: "2024-01-11T11:58:06.634736+03:00",
    customer: 16,
    alert_id: 301,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 08:57:58 on 2024.01.11\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (7.935059 7.052246 5.563965), # of CPUs: 4\r\nOriginal problem ID: 64824",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-11T04:51:07.088270+03:00",
    updated: "2024-01-11T04:51:07.089283+03:00",
    customer: 16,
    alert_id: 300,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 01:50:58 on 2024.01.11\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.173828 7.717285 5.852539), # of CPUs: 4\r\nOriginal problem ID: 64822",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-11T00:40:07.832611+03:00",
    updated: "2024-01-11T00:40:07.832721+03:00",
    customer: 16,
    alert_id: 299,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 21:39:59 on 2024.01.10\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.375977 7.441406 5.901367), # of CPUs: 4\r\nOriginal problem ID: 64820",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-10T23:51:37.287493+03:00",
    updated: "2024-01-10T23:51:37.288900+03:00",
    customer: 16,
    alert_id: 298,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:50:59 on 2024.01.10\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.572754 8.068359 6.100586), # of CPUs: 4\r\nOriginal problem ID: 64818",
    alert_count: 1,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-10T19:27:08.939633+03:00",
    updated: "2024-01-10T19:27:08.939739+03:00",
    customer: 16,
    alert_id: 297,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 16:26:59 on 2024.01.10\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (12.143555 8.811035 5.939941), # of CPUs: 4\r\nOriginal problem ID: 64816",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-09T10:29:06.807660+03:00",
    updated: "2024-01-09T10:29:06.807760+03:00",
    customer: 16,
    alert_id: 296,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 07:28:59 on 2024.01.09\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (7.050781 6.199707 2.987793), # of CPUs: 4\r\nOriginal problem ID: 64814",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-08T22:10:08.980141+03:00",
    updated: "2024-01-08T22:10:08.980256+03:00",
    customer: 16,
    alert_id: 295,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 19:09:59 on 2024.01.08\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (8.845703 8.339844 6.259766), # of CPUs: 4\r\nOriginal problem ID: 64812",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-08T04:50:08.104331+03:00",
    updated: "2024-01-08T04:50:08.104475+03:00",
    customer: 16,
    alert_id: 294,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 01:49:58 on 2024.01.08\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.222656 8.163086 6.145508), # of CPUs: 4\r\nOriginal problem ID: 64808",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-07T23:50:07.785723+03:00",
    updated: "2024-01-07T23:50:07.785871+03:00",
    customer: 16,
    alert_id: 293,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:49:58 on 2024.01.07\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (10.717285 7.943359 5.879395), # of CPUs: 4\r\nOriginal problem ID: 64806",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-07T18:50:10.500910+03:00",
    updated: "2024-01-07T18:50:10.500954+03:00",
    customer: 16,
    alert_id: 292,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:59 on 2024.01.07\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.07373 8.091797 5.89209), # of CPUs: 4\r\nOriginal problem ID: 64804",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-07T04:50:07.839966+03:00",
    updated: "2024-01-07T04:50:07.840066+03:00",
    customer: 16,
    alert_id: 291,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 01:49:58 on 2024.01.07\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (8.465332 7.534668 5.885254), # of CPUs: 4\r\nOriginal problem ID: 64801",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-07T02:49:09.665620+03:00",
    updated: "2024-01-07T02:49:09.665750+03:00",
    customer: 16,
    alert_id: 290,
    alert_content:
      "Problem: Linux: Number of installed packages has been changed",
    other_id: "10602",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 23:49:03 on 2024.01.06\r\nProblem name: Linux: Number of installed packages has been changed\r\nHost: İsbi Backup Server\r\nSeverity: Warning\r\nOperational data: 493\r\nOriginal problem ID: 64800",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-06T23:50:06.769635+03:00",
    updated: "2024-01-06T23:50:06.769736+03:00",
    customer: 16,
    alert_id: 289,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:49:58 on 2024.01.06\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.371094 8.331055 6.192383), # of CPUs: 4\r\nOriginal problem ID: 64798",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-06T19:26:07.910715+03:00",
    updated: "2024-01-06T19:26:07.910971+03:00",
    customer: 16,
    alert_id: 288,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 16:25:59 on 2024.01.06\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.001465 7.753418 5.488281), # of CPUs: 4\r\nOriginal problem ID: 64796",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-05T04:50:07.817751+03:00",
    updated: "2024-01-05T04:50:07.817852+03:00",
    customer: 16,
    alert_id: 287,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 01:49:58 on 2024.01.05\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.414551 7.743652 5.888184), # of CPUs: 4\r\nOriginal problem ID: 64794",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-04T23:50:08.279898+03:00",
    updated: "2024-01-04T23:50:08.280050+03:00",
    customer: 16,
    alert_id: 286,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:49:59 on 2024.01.04\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.811523 7.380371 5.575195), # of CPUs: 4\r\nOriginal problem ID: 64792",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-04T18:50:07.857322+03:00",
    updated: "2024-01-04T18:50:07.857439+03:00",
    customer: 16,
    alert_id: 285,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:58 on 2024.01.04\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (10.193848 7.954102 5.722168), # of CPUs: 4\r\nOriginal problem ID: 64790",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-03T18:51:06.767171+03:00",
    updated: "2024-01-03T18:51:06.767330+03:00",
    customer: 16,
    alert_id: 284,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:50:58 on 2024.01.03\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (11.077148 8.473633 6.055664), # of CPUs: 4\r\nOriginal problem ID: 64788",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-03T04:50:07.039859+03:00",
    updated: "2024-01-03T04:50:07.039907+03:00",
    customer: 16,
    alert_id: 283,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 01:49:59 on 2024.01.03\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (8.836914 7.131348 5.432129), # of CPUs: 4\r\nOriginal problem ID: 64786",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-02T23:51:05.224154+03:00",
    updated: "2024-01-02T23:51:05.224255+03:00",
    customer: 16,
    alert_id: 282,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:50:58 on 2024.01.02\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (7.77832 7.496094 5.750977), # of CPUs: 4\r\nOriginal problem ID: 64784",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-02T18:51:08.445279+03:00",
    updated: "2024-01-02T18:51:08.445399+03:00",
    customer: 16,
    alert_id: 281,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:50:59 on 2024.01.02\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (10.939453 8.36084 6.004883), # of CPUs: 4\r\nOriginal problem ID: 64782",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2024-01-01T23:50:08.408222+03:00",
    updated: "2024-01-01T23:50:08.408307+03:00",
    customer: 16,
    alert_id: 280,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:49:59 on 2024.01.01\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (8.855957 7.374023 5.533203), # of CPUs: 4\r\nOriginal problem ID: 64780",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-31T15:13:43.041581+03:00",
    updated: "2023-12-31T15:13:43.041649+03:00",
    customer: 16,
    alert_id: 279,
    alert_content: "Problem: ICMP: High ICMP ping response time",
    other_id: "10604",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem started at 12:13:32 on 2023.12.31\r\nProblem name: ICMP: High ICMP ping response time\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Warning\r\nOperational data: Value: 281ms\r\nOriginal problem ID: 64778",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-31T15:00:41.984874+03:00",
    updated: "2023-12-31T15:00:41.984971+03:00",
    customer: 16,
    alert_id: 278,
    alert_content: "Problem: ICMP: High ICMP ping response time",
    other_id: "10604",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem started at 12:00:32 on 2023.12.31\r\nProblem name: ICMP: High ICMP ping response time\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Warning\r\nOperational data: Value: 287ms\r\nOriginal problem ID: 64776",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-31T10:51:42.378578+03:00",
    updated: "2023-12-31T10:51:42.378670+03:00",
    customer: 16,
    alert_id: 277,
    alert_content: "Problem: ICMP: High ICMP ping response time",
    other_id: "10604",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem started at 07:51:32 on 2023.12.31\r\nProblem name: ICMP: High ICMP ping response time\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Warning\r\nOperational data: Value: 286ms\r\nOriginal problem ID: 64774",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-29T03:59:39.759059+03:00",
    updated: "2023-12-29T03:59:39.759101+03:00",
    customer: 16,
    alert_id: 276,
    alert_content: "Problem: Linux: Zabbix agent is not available (for 3m)",
    other_id: "10618",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 00:59:32 on 2023.12.29\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Average\r\nOperational data: not available (0)\r\nOriginal problem ID: 64769",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-29T03:57:55.790576+03:00",
    updated: "2023-12-29T03:57:55.790702+03:00",
    customer: 16,
    alert_id: 275,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10618",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 00:57:44 on 2023.12.29\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 64768",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-29T00:03:56.460542+03:00",
    updated: "2023-12-29T00:03:56.460673+03:00",
    customer: 16,
    alert_id: 274,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10618",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 21:03:44 on 2023.12.28\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 64764",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-28T22:00:56.039851+03:00",
    updated: "2023-12-28T22:00:56.039994+03:00",
    customer: 16,
    alert_id: 273,
    alert_content: "Problem: ICMP: Unavailable by ICMP ping",
    other_id: "10618",
    status: 1,
    severity: "High",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 19:00:44 on 2023.12.28\r\nProblem name: ICMP: Unavailable by ICMP ping\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 64762",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-27T21:28:07.584103+03:00",
    updated: "2023-12-27T21:28:07.584336+03:00",
    customer: 16,
    alert_id: 272,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 18:27:58 on 2023.12.27\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (8.791504 7.768555 6.258789), # of CPUs: 4\r\nOriginal problem ID: 64760",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-27T18:50:06.444440+03:00",
    updated: "2023-12-27T18:50:06.444550+03:00",
    customer: 16,
    alert_id: 271,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:58 on 2023.12.27\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (6.513672 6.963379 5.409668), # of CPUs: 4\r\nOriginal problem ID: 64758",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-26T18:50:09.102942+03:00",
    updated: "2023-12-26T18:50:09.103076+03:00",
    customer: 16,
    alert_id: 270,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:59 on 2023.12.26\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.848145 7.944824 6.005371), # of CPUs: 4\r\nOriginal problem ID: 64744",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-25T23:50:07.710129+03:00",
    updated: "2023-12-25T23:50:07.710240+03:00",
    customer: 16,
    alert_id: 269,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 20:49:58 on 2023.12.25\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (8.064453 7.282227 5.369629), # of CPUs: 4\r\nOriginal problem ID: 64742",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-25T18:50:07.487189+03:00",
    updated: "2023-12-25T18:50:07.487321+03:00",
    customer: 16,
    alert_id: 268,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:58 on 2023.12.25\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (7.758789 7.392578 5.562988), # of CPUs: 4\r\nOriginal problem ID: 64740",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-25T10:14:06.123746+03:00",
    updated: "2023-12-25T10:14:06.123835+03:00",
    customer: 16,
    alert_id: 267,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 07:13:58 on 2023.12.25\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (12.121094 8.312988 5.927246), # of CPUs: 4\r\nOriginal problem ID: 64737",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-24T18:50:08.468815+03:00",
    updated: "2023-12-24T18:50:08.468883+03:00",
    customer: 16,
    alert_id: 266,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 15:49:59 on 2023.12.24\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (9.922852 8.437988 6.18457), # of CPUs: 4\r\nOriginal problem ID: 63815",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-24T11:35:49.274918+03:00",
    updated: "2023-12-24T11:35:49.275019+03:00",
    customer: 16,
    alert_id: 265,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "63367",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 08:35:45 on 2023.12.24\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.52 ms, 110.47 ms\r\nOriginal problem ID: 63367",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-24T11:34:17.739077+03:00",
    updated: "2023-12-24T11:34:17.739198+03:00",
    customer: 16,
    alert_id: 264,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "10618",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud TR-01 DT Server",
    description:
      "Problem started at 08:34:09 on 2023.12.24\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR-01 DT Server\r\nSeverity: Warning\r\nOperational data: Space used: 90.21 GB of 97.87 GB (97.15 %)\r\nOriginal problem ID: 63354",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-24T09:12:38.974360+03:00",
    updated: "2023-12-24T09:12:38.974447+03:00",
    customer: 16,
    alert_id: 263,
    alert_content: "Problem: Linux: Zabbix agent is not available (for 3m)",
    other_id: "10604",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem started at 06:12:30 on 2023.12.24\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Average\r\nOperational data: not available (0)\r\nOriginal problem ID: 63202",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-24T07:29:54.215181+03:00",
    updated: "2023-12-24T07:29:54.215509+03:00",
    customer: 16,
    alert_id: 262,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "63097",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 04:29:45 on 2023.12.24\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 2.47 ms, 88.16 ms\r\nOriginal problem ID: 63097",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-24T02:10:57.710517+03:00",
    updated: "2023-12-24T02:10:57.711877+03:00",
    customer: 16,
    alert_id: 261,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "62776",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:10:45 on 2023.12.23\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.62 ms, 72.46 ms\r\nOriginal problem ID: 62776",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-23T02:37:01.761986+03:00",
    updated: "2023-12-23T02:37:01.762085+03:00",
    customer: 16,
    alert_id: 260,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "61360",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:36:45 on 2023.12.22\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.79 ms, 63.35 ms\r\nOriginal problem ID: 61360",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-22T02:36:54.335625+03:00",
    updated: "2023-12-22T02:36:54.335761+03:00",
    customer: 16,
    alert_id: 259,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "59918",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:36:45 on 2023.12.21\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.99 ms, 192.22 ms\r\nOriginal problem ID: 59918",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-21T02:36:54.667884+03:00",
    updated: "2023-12-21T02:36:54.667956+03:00",
    customer: 16,
    alert_id: 258,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "58476",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:36:45 on 2023.12.20\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.69 ms, 71.49 ms\r\nOriginal problem ID: 58476",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-20T16:56:35.832580+03:00",
    updated: "2023-12-20T16:56:35.832687+03:00",
    customer: 16,
    alert_id: 257,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "57892",
    status: 1,
    severity: "Average",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 13:56:31 on 2023.12.20\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (50.47 39.7 23.16), # of CPUs: 16\r\nOriginal problem ID: 57892",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-20T16:56:31.232608+03:00",
    updated: "2023-12-20T16:56:31.232673+03:00",
    customer: 16,
    alert_id: 256,
    alert_content: "Problem: Linux: High swap space usage (less than 50% free)",
    other_id: "57890",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 13:56:26 on 2023.12.20\r\nProblem name: Linux: High swap space usage (less than 50% free)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Free: 31.94 %, total: 32 GB\r\nOriginal problem ID: 57890",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-20T16:53:52.973782+03:00",
    updated: "2023-12-20T16:53:52.973849+03:00",
    customer: 16,
    alert_id: 255,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "57881",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 13:53:45 on 2023.12.20\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 211.1 ms, 290 ms\r\nOriginal problem ID: 57881",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-20T16:46:21.513021+03:00",
    updated: "2023-12-20T16:46:21.513146+03:00",
    customer: 16,
    alert_id: 254,
    alert_content: "Problem: Linux: High memory utilization (>90% for 5m)",
    other_id: "57871",
    status: 1,
    severity: "Average",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 13:46:12 on 2023.12.20\r\nProblem name: Linux: High memory utilization (>90% for 5m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Average\r\nOperational data: 92.84 %\r\nOriginal problem ID: 57871",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-20T02:37:53.585862+03:00",
    updated: "2023-12-20T02:37:53.585963+03:00",
    customer: 16,
    alert_id: 253,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "57021",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:37:45 on 2023.12.19\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 6.61 ms, 85.72 ms\r\nOriginal problem ID: 57021",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-19T02:34:53.596823+03:00",
    updated: "2023-12-19T02:34:53.596880+03:00",
    customer: 16,
    alert_id: 252,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "55576",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:34:45 on 2023.12.18\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 7.42 ms, 127.2 ms\r\nOriginal problem ID: 55576",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-18T08:47:36.722214+03:00",
    updated: "2023-12-18T08:47:36.722304+03:00",
    customer: 16,
    alert_id: 251,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "54505",
    status: 1,
    severity: "Average",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 05:47:31 on 2023.12.18\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (29.59 20.79 9.57), # of CPUs: 16\r\nOriginal problem ID: 54505",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-18T08:41:57.611115+03:00",
    updated: "2023-12-18T08:41:57.611176+03:00",
    customer: 16,
    alert_id: 250,
    alert_content:
      "Problem: Linux: Eska Cloud TR Server has been restarted (uptime < 10m)",
    other_id: "54493",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 05:41:50 on 2023.12.18\r\nProblem name: Linux: Eska Cloud TR Server has been restarted (uptime < 10m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 00:01:31\r\nOriginal problem ID: 54493",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-18T08:36:57.711357+03:00",
    updated: "2023-12-18T08:36:57.711508+03:00",
    customer: 16,
    alert_id: 249,
    alert_content: "Problem: Linux: Zabbix agent is not available (for 3m)",
    other_id: "54487",
    status: 1,
    severity: "Average",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 05:36:50 on 2023.12.18\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Average\r\nOperational data: not available (0)\r\nOriginal problem ID: 54487",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-18T08:20:51.475932+03:00",
    updated: "2023-12-18T08:20:51.476008+03:00",
    customer: 16,
    alert_id: 248,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "54442",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 05:20:45 on 2023.12.18\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 180.09 ms, 275.63 ms\r\nOriginal problem ID: 54442",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-18T08:15:38.520793+03:00",
    updated: "2023-12-18T08:15:38.520923+03:00",
    customer: 16,
    alert_id: 247,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "54426",
    status: 1,
    severity: "Average",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 05:15:32 on 2023.12.18\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (48.78 31 16.74), # of CPUs: 16\r\nOriginal problem ID: 54426",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-18T08:09:21.761461+03:00",
    updated: "2023-12-18T08:09:21.761640+03:00",
    customer: 16,
    alert_id: 246,
    alert_content: "Problem: Linux: High memory utilization (>90% for 5m)",
    other_id: "54413",
    status: 1,
    severity: "Average",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 05:09:12 on 2023.12.18\r\nProblem name: Linux: High memory utilization (>90% for 5m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Average\r\nOperational data: 96.12 %\r\nOriginal problem ID: 54413",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-18T02:34:54.490267+03:00",
    updated: "2023-12-18T02:34:54.490394+03:00",
    customer: 16,
    alert_id: 245,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "54077",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:34:45 on 2023.12.17\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 6.05 ms, 109.92 ms\r\nOriginal problem ID: 54077",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-17T02:35:18.627455+03:00",
    updated: "2023-12-17T02:35:18.627618+03:00",
    customer: 16,
    alert_id: 244,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "52635",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:34:46 on 2023.12.16\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 6.91 ms, 137.62 ms\r\nOriginal problem ID: 52635",
    alert_count: 1,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-16T02:39:50.903281+03:00",
    updated: "2023-12-16T02:39:50.903422+03:00",
    customer: 16,
    alert_id: 243,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "51198",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:39:45 on 2023.12.15\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 8.27 ms, 78.47 ms\r\nOriginal problem ID: 51198",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-16T02:05:52.439974+03:00",
    updated: "2023-12-16T02:05:52.440077+03:00",
    customer: 16,
    alert_id: 242,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "51162",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:05:46 on 2023.12.15\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 7.16 ms, 117.9 ms\r\nOriginal problem ID: 51162",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-15T02:37:51.917390+03:00",
    updated: "2023-12-15T02:37:51.917486+03:00",
    customer: 16,
    alert_id: 241,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "49752",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:37:45 on 2023.12.14\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 11.95 ms, 179.64 ms\r\nOriginal problem ID: 49752",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-14T02:39:50.561063+03:00",
    updated: "2023-12-14T02:39:50.561159+03:00",
    customer: 16,
    alert_id: 240,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "48777",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:39:45 on 2023.12.13\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 6.08 ms, 120.44 ms\r\nOriginal problem ID: 48777",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-13T12:39:38.156191+03:00",
    updated: "2023-12-13T12:39:38.156239+03:00",
    customer: 16,
    alert_id: 239,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "47932",
    status: 1,
    severity: "Average",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 09:39:32 on 2023.12.13\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (29.87 32.91 23.11), # of CPUs: 16\r\nOriginal problem ID: 47932",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-13T12:28:55.085709+03:00",
    updated: "2023-12-13T12:28:55.085757+03:00",
    customer: 16,
    alert_id: 238,
    alert_content: "Problem: Linux: High swap space usage (less than 50% free)",
    other_id: "47919",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 09:28:48 on 2023.12.13\r\nProblem name: Linux: High swap space usage (less than 50% free)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Free: 40.22 %, total: 32 GB\r\nOriginal problem ID: 47919",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-13T12:28:40.091061+03:00",
    updated: "2023-12-13T12:28:40.091108+03:00",
    customer: 16,
    alert_id: 237,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "47918",
    status: 1,
    severity: "Average",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 09:28:32 on 2023.12.13\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (38.63 28.81 14.74), # of CPUs: 16\r\nOriginal problem ID: 47918",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-13T12:23:50.762934+03:00",
    updated: "2023-12-13T12:23:50.762990+03:00",
    customer: 16,
    alert_id: 236,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "47911",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 09:23:46 on 2023.12.13\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.91 ms, 98.62 ms\r\nOriginal problem ID: 47911",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-13T12:21:20.758744+03:00",
    updated: "2023-12-13T12:21:20.758854+03:00",
    customer: 16,
    alert_id: 235,
    alert_content: "Problem: Linux: High memory utilization (>90% for 5m)",
    other_id: "47907",
    status: 1,
    severity: "Average",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 09:21:12 on 2023.12.13\r\nProblem name: Linux: High memory utilization (>90% for 5m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Average\r\nOperational data: 93.16 %\r\nOriginal problem ID: 47907",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-13T07:49:07.753154+03:00",
    updated: "2023-12-13T07:49:07.753201+03:00",
    customer: 16,
    alert_id: 234,
    alert_content:
      "Problem: Linux: Number of installed packages has been changed",
    other_id: "10602",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 04:49:03 on 2023.12.13\r\nProblem name: Linux: Number of installed packages has been changed\r\nHost: İsbi Backup Server\r\nSeverity: Warning\r\nOperational data: 495\r\nOriginal problem ID: 47634",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-13T02:34:54.295030+03:00",
    updated: "2023-12-13T02:34:54.295137+03:00",
    customer: 16,
    alert_id: 233,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "47317",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:34:46 on 2023.12.12\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 7.46 ms, 140.32 ms\r\nOriginal problem ID: 47317",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-12T14:49:10.349999+03:00",
    updated: "2023-12-12T14:49:10.350076+03:00",
    customer: 16,
    alert_id: 232,
    alert_content:
      "Problem: Linux: Number of installed packages has been changed",
    other_id: "10602",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 11:49:03 on 2023.12.12\r\nProblem name: Linux: Number of installed packages has been changed\r\nHost: İsbi Backup Server\r\nSeverity: Warning\r\nOperational data: 500\r\nOriginal problem ID: 46606",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-12T14:03:33.081254+03:00",
    updated: "2023-12-12T14:03:33.081300+03:00",
    customer: 16,
    alert_id: 231,
    alert_content: "Problem: Linux: High swap space usage (less than 50% free)",
    other_id: "46556",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 11:03:26 on 2023.12.12\r\nProblem name: Linux: High swap space usage (less than 50% free)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Free: 30.15 %, total: 32 GB\r\nOriginal problem ID: 46556",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-12T14:01:55.781045+03:00",
    updated: "2023-12-12T14:01:55.781248+03:00",
    customer: 16,
    alert_id: 230,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "46552",
    status: 1,
    severity: "Average",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 11:01:49 on 2023.12.12\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (121.62 58.19 24.87), # of CPUs: 16\r\nOriginal problem ID: 46552",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-12T13:52:51.433313+03:00",
    updated: "2023-12-12T13:52:51.433445+03:00",
    customer: 16,
    alert_id: 229,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "46541",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 10:52:45 on 2023.12.12\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 21.24 ms, 288.2 ms\r\nOriginal problem ID: 46541",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-12T13:51:24.524870+03:00",
    updated: "2023-12-12T13:51:24.524976+03:00",
    customer: 16,
    alert_id: 228,
    alert_content: "Problem: Linux: High memory utilization (>90% for 5m)",
    other_id: "46538",
    status: 1,
    severity: "Average",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 10:51:12 on 2023.12.12\r\nProblem name: Linux: High memory utilization (>90% for 5m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Average\r\nOperational data: 91.08 %\r\nOriginal problem ID: 46538",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-12T02:35:51.800995+03:00",
    updated: "2023-12-12T02:35:51.801097+03:00",
    customer: 16,
    alert_id: 227,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "45861",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:35:45 on 2023.12.11\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 6.55 ms, 63 ms\r\nOriginal problem ID: 45861",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-11T16:33:51.825241+03:00",
    updated: "2023-12-11T16:33:51.825348+03:00",
    customer: 16,
    alert_id: 226,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "45249",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 13:33:46 on 2023.12.11\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 6.11 ms, 70.57 ms\r\nOriginal problem ID: 45249",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-11T02:33:52.537924+03:00",
    updated: "2023-12-11T02:33:52.538027+03:00",
    customer: 16,
    alert_id: 225,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "44407",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:33:45 on 2023.12.10\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.88 ms, 83.39 ms\r\nOriginal problem ID: 44407",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-10T02:33:54.161266+03:00",
    updated: "2023-12-10T02:33:54.162682+03:00",
    customer: 16,
    alert_id: 224,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "42965",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:33:45 on 2023.12.09\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 6.2 ms, 157.9 ms\r\nOriginal problem ID: 42965",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-09T02:34:50.323731+03:00",
    updated: "2023-12-09T02:34:50.323854+03:00",
    customer: 16,
    alert_id: 223,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "41524",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:34:46 on 2023.12.08\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 8.05 ms, 127.92 ms\r\nOriginal problem ID: 41524",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-08T02:33:52.524992+03:00",
    updated: "2023-12-08T02:33:52.525661+03:00",
    customer: 16,
    alert_id: 222,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "40081",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:33:45 on 2023.12.07\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 9.18 ms, 58.2 ms\r\nOriginal problem ID: 40081",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-07T02:37:52.996751+03:00",
    updated: "2023-12-07T02:37:52.996854+03:00",
    customer: 16,
    alert_id: 221,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "38643",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:37:45 on 2023.12.06\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 7.66 ms, 146.81 ms\r\nOriginal problem ID: 38643",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-06T02:32:52.002208+03:00",
    updated: "2023-12-06T02:32:52.002333+03:00",
    customer: 16,
    alert_id: 220,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "37196",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:32:45 on 2023.12.05\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 6.84 ms, 45.63 ms\r\nOriginal problem ID: 37196",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-05T02:35:52.188286+03:00",
    updated: "2023-12-05T02:35:52.188922+03:00",
    customer: 16,
    alert_id: 219,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "35757",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:35:45 on 2023.12.04\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 7.01 ms, 179.99 ms\r\nOriginal problem ID: 35757",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-04T16:09:51.794129+03:00",
    updated: "2023-12-04T16:09:51.794188+03:00",
    customer: 16,
    alert_id: 218,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "35129",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 13:09:45 on 2023.12.04\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 10.64 ms, 143.3 ms\r\nOriginal problem ID: 35129",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-04T15:59:19.957525+03:00",
    updated: "2023-12-04T15:59:19.957632+03:00",
    customer: 16,
    alert_id: 217,
    alert_content: "Problem: Linux: High memory utilization (>90% for 5m)",
    other_id: "35116",
    status: 1,
    severity: "Average",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 12:59:12 on 2023.12.04\r\nProblem name: Linux: High memory utilization (>90% for 5m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Average\r\nOperational data: 94.44 %\r\nOriginal problem ID: 35116",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-04T10:05:04.407378+03:00",
    updated: "2023-12-04T10:05:04.407506+03:00",
    customer: 16,
    alert_id: 216,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "10602",
    status: 1,
    severity: "Average",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 07:04:58 on 2023.12.04\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: İsbi Backup Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (13.602539 8.162109 3.935547), # of CPUs: 4\r\nOriginal problem ID: 34757",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-04T10:01:32.919459+03:00",
    updated: "2023-12-04T10:01:32.919561+03:00",
    customer: 16,
    alert_id: 215,
    alert_content: "Problem: Linux: High swap space usage (less than 50% free)",
    other_id: "34752",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 07:01:26 on 2023.12.04\r\nProblem name: Linux: High swap space usage (less than 50% free)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Free: 37.95 %, total: 32 GB\r\nOriginal problem ID: 34752",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-04T10:00:38.847118+03:00",
    updated: "2023-12-04T10:00:38.847169+03:00",
    customer: 16,
    alert_id: 214,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "34751",
    status: 1,
    severity: "Average",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 07:00:32 on 2023.12.04\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (76.38 50.04 25.77), # of CPUs: 16\r\nOriginal problem ID: 34751",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-04T09:57:19.158598+03:00",
    updated: "2023-12-04T09:57:19.158638+03:00",
    customer: 16,
    alert_id: 213,
    alert_content: "Problem: Linux: High memory utilization (>90% for 5m)",
    other_id: "34745",
    status: 1,
    severity: "Average",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 06:57:12 on 2023.12.04\r\nProblem name: Linux: High memory utilization (>90% for 5m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Average\r\nOperational data: 94.02 %\r\nOriginal problem ID: 34745",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-04T09:50:52.271140+03:00",
    updated: "2023-12-04T09:50:52.271183+03:00",
    customer: 16,
    alert_id: 212,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "34738",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 06:50:45 on 2023.12.04\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 34.23 ms, 243.19 ms\r\nOriginal problem ID: 34738",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-04T02:42:52.893835+03:00",
    updated: "2023-12-04T02:42:52.894285+03:00",
    customer: 16,
    alert_id: 211,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "34308",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:42:45 on 2023.12.03\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.55 ms, 78.79 ms\r\nOriginal problem ID: 34308",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-03T02:40:51.882557+03:00",
    updated: "2023-12-03T02:40:51.882615+03:00",
    customer: 16,
    alert_id: 210,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "32864",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:40:45 on 2023.12.02\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.59 ms, 66.22 ms\r\nOriginal problem ID: 32864",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-02T11:34:49.414507+03:00",
    updated: "2023-12-02T11:34:49.414644+03:00",
    customer: 16,
    alert_id: 209,
    alert_content:
      "Problem: Linux: İsbi Backup Server has been restarted (uptime < 10m)",
    other_id: "10602",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 08:34:43 on 2023.12.02\r\nProblem name: Linux: İsbi Backup Server has been restarted (uptime < 10m)\r\nHost: İsbi Backup Server\r\nSeverity: Warning\r\nOperational data: 00:04:24\r\nOriginal problem ID: 31946",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-02T09:28:20.920750+03:00",
    updated: "2023-12-02T09:28:20.920813+03:00",
    customer: 16,
    alert_id: 208,
    alert_content:
      "Problem: Linux: İsbi Backup Server has been restarted (uptime < 10m)",
    other_id: "10602",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 06:28:13 on 2023.12.02\r\nProblem name: Linux: İsbi Backup Server has been restarted (uptime < 10m)\r\nHost: İsbi Backup Server\r\nSeverity: Warning\r\nOperational data: 00:05:13\r\nOriginal problem ID: 31927",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-02T02:45:52.642130+03:00",
    updated: "2023-12-02T02:45:52.642263+03:00",
    customer: 16,
    alert_id: 207,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "31839",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:45:45 on 2023.12.01\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 6.06 ms, 84.32 ms\r\nOriginal problem ID: 31839",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-01T11:01:18.306532+03:00",
    updated: "2023-12-01T11:01:18.306643+03:00",
    customer: 16,
    alert_id: 206,
    alert_content:
      "Problem: Linux: İsbi Backup Server has been restarted (uptime < 10m)",
    other_id: "10602",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 08:01:13 on 2023.12.01\r\nProblem name: Linux: İsbi Backup Server has been restarted (uptime < 10m)\r\nHost: İsbi Backup Server\r\nSeverity: Warning\r\nOperational data: 00:04:20\r\nOriginal problem ID: 30882",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-01T10:40:22.002307+03:00",
    updated: "2023-12-01T10:40:22.002354+03:00",
    customer: 16,
    alert_id: 205,
    alert_content:
      "Problem: Linux: İsbi Backup Server has been restarted (uptime < 10m)",
    other_id: "10602",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 07:40:13 on 2023.12.01\r\nProblem name: Linux: İsbi Backup Server has been restarted (uptime < 10m)\r\nHost: İsbi Backup Server\r\nSeverity: Warning\r\nOperational data: 00:04:28\r\nOriginal problem ID: 30864",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-12-01T02:42:52.196166+03:00",
    updated: "2023-12-01T02:42:52.196281+03:00",
    customer: 16,
    alert_id: 204,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "30783",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:42:45 on 2023.11.30\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.74 ms, 98.01 ms\r\nOriginal problem ID: 30783",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-30T16:09:53.421202+03:00",
    updated: "2023-11-30T16:09:53.421282+03:00",
    customer: 16,
    alert_id: 203,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "30133",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 13:09:46 on 2023.11.30\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 2.32 ms, 44.91 ms\r\nOriginal problem ID: 30133",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-30T16:03:52.203685+03:00",
    updated: "2023-11-30T16:03:52.203800+03:00",
    customer: 16,
    alert_id: 202,
    alert_content:
      "Problem: Linux: İsbi Backup Server has been restarted (uptime < 10m)",
    other_id: "10602",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 13:03:43 on 2023.11.30\r\nProblem name: Linux: İsbi Backup Server has been restarted (uptime < 10m)\r\nHost: İsbi Backup Server\r\nSeverity: Warning\r\nOperational data: 00:04:20\r\nOriginal problem ID: 30129",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-30T03:34:45.392823+03:00",
    updated: "2023-11-30T03:34:45.392885+03:00",
    customer: 16,
    alert_id: 201,
    alert_content:
      "Resolved in 20m 0s: Linux: Zabbix agent is not available (for 3m)",
    other_id: "29992",
    status: 0,
    severity: "Average",
    resource: "",
    host: "Eska Cloud USA-01 Server",
    description:
      "Problem has been resolved at 00:34:40 on 2023.11.30\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nProblem duration: 20m 0s\r\nHost: Eska Cloud EU-01 Server\r\nSeverity: Average\r\nOriginal problem ID: 29992",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-30T03:34:35.996178+03:00",
    updated: "2023-11-30T03:34:35.996222+03:00",
    customer: 16,
    alert_id: 200,
    alert_content:
      "Resolved in 20m 0s: Linux: Zabbix agent is not available (for 3m)",
    other_id: "10604",
    status: 0,
    severity: "Average",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem has been resolved at 00:34:30 on 2023.11.30\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nProblem duration: 20m 0s\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Average\r\nOriginal problem ID: 29991",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-30T03:33:57.561112+03:00",
    updated: "2023-11-30T03:33:57.561211+03:00",
    customer: 16,
    alert_id: 199,
    alert_content:
      "Resolved in 19m 0s: Linux: Zabbix agent is not available (for 3m)",
    other_id: "29993",
    status: 0,
    severity: "Average",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem has been resolved at 00:33:50 on 2023.11.30\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nProblem duration: 19m 0s\r\nHost: Eska Cloud TR Server\r\nSeverity: Average\r\nOriginal problem ID: 29993",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-30T02:42:51.612933+03:00",
    updated: "2023-11-30T02:42:51.613016+03:00",
    customer: 16,
    alert_id: 198,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "29958",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:42:45 on 2023.11.29\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.97 ms, 123.59 ms\r\nOriginal problem ID: 29958",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-30T00:41:52.653140+03:00",
    updated: "2023-11-30T00:41:52.653254+03:00",
    customer: 16,
    alert_id: 197,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "29835",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 21:41:45 on 2023.11.29\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 7.77 ms, 95.69 ms\r\nOriginal problem ID: 29835",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-29T03:34:50.724875+03:00",
    updated: "2023-11-29T03:34:50.724927+03:00",
    customer: 16,
    alert_id: 196,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "28565",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 00:34:45 on 2023.11.29\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 6.05 ms, 134.43 ms\r\nOriginal problem ID: 28565",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-29T03:24:46.262677+03:00",
    updated: "2023-11-29T03:24:46.262775+03:00",
    customer: 16,
    alert_id: 195,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "28553",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 00:24:27 on 2023.11.29\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80.21 %)\r\nOriginal problem ID: 28553",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-29T02:41:54.333732+03:00",
    updated: "2023-11-29T02:41:54.333837+03:00",
    customer: 16,
    alert_id: 194,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "28509",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:41:45 on 2023.11.28\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 4.84 ms, 68.24 ms\r\nOriginal problem ID: 28509",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-28T03:58:34.734203+03:00",
    updated: "2023-11-28T03:58:34.734498+03:00",
    customer: 16,
    alert_id: 193,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "27142",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 00:58:27 on 2023.11.28\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80.07 %)\r\nOriginal problem ID: 27142",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-28T02:39:53.523385+03:00",
    updated: "2023-11-28T02:39:53.523515+03:00",
    customer: 16,
    alert_id: 192,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "27063",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:39:46 on 2023.11.27\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.66 ms, 98.06 ms\r\nOriginal problem ID: 27063",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-27T03:57:34.334553+03:00",
    updated: "2023-11-27T03:57:34.334669+03:00",
    customer: 16,
    alert_id: 191,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "25697",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 00:57:27 on 2023.11.27\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80.17 %)\r\nOriginal problem ID: 25697",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-27T02:38:52.909463+03:00",
    updated: "2023-11-27T02:38:52.909587+03:00",
    customer: 16,
    alert_id: 190,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "25618",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:38:45 on 2023.11.26\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 8.94 ms, 140.15 ms\r\nOriginal problem ID: 25618",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-26T03:53:33.601891+03:00",
    updated: "2023-11-26T03:53:33.602009+03:00",
    customer: 16,
    alert_id: 189,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "24249",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 00:53:27 on 2023.11.26\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.23 TB of 1.61 TB (80.45 %)\r\nOriginal problem ID: 24249",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-26T02:24:52.395941+03:00",
    updated: "2023-11-26T02:24:52.396110+03:00",
    customer: 16,
    alert_id: 188,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "24160",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:24:45 on 2023.11.25\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 8.13 ms, 169.61 ms\r\nOriginal problem ID: 24160",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-25T04:05:39.421772+03:00",
    updated: "2023-11-25T04:05:39.421868+03:00",
    customer: 16,
    alert_id: 187,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "22817",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 01:05:27 on 2023.11.25\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80.01 %)\r\nOriginal problem ID: 22817",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-25T02:38:51.888111+03:00",
    updated: "2023-11-25T02:38:51.888206+03:00",
    customer: 16,
    alert_id: 186,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "22730",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:38:45 on 2023.11.24\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 2.29 ms, 101.13 ms\r\nOriginal problem ID: 22730",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-24T04:20:34.920043+03:00",
    updated: "2023-11-24T04:20:34.921585+03:00",
    customer: 16,
    alert_id: 185,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "21388",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 01:20:27 on 2023.11.24\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80.02 %)\r\nOriginal problem ID: 21388",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-24T02:38:52.933811+03:00",
    updated: "2023-11-24T02:38:52.933960+03:00",
    customer: 16,
    alert_id: 184,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "21286",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:38:45 on 2023.11.23\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 11.29 ms, 159.4 ms\r\nOriginal problem ID: 21286",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-23T05:12:33.993021+03:00",
    updated: "2023-11-23T05:12:33.993120+03:00",
    customer: 16,
    alert_id: 183,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "19996",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 02:12:27 on 2023.11.23\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80.01 %)\r\nOriginal problem ID: 19996",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-23T02:37:51.825095+03:00",
    updated: "2023-11-23T02:37:51.825211+03:00",
    customer: 16,
    alert_id: 182,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "19841",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:37:46 on 2023.11.22\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 4.99 ms, 144.72 ms\r\nOriginal problem ID: 19841",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-22T02:39:51.799775+03:00",
    updated: "2023-11-22T02:39:51.799908+03:00",
    customer: 16,
    alert_id: 181,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "18401",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:39:45 on 2023.11.21\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 4.86 ms, 73.64 ms\r\nOriginal problem ID: 18401",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-21T02:38:53.225918+03:00",
    updated: "2023-11-21T02:38:53.225965+03:00",
    customer: 16,
    alert_id: 180,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "16958",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:38:45 on 2023.11.20\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 4.86 ms, 60.24 ms\r\nOriginal problem ID: 16958",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-20T03:27:33.227793+03:00",
    updated: "2023-11-20T03:27:33.227846+03:00",
    customer: 16,
    alert_id: 179,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "15563",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 00:27:27 on 2023.11.20\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80.18 %)\r\nOriginal problem ID: 15563",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-20T03:19:34.010486+03:00",
    updated: "2023-11-20T03:19:34.010605+03:00",
    customer: 16,
    alert_id: 178,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "15553",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 00:19:27 on 2023.11.20\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80.02 %)\r\nOriginal problem ID: 15553",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-20T02:35:51.404158+03:00",
    updated: "2023-11-20T02:35:51.404244+03:00",
    customer: 16,
    alert_id: 177,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "15509",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:35:46 on 2023.11.19\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 6.45 ms, 116.76 ms\r\nOriginal problem ID: 15509",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-19T03:44:34.775202+03:00",
    updated: "2023-11-19T03:44:34.775299+03:00",
    customer: 16,
    alert_id: 176,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "14132",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 00:44:27 on 2023.11.19\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80 %)\r\nOriginal problem ID: 14132",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-19T02:43:51.872231+03:00",
    updated: "2023-11-19T02:43:51.872354+03:00",
    customer: 16,
    alert_id: 175,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "14071",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:43:46 on 2023.11.18\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.49 ms, 56.73 ms\r\nOriginal problem ID: 14071",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-19T00:27:37.976320+03:00",
    updated: "2023-11-19T00:27:37.976406+03:00",
    customer: 16,
    alert_id: 174,
    alert_content:
      "Problem: Linux: Load average is too high (per CPU load over 1.5 for 5m)",
    other_id: "13933",
    status: 1,
    severity: "Average",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 21:27:31 on 2023.11.18\r\nProblem name: Linux: Load average is too high (per CPU load over 1.5 for 5m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Average\r\nOperational data: Load averages(1m 5m 15m): (28.8 19.52 9.77), # of CPUs: 16\r\nOriginal problem ID: 13933",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-18T14:07:01.778091+03:00",
    updated: "2023-11-18T14:07:01.778144+03:00",
    customer: 16,
    alert_id: 173,
    alert_content:
      "Problem: Linux: Eska Cloud USA-01 Server has been restarted (uptime < 10m)",
    other_id: "10604",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem started at 11:06:56 on 2023.11.18\r\nProblem name: Linux: Eska Cloud USA-01 Server has been restarted (uptime < 10m)\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Warning\r\nOperational data: 00:01:42\r\nOriginal problem ID: 13307",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-18T14:06:36.412158+03:00",
    updated: "2023-11-18T14:06:36.412253+03:00",
    customer: 16,
    alert_id: 172,
    alert_content: "Problem: Linux: Zabbix agent is not available (for 3m)",
    other_id: "10604",
    status: 1,
    severity: "Average",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem started at 11:06:30 on 2023.11.18\r\nProblem name: Linux: Zabbix agent is not available (for 3m)\r\nHost: Eska Cloud USA-01 Server\r\nSeverity: Average\r\nOperational data: not available (0)\r\nOriginal problem ID: 13306",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-18T13:09:51.836842+03:00",
    updated: "2023-11-18T13:09:51.836967+03:00",
    customer: 16,
    alert_id: 171,
    alert_content:
      "Problem: Linux: İsbi Backup Server has been restarted (uptime < 10m)",
    other_id: "10602",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "isbi Backup Server",
    description:
      "Problem started at 10:09:43 on 2023.11.18\r\nProblem name: Linux: İsbi Backup Server has been restarted (uptime < 10m)\r\nHost: İsbi Backup Server\r\nSeverity: Warning\r\nOperational data: 00:04:31\r\nOriginal problem ID: 13237",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-18T03:57:36.711303+03:00",
    updated: "2023-11-18T03:57:36.711422+03:00",
    customer: 16,
    alert_id: 170,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "13123",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 00:57:27 on 2023.11.18\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.23 TB of 1.61 TB (80.76 %)\r\nOriginal problem ID: 13123",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-18T02:41:53.998120+03:00",
    updated: "2023-11-18T02:41:53.998210+03:00",
    customer: 16,
    alert_id: 169,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "13047",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:41:46 on 2023.11.17\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 4.99 ms, 74.82 ms\r\nOriginal problem ID: 13047",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-17T11:40:01.978210+03:00",
    updated: "2023-11-17T11:40:01.978325+03:00",
    customer: 16,
    alert_id: 168,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "12143",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 08:39:45 on 2023.11.17\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 6.02 ms, 21.14 ms\r\nOriginal problem ID: 12143",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-17T03:52:32.442390+03:00",
    updated: "2023-11-17T03:52:32.442435+03:00",
    customer: 16,
    alert_id: 167,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "11672",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 00:52:27 on 2023.11.17\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80.3 %)\r\nOriginal problem ID: 11672",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-17T02:39:51.748454+03:00",
    updated: "2023-11-17T02:39:51.748555+03:00",
    customer: 16,
    alert_id: 166,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "11599",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:39:46 on 2023.11.16\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 8.02 ms, 160.94 ms\r\nOriginal problem ID: 11599",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-17T02:15:36.301703+03:00",
    updated: "2023-11-17T02:15:36.301789+03:00",
    customer: 16,
    alert_id: 165,
    alert_content: "Problem: Website Error: URL rejected: Bad hostname",
    other_id: "11573",
    status: 0,
    severity: "Average",
    resource: "",
    host: "stage.octet.com.tr",
    description:
      "Problem started at 23:15:24 on 2023.11.16\r\nProblem name: Website Error: URL rejected: Bad hostname\r\nHost: stage.octet.com.tr Odoo Website\r\nSeverity: Average\r\nOperational data: 1, URL rejected: Bad hostname\r\nOriginal problem ID: 11573",
    alert_count: 1,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-17T02:15:34.489417+03:00",
    updated: "2023-11-17T02:15:34.489463+03:00",
    customer: 16,
    alert_id: 164,
    alert_content: "Problem: Website Error: URL rejected: Bad hostname",
    other_id: "11572",
    status: 0,
    severity: "Average",
    resource: "",
    host: "smartfume.com",
    description:
      "Problem started at 23:15:23 on 2023.11.16\r\nProblem name: Website Error: URL rejected: Bad hostname\r\nHost: Smartfume Odoo Website\r\nSeverity: Average\r\nOperational data: 1, URL rejected: Bad hostname\r\nOriginal problem ID: 11572",
    alert_count: 1,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-17T02:15:32.736008+03:00",
    updated: "2023-11-17T02:15:32.736062+03:00",
    customer: 16,
    alert_id: 163,
    alert_content: "Problem: Website Error: URL rejected: Bad hostname",
    other_id: "11571",
    status: 0,
    severity: "Average",
    resource: "",
    host: "erp.myrobothink.com",
    description:
      "Problem started at 23:15:22 on 2023.11.16\r\nProblem name: Website Error: URL rejected: Bad hostname\r\nHost: Robothink Odoo Website\r\nSeverity: Average\r\nOperational data: 1, URL rejected: Bad hostname\r\nOriginal problem ID: 11571",
    alert_count: 1,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-17T02:15:30.862289+03:00",
    updated: "2023-11-17T02:15:30.862347+03:00",
    customer: 16,
    alert_id: 162,
    alert_content: "Problem: Website Error: URL rejected: Bad hostname",
    other_id: "11570",
    status: 0,
    severity: "Average",
    resource: "",
    host: "odoobay.com",
    description:
      "Problem started at 23:15:21 on 2023.11.16\r\nProblem name: Website Error: URL rejected: Bad hostname\r\nHost: odoobay Odoo Website\r\nSeverity: Average\r\nOperational data: 1, URL rejected: Bad hostname\r\nOriginal problem ID: 11570",
    alert_count: 1,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-17T02:15:28.889067+03:00",
    updated: "2023-11-17T02:15:28.889123+03:00",
    customer: 16,
    alert_id: 161,
    alert_content: "Problem: Website Error: URL rejected: Bad hostname",
    other_id: "11569",
    status: 0,
    severity: "Average",
    resource: "",
    host: "odoo.dt.net.tr",
    description:
      "Problem started at 23:15:20 on 2023.11.16\r\nProblem name: Website Error: URL rejected: Bad hostname\r\nHost: odoo.dt.net.tr Odoo Website\r\nSeverity: Average\r\nOperational data: 1, URL rejected: Bad hostname\r\nOriginal problem ID: 11569",
    alert_count: 1,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-17T02:15:27.314832+03:00",
    updated: "2023-11-17T02:15:27.314895+03:00",
    customer: 16,
    alert_id: 160,
    alert_content: "Problem: Website Error: URL rejected: Bad hostname",
    other_id: "10604",
    status: 0,
    severity: "Average",
    resource: "",
    host: "Eska Cloud EU-01 Server",
    description:
      "Problem started at 23:15:19 on 2023.11.16\r\nProblem name: Website Error: URL rejected: Bad hostname\r\nHost: ocramps.Eska Cloud EU-01 Server Odoo Website\r\nSeverity: Average\r\nOperational data: 1, URL rejected: Bad hostname\r\nOriginal problem ID: 11568",
    alert_count: 1,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-17T02:15:25.117565+03:00",
    updated: "2023-11-17T02:15:25.117625+03:00",
    customer: 16,
    alert_id: 159,
    alert_content: "Problem: Website Error: URL rejected: Bad hostname",
    other_id: "11567",
    status: 0,
    severity: "Average",
    resource: "",
    host: "erp.gemininyc.com",
    description:
      "Problem started at 23:15:18 on 2023.11.16\r\nProblem name: Website Error: URL rejected: Bad hostname\r\nHost: erp.gemininyc Odoo Website\r\nSeverity: Average\r\nOperational data: 1, URL rejected: Bad hostname\r\nOriginal problem ID: 11567",
    alert_count: 1,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-17T02:15:22.808277+03:00",
    updated: "2023-11-17T02:15:22.809272+03:00",
    customer: 16,
    alert_id: 158,
    alert_content: "Problem: Website Error: URL rejected: Bad hostname",
    other_id: "11566",
    status: 0,
    severity: "Average",
    resource: "",
    host: "b2b.charismaoptic.com",
    description:
      "Problem started at 23:15:17 on 2023.11.16\r\nProblem name: Website Error: URL rejected: Bad hostname\r\nHost: b2b.charismaoptic Odoo Website\r\nSeverity: Average\r\nOperational data: 1, URL rejected: Bad hostname\r\nOriginal problem ID: 11566",
    alert_count: 1,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-16T04:10:35.299254+03:00",
    updated: "2023-11-16T04:10:35.299367+03:00",
    customer: 16,
    alert_id: 157,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "10200",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 01:10:27 on 2023.11.16\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80.01 %)\r\nOriginal problem ID: 10200",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-16T02:44:52.788548+03:00",
    updated: "2023-11-16T02:44:52.789327+03:00",
    customer: 16,
    alert_id: 156,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "10114",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:44:46 on 2023.11.15\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.15 ms, 56.47 ms\r\nOriginal problem ID: 10114",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-15T03:54:32.862949+03:00",
    updated: "2023-11-15T03:54:32.863040+03:00",
    customer: 16,
    alert_id: 155,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "8740",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 00:54:27 on 2023.11.15\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.23 TB of 1.61 TB (80.46 %)\r\nOriginal problem ID: 8740",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-15T02:39:55.614439+03:00",
    updated: "2023-11-15T02:39:55.614560+03:00",
    customer: 16,
    alert_id: 154,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "8665",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:39:46 on 2023.11.14\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 6.17 ms, 117.93 ms\r\nOriginal problem ID: 8665",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-14T05:02:53.818987+03:00",
    updated: "2023-11-14T05:02:53.819092+03:00",
    customer: 16,
    alert_id: 153,
    alert_content: "Problem: Linux: High swap space usage (less than 50% free)",
    other_id: "7364",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 02:02:48 on 2023.11.14\r\nProblem name: Linux: High swap space usage (less than 50% free)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Free: 49.96 %, total: 32 GB\r\nOriginal problem ID: 7364",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-14T04:46:31.288429+03:00",
    updated: "2023-11-14T04:46:31.288516+03:00",
    customer: 16,
    alert_id: 152,
    alert_content: "Problem: Linux: High swap space usage (less than 50% free)",
    other_id: "7345",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 01:46:26 on 2023.11.14\r\nProblem name: Linux: High swap space usage (less than 50% free)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Free: 49.98 %, total: 32 GB\r\nOriginal problem ID: 7345",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-14T04:35:54.275886+03:00",
    updated: "2023-11-14T04:35:54.275999+03:00",
    customer: 16,
    alert_id: 151,
    alert_content: "Problem: Linux: High swap space usage (less than 50% free)",
    other_id: "7333",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 01:35:48 on 2023.11.14\r\nProblem name: Linux: High swap space usage (less than 50% free)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Free: 49.99 %, total: 32 GB\r\nOriginal problem ID: 7333",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-14T04:06:32.761385+03:00",
    updated: "2023-11-14T04:06:32.761437+03:00",
    customer: 16,
    alert_id: 150,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "7302",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 01:06:27 on 2023.11.14\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80.23 %)\r\nOriginal problem ID: 7302",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-14T03:59:53.990919+03:00",
    updated: "2023-11-14T03:59:53.991003+03:00",
    customer: 16,
    alert_id: 149,
    alert_content: "Problem: Linux: High swap space usage (less than 50% free)",
    other_id: "7294",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 00:59:48 on 2023.11.14\r\nProblem name: Linux: High swap space usage (less than 50% free)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Free: 50 %, total: 32 GB\r\nOriginal problem ID: 7294",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-14T02:40:50.757174+03:00",
    updated: "2023-11-14T02:40:50.757227+03:00",
    customer: 16,
    alert_id: 148,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "7214",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:40:45 on 2023.11.13\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 4.88 ms, 96.67 ms\r\nOriginal problem ID: 7214",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-14T02:08:49.433947+03:00",
    updated: "2023-11-14T02:08:49.434043+03:00",
    customer: 16,
    alert_id: 147,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "7180",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:08:45 on 2023.11.13\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 6.55 ms, 62.94 ms\r\nOriginal problem ID: 7180",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-13T04:02:32.908103+03:00",
    updated: "2023-11-13T04:02:32.908222+03:00",
    customer: 16,
    alert_id: 146,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "5850",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 01:02:27 on 2023.11.13\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80 %)\r\nOriginal problem ID: 5850",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-13T02:36:50.159183+03:00",
    updated: "2023-11-13T02:36:50.159293+03:00",
    customer: 16,
    alert_id: 145,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "5764",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:36:45 on 2023.11.12\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.74 ms, 73.8 ms\r\nOriginal problem ID: 5764",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-12T03:59:33.194418+03:00",
    updated: "2023-11-12T03:59:33.194521+03:00",
    customer: 16,
    alert_id: 144,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "4403",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 00:59:27 on 2023.11.12\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80.01 %)\r\nOriginal problem ID: 4403",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-12T02:37:49.941110+03:00",
    updated: "2023-11-12T02:37:49.941240+03:00",
    customer: 16,
    alert_id: 143,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "4321",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:37:45 on 2023.11.11\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.05 ms, 114.86 ms\r\nOriginal problem ID: 4321",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-11T04:47:33.867539+03:00",
    updated: "2023-11-11T04:47:33.868064+03:00",
    customer: 16,
    alert_id: 142,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "3007",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 01:47:27 on 2023.11.11\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80.01 %)\r\nOriginal problem ID: 3007",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-11T02:40:51.665427+03:00",
    updated: "2023-11-11T02:40:51.665529+03:00",
    customer: 16,
    alert_id: 141,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "2880",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:40:46 on 2023.11.10\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 4.99 ms, 57.37 ms\r\nOriginal problem ID: 2880",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-10T12:30:52.900625+03:00",
    updated: "2023-11-10T12:30:52.900722+03:00",
    customer: 16,
    alert_id: 140,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "2028",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 09:30:46 on 2023.11.10\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 19.16 ms, 139.9 ms\r\nOriginal problem ID: 2028",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-10T04:50:32.329180+03:00",
    updated: "2023-11-10T04:50:32.329278+03:00",
    customer: 16,
    alert_id: 139,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "1564",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 01:50:27 on 2023.11.10\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80.02 %)\r\nOriginal problem ID: 1564",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-10T02:36:52.604120+03:00",
    updated: "2023-11-10T02:36:52.604921+03:00",
    customer: 16,
    alert_id: 138,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "1430",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:36:46 on 2023.11.09\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.64 ms, 173.24 ms\r\nOriginal problem ID: 1430",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-08T18:54:24.843754+03:00",
    updated: "2023-11-09T17:19:44.133183+03:00",
    customer: 1,
    alert_id: 126,
    alert_content: "Critical server error ICMP: Disk space is running low",
    other_id: "0",
    status: 1,
    severity: "CRITICAL",
    resource: "123",
    host: "192.168.1.10",
    description: "",
    alert_count: 0,
    filter: [],
    customer_name: "Devops",
  },
  {
    created: "2023-11-08T18:33:21.878784+03:00",
    updated: "2023-11-09T16:58:02.667170+03:00",
    customer: 1,
    alert_id: 124,
    alert_content: "Problem: Linux: Unava132123ilable by ICMP ping",
    other_id: "11606",
    status: 0,
    severity: "Moderate",
    resource: "123",
    host: "CenkTest",
    description:
      "Problem started at 15:18:35 on 2023.11.08\r\nProblem name: Linux: Unavailable by ICMP ping\r\nHost: Cenk Test 1.1.1.1 gee\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 606",
    alert_count: 0,
    filter: [],
    customer_name: "Devops",
  },
  {
    created: "2023-11-09T13:19:42.596019+03:00",
    updated: "2023-11-09T13:19:42.596103+03:00",
    customer: 16,
    alert_id: 137,
    alert_content: "Problem: Linux: Unavailable by ICMP ping",
    other_id: "636",
    status: 0,
    severity: "High",
    resource: "",
    host: "CenkTest",
    description:
      "Problem started at 10:19:35 on 2023.11.09\r\nProblem name: Linux: Unavailable by ICMP ping\r\nHost: Cenk Test 1.1.1.1 gee\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 636",
    alert_count: 1,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-09T12:51:51.325787+03:00",
    updated: "2023-11-09T12:51:51.325823+03:00",
    customer: 16,
    alert_id: 136,
    alert_content: "11 se11111rver 1err1or ICMP: Disk space is running low",
    other_id: "61112111117",
    status: 0,
    severity: "CRITIL",
    resource: "",
    host: "192.168.111.10",
    description: "",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-09T12:48:39.431418+03:00",
    updated: "2023-11-09T12:48:39.431474+03:00",
    customer: 16,
    alert_id: 135,
    alert_content:
      "1111111C1111rit111ical se111rver 1err1or ICMP: Disk space is running low",
    other_id: "6121117",
    status: 1,
    severity: "CRITIL",
    resource: "",
    host: "192.168.111.10",
    description: "",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-09T04:57:31.385649+03:00",
    updated: "2023-11-09T12:07:22.441037+03:00",
    customer: 16,
    alert_id: 134,
    alert_content: "Problem: /: Disk space is low (used > 80%)",
    other_id: "627",
    status: 1,
    severity: "Warning",
    resource: "123",
    host: "188.132.187.26",
    description:
      "Problem started at 01:57:27 on 2023.11.09\r\nProblem name: /: Disk space is low (used > 80%)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: Space used: 1.22 TB of 1.61 TB (80.01 %)\r\nOriginal problem ID: 627",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-09T02:38:48.397745+03:00",
    updated: "2023-11-09T02:38:48.397849+03:00",
    customer: 16,
    alert_id: 133,
    alert_content:
      "Problem: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)",
    other_id: "626",
    status: 1,
    severity: "Warning",
    resource: "",
    host: "188.132.187.26",
    description:
      "Problem started at 23:38:45 on 2023.11.08\r\nProblem name: sda: Disk read/write request responses are too high (read > 20 ms for 15m or write > 20 ms for 15m)\r\nHost: Eska Cloud TR Server\r\nSeverity: Warning\r\nOperational data: 5.43 ms, 133.47 ms\r\nOriginal problem ID: 626",
    alert_count: 0,
    filter: [],
    customer_name: "ESKA YAZILIM",
  },
  {
    created: "2023-11-08T18:33:45.243950+03:00",
    updated: "2023-11-08T18:33:45.244062+03:00",
    customer: 1,
    alert_id: 125,
    alert_content: "Problem: Linux: Un1ava132123ilable by ICMP ping",
    other_id: "111606",
    status: 0,
    severity: "High",
    resource: "",
    host: "CenkTest",
    description:
      "Problem started at 15:18:35 on 2023.11.08\r\nProblem name: Linux: Unavailable by ICMP ping\r\nHost: Cenk Test 1.1.1.1 gee\r\nSeverity: High\r\nOperational data: Down (0)\r\nOriginal problem ID: 606",
    alert_count: 0,
    filter: [41],
    customer_name: "Devops",
  },
];
