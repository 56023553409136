import React, { useState } from "react";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import { LoginButton } from "../AuthStyled";
import { StyledTextField } from "components/textField/CustomTextField";
import {
  fetchHandleLogin,
  postLoginVerified,
  setLoading,
} from "redux/slices/LoginSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/Store";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface ISignInForm {
  email: string;
  password: string;
}

const SignInPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const login = useSelector((state: RootState) => state.login);
  const errorMessage = login.loginMessage;
  const [emailToken, setEmailToken] = useState<string>("");
  /* Password Visibility  */
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  /* Password Visibility  */

  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Required field"),
    password: Yup.string()
      .required("Required field")
      .min(6, "Password too short"),
  });

  const verify = login.verifyStatus;
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={SignInSchema}
      onSubmit={(values: ISignInForm, actions) => {}}
    >
      {(props: FormikProps<ISignInForm>) => {
        const {
          values,
          touched,
          errors,
          dirty,
          handleBlur,
          handleChange,
          handleSubmit,
          setSubmitting,
        } = props;
        return (
          <Form
            style={{ width: "100%" }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              setSubmitting(false);
            }}
          >
            <Stack sx={{ gap: "16px", width: "100%" }}>
              <StyledTextField
                id="mail"
                name="email"
                /*  type="email" */
                fullWidth
                variant="standard"
                size="small"
                label="Email"
                value={values.email}
                helperText={errors.email && touched.email ? errors.email : " "}
                error={errors.email && touched.email ? true : false}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Stack width={"100%"}>
                <StyledTextField
                  name="password"
                  id="password"
                  label="Password"
                  fullWidth
                  variant="standard"
                  size="small"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  helperText={
                    errors.password && touched.password ? errors.password : " "
                  }
                  error={errors.password && touched.password ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              {verify === true && (
                <Stack width={"100%"}>
                  <StyledTextField
                    name="emailToken"
                    id="emailToken"
                    label="Email Token"
                    fullWidth
                    variant="standard"
                    size="small"
                    type={"text"}
                    value={emailToken}
                    onChange={(event) => {
                      setEmailToken(event.target.value);
                    }}
                  />
                  <Typography variant="caption">
                    * Please enter the token sent to your email.
                  </Typography>
                </Stack>
              )}
              <Stack
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography variant="subtitle2" color={"warning.dark"}>
                  {errorMessage}
                </Typography>
              </Stack>
              {verify === true ? (
                <LoginButton
                  type="submit"
                  autoFocus={true}
                  onClick={() => {
                    dispatch(postLoginVerified(emailToken));
                  }}
                >
                  Sign In
                </LoginButton>
              ) : (
                <LoginButton
                  type="submit"
                  autoFocus={true}
                  disabled={
                    !dirty ||
                    !(errors.email === undefined) ||
                    !(errors.password === undefined)
                  }
                  onClick={() => {
                    dispatch(setLoading(true));
                    dispatch(
                      fetchHandleLogin({
                        email: values.email,
                        password: values.password,
                      })
                    );
                  }}
                >
                  {login.loginLoading === true ? (
                    <CircularProgress size={"24px"} color="inherit" />
                  ) : (
                    "login"
                  )}
                </LoginButton>
              )}
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignInPage;
