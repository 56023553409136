import React from "react";
import {
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useTheme } from "layouts/theme/ThemeContext";
import { BorderContainer } from "components/box/BorderContainer";
import { useTranslation } from "react-i18next";

const ButtonContainer = styled(Button)<{ isSelect: boolean }>(
  ({ theme, isSelect }) => ({
    textTransform: "none",
    borderRadius: "8px",
    padding: "12px 12px",
    gap: "12px",
    color: isSelect ? theme.palette.success.main : theme.palette.primary.dark,
    border: isSelect ? "none" : ` 1px solid ${theme.palette.primary.main}`,

    "&:hover": {
      color: isSelect
        ? theme.palette.success.dark
        : theme.palette.primary.contrastText,
      backgroundColor: isSelect
        ? ""
        : ` ${alpha(theme.palette.primary.contrastText, 0.1)}  `,
    },
  })
);

const IntegrationList = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  /* Sugarcrm Odoo zohoo Logo SAP salesforce */
  const listArr = [
    {
      name: "Sugarcrm",
      subName: t("integration.crmCardDesc"),
      img: "https://cdn.icon-icons.com/icons2/2699/PNG/512/sugarcrm_logo_icon_168780.png",
      status: false,
    },
    {
      name: "Odoo",
      subName: t("integration.crmCardDesc"),
      img: "https://seeklogo.com/images/O/odoo-logo-62B6A3D625-seeklogo.com.png",
      status: false,
    },
    {
      name: "Zohoo",
      subName: t("integration.crmCardDesc"),
      img: "https://static-00.iconduck.com/assets.00/zoho-icon-2048x2048-jvw3wrfg.png",
      status: false,
    },
    {
      name: "SAP",
      subName: t("integration.crmCardDesc"),
      img: "https://cdn-icons-png.flaticon.com/512/882/882701.png",
      status: false,
    },
    {
      name: "Salesforce",
      subName: t("integration.crmCardDesc"),
      img: "https://cdn.worldvectorlogo.com/logos/salesforce-2.svg",
      status: false,
    },
    /*  {
      name: "Zabbix",
      subName: "Your subtitle comes here",
      img: "https://www.bilisimasistani.com/wp-content/uploads/2023/02/zabbix_logo_icon_167937.png",
      status: false,
    },
    {
      name: "Scada",
      subName: "Your subtitle comes here",
      img: "https://icons.veryicon.com/png/o/miscellaneous/tbk/scada-system.png",
      status: false,
    },
    {
      name: "MySQL",
      subName: "Your subtitle comes here",
      img: "https://ucarecdn.com/0295176b-b17a-4106-b3b0-5bf2b14365e6/",
      status: false,
    },
    {
      name: "Azure",
      subName: "Your subtitle comes here",
      img: "https://www.pybilisim.com.tr/wp-content/uploads/2023/06/AZURE.png",
      status: false,
    },
    {
      name: "Ruby",
      subName: "Your subtitle comes here",
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Ruby_logo.svg/800px-Ruby_logo.svg.png  ",
      status: false,
    }, */
  ];
  return (
    <Grid container spacing={2}>
      {listArr.map((item) => {
        return (
          <Grid
            key={`integration${item.name}`}
            sx={{ backgroundColor: "transparent" }}
            item
            xs={12}
            md={4}
          >
            <BorderContainer>
              <Stack
                width={"100%"}
                justifyContent={"flex-end"}
                direction={"row"}
              >
                <IconButton>
                  <AutorenewIcon fontSize="small" />
                </IconButton>
                <IconButton>
                  <MoreHorizIcon fontSize="small" />
                </IconButton>
              </Stack>
              <Stack
                width={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
                direction={"row"}
              >
                <img src={item.img} alt="" width={"64px"} height={"64px"} />
                <Stack>
                  {!item.status && (
                    <ButtonContainer isSelect={false}>
                      +{t("integration.install")}
                    </ButtonContainer>
                  )}
                  {item.status && (
                    <ButtonContainer isSelect={true}>
                      <TaskAltIcon />
                      {t("integration.Installed")}
                    </ButtonContainer>
                  )}
                </Stack>
              </Stack>
              <Stack alignItems={"flex-start"} width={"100%"}>
                <Typography variant="subtitle2">{item.name}</Typography>
                <Typography variant="caption">{item.subName}</Typography>
              </Stack>
            </BorderContainer>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default IntegrationList;
