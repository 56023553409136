import React from "react";
import { Button, Grid, Stack, Typography, alpha, styled } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { toImageUrl } from "utils/helpers/AssetHelpers";
import { listArr, listArrEn } from "../data/data";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export const BorderContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "16px",
  padding: "24px",
  width: "100%",
  gap: "16px ",
  backgroundColor: `${alpha(theme.palette.info.main, 0.05)} `,
  boxShadow: ` ${alpha(
    theme.palette.secondary.main,
    0.4
  )}  0px 2px 5px -1px,${alpha(
    theme.palette.primary.contrastText,
    0.7
  )}  0px 1px 3px -1px `,
}));

const ButtonContainer = styled(Button)<{ isSelect: boolean }>(
  ({ theme, isSelect }) => ({
    textTransform: "none",
    borderRadius: "8px",
    padding: "12px 12px",
    gap: "4px",
    color: isSelect ? theme.palette.success.dark : theme.palette.primary.dark,
    /*  border: isSelect ? "none" : ` 1px solid ${theme.palette.primary.main}`, */

    "&:hover": {
      color: isSelect
        ? theme.palette.success.dark
        : theme.palette.primary.contrastText,
      backgroundColor: isSelect
        ? ""
        : `${alpha(theme.palette.primary.contrastText, 0.2)}`,
    },
  })
);

const DetailButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: "8px",
  padding: "12px 12px",
  gap: "4px",
  color: `${alpha(theme.palette.warning.dark, 0.6)}`,

  "&:hover": {
    color: theme.palette.warning.dark,
  },
}));

const MarketCard = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  return (
    <Grid container spacing={2}>
      {listArr.map((item, i) => {
        return (
          <Grid
            key={`marketCard${i}`}
            sx={{ backgroundColor: "transparent" }}
            item
            xs={12}
            sm={4}
            md={3}
            xl={2}
          >
            <BorderContainer>
              <Stack
                sx={{
                  padding: { sm: "12px", md: "48px" },
                }}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
                direction={"row"}
                boxSizing={"border-box"}
              >
                <img
                  src={toImageUrl(item.img)}
                  alt=""
                  width={"100%"}
                  style={{
                    opacity: 0.9,
                  }}
                  height={"100%"}
                />
              </Stack>
              <Stack
                direction={"row"}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                {!item.status && (
                  <ButtonContainer isSelect={false}>
                    + {t(`marketPlace.install`)}
                  </ButtonContainer>
                )}
                {item.status && (
                  <ButtonContainer isSelect={true}>
                    <TaskAltIcon sx={{ width: "16px", height: "16px" }} />
                    {t(`marketPlace.installed`)}
                  </ButtonContainer>
                )}
                <DetailButton
                  onClick={() => {
                    navigate(`/marketPlace/${item.id}`);
                  }}
                >
                  {t(`marketPlace.detail`)}
                </DetailButton>
              </Stack>
              <Stack
                sx={{
                  gap: "4px",
                  width: "100%",
                  maxWidth: "100%",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                  }}
                  variant="subtitle2"
                >
                  {t(`marketPlace.${item.title}`)}
                </Typography>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                  }}
                  variant="caption"
                >
                  {t(`marketPlace.${item.desc}`)}
                </Typography>
              </Stack>
            </BorderContainer>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MarketCard;
