import { baseUrl } from "utils/helpers/BaseUrl";

export const fetchDelete = async (url: string) => {
  const user: string | null = sessionStorage.getItem("user");
  try {
    if (user !== null) {
      const userData = JSON.parse(user);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Token ${userData.token}`);

      const data = await fetch(`${baseUrl}${url}`, {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      }).catch((error) => {
        console.log(error);
      });
      return data;
    }
  } catch (error) {}
};
