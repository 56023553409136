import React from "react";
import ReactEcharts from "echarts-for-react";
import { Stack } from "@mui/material";
import { useTheme } from "layouts/theme/ThemeContext";

/* const legendData = [
  "rose1",
  "rose2",
  "rose3",
  "rose4",
  "rose5",
  "rose6",
  "rose7",
  "rose8",
];
const seriesData = [
  { value: 40, name: "rose 1" },
  { value: 33, name: "rose 2" },
  { value: 28, name: "rose 3" },
  { value: 22, name: "rose 4" },
  { value: 20, name: "rose 5" },
  { value: 15, name: "rose 6" },
  { value: 12, name: "rose 7" },
  { value: 10, name: "rose 8" },
]; */

const NightingaleChartEcharts = ({
  legendData,
  seriesData,
}: {
  legendData: string[];
  seriesData: { value: number; name: string }[];
}) => {
  const { theme } = useTheme();
  return (
    <Stack width={"100%"}>
      <ReactEcharts
        /*   style={{ height: "150px", width: "50px" }} */
        option={{
          /*  title: {
            text: "Nightingale Chart",
            subtext: "Fake Data",
            left: "center",
          }, */
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          legend: {
            left: "center",
            top: "bottom",
            data: legendData,
            textStyle: {
              color: theme.palette.primary.dark,
            },
          },
          /* indirme ve data bilgileri görünüyor  */
          /*   toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              restore: { show: true },
              saveAsImage: { show: true },
            },
          }, */
          series: [
            {
              name: "Radius Mode",
              type: "pie",
              /*    width: "100px", */
              radius: [20, 140],
              /*    center: ["50%", "50%"], */
              roseType: "radius",
              itemStyle: {
                borderRadius: 5,
              },
              label: {
                show: false,
              },
              /*    emphasis: {
                label: {
                  show: true,
                },
              }, */
              data: seriesData,
            },
            /*      {
              name: "Area Mode",
              type: "pie",
              radius: [20, 140],
              center: ["75%", "50%"],
              roseType: "area",
              itemStyle: {
                borderRadius: 5,
              },
              data: [
                { value: 30, name: "rose 1" },
                { value: 28, name: "rose 2" },
                { value: 26, name: "rose 3" },
                { value: 24, name: "rose 4" },
                { value: 22, name: "rose 5" },
                { value: 20, name: "rose 6" },
                { value: 18, name: "rose 7" },
                { value: 16, name: "rose 8" },
              ],
            }, */
          ],
        }}
      />
    </Stack>
  );
};

export default NightingaleChartEcharts;
