import { Stack, Typography, alpha, styled } from "@mui/material";
import { useTheme } from "layouts/theme/ThemeContext";
import { IGraphifyOs } from "page/graphify/type";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const Container = styled(Stack)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const Content = styled(Stack)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0px 12px",
  borderRadius: "16px",
  gap: "8px",

  boxShadow: ` ${alpha(theme.palette.primary.dark, 0.25)}  0px 5px 15px `,
}));

const MetricsMiniDashboard = ({ data,slice }: { data: IGraphifyOs,slice:number }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  const findIndex = (name: string) => {
    const find = data.metrics.find((item) => {
      return item.name_x === name;
    });
    return find
      ? Number(find.data[find.data.length - 1].lastvalue.toFixed(2))
      : 0;
  };
  const metricsData = [
    {
      data: findIndex("Linux: Memory utilization"),
      name: t("graphify.memory"),
      desc: t("graphify.memoryMiniDashboard"),
      color: "#ED2438",
    },
    {
      data: findIndex("Linux: CPU utilization"),
      name: t("graphify.cpu"),
      desc: t("graphify.cpuMiniDashboard"),
      color: "#F06F05",
    },
    {
      data: findIndex("sda: Disk utilization"),
      name: t("graphify.disk"),
      desc: t("graphify.diskMiniDashboard"),
      color: "#51C1C0",
    },
  ];

  return (
    <Container>
      {metricsData.slice(0,slice).map((item, i) => {
        return (
          <Content key={`metricsData_${i}`}>
            <ReactApexChart
              options={{
                colors: [item.color],
                chart: {
                  height: 120,
                  type: "radialBar",
                },

                plotOptions: {
                  radialBar: {
                    hollow: {
                      size: "40%",
                    },
                    dataLabels: {
                      name: {
                        show: false,
                      },
                      value: {
                        color: item.color,
                        fontWeight: 700,
                        fontSize: "12px",
                        offsetY: 5,
                      },
                    },
                  },
                },

                labels: [""],
              }}
              series={[item.data]}
              type="radialBar"
              height={130}
              width={80}
            />
            <Stack>
              <Typography
                sx={{ color: item.color }}
                variant="body2"
                fontWeight={700}
              >
                {item.name}
              </Typography>
              <Typography variant="caption">{item.desc}</Typography>
            </Stack>
          </Content>
        );
      })}
    </Container>
  );
};

export default MetricsMiniDashboard;
