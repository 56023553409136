import React, { useEffect, useState } from "react";
import { Stack, Typography, styled } from "@mui/material";
import { StyledTextField } from "components/textField/CustomTextField";
import { INodeParameters } from "page/workflow/types/nodeTypes";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  fontWeight: 700,
  color: theme.palette.secondary.main,
}));

const IntInput = ({
  parameter,
  onInputChange,
}: {
  parameter: INodeParameters;
  onInputChange: (parameterName: string, value: any) => void;
}) => {
  const [value, setValue] = useState<string>("");
  useEffect(() => {
    if (parameter.parameter_value) {
      setValue(parameter.parameter_value);
    }
  }, []);
  return (
    <Stack key={`intInput_${parameter.parameter_name}`} gap={"6px"}>
      <TextFieldTitle>{parameter.parameter_name}:</TextFieldTitle>
      <StyledTextField
        id={`${parameter.parameter_name}`}
        fullWidth
        variant="outlined"
        size="small"
        /*       placeholder={parameter.parameter_value} */
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
          onInputChange(parameter.parameter_name, event.target.value);
        }}
        /*   onBlur={(event) => {
          setTimeout(() => {
            onInputChange(parameter.parameter_name, value);
          }, 90);
        }} */
      />
    </Stack>
  );
};

export default IntInput;
