import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { IconButton, Stack, Typography, styled } from "@mui/material";
import { useState } from "react";
import { useTheme } from "layouts/theme/ThemeContext";
import { formatDateTime } from "utils/helpers/TimeFunc";
import StatusStack from "page/alert/components/StatusStack";
import { IAlertsModel } from "page/graphify/type";
import AlartDescModal from "./AlartDescModal";
import { useTranslation } from "react-i18next";

const severityList = [
  {
    severity_name: "HIGH",
    severity_color: "#FF6347",
  },
  {
    severity_name: "moderate",
    severity_color: "#871414",
  },
  {
    severity_name: "Average",
    severity_color: "#F7CD3B",
  },
  {
    severity_name: "CRITICAL",
    severity_color: "#F7CD3B",
  },
  {
    severity_name: "CRITIL",
    severity_color: "#F7CD3B",
  },
  {
    severity_name: "Warning",
    severity_color: "#F7CD3B",
  },
];
const Container = styled(Stack)(({ theme }) => ({
  width: "100%",
  maxHeight: "440px",
  borderRadius: "16px",
  padding: "8px",
  boxShadow: ` ${alpha(theme.palette.primary.dark, 0.25)}  0px 5px 15px `,
}));

interface Data {
  id: number;
  severity: string;
  customer_name: string;
  status: number;
  host_name: string;
  alert_content: string;
  created: string;
  actions: number;
}

function createData(
  id: number,
  severity: string,
  customer_name: string,
  status: number,
  host_name: string,
  alert_content: string,
  created: string,
  actions: number
): Data {
  return {
    id,
    severity,
    customer_name,
    status,
    host_name,
    alert_content,
    created,
    actions,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  /*   {
    id: "status",
    numeric: false,
    label: "Status",
  }, */

  {
    id: "customer_name",
    numeric: false,
    label: "Customer",
  },
  {
    id: "severity",
    numeric: false,
    label: "Severity",
  },

  {
    id: "host_name",
    numeric: false,
    label: "Host",
  },
  {
    id: "alert_content",
    numeric: true,
    label: "Description",
  },
  {
    id: "created",
    numeric: true,
    label: "Created",
  },
];

interface AlertTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function AlertTableHead(props: AlertTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{ fontWeight: 700, height: "64px", fontSize: "16px" }}
            align={headCell.numeric ? "center" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography variant="subtitle2">
                {" "}
                {t(`graphify.table${headCell.label}`)}
              </Typography>

              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function AlarmList({ alerts }: { alerts: IAlertsModel[] }) {
  const { theme } = useTheme();

  const rows: Data[] = alerts.map((item) => {
    return createData(
      item.alert_id,
      item.severity,
      item.customer_name,
      item.status,
      item.host,
      item.alert_content,
      item.created,
      0
    );
  });
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("customer_name");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.customer_name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  return (
    <Container>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <AlertTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {visibleRows.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={`${row.customer_name}_${index}`}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell component="th" id={labelId} scope="row">
                    {row.customer_name}
                  </TableCell>
                  <TableCell align="center">
                    <StatusStack
                      severity={row.severity}
                      severityList={severityList}
                    />
                  </TableCell>
                  <TableCell component="th" id={labelId} scope="row">
                    {row.host_name}
                  </TableCell>
                  <TableCell align="right">
                    <AlartDescModal
                      data={alerts.filter((item) => item.alert_id)[0]}
                      buttonTitle={row.alert_content}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {formatDateTime(row.created)}{" "}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack height={"60px"}>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
    </Container>
  );
}

export default AlarmList;
