import React from "react";
import { Grid, Stack } from "@mui/material";
import HeadDash from "./components/HeadDash";
import HeadTitle from "./components/HeadTitle";
import SeverityLineChart from "./components/SeverityLineChart";
import Host10DayData from "./components/Host10DayData";

import { Size, useWindowSize } from "utils/hooks/use_window_size";
import { useSelector } from "react-redux";
import { RootState } from "redux/Store";

function DashAlarm() {
  const windowsize: Size = useWindowSize();
  const layouts = useSelector((state: RootState) => state.layouts);
  const cwidth =
    layouts.sidebarIsOpen === true || layouts.mobileIsOpen === true
      ? `calc(${windowsize?.width}px - 360px)`
      : `calc(${windowsize?.width}px - 190px)`;
  return (
    <Stack width={"100%"}>
      <HeadTitle />
      <Stack width={"100%"} gap={"16px"}>
        <HeadDash />
        <SeverityLineChart />
        <Host10DayData />
      </Stack>
    </Stack>
  );
}

export default DashAlarm;
