import { Box, BoxProps, alpha, styled } from "@mui/material";
import { Size } from "utils/hooks/use_window_size";

export const PagesContent = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "24px",
  boxSizing: "border-box",
  borderRadius: "16px",
  paddingBottom: "16px",
  height: "100%",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: 6,
  },
  "&::-webkit-scrollbar-thumb:vertical": {
    borderRadius: 4,
    background: theme.palette.primary.main,
  },
  "&::-webkit-scrollbar-track:vertical": {
    borderRadius: 4,
    background: "transparent",
  },
}));

export const PagesCotaniner = styled(Box)<{ windowsize: Size }>(
  ({ theme, windowsize }) => ({
    width: "100%",
    padding: "12px",
    paddingBottom: "24px",
    height: `calc(${windowsize?.height}px - 88px)`,
    boxSizing: "border-box",
    borderRadius: "16px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: ` ${alpha(
      theme.palette.primary.dark,
      0.25
    )}  0px 2px 5px -1px,${alpha(
      theme.palette.primary.contrastText,
      0.3
    )}  0px 1px 3px -1px `,
  })
);
