import React, { useEffect } from "react";
import {
  Stack,
  Typography,
  styled,
  alpha,
  linearProgressClasses,
  LinearProgress,
} from "@mui/material";
import { AppDispatch, RootState } from "redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "layouts/theme/ThemeContext";
import { getAlertsList } from "redux/slices/AlertsSlice";

const Cotaniner = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: "16px",
  padding: "10px 40px",
  boxSizing: "border-box",
  gap: "24px",
  borderBlockColor: theme.palette.background.paper,
  /*   boxShadow: ` ${alpha(theme.palette.primary.dark, 0.25)} 0px 7px 29px 0px `, */
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.error.light,
  },
}));

const AlertsCount = () => {
  const { theme } = useTheme();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getAlertsList(`/customer/alert/`));
  }, [dispatch]);

  const alerts = useSelector((state: RootState) => state.alerts);
  const openAlerts = alerts.alertsList.filter((item) => item.status === 0);
  const closeAlerts = alerts.alertsList.filter((item) => item.status === 1);
  const openAlertsValue = (openAlerts.length * 100) / alerts.alertsList.length;

  const closeAlertsValue =
    (closeAlerts.length * 100) / alerts.alertsList.length;

  const limitArr = [
    {
      name: "Open Alerts",
      count: openAlerts.length,
      secondDtata: alerts.alertsList.length,
      value: openAlertsValue,
      color: theme.palette.success.dark,
    },
    {
      name: "Close Alerts",
      count: closeAlerts.length,
      secondDtata: alerts.alertsList.length,
      value: closeAlertsValue,
      color: theme.palette.error.dark,
    },
  ];
  return (
    <Cotaniner sx={{}}>
      {limitArr.map((item) => {
        return (
          <Stack
            sx={{
              borderRadius: "16px",
              boxShadow: ` ${alpha(
                theme.palette.primary.main,
                0.25
              )} 0px 7px 7px 0px `,
              backgroundColor: `${alpha(item.color, 0.15)}`,
            }}
            key={`limitArr${item.name}`}
            children={
              <Stack
                sx={{
                  padding: "16px 24px",
                  gap: "16px",
                  height: "132px",
                  maxHeight: "132px",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.secondary.main,
                  }}
                  variant="subtitle2"
                >
                  {item.name}{" "}
                </Typography>

                <Stack direction={"row"} gap={"4px"} alignItems={"center"}>
                  <Typography fontWeight={700} variant="body1">
                    {item.count} alerts
                  </Typography>
                  {" - "}
                  <Typography variant="body1">
                    {item.value.toFixed(2)}%{" "}
                  </Typography>
                </Stack>
                <Stack>
                  <BorderLinearProgress
                    sx={{
                      height: 10,
                      borderRadius: 5,
                      [`&.${linearProgressClasses.colorPrimary}`]: {
                        backgroundColor: theme.palette.background.paper,
                      },
                      [`& .${linearProgressClasses.bar}`]: {
                        borderRadius: 5,
                        backgroundColor: item.color,
                      },
                    }}
                    variant="determinate"
                    value={item.value}
                  />
                </Stack>
              </Stack>
            }
          />
        );
      })}
    </Cotaniner>
  );
};

export default AlertsCount;
