import React from "react";
import { Stack, styled } from "@mui/material";
import { useGetEventsTitles } from "page/dashboard/hooks/useResults";
import AreaChartsStacked from "components/apexcharts/AreaChartsStacked";
import { baseUrl } from "utils/helpers/BaseUrl";
import CustomLoading from "components/loading/CustomLoading";
import { useTranslation } from "react-i18next";

const Container = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "48px",
  width: "100%",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

interface IDataAll {
  date: string[];
  data: { name: string; data: number[] }[];
}

const emptyData = {
  date: [],
  data: [],
};

const Host10DayData = () => {
  const { t } = useTranslation();
  const urlHosts = `${baseUrl}/alert/analytics/grouped_by_values/?day=10&key=host`;
  const hostsData = useGetEventsTitles({ key: "hostDash", url: urlHosts }).data;

  if (hostsData === undefined) {
    return <CustomLoading />;
  } else {
    return (
      <Container>
        {
          <AreaChartsStacked
            categories={hostsData.date}
            series={hostsData.data}
            title={t("dashboard.hostChartDesc")}
          />
        }
      </Container>
    );
  }
};

export default Host10DayData;
