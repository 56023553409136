import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import StackedLineEChart from "components/eCharts/StackedLineEChartCustom";
import AssociatedResources from "./components/AssociatedResources";
import MonitoringView from "./components/MonitoringView";
import IntegrationList from "./components/DashList";
import CustomTreeEChart from "components/eCharts/CustomTreeEChart";
import DashTitle from "../DashTitle";

const stackedLegendData = ["Last Month", "This Month"];
const stackedXAxisData = ["Mon", "Tue", "Wed", "Thu", "Fri"];
const stackedSeriesData1 = [250, 50, 201, 154, 25, 330, 10];
/* const stackedSeriesData2 = [220, 230, 201, 234, 190, 230, 10]; */
const stackedSeriesName1 = "Last Month";
const stackedSeriesName2 = "This Month";

const DashMonitor = () => {
  return (
    <Stack width={"100%"} gap={"36px"}>
      <DashTitle title={"Dashboard Monitor"} />
      <Grid container spacing={2}>
        <Grid sx={{ backgroundColor: "transparent" }} item xs={12} md={8}>
          <MonitoringView />
        </Grid>
        <Grid sx={{ backgroundColor: "transparent" }} item xs={12} md={4}>
          <AssociatedResources />
        </Grid>
      </Grid>
      <IntegrationList />
      <Typography fontWeight={700} variant="body1">
        Revenue
      </Typography>
      <StackedLineEChart
        legendData={stackedLegendData}
        seriesData1={stackedSeriesData1}
        seriesData2={stackedSeriesData1}
        seriesName1={stackedSeriesName1}
        seriesName2={stackedSeriesName2}
        xAxisData={stackedXAxisData}
      />
      <CustomTreeEChart />
    </Stack>
  );
};

export default DashMonitor;
