import React from "react";
import ReactEcharts from "echarts-for-react";
import { Size, useWindowSize } from "utils/hooks/use_window_size";
import { Stack } from "@mui/material";

interface IType {
  name: string;
  data: number[];
}

const StackedLineEChart = ({
  dateArr,
  categoryArr,
  stackedData,
}: {
  dateArr: string[];
  categoryArr: string[];
  stackedData: IType[];
}) => {
  const windowsize: Size = useWindowSize();

  return (
    <Stack
      sx={{
        width: "100%",
        height: `calc(${windowsize?.height}px - 230px)`,
      }}
    >
      <ReactEcharts
        style={{
          width: "100%",
          height: "350px",
          margin: "0",
          padding: "0",
        }}
        option={{
          title: {
            show: false,
          },
          tooltip: {
            trigger: "axis",
          },
          toolbox: {
            feature: {
              dataZoom: {},
              dataView: {
                show: true,
              },
              saveAsImage: {},
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            top: "9%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: dateArr,
          },
          yAxis: {
            type: "value",
          },
          series: stackedData.map((item) => ({
            name: item.name,
            type: "line",
            data: item.data,
          })),
        }}
      />
    </Stack>
  );
};

export default StackedLineEChart;
