import React from "react";
import Topbar from "./topbar/Topbar";
import { Outlet } from "react-router";
import CustomMuiSidebar from "./sidebar/CustomSidebar";
import { Box, Stack, styled } from "@mui/material";

const AppContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  width: "100vw",
  maxWidth: "100vw",
  height: "100vh",
  padding: "12px",
  boxSizing: "border-box",
  backgroundColor: theme.palette.background.default,
}));

const MasterLayout = () => {
  return (
    <AppContainer>
      <Stack
        sx={{
          display: { xs: "none ", sm: "block" },
        }}
      >
        <CustomMuiSidebar />
      </Stack>

      <Stack style={{ width: "100%" }}>
        <Topbar />
        <Outlet />
      </Stack>
    </AppContainer>
  );
};

export default MasterLayout;
