import { useEffect, useState } from "react";
import { Box, BoxProps, Stack, Typography } from "@mui/material";
import { Size, useWindowSize } from "utils/hooks/use_window_size";
import { pathShortArr, topTabBarArr } from "data/pathArr";
import { useDispatch, useSelector } from "react-redux";
import {
  setTopTabBarName,
  setTopbarMainTitle,
} from "redux/slices/LayoutsSlice";
import { setUserInfo } from "redux/slices/LoginSlice";
import { RootState } from "redux/Store";
import { PagesContent, PagesCotaniner } from "./Components";

const PageContainer = (props: BoxProps) => {
  const windowsize: Size = useWindowSize();
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const dispatch = useDispatch();
  const layouts = useSelector((state: RootState) => state.layouts);

  useEffect(() => {
    /*  Get User Info*/
    const userJSON = sessionStorage.getItem("user");
    if (userJSON) {
      const userData = JSON.parse(userJSON);
      dispatch(setUserInfo(userData));
    }
    /* TopBar Change Name  */
    setCurrentPath(window.location.pathname);
    const newObj = pathShortArr.find((item) => item.path === currentPath);
    if (newObj) {
      dispatch(setTopbarMainTitle(newObj.name));
    }
  }, []);

  /* start: tabler arasında gezinirken toptabBarın ve topBarın ismini rengini değiştrien foksiyon  */
  /* start: mouse ile ileri geri gidince toptabBarın renklerinin değişmesine yardımcı oluyor */
  useEffect(() => {
    const handleLocationChange = () => {
      var path = window.location.pathname;
      var parts = path.split("/");

      const findPath1 = topTabBarArr.find(
        (item) => item.title.path === parts[1]
      );
      if (findPath1) {
        if (parts[2]) {
          const findPath2 = findPath1?.tab.find((item) =>
            item.path.includes(parts[2])
          );
          dispatch(setTopbarMainTitle(findPath1.title.name));
          findPath2 && dispatch(setTopTabBarName(findPath2?.name));
        } else {
          dispatch(setTopbarMainTitle(findPath1.title.name));
          dispatch(setTopTabBarName(findPath1?.tab[0].name));
        }
      }
    };
    window.addEventListener("popstate", handleLocationChange);
    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, []);

  const desktopWidth =
    layouts.sidebarIsOpen === true || layouts.mobileIsOpen === true
      ? `calc(${windowsize?.width}px - 310px)`
      : `calc(${windowsize?.width}px - 130px)`;
  const mobileWidth = `calc(${windowsize?.width}px - 24px)`;

  return (
    <PagesCotaniner sx={{ width: "100%" }} windowsize={windowsize}>
      <PagesContent sx={{ width: { xs: mobileWidth, md: desktopWidth } }}>
        {props.children}
      </PagesContent>
      <Stack
        padding={"4px 24px"}
        direction={"row"}
        width={"100%"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography fontSize={"10px"} variant="caption">
          version 2.0
        </Typography>
        <Typography fontSize={"10px"} variant="caption">
          copyright © 2023 Robenice tüm hakları saklıdır.
        </Typography>
      </Stack>
    </PagesCotaniner>
  );
};

export default PageContainer;
