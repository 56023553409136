import React, { useEffect, useState } from "react";
import { MenuItem, Stack, Typography, styled } from "@mui/material";
import { StyledTextField } from "components/textField/CustomTextField";
import { INodeParameters } from "page/workflow/types/nodeTypes";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  fontWeight: 700,
  color: theme.palette.secondary.main,
}));

const DropDownMenu = ({
  parameter,
  onInputChange,
  extraParameters,
}: {
  parameter: INodeParameters;
  onInputChange: (parameterName: string, value: any) => void;
  extraParameters: any;
}) => {
  const [value, setValue] = useState<string>("");
  useEffect(() => {
    if (parameter.parameter_name) {
      setValue(parameter.parameter_value);
    }
  }, []);
  /* extraParameters */
  /*   useEffect(() => {
    if (parameter.parameter_value) {
      setValue(parameter.parameter_value.split(",")[0]);
    }
  }, [, value]); */

  return (
    <>
      {extraParameters[parameter.parameter_name] && (
        <Stack key={`${parameter.parameter_name}`} gap={"6px"}>
          <TextFieldTitle>{parameter.parameter_name}:</TextFieldTitle>
          <StyledTextField
            id="outlined-select-currency"
            select
            fullWidth
            size="small"
            placeholder={"Select"}
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
              onInputChange(parameter.parameter_name, event.target.value);
            }}
          >
            {extraParameters[parameter.parameter_name].map((option: any) => (
              <MenuItem key={`dropdown${option}`} value={option}>
                {option}
              </MenuItem>
            ))}
          </StyledTextField>
        </Stack>
      )}
    </>
  );
};

export default DropDownMenu;
