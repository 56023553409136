import { useQuery } from "@tanstack/react-query";
import { fetchGet } from "apis/Get";

/**
 * @returns useGetData, label and variant values ​​of events
 */
export const useGetData = function <T>({
  path,
  key,
  refetchInterval,
}: /*  enabled, */
{
  path: string;
  key: string;
  refetchInterval?: number;
  /*   enabled: boolean; */
}) {
  return useQuery({
    queryKey: [key],
    queryFn: async () => await fetchGet(path),
    select: (data: T[]) => data,
    refetchInterval: refetchInterval ? refetchInterval : false,

    /*  enabled: enabled, */
  });
};

/**
 * @returns useGetDataObj, label and variant values ​​of events
 */
export const useGetDataObj = function <T>({
  path,
  key,
}: {
  path: string;
  key: string;
}) {
  return useQuery({
    queryKey: [key],
    queryFn: async () => await fetchGet(path),
    select: (data: T) => data,

    /*  enabled: enabled, */
  });
};

/* How use useGetData 
  const { data, refetch, isRefetching, isRefetchError, error } =
    useGetData<IEventInfo>({
      path: eventsCollPath,
      key:`${baseUrl}/filter/` ,
      enabled: userPanelStore.currentTab === "myNotes",
    }); */
