import React from "react";
import { Grid, Stack } from "@mui/material";

import { useTheme } from "layouts/theme/ThemeContext";
import RadialBarChartsSimple from "components/apexcharts/RadialBarChartsSimple";
import ChartWrapper from "./ChartWrapper";
import DoughnutChartwithRoundEChart from "components/eCharts/DoughnutChartwithRoundEChart";

const doughnutChartSeriesData = [
  { value: 1048, name: "Search Engine" },
  { value: 735, name: "Direct" },
  { value: 580, name: "Email" },
  { value: 484, name: "Union Ads" },
  { value: 300, name: "Video Ads" },
];

const AutomationReports = () => {
  const { theme } = useTheme();
  return (
    <Stack>
      <Stack>
        <Grid container spacing={4}>
          <ChartWrapper
            title={"Scenarios"}
            children={
              <RadialBarChartsSimple
                seriesData={[90]}
                labelsName={["Scenarios"]}
                circleColors={[theme.palette.warning.main]}
                height={"100%"}
                width="100%"
              />
            }
          />
          <ChartWrapper
            title={"Agent Count"}
            children={
              <RadialBarChartsSimple
                seriesData={[85]}
                labelsName={["Agent Count"]}
                circleColors={[theme.palette.error.main]}
                height={"100%"}
                width="100%"
              />
            }
          />
          <ChartWrapper
            title={"Filter Count"}
            children={
              <RadialBarChartsSimple
                seriesData={[70]}
                labelsName={["Filter Count"]}
                circleColors={[theme.palette.success.main]}
                height={"100%"}
                width="100%"
              />
            }
          />
        </Grid>
      </Stack>
    </Stack>
  );
};

export default AutomationReports;
