import React from "react";
import { Grid, Stack, Typography } from "@mui/material";

import PieLegenEChart from "components/eCharts/PieLegenEChart";
import StackedLineEChart from "components/eCharts/StackedLineEChart";
import CustomLoading from "components/loading/CustomLoading";
import { useGetDataObj } from "query/GetQuery";
import AlertsCount from "./AlertsCount";
import ChartWrapper from "../ChartWrapper";
import ChartsPrint from "../print/ChartsPrint";
import { useTranslation } from "react-i18next";

interface IData {
  date: string[];
  data: { name: string; data: number[] }[];
}
const AlertsReports = () => {
  const { t } = useTranslation();
  const url = `/alert/analytics/grouped_by_values/?day=10&key=severity`;
  const severityData = useGetDataObj<IData>({
    key: "severityDashData",
    path: url,
  }).data;

  const urlHosts = `/alert/analytics/grouped_by_values/?day=10&key=host`;
  const hostsData = useGetDataObj<IData>({
    key: "hostDashData",
    path: urlHosts,
  }).data;

  if (severityData === undefined && hostsData === undefined) {
    return <CustomLoading />;
  } else {
    return (
      <Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="subtitle2">{t("reports.allReports")}</Typography>
          <Stack direction={"row"} alignItems={"center"}>
            <ChartsPrint />
          </Stack>
        </Stack>
        <Grid container spacing={4}>
          {severityData && (
            <ChartWrapper
              title={t("reports.pieLegenChartDesc")}
              children={
                <PieLegenEChart
                  pieData={severityData.data.map(
                    (item: { name: any; data: any[] }) => ({
                      name: item.name,
                      value: item.data.reduce((acc, value) => acc + value, 0),
                    })
                  )}
                  name={""}
                  category={severityData.data.map(
                    (item: { name: any }) => item.name
                  )}
                />
              }
            />
          )}
          <ChartWrapper
            title={t("reports.alertsCount")}
            children={<AlertsCount />}
          />

          <Grid sx={{ backgroundColor: "transparent" }} item xs={12}>
            <Stack
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "transparent",
              }}
            >
              <Typography
                sx={{
                  height: "60px",
                  padding: "10px",
                  fontWeight: "700",
                  boxSizing: "border-box",
                }}
              >
                {t("reports.stackedLineEChart")}
              </Typography>
              {hostsData && (
                <StackedLineEChart
                  dateArr={hostsData.date}
                  categoryArr={[]}
                  stackedData={hostsData.data}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    );
  }
};

export default AlertsReports;
