import React from "react";
import ChartWrapper from "components/eCharts/ChartWrapper";
import { Stack, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ScatteronSingleAxisEchart from "components/eCharts/ScatteronSingleAxisEchart";

const AverangeCosts = () => {
  return (
    <ChartWrapper
      children={
        <Stack width={"100%"} gap={"1px"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="subtitle2">Averange Costs</Typography>{" "}
            <MoreHorizIcon />
          </Stack>
          <ScatteronSingleAxisEchart />
        </Stack>
      }
    />
  );
};

export default AverangeCosts;
