import React from "react";
import {
  Stack,
  IconButton,
  Modal,
  styled,
  Box,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "components/tooltip/tooltip";
import { RowStack } from "components/stack/RowStack";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { CustomIconButton } from "components/buttons/CustomIconButton";
import { CancelButtonSyled } from "components/buttons/CancelButtonSyled";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/Store";
import {
  getScenario,
  setAutomationFlowData,
} from "redux/slices/AutomationSlice";
import { useTranslation } from "react-i18next";

const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "36px",
  borderRadius: "16px",
  backgroundColor: theme.palette.background.default,
}));

const PlayButton = styled(IconButton)(({ theme }) => ({
  width: "88px",
  textTransform: "lowercase",
  borderRadius: "8px",
  ...theme.typography.subtitle2,
  marginTop: "36px",
  padding: "8px 16px",
  border: `1px solid ${theme.palette.error.main}`,
  "&:hover": {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.light,
  },
}));

const EditScenarioModal = ({
  flowData,
}: {
  flowData: { id: number; name: string };
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [openModal, setOpenModal] = React.useState(false);

  const handlefilter = () => {
    dispatch(getScenario(flowData.id));
  };

  const handleEdit = () => {
    dispatch(setAutomationFlowData(""));
    navigate(`/workflow`);
  };
  /*  burasına bak */
  return (
    <Stack>
      <CustomTooltip title={t("automation.editScenario")}>
        <CustomIconButton
          onClick={() => {
            handlefilter();
            setOpenModal(true);
          }}
        >
          <AccountTreeIcon />
        </CustomIconButton>
      </CustomTooltip>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent>
          <Stack width={"250px"} gap={"16px"} alignItems={"center"}>
            <RowStack gap={"24px"}>
              <Typography align="center" variant="body1">
                <b> {flowData.name.toLowerCase()}</b> {t("automation.longEdit")}
              </Typography>
            </RowStack>
          </Stack>

          <Stack
            direction={"row"}
            width={"100%"}
            gap={"16px"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CancelButtonSyled
              onClick={() => {
                setOpenModal(false);
              }}
            >
              {t("general.cancel")}
            </CancelButtonSyled>
            <PlayButton
              onClick={() => {
                setOpenModal(false);
                handleEdit();
              }}
            >
              {t("general.edit")}
            </PlayButton>
          </Stack>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default EditScenarioModal;
