import React from "react";
import { Navigate, Route, Routes } from "react-router";
import MasterLayout from "layouts/MasterLayout";
import AlertsPage from "page/alert/AlertsPage";
import FlowPage from "page/workflow/WorkflowPage";
import SettingsPage from "page/settings/SettingsPage";
import AccountPage from "page/account/AccountPage";
import AutomationPage from "page/automation/AutomationPage";
import AgentManagementPage from "page/agentManagement/AgentManagementPage";
import FilterManagement from "page/filterManagement/FilterManagement";
import OsHostDetail from "page/graphify/components/OS/OsHostDetail";
import OsHostDashboard from "page/graphify/components/OS/OsHostDashboard";
import DashAlarm from "page/dashboard/components/Alarm/DashAlarm";
import DashCRM from "page/dashboard/components/CRM/DashCRM";
import DashCustom from "page/dashboard/components/Custom/DashCustom";
import DashCustomer from "page/dashboard/components/Customer/DashCustomer";
import DashMonitor from "page/dashboard/components/monitor/DashMonitor";
import AlertsReports from "page/reports/components/alerts/AlertsReports";
import AutomationReports from "page/reports/components/automation/AutomationReports";
import GeneralView from "page/general/GeneralView";
import IntegrationCrm from "page/integration/components/crm/IntegrationCrm";
import IntegrationTicket from "page/integration/components/ticket/IntegrationTicket";
import IntegrationMonitor from "page/integration/components/monitor/IntegrationMonitor";
import IoTView from "page/integration/components/iot/IoTView";
import MarketCard from "page/marketPlace/component/MarketCard";
import UnderConstruction from "page/general/UnderConstruction/UnderConstruction";
import MarketDetail from "page/marketPlace/component/MarketDetail";
import NetworkDashboard from "page/graphify/components/Network/NetworkDashboard";
import NetworkHostDetail from "page/graphify/components/Network/NetworkHostDetail";

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        <Route path="alerts" element={<AlertsPage />} />
        {/* Dashboard */}
        <Route element={<GeneralView />}>
          <Route path="dashboard" element={<DashAlarm />} />
          <Route path="dashboard/crmerp" element={<DashCRM />} />
          <Route path="dashboard/custom" element={<DashCustom />} />
          <Route path="dashboard/customer" element={<DashCustomer />} />
          <Route path="dashboard/monitor" element={<DashMonitor />} />
          <Route path="*" element={<Navigate to="/error/404" />} />
        </Route>
        {/* Dashboard */}

        {/* Graphify */}

        <Route element={<GeneralView />}>
          <Route path="graphifyx" element={<OsHostDashboard />} />
          <Route path="*" element={<Navigate to="/error/404" />} />
          <Route path="graphifyx/os/:id" element={<OsHostDetail />} />
          <Route path="graphifyx/network" element={<NetworkDashboard />} />
          <Route path="graphifyx/network/:id" element={<NetworkHostDetail />} />
          <Route
            path="graphifyx/physicalServer"
            element={<UnderConstruction />}
          />
          <Route
            path="graphifyx/virtualServer"
            element={<UnderConstruction />}
          />
          <Route path="graphifyx/storage" element={<UnderConstruction />} />
          <Route path="graphifyx/web" element={<UnderConstruction />} />
          <Route path="graphifyx/application" element={<UnderConstruction />} />
        </Route>
        {/* Graphify */}

        {/* Reports */}
        <Route element={<GeneralView />}>
          <Route path="reports" element={<AlertsReports />} />
          <Route path="reports/automation" element={<AutomationReports />} />
        </Route>
        {/* Reports */}

        {/* Integration */}
        <Route element={<GeneralView />}>
          <Route path="integration" element={<IntegrationCrm />} />
          <Route path="integration/ticket" element={<IntegrationTicket />} />
          <Route path="integration/monitor" element={<IntegrationMonitor />} />
          <Route path="integration/ioT" element={<IoTView />} />
        </Route>
        {/* Integration */}

        {/* Market Place */}
        <Route element={<GeneralView />}>
          <Route path="marketPlace" element={<MarketCard />} />
          <Route path="marketPlace/:id" element={<MarketDetail />} />
          <Route path="marketPlace/insurance" element={<UnderConstruction />} />
          <Route path="marketPlace/analysis" element={<UnderConstruction />} />
          <Route path="marketPlace/ai" element={<UnderConstruction />} />
          <Route path="marketPlace/mail" element={<UnderConstruction />} />
          <Route path="marketPlace/sap" element={<UnderConstruction />} />
        </Route>
        {/* Market Place */}

        <Route path="automation" element={<AutomationPage />} />
        <Route path="workflow" element={<FlowPage />} />
        <Route path="agentManagement" element={<AgentManagementPage />} />
        <Route path="filterManagement" element={<FilterManagement />} />
        <Route path="settings" element={<SettingsPage />} />
        <Route path="account" element={<AccountPage />} />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

export default PrivateRoutes;
