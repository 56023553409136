import React from "react";
import ReactEcharts from "echarts-for-react";
import { Stack } from "@mui/material";

/* const seriesData = [
                  { value: 1048, name: 'Search Engine' },
                  { value: 735, name: 'Direct' },
                  { value: 580, name: 'Email' },
                  { value: 484, name: 'Union Ads' },
                  { value: 300, name: 'Video Ads' }
                ];  */

const DoughnutChartwithRoundEChart = ({
  seriesData,
}: {
  seriesData: { value: number; name: string }[];
}) => {
  return (
    <Stack width={"100%"}>
      <ReactEcharts
        option={{
          height: "350px",
          tooltip: {
            trigger: "item",
          },
          legend: {
            top: "5%",
            left: "center",
          },
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: "#fff",
                borderWidth: 2,
              },
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 40,
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: seriesData,
            },
          ],
        }}
      />
    </Stack>
  );
};

export default DoughnutChartwithRoundEChart;
