// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wheel {
  animation: animName 2s linear infinite;
}
@keyframes animName {
  0% {
    transition: all 0.1s linear;
    opacity: 1;
  }
  20%,
  80% {
    transition: all 0.1s linear;
    opacity: 0.3;
  }
  100% {
    transition: all 0.1s linear;
    opacity: 1;
  }
}

.point1 {
  animation: scaleName 2s linear infinite;
}

.point2 {
  animation: scaleName 4s linear infinite;
}

.point3 {
  animation: scaleName 2s linear infinite;
}

.point4 {
  animation: scaleName 4s linear infinite;
}
@keyframes scaleName {
  0% {
    transition: all 0.2s ease-in-out;
    transform: scale(1);
  }
  50% {
    transition: all 0.2s ease-in-out;
    transform: scale(1.03);
  }
  100% {
    transition: all 0.2s ease-in-out;
    transform: scale(1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/loading/style.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;AACA;EACE;IACE,2BAA2B;IAC3B,UAAU;EACZ;EACA;;IAEE,2BAA2B;IAC3B,YAAY;EACd;EACA;IACE,2BAA2B;IAC3B,UAAU;EACZ;AACF;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,uCAAuC;AACzC;AACA;EACE;IACE,gCAAgC;IAChC,mBAAmB;EACrB;EACA;IACE,gCAAgC;IAChC,sBAAsB;EACxB;EACA;IACE,gCAAgC;IAChC,mBAAmB;EACrB;AACF","sourcesContent":[".wheel {\n  animation: animName 2s linear infinite;\n}\n@keyframes animName {\n  0% {\n    transition: all 0.1s linear;\n    opacity: 1;\n  }\n  20%,\n  80% {\n    transition: all 0.1s linear;\n    opacity: 0.3;\n  }\n  100% {\n    transition: all 0.1s linear;\n    opacity: 1;\n  }\n}\n\n.point1 {\n  animation: scaleName 2s linear infinite;\n}\n\n.point2 {\n  animation: scaleName 4s linear infinite;\n}\n\n.point3 {\n  animation: scaleName 2s linear infinite;\n}\n\n.point4 {\n  animation: scaleName 4s linear infinite;\n}\n@keyframes scaleName {\n  0% {\n    transition: all 0.2s ease-in-out;\n    transform: scale(1);\n  }\n  50% {\n    transition: all 0.2s ease-in-out;\n    transform: scale(1.03);\n  }\n  100% {\n    transition: all 0.2s ease-in-out;\n    transform: scale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
