import React, { useEffect, useState } from "react";
import { Checkbox, Stack, Typography, styled } from "@mui/material";
import { INodeParameters } from "page/workflow/types/nodeTypes";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  fontWeight: 700,
  color: theme.palette.secondary.main,
}));

const BoolInput = ({
  parameter,
  onInputChange,
}: {
  parameter: INodeParameters;
  onInputChange: (parameterName: string, value: any) => void;
}) => {
  const [value, setValue] = useState<boolean>(false);
  useEffect(() => {
    if (
      parameter.parameter_value === "True" ||
      parameter.parameter_value === "true" ||
      parameter.parameter_value === true
    ) {
      setValue(true);
    } else {
      setValue(false);
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      onInputChange(parameter.parameter_name, value);
      console.log("value", value);
    }, 90);
  }, [value]);

  return (
    <Stack
      width={"100%"}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      gap={"6px"}
    >
      <TextFieldTitle>{parameter.parameter_name}:</TextFieldTitle>
      <Checkbox
        checked={value}
        onChange={(event) => {
          setValue(event.target.checked);
        }}
      />
    </Stack>
  );
};

export default BoolInput;
