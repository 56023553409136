import * as React from "react";
import { Box, IconButton, Stack, styled } from "@mui/material";

export const AddButton = styled(IconButton)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",

  alignContent: "center",
  justifyContent: "space-between",
  gap: "6px ",
  border: `1px solid ${theme.palette.primary.light}`,
  borderRadius: "16px",
  textTransform: "lowercase",
  padding: "16px",

  "&:hover": {
    fontWeight: 700,
    color: theme.palette.primary.dark,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: "transparent",
  },
}));

export const AddSmallButton = styled(IconButton)(({ theme }) => ({
  height: "40px",
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "4px",
  padding: "8px",

  "&:hover": {
    color: theme.palette.primary.dark,
    border: `1px solid ${theme.palette.primary.dark}`,
    backgroundColor: "transparent",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "12px",
  },
}));

export const CreateButton = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.light}`,
  width: "100%",
  borderRadius: "8px",
  marginTop: "6px",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.light,
  padding: "8px",

  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    /*     color: theme.palette.primary.dark,
    border: `1px solid ${theme.palette.primary.dark}`,
    backgroundColor: "transparent", */
  },
  "&:disabled": {
    backgroundColor: theme.palette.primary.light,
  },
}));

export const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "720px",
  padding: "48px",
  borderRadius: "16px",
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.between("sm", "md")]: {
    width: "640px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "420px",
  },
}));

export const FormContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "6px",
  width: "100%",
  alignItems: "flex-end",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));
