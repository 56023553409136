import React, { useContext, useState } from "react";
import { FuncContext } from "page/workflow/context/FuncContext";
import { RowStack } from "components/stack/RowStack";
import { Modal, Stack, Typography, styled } from "@mui/material";
import { CustomStyleButton } from "components/buttons/CustomButton";
import { ModalContent } from "components/modal/ModalContent";
import { StyledTextField } from "components/textField/CustomTextField";
import ClearModal from "./ClearModal";
import { AppDispatch, RootState } from "redux/Store";
import { useDispatch, useSelector } from "react-redux";
import {
  editScenarioData,
  postScenarioData,
  setAutomationFlowData,
} from "redux/slices/AutomationSlice";
import { useTranslation } from "react-i18next";

const DisplayNameContent = styled(Stack)(({ theme }) => ({
  maxHeight: "400px",
  width: "100%",
  gap: "4px",
  padding: "8px",
  alignItems: "center",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: 6,
  },

  "&::-webkit-scrollbar-thumb:vertical": {
    borderRadius: 4,
    background: theme.palette.primary.main,
  },

  "&::-webkit-scrollbar-track:vertical": {
    borderRadius: 4,
    background: "transparent",
  },
}));

const SaveView = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const automation = useSelector((state: RootState) => state.automation);
  const user: string | null = sessionStorage.getItem("user");
  const userData = user && JSON.parse(user);

  const [sName, setSName] = useState<string>(
    automation.automationFlowData.screnario_name === ""
      ? ""
      : automation.automationFlowData.screnario_name
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { nodes, setNodes, edges, setEdges } = useContext(FuncContext);

  const setEmptyData = () => {
    setTimeout(() => {
      dispatch(
        setAutomationFlowData({
          screnario_id: 0,
          screnario_name: "",
          created: "",
          user: 0,
          content: {
            nodes: [],
            edges: [],
          },
        })
      );
      setSName("");
      setOpen(false);
      setNodes([]);
      setEdges([]);
    }, 200);
  };
  const handleSave = () => {
    console.log(sName);
    console.log({ nodes: nodes, edges: edges });
    console.log(userData.id);
    dispatch(
      postScenarioData({
        screnario_name: sName,
        content: { nodes: nodes, edges: edges },
        user: userData.id,
      })
    );

    setOpen(false);
  };

  const handleEdit = () => {
    dispatch(
      editScenarioData({
        scenarioData: {
          screnario_name: sName,
          content: { nodes: nodes, edges: edges },
          user: userData.id,
        },
        id: automation.automationFlowData.screnario_id,
      })
    );
    setOpen(false);
  };

  return (
    <RowStack>
      <Typography variant="subtitle2">{t("workflow.saveDesc")} </Typography>
      <Stack direction={"row"} gap={"12px"}>
        <CustomStyleButton
          onClick={handleOpen}
          disabled={nodes.length !== edges.length + 1}
        >
          {automation.automationFlowData.screnario_name !== ""
            ? "Edit Workflow"
            : "Save Workflow"}
        </CustomStyleButton>

        <ClearModal
          onClick={() => {
            setEmptyData();
          }}
        />
      </Stack>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent>
          <Stack gap={"16px"}>
            <StyledTextField
              id={"WorkFlowScenarioName"}
              fullWidth
              variant="standard"
              size="small"
              placeholder={t("workflow.savePlaceholder")}
              value={sName}
              onChange={(event) => {
                setSName(event.target.value);
              }}
            />
            <Stack sx={{ marginTop: "20px" }} gap={"8px"} alignItems={"center"}>
              <Typography fontWeight={700} variant="h6">
                {t("workflow.nodeList")}
              </Typography>
              {/*  <Divider sx={{ width: "40%" }} /> */}
              <DisplayNameContent>
                {nodes.map((item, i) => {
                  return (
                    <Typography
                      fontWeight={400}
                      variant="caption"
                      key={`savenodeitem${i}`}
                    >
                      {item.data.data.display_name}
                    </Typography>
                  );
                })}
              </DisplayNameContent>
            </Stack>
            {automation.automationFlowData.screnario_name === "" ? (
              <CustomStyleButton
                disabled={sName === "" || sName === " "}
                sx={{ marginTop: "20px" }}
                onClick={() => {
                  handleSave();
                }}
              >
                {t("general.submit")}
              </CustomStyleButton>
            ) : (
              <CustomStyleButton
                disabled={sName === "" || sName === " "}
                sx={{ marginTop: "20px" }}
                onClick={() => {
                  handleEdit();
                }}
              >
                {t("general.edit")}
              </CustomStyleButton>
            )}
          </Stack>
        </ModalContent>
      </Modal>
    </RowStack>
  );
};

export default SaveView;
