import { IconButton, styled } from "@mui/material";

export const CancelButtonSyled = styled(IconButton)(({ theme }) => ({
  width: "88px",
  textTransform: "lowercase",
  borderRadius: "8px",
  ...theme.typography.subtitle2,
  marginTop: "36px",
  padding: "8px 16px",
  border: `1px solid ${theme.palette.primary.main}`,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
  },
}));
