import { Stack, Tooltip, Typography, alpha, styled } from "@mui/material";
import { IGraphifyOs } from "page/graphify/type";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "layouts/theme/ThemeContext";
import {  SlimAreasChartsW } from "./charts/SlimAreasCharts";
import { ClosePort, OpenPort, PortDesc, PortInDesc } from "./PortView";


const Container = styled(Stack)(({ theme }) => ({
  alignItems: "flex-start",
  padding: "16px",
  borderRadius: "16px",
  gap: "8px",
  boxShadow: ` ${alpha(theme.palette.primary.dark, 0.25)}  0px 5px 15px `,
}));

export const ButtonContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  padding: "20px",

  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: 6,
  },
  maxHeight: "420px",
  "&::-webkit-scrollbar-thumb:vertical": {
    borderRadius: 4,
    background: theme.palette.primary.main,
  },

  "&::-webkit-scrollbar-track:vertical": {
    borderRadius: 4,
    background: "transparent",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
  },
}));

const NetworkChartsGroups = ({ data }: { data: IGraphifyOs }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [chartView, setChartView] = useState<string[]>([]);

  function findInterfacesNames() {
    const interfacesName = data?.network_interfaces
      .sort((a, b) => {
        return (
          b.data[b.data.length - 1].lastvalue -
          a.data[a.data.length - 1].lastvalue
        );
      })
      .map((item) => {
        return { name: item.name };
      });

    const uniqueData: string[] = [];
    interfacesName?.forEach((item) => {
      const parts = item.name.split(":");
      const interfaceName = parts[0].trim();

      if (!uniqueData.includes(interfaceName)) {
        uniqueData.push(interfaceName);
      }
    });
    return uniqueData.map((interfaceName) => {
      return { name: interfaceName };
    });
  }

  const interfacesName = findInterfacesNames();

  const createChartData = (name: string) => {
    let chartsData = data?.network_interfaces.filter((item) =>
      item.name.includes(name)
    );
    return chartsData ?? [];
  };

  const createChartList = (name: string) => {
    if (chartView.includes(name)) {
      setChartView(chartView.filter((item) => item !== name));
    } else return setChartView([...chartView, name]);
  };

  const createTime = data.network_interfaces[0].data.map(
    (item) => item.recivedTime
  );

  return (
    <Container>
      <Stack
        sx={{
          gap: "8px",
          marginBottom: { xs: "24px", md: "48pxpx" },
        }}
      >
        <Typography variant="body1" fontWeight={700}>
          Interfaces
        </Typography>
        <Typography variant="caption">{t("graphify.charDesc")}</Typography>
        <PortDesc />
      </Stack>

      <Stack
        sx={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: "8px",
          padding: "12px 16px",
        }}
      >
        <PortInDesc />
        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              display: "grid",
              gridTemplateRows: `repeat(2, 1fr)`,
              gridTemplateColumns: `repeat(${Math.ceil(
                interfacesName.length / 2
              )}, 1fr)`,
              gap: "4px",
            }}
          >
            {interfacesName.map((item, i) => {
              return (
                <Tooltip key={i} title={item.name}>
                  <Stack
                    sx={{
                      flexDirection:
                        interfacesName.length / 2 > i
                          ? "column"
                          : "column-reverse",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <Typography variant="caption">{i + 1}</Typography>
                    {createChartData(item.name)[0].data[0].lastvalue === 0 ? (
                      <ClosePort />
                    ) : (
                      <OpenPort
                        chartView={chartView}
                        onClick={() => {
                          createChartList(item.name);
                        }}
                        name={item.name}
                      />
                    )}
                  </Stack>
                </Tooltip>
              );
            })}
          </Stack>
        </Stack>
      </Stack>

      <Stack sx={{ width: "100%", alignItems: "center", gap: "24px" }}>
        {chartView.map((item) => {
          return (
            <Stack>
              <Typography
                sx={{
                  color: theme.palette.secondary.main,
                  marginBottom: "-24px",
                }}
                variant="subtitle2"
              >
                {item}
              </Typography>
              <SlimAreasChartsW data={createChartData(item)} time={createTime} />
            </Stack>
          );
        })}
      </Stack>
    </Container>
  );
};

export default NetworkChartsGroups;
