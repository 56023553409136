import {
  Button,
  InputBase,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { IGraphifyOs } from "page/graphify/type";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { toImageUrl } from "utils/helpers/AssetHelpers";
import { useTranslation } from "react-i18next";

const CardContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  display: "grid",
  /*   gridTemplateColumns: "1fr 1fr 1fr 1fr",
      gridTemplateRows: " auto", */
  gap: "4px",
}));

const CardContent = styled(Stack)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  gap: "16px",
  height: "36px",
  boxSizing: "border-box",
  borderRadius: "16px",
  padding: "4px 16px",
  cursor: "pointer",
  /*   boxShadow: ` ${alpha(theme.palette.primary.dark, 0.1)}  0px 5px 15px `, */
  "&:hover": {
    transition: "all 0.4s ease-in-out",
    transform: "translateX(20px) ",
    opacity: 1,
    /*   boxShadow: ` ${alpha(theme.palette.primary.dark, 0.1)}  0px 5px 15px `, */
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  padding: "0px",
  margin: "0px",
  textAlign: "left",
  color: theme.palette.error.dark,
  textTransform: "none",
  opacity: 0.6,
}));
const SearchContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  maxWidth: "450px",
  padding: "0px 4px 0px 16px",
  borderRadius: "32px",
  boxShadow: `${alpha(
    theme.palette.error.dark,
    0.25
  )} 0px 2px 5px -1px, ${alpha(
    theme.palette.error.dark,
    0.3
  )} 0px 1px 3px -1px`,
  "&:hover": {
    boxShadow: `${alpha(
      theme.palette.error.dark,
      0.35
    )} 0px 2px 5px -1px, ${alpha(
      theme.palette.error.dark,
      0.45
    )} 0px 1px 3px -1px`,
  },
  /*   border: `1px solid ${theme.palette.error.main}`, */
  [theme.breakpoints.down("sm")]: {},
}));

const GeneralList = ({
  data,
  title,
}: {
  data: IGraphifyOs[];
  title: string;
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>("");
  const [filterData, setFilterData] = useState<IGraphifyOs[]>(data);
  let navigate = useNavigate();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearchValue(event.target.value);
    setFilterData(
      data.filter((option) =>
        option.host_name.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
  };
  return (
    <Stack minHeight={"250px"} marginBottom={"30px"} gap={"24px"}>
      <Stack
        sx={{
          gap: "16px",
          flexDirection: { xs: "column", md: "row" },
        }}
        justifyContent={"space-between"}
      >
        <Stack>
          <Typography fontWeight={700} variant="h6">
            {title}
          </Typography>
          <Typography variant="caption">total : {data.length}</Typography>
        </Stack>

        <SearchContainer>
          <SearchIcon sx={{ color: "#ED243860" }} />
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="search host"
            value={searchValue}
            onChange={handleInputChange}
          />
        </SearchContainer>
      </Stack>
      <CardContainer>
        {filterData.map((item, i) => {
          return (
            <CardContent
              onClick={() => {
                navigate(`/graphifyx/os/${item.host_id}`);
              }}
              key={`hostlist_${i} `}
            >
              <img
                src={toImageUrl("/media/general/server.png")}
                alt=""
                style={{ width: "24px", height: "24px" }}
              />
              <Stack
                width={"100%"}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <Stack
                  direction={"row"}
                  sx={{ alignItems: "center", gap: "16px" }}
                >
                  <Typography fontWeight={700} variant="body2">
                    {item.host_id}
                  </Typography>
                  <Typography fontWeight={700} variant="body2">
                    {item.host_name}
                  </Typography>
                </Stack>

                <CustomButton disableRipple> {t("graphify.go")} </CustomButton>
              </Stack>
            </CardContent>
          );
        })}
      </CardContainer>
    </Stack>
  );
};

export default GeneralList;
