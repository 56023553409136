import React, { useEffect, useState } from "react";
import { Button, Stack, styled } from "@mui/material";
import { topTabBarArr } from "data/pathArr";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/Store";
import {
  setTopTabBarName,
  setTopTabBarSelect,
} from "redux/slices/LayoutsSlice";
import { ITopTabBarItem } from "redux/slices/types";
import { useNavigate } from "react-router-dom";

const TopButton = styled(Button)<{ isSelect: boolean }>(
  ({ theme, isSelect }) => ({
    textTransform: "none",
    ...theme.typography.subtitle2,
    lineHeight: "14px",
    color: isSelect ? theme.palette.warning.dark : theme.palette.primary.main,
    padding: "8px 18px",
  })
);

const TopTabMenu = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [tabTopBarTabs, setTabTopTabs] = useState<ITopTabBarItem[]>([]);
  /*  const [nameSelect, setNameSelect] = useState<string>(""); */
  const layouts = useSelector((state: RootState) => state.layouts);
  useEffect(() => {
    const newObj = topTabBarArr.find(
      (item) => item.title.name === layouts.topbarMainTitle
    );
    const path = window.location.pathname;
    const parts = path.split("/");
    const subPath = parts[2];
    if (newObj) {
      if (subPath) {
        const selectedTab = newObj.tab.find((item) =>
          item.path.includes(subPath)
        );
        selectedTab && dispatch(setTopTabBarName(selectedTab?.name));
      } else {
        dispatch(setTopTabBarName(newObj.tab[0].name));
      }

      setTabTopTabs(newObj.tab);
      dispatch(setTopTabBarSelect(newObj));
    } else {
      setTabTopTabs([]);
    }
  }, [dispatch, layouts.topbarMainTitle]);
  /* add dispatch  */

  return (
    <Stack sx={{ flexDirection: { xs: "column", md: "row" } }}>
      {tabTopBarTabs.map((item) => {
        return (
          <TopButton
            onClick={() => {
              navigate(`/${layouts.topTabBarSelect.title.path}/${item.path}`);
              dispatch(setTopTabBarName(item.name));
            }}
            isSelect={item.name === layouts.topTabBarName}
            key={`tabBarName${item.name}`}
          >
            {item.name}
          </TopButton>
        );
      })}
    </Stack>
  );
};

export default TopTabMenu;
