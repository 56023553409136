import React from "react";
import AgentManagementView from "./components/AgentManagementView";
import AgentTable from "./components/AgentTable";
import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { IAgentDataProps } from "./type/Type";
import CustomLoading from "components/loading/CustomLoading";
import { fetchGet } from "apis/Get";
import PageContainer from "page/general/PageContainer";

const AgentManagementPage = () => {
  const agentManagemenData = useQuery({
    queryKey: ["agentManagemenData"],
    queryFn: async () => await fetchGet(`/agent/`),
    select: (data: IAgentDataProps[]) => data,
  });

  return (
    <PageContainer>
      {agentManagemenData.isLoading ? (
        <CustomLoading />
      ) : (
        <Stack>
          {agentManagemenData.data && (
            <AgentManagementView data={agentManagemenData.data} />
          )}
          {agentManagemenData.data && (
            <AgentTable
              data={agentManagemenData.data}
              loading={agentManagemenData.isLoading}
            />
          )}
        </Stack>
      )}
    </PageContainer>
  );
};

export default AgentManagementPage;
