import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VerifcationModel, ICurrentUser, IUserInfo } from "./types";
import { baseUrl } from "utils/helpers/BaseUrl";
import { Notify } from "components/notify/notify";

export interface LayoutsState {
  currentUser: ICurrentUser;
  verifyStatus: boolean;
  loginLoading: boolean;
  userInfo: IUserInfo;
  loginMessage: string;
}

const initialState: LayoutsState = {
  currentUser: {
    id: 0,
    token: "",
  },
  verifyStatus: false,
  loginLoading: false,
  userInfo: { token: "" },
  loginMessage: "",
};

export const fetchHandleLogin = createAsyncThunk<
  ICurrentUser,
  VerifcationModel,
  {
    rejectValue: string;
  }
>("login/fetchHandleLogin", async (value, { rejectWithValue }) => {
  try {
    if (value.email) {
      var formdata = new FormData();
      formdata.append("username", `${value.email}`);
      formdata.append("password", `${value.password}`);

      const response = await fetch(`${baseUrl}/auth/login/`, {
        method: "POST",
        body: formdata,
        redirect: "follow",
      });

      if (response.status === 200) {
        const result: ICurrentUser = await response.json();
        return result;
      } else if (response.status === 400) {
        return rejectWithValue("The login details are incorrect.");
      } else {
        Notify.notifyError("Your password may be incorrect, please try again.");
        return rejectWithValue("The login details are incorrect.");
      }
    } else {
      return rejectWithValue("Username is required.");
    }
  } catch (error) {
    return rejectWithValue("An error occurred.");
  }
});

export const postLoginVerified = createAsyncThunk(
  "login/postLoginVerified",
  async (code: string) => {
    const user: string | null = sessionStorage.getItem("user");
    if (user !== null) {
      const userData = JSON.parse(user);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Token ${userData.token}`);

      try {
        const response = await fetch(`${baseUrl}/auth/verify_code/`, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            login_code: `${code}`,
          }),
          redirect: "follow",
        });

        if (response.status === 200) {
          const result = await response.json();
          if (result.status === true) {
            sessionStorage.setItem("emailToken", JSON.stringify(result.status));
            document.location.reload();
            return result;
          } else {
            Notify.notifyError(
              "Your Login Code may be incorrect, please try again."
            );
          }
        } else if (response.status === 400) {
          throw new Error("The login details are incorrect.");
        } else {
          throw new Error("Network error");
        }
      } catch (error) {}
    } else {
      throw new Error("User not found");
    }
  }
);

const handleLoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    handleLogOut: (state) => {
      try {
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("emailToken");
        state.currentUser = {
          id: 0,
          token: "",
        };
        state.userInfo = { token: "" };
        document.location.reload();
      } catch (error) {
        console.log(error);
      }
    },
    setUserInfo: (state, action: PayloadAction<ICurrentUser>) => {
      state.currentUser = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loginLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHandleLogin.fulfilled, (state, action) => {
      state.loginLoading = false;
      state.verifyStatus = true;
      state.currentUser = action.payload;
      sessionStorage.setItem("user", JSON.stringify(action.payload));
      state.loginMessage = "";
    });

    builder.addCase(fetchHandleLogin.rejected, (state, action) => {
      state.loginLoading = false;
      state.loginMessage = action.payload as string;
    });
    builder.addCase(postLoginVerified.rejected, (state, action) => {
      state.loginLoading = false;
      state.loginMessage = action.payload as string;
    });
  },
});

export const { handleLogOut, setUserInfo, setLoading } =
  handleLoginSlice.actions;
export default handleLoginSlice.reducer;
