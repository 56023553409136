// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dndflow {
  flex-direction: column;
  width: 100%;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  height: 20px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}
.dndflow .dndnode.input:hover {
  background-color: #0041d020;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/page/workflow/components/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,4BAA4B;EAC5B,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,gBAAgB;EAClB;AACF","sourcesContent":[".dndflow {\n  flex-direction: column;\n  width: 100%;\n  display: flex;\n  flex-grow: 1;\n  height: 100%;\n}\n\n.dndflow aside {\n  border-right: 1px solid #eee;\n  padding: 15px 10px;\n  font-size: 12px;\n  background: #fcfcfc;\n}\n\n.dndflow aside .description {\n  margin-bottom: 10px;\n}\n\n.dndflow .dndnode {\n  height: 20px;\n  padding: 4px;\n  border: 1px solid #1a192b;\n  border-radius: 8px;\n  margin-bottom: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: grab;\n}\n\n.dndflow .dndnode.input {\n  border-color: #0041d0;\n}\n.dndflow .dndnode.input:hover {\n  background-color: #0041d020;\n}\n\n.dndflow .reactflow-wrapper {\n  flex-grow: 1;\n  width: 100%;\n  height: 100%;\n}\n\n.dndflow .selectall {\n  margin-top: 10px;\n}\n\n@media screen and (min-width: 768px) {\n  .dndflow {\n    flex-direction: row;\n  }\n\n  .dndflow aside {\n    width: 20%;\n    max-width: 250px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
