import { Stack } from "@mui/material";
import React from "react";
import IntegrationList from "./components/IntegrationList";

const IntegrationTicket = () => {
  return (
    <Stack width={"100%"} gap={"36px"}>
      <IntegrationList />
    </Stack>
  );
};

export default IntegrationTicket;
