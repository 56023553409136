import React from "react";
import { Stack } from "@mui/material";
import { IGraphifyOs } from "page/graphify/type";
import { useTranslation } from "react-i18next";
import GeneralTopCharts from "../GeneralComponents/GeneralTopCharts";
import {
  createNetworkFn,
  createRoseDataFunc,
} from "page/graphify/functions/NetworkFunctions";
import { createMetricsRoseDataFunc } from "page/graphify/functions/MetricsFunctions";

const OsTopCharts = ({ data }: { data: IGraphifyOs[] }) => {
  const { t } = useTranslation();
  const InterfacesOutData = createNetworkFn(data, "sent").slice(0, 7);
  const InterfacesInData = createNetworkFn(data, "received").slice(0, 7);

  const totalData = [
    {
      name: t("graphify.osMemoryDashboardTitle"),
      data: createMetricsRoseDataFunc(data, "Linux: Memory utilization").slice(
        0,
        7
      ),
      desc: t("graphify.memoryDashboardDesc"),
    },
    {
      name: t("graphify.osCpuDashboardTitle"),
      data: createMetricsRoseDataFunc(data, "Linux: CPU utilization").slice(
        0,
        7
      ),
      desc: t("graphify.cpuDashboardDesc"),
    },
    {
      name: t("graphify.osDiskDashboardTitle"),
      data: createMetricsRoseDataFunc(data, "sda: Disk utilization").slice(
        0,
        7
      ),
      desc: t("graphify.diskDashboarDesc"),
    },
    {
      name: t("graphify.osNetworkInDashboardTitle"),
      data: createRoseDataFunc(InterfacesInData),
      desc: t("graphify.networkInDashboardDesc"),
    },
    {
      name: t("graphify.osNetworkOutDashboardTitle"),
      data: createRoseDataFunc(InterfacesOutData),
      desc: t("graphify.networkOutDashboardDesc"),
    },
  ];

  return (
    <Stack>
      <GeneralTopCharts data={totalData} type="os" showDisk={true} />
    </Stack>
  );
};

export default OsTopCharts;
