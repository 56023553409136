import React, { useEffect, useState } from "react";
import { Divider, Drawer, IconButton, Stack, Typography } from "@mui/material";
import TopTabMenu from "./TopTabMenu";
import { topTabBarArr } from "data/pathArr";
import HouseIcon from "@mui/icons-material/House";
import CustomTooltip from "components/tooltip/tooltip";
import { RootState } from "redux/Store";
import { useSelector } from "react-redux";
import { ITopTabBarItem } from "redux/slices/types";

const TopTabBarMobil = () => {
  const [tabOpen, setTabOpen] = useState<boolean>(false);
  const [tabTopBarTabs, setTabTopTabs] = useState<ITopTabBarItem[]>([]);
  const layouts = useSelector((state: RootState) => state.layouts);

  useEffect(() => {
    const newObj = topTabBarArr.find(
      (item) => item.title.name === layouts.topbarMainTitle
    );
    if (newObj) {
      setTabTopTabs(newObj.tab);
    } else {
      setTabTopTabs([]);
    }
  }, [layouts.topbarMainTitle]);
  return (
    <Stack sx={{ display: { xs: "block ", md: "none" } }}>
      {tabTopBarTabs.length > 0 && (
        <Stack>
          <CustomTooltip title={"Top Menu"}>
            <IconButton
              onClick={() => {
                setTabOpen(true);
              }}
            >
              <HouseIcon sx={{ fontSize: "18px" }} />
            </IconButton>
          </CustomTooltip>

          <Drawer
            variant="temporary"
            open={tabOpen}
            anchor={"right"}
            onClose={() => {
              setTabOpen(false);
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: "200px" },
            }}
          >
            <Stack marginTop={"24px"} padding={"16px"}>
              {tabOpen && (
                <Typography
                  fontWeight={700}
                  variant="body1"
                  width={"100%"}
                  align="center"
                >
                  MENU
                </Typography>
              )}
              <Divider />
            </Stack>

            <TopTabMenu />
          </Drawer>
        </Stack>
      )}
    </Stack>
  );
};

export default TopTabBarMobil;
