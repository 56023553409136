import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { fetchGet } from "apis/Get";
import { fetchPatch } from "apis/Patch";
import { fetchPost } from "apis/Post";
import { Notify } from "components/notify/notify";
import { IFlowArr } from "page/workflow/types/nodeTypes";
import {
  IScenarioContent,
  IScenarioList,
  IScenariodata,
} from "page/workflow/types/types";
import { useTranslation } from "react-i18next";

export interface AutomationState {
  sidebarData: IFlowArr[];
  automationFlowData: IScenariodata;
  scenarioData: IScenariodata;
  scenarioContent: IScenarioContent;
  scenarioList: IScenarioList[];
  scenarioLoading: boolean;
}

const initialState: AutomationState = {
  sidebarData: [],
  automationFlowData: {
    screnario_id: 0,
    screnario_name: "",
    created: "",
    user: 0,
    content: {
      nodes: [],
      edges: [],
    },
  },
  scenarioContent: {
    nodes: [],
    edges: [],
  },
  scenarioLoading: false,
  scenarioData: {} as IScenariodata,
  scenarioList: [],
};

export const getScenarioList = createAsyncThunk("getScenarioList", async () => {
  const data = await fetchGet(`/scenario/`);
  return data;
});

export const getScenario = createAsyncThunk(
  "getScenario",
  async (id: number) => {
    const data = await fetchGet(`/scenario/${id}/`);
    return data;
  }
);

export const postScenarioData = createAsyncThunk(
  "postScenarioData",
  async (scenarioData: any) => {
    return fetchPost(scenarioData, `/scenario/`).then(() => {
      Notify.notifySuccess("Workflow saved successfully");
      getScenarioList();
    });
  }
);
/*  editScenarioData , postScenarioData data type -----> 
          {
            screnario_name: data.screnario_name,
            content: { nodes: data.nodes, edges: data.edges },
            user: userData.id,
          }, */

export const editScenarioData = createAsyncThunk(
  "editScenarioData",
  async ({ scenarioData, id }: { scenarioData: any; id: number }) => {
    return fetchPatch(scenarioData, `/scenario/${id}/`).then(() => {
      Notify.notifySuccess("Workflow edited successfully");
      getScenarioList();
    });
  }
);

export const postJobData = createAsyncThunk(
  "postJobData",
  async (scenarioData: any) => {
    return fetchPost(scenarioData, `/job/`).then(() => {
      Notify.notifySuccess("Notification added to list");
      getScenarioList();
    });
  }
);
/*
scenarioData ---->
{ agent: agentId,
  scenario: scenarioId,
}, */

export const automationSlice = createSlice({
  name: "automation",
  initialState,
  reducers: {
    setAutomationFlowData: (state, action: PayloadAction<any>) => {
      if (action.payload !== "") {
        state.automationFlowData = action.payload;
      } else {
        state.automationFlowData = state.scenarioData;
      }
    },
    setScenarioContent: (state, action: PayloadAction<IScenarioContent>) => {
      state.scenarioContent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getScenarioList.pending, (state, action) => {
      state.scenarioLoading = true;
    });
    builder.addCase(getScenarioList.fulfilled, (state, action) => {
      state.scenarioList = action.payload;
      state.scenarioLoading = false;
    });
    builder.addCase(getScenario.fulfilled, (state, action) => {
      state.scenarioData = action.payload;
    });
  },
});

export const { setAutomationFlowData, setScenarioContent } =
  automationSlice.actions;

export default automationSlice.reducer;
