import * as React from "react";
import { styled, CSSObject, Theme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Drawer from "@mui/material/Drawer";
import CustomMenuItem from "./CustomMenuItem";
import { IconLogoLarge } from "components/icons/Logos";
import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { useTheme } from "layouts/theme/ThemeContext";
import {
  setMobileOpen,
  setSidebarIsOpen,
  setTopbarMainTitle,
} from "redux/slices/LayoutsSlice";
import { RootState } from "redux/Store";
import { useDispatch, useSelector } from "react-redux";

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")<{ open: boolean }>(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxSizing: "border-box",
  height: "90px",
  padding: open === true ? "16px 20px " : "20px 10px",

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const LargeDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiPaper-root": {
    margin: "10px",
    height: "calc(100% - 20px)",
    boxSizing: "border-box",
    borderRadius: "16px",
    boxShadow: ` ${alpha(
      theme.palette.primary.dark,
      0.25
    )}  0px 2px 5px -1px,${alpha(
      theme.palette.primary.contrastText,
      0.3
    )}  0px 1px 3px -1px `,
    borderRight: "none",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const TitleSub = styled(Typography)(({ theme }) => ({
  ...theme.typography.h1,
  fontSize: "28px",
  lineHeight: "30px",
  fontWeight: 600,
  fontFamily: "Saira Condensed",
  textDecoration: "none",
  color: theme.palette.text.primary,
}));

const TitleDsc = styled(Typography)(({ theme }) => ({
  fontSize: "10px",
  lineHeight: "12px",
  fontWeight: 600,
  letterSpacing: "3px",
  fontFamily: "Saira Condensed",
  textDecoration: "none",
  color: theme.palette.text.primary,
}));

const Title = () => {
  const { theme } = useTheme();
  return (
    <Stack
      gap={"16px"}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Stack>
        <TitleSub> Robenice </TitleSub>
        <TitleDsc>orchestrator</TitleDsc>
      </Stack>

      <IconLogoLarge
        sx={{
          width: "40px",
          height: "40px",
        }}
        fill={theme.palette.text.primary}
      />
    </Stack>
  );
};

function CustomMuiSidebar() {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const layouts = useSelector((state: RootState) => state.layouts);
  const open = layouts.sidebarIsOpen;

  return (
    <Box>
      <Drawer
        variant="temporary"
        open={layouts.mobileIsOpen}
        onClose={() => {
          dispatch(setMobileOpen(false));
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        <DrawerHeader open={open}>
          {open && (
            <Link
              onClick={() => {
                dispatch(setTopbarMainTitle("Dashboard"));
              }}
              style={{
                textDecoration: "none",
              }}
              to="/dashboard"
            >
              <Title />
            </Link>
          )}
          <Divider />
        </DrawerHeader>
        <CustomMenuItem open={open} />
      </Drawer>
      <LargeDrawer
        sx={{
          display: { xs: "none", sm: "block" },
        }}
        variant="permanent"
        open={open}
      >
        <DrawerHeader open={open}>
          {open && (
            <Link
              onClick={() => {
                dispatch(setTopbarMainTitle("Dashboard"));
              }}
              style={{
                textDecoration: "none",
              }}
              to="/dashboard"
            >
              <Title />
            </Link>
          )}
          <IconButton
            onClick={() => {
              dispatch(setSidebarIsOpen());
            }}
          >
            {open === false ? (
              <IconLogoLarge fill={theme.palette.text.primary} />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <CustomMenuItem open={open} />
      </LargeDrawer>
    </Box>
  );
}

export default CustomMuiSidebar;
