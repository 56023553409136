import React, { useState } from "react";
import { Stack, Modal, styled, Box, Typography } from "@mui/material";
import CustomTooltip from "components/tooltip/tooltip";
import { RowStack } from "components/stack/RowStack";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import { useTheme } from "layouts/theme/ThemeContext";
import JobTable from "./JobTable";
import { CustomIconButton } from "components/buttons/CustomIconButton";
import { CancelButtonSyled } from "components/buttons/CancelButtonSyled";
import { baseUrl } from "utils/helpers/BaseUrl";
import { useTranslation } from "react-i18next";

interface IData {
  created: string;
  agent?: string;
  scenario?: number;
  user?: string;
  extra_parameters?: any[];
  job_response?: string;
  status?: number;
}
const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "48px",
  borderRadius: "16px",
  maxHeight: "700px",

  backgroundColor: theme.palette.background.default,
}));

const Content = styled(Stack)(({ theme }) => ({
  height: "100%",
  width: "100%",
  minWidth: "600px",
  gap: "16px",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: 6,
  },

  "&::-webkit-scrollbar-thumb:vertical": {
    borderRadius: 4,
    background: theme.palette.primary.main,
  },

  "&::-webkit-scrollbar-track:vertical": {
    borderRadius: 4,
    background: "transparent",
  },
}));

const JobModal = ({ flowData }: { flowData: { id: number; name: string } }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [agentList, setAgentList] = useState<IData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getJobList = async () => {
    setLoading(true);
    const user: string | null = sessionStorage.getItem("user");
    try {
      if (user !== null) {
        const userData = JSON.parse(user);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Token ${userData.token}`);
        await fetch(`${baseUrl}/scenario/${flowData.id}/job/`, {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        })
          .then((response) => response.json())
          .then((result) => {
            setAgentList(result);
            setLoading(false);
          })
          .catch((_error) => {});
      }
    } catch (error) {}
  };

  return (
    <Stack>
      <CustomTooltip title={"Job"}>
        <CustomIconButton
          onClick={() => {
            setOpenModal(true);
            getJobList();
          }}
        >
          <WorkOutlineIcon />
        </CustomIconButton>
      </CustomTooltip>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent>
          <Content>
            <RowStack gap={"24px"}>
              <Typography align="center" fontWeight={700} variant="body1">
                {flowData.name.toLowerCase()}
              </Typography>
            </RowStack>
            {agentList.length === 0 ? (
              <Typography
                sx={{
                  color: theme.palette.error.light,
                }}
                variant="subtitle2"
              >
                {t("automation.emptyJob")}
              </Typography>
            ) : (
              <JobTable data={agentList} loading={loading} />
            )}
          </Content>

          <Stack
            direction={"row"}
            width={"100%"}
            gap={"16px"}
            justifyContent={"flex-end"}
          >
            <CancelButtonSyled
              onClick={() => {
                setOpenModal(false);
              }}
            >
              {t("general.cancel")}
            </CancelButtonSyled>
          </Stack>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default JobModal;
