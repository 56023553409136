import ReactApexChart from "react-apexcharts";

export const SlimLineCharts = ({
  data,
  color,
}: {
  data: number[];
  color: string;
}) => {
  return (
    <ReactApexChart
      options={{
        chart: {
          height: 80,
          type: "area",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
          },
        },
        title: {
          margin: 0,
        },
        stroke: {
          width: 3,
          curve: "smooth",
          colors: [color],
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
            offsetX: -10,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },

        xaxis: {
          labels: {
            show: false,
            offsetX: -10,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          enabled: false, // Tooltip'u devre dışı bırakır
        },
      }}
      series={[
        {
          data: data,
        },
      ]}
      type="line"
      height={80}
    />
  );
};
