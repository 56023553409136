import React from "react";
import { Stack } from "@mui/material";
import { IGraphifyOs } from "page/graphify/type";
import { useTranslation } from "react-i18next";
import {
  createNetworkFn,
  createRoseDataFunc,
} from "page/graphify/functions/NetworkFunctions";
import { createMetricsRoseDataFunc } from "page/graphify/functions/MetricsFunctions";
import GeneralTopCharts from "../../GeneralComponents/GeneralTopCharts";

const NetworkTopCharts = ({ data }: { data: IGraphifyOs[] }) => {
  const { t } = useTranslation();
  const InterfacesOutData = data && createNetworkFn(data, "sent").slice(0, 7);
  const InterfacesInData =
    data && createNetworkFn(data, "received").slice(0, 7);
  const totalData = [
    {
      name: t("graphify.netMemoryDashboardTitle"),
      data: createMetricsRoseDataFunc(data, "Linux: Memory utilization").slice(
        0,
        7
      ),
      desc: t("graphify.memoryDashboardDesc"),
    },
    {
      name: t("graphify.netCpuDashboardTitle"),
      data: createMetricsRoseDataFunc(data, "Linux: CPU utilization").slice(
        0,
        7
      ),
      desc: t("graphify.cpuDashboardDesc"),
    },
    {
      name: t("graphify.netDiskDashboardTitle"),
      data: createMetricsRoseDataFunc(data, "sda: Disk utilization").slice(
        0,
        7
      ),
      desc: t("graphify.diskDashboarDesc"),
    },
    {
      name: t("graphify.netNetworkInDashboardTitle"),
      data: createRoseDataFunc(InterfacesInData),
      desc: t("graphify.networkInDashboardDesc"),
    },
    {
      name: t("graphify.netNetworkOutDashboardTitle"),
      data: createRoseDataFunc(InterfacesOutData),
      desc: t("graphify.networkOutDashboardDesc"),
    },
  ];

  return (
    <Stack>
      <GeneralTopCharts data={totalData} type={"network"}  showDisk={false} />
    </Stack>
  );
};

export default NetworkTopCharts;
