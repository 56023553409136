import React, { useRef, useCallback, useContext, useEffect } from "react";
import ReactFlow, {
  addEdge,
  Controls,
  Edge,
  Connection,
  updateEdge,
} from "reactflow";
import "reactflow/dist/style.css";
import Sidebar from "./sidebar/Sidebar";
import "./index.css";
import { Size, useWindowSize } from "utils/hooks/use_window_size";
import { IDataNode } from "../types/nodeTypes";
import { Stack, styled } from "@mui/material";
import { typesContext } from "../context/TypesContext";
import { FuncContext } from "../context/FuncContext";
import SaveView from "./save/SaveView";
import BuiltinNode from "./nodes/builtinNode/BuiltinNode";
import BreakNode from "./nodes/breakNode/BreakNode";
import InputNode from "./nodes/inputNode/InputNode";
import GeneralNode from "./nodes/generalNode/GeneralNode";
import TopSidebar from "./topSidebar/TopSidebar";
import { AppDispatch, RootState } from "redux/Store";
import { useDispatch, useSelector } from "react-redux";
import {
  setAutomationFlowData,
  setScenarioContent,
} from "redux/slices/AutomationSlice";

const ReactFlowContent = styled("div")<{ windowsize: Size }>(
  ({ theme, windowsize }) => ({
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    width: "%70",
    height: `calc(${windowsize.height}px - 190px)`,
  })
);

function generateCustomUID(length: number): string {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let uid = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    uid += characters[randomIndex];
  }
  return uid;
}

const nodeTypes = {
  customNode: GeneralNode,
  cInput: InputNode,
  builtinNode: BuiltinNode,
  breakNode: BreakNode,
  /*   scriptNode: ScriptNode, */
};

const edgeTypes = {
  /*  buttonedge: ButtonEdge, */
};

const CustomReactFlow = () => {
  const windowsize: Size = useWindowSize();
  const dispatch: AppDispatch = useDispatch();
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const automation = useSelector((state: RootState) => state.automation);
  const { reactFlowInstance, setReactFlowInstance } = useContext(typesContext);
  let { nodes, setNodes, onNodesChange, edges, setEdges, onEdgesChange } =
    useContext(FuncContext);
  const onConnect = useCallback((params: Edge | Connection) => {
    setEdges((eds) => addEdge({ ...params, type: "buttonedge" }, eds));
  }, []);

  const onEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) =>
      setEdges((els) => updateEdge(oldEdge, newConnection, els)),
    []
  );

  const getId = () => `dndnode_${generateCustomUID(5)}`;

  useEffect(() => {
    /*  Edit etme kısmında datanın dolması için  */
    if (automation.automationFlowData.screnario_name !== "") {
      setEdges(automation.automationFlowData.content.edges);
      setNodes(automation.automationFlowData.content.nodes);
    } else {
      /*  Save etme kısmında sayfa geçişlerinde sayfanın dolması için */
      setEdges(automation.scenarioContent.edges);
      setNodes(automation.scenarioContent.nodes);
    }
  }, []);

  useEffect(() => {
    /* Sayfa geçişlerinde edit kısmı aktıfken değişen datanın güncellenmesi için  */
    if (automation.automationFlowData.screnario_name !== "") {
      dispatch(
        setAutomationFlowData({
          ...automation.automationFlowData,
          content: {
            nodes: nodes,
            edges: edges,
          },
        })
      );
    } else {
      /* Sayfa geçişlerinde save kısmı aktıfken değişen datanın güncellenmesi için  */
      dispatch(
        setScenarioContent({
          nodes: nodes,
          edges: edges,
        })
      );
    }
  }, [edges, nodes]);

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event: any) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current!.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      if (typeof type === "undefined" || !type) {
        return;
      }
      const node: IDataNode = JSON.parse(
        event.dataTransfer.getData("application/reactflow1")
      );

      const position = reactFlowInstance!.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const customId = getId();
      const newNode = {
        id: customId,
        type,
        position,
        data: { ...node.data, component_id: customId },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );
  /*   console.log("edges:", toJS(edges));
  console.log("nodes:", nodes); */
  return (
    <Stack className="dndflow">
      <Stack direction={"row"}>
        <TopSidebar />
        <ReactFlowContent windowsize={windowsize} ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onEdgeUpdate={onEdgeUpdate}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            className="touchdevice-flow"
            fitView
          >
            <Controls />
          </ReactFlow>
          <Sidebar />
        </ReactFlowContent>
      </Stack>
      <SaveView />
    </Stack>
  );
};

export default CustomReactFlow;
