import React, { useState } from "react";
import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import { RowStack } from "components/stack/RowStack";
import { useQuery } from "@tanstack/react-query";
import { usePostMutation } from "query/PostQuery";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/Store";
import { handleLogOut } from "redux/slices/LoginSlice";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { fetchGet } from "apis/Get";

interface IMailStatus {
  is_mail: boolean;
}
const AccountSettings = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const getUserMailStatus = useQuery({
    queryKey: ["getUserMailStatus"],
    queryFn: async () => await fetchGet(`/auth/change_pref/`),
    select: (data: IMailStatus) => data,
  });
  const postUserMailStatus = usePostMutation();
  const [checked, setChecked] = useState<boolean>(
    getUserMailStatus.data?.is_mail ? getUserMailStatus.data?.is_mail : false
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const user = sessionStorage.getItem("user");

  return (
    <div>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <PersonIcon sx={{ fontSize: "18px" }} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        /* onClick={handleClose} */
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Stack minWidth={"240px"} p={"20px"}>
          <Stack gap={"4px"}>
            <Typography pl={"14px"} variant="caption">
              Account:
            </Typography>
            <RowStack gap={"24px"}>
              <Typography pl={"14px"} fontWeight={700} variant="caption">
                {user && JSON.parse(user).user}
              </Typography>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography variant="caption">off</Typography>
                <Switch
                  size="small"
                  color="warning"
                  checked={checked}
                  onChange={(event) => {
                    setChecked(event.target.checked);
                    /*  mainStore.postUserMailStatus(event.target.checked); */
                    postUserMailStatus.mutate(
                      {
                        data: { is_mail: event.target.checked },
                        url: `/auth/change_pref/`,
                      },
                      {
                        onSuccess() {
                          getUserMailStatus.refetch();
                        },
                      }
                    );
                  }}
                />
                <Typography variant="caption">on</Typography>
              </Stack>
            </RowStack>
          </Stack>

          <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
          {/*     <MenuItem
            onClick={() => {
              navigate(`/settings`);
              handleClose();
            }}
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            <Typography variant="caption">Settings</Typography>
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              navigate(`/account`);
              handleClose();
            }}
          >
            <ListItemIcon>
              <ManageAccountsIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="caption">Account</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              dispatch(handleLogOut());
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <Typography variant="caption">Logout</Typography>
          </MenuItem>
        </Stack>
      </Menu>
    </div>
  );
};

export default AccountSettings;
