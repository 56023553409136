import React, { useState } from "react";
import {
  IconButton,
  Modal,
  Stack,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import CustomTooltip from "components/tooltip/tooltip";
import { IFilterAlarms } from "../types/Type";
import FilterAlarmTable from "./FilterAlarmTable";
import { baseUrl } from "utils/helpers/BaseUrl";
import { useTranslation } from "react-i18next";

export const ModalContent = styled(Stack)(({ theme }) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  padding: "48px",
  borderRadius: "16px",
  backgroundColor: theme.palette.background.default,
}));

const ShowAlarm = ({ id }: { id: number }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IFilterAlarms[]>([]);

  const getFilterData = async (id: number) => {
    const user: string | null = sessionStorage.getItem("user");
    setLoading(true);
    try {
      if (user !== null) {
        const userData = JSON.parse(user);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Token ${userData.token}`);
        await fetch(`${baseUrl}/filter/${id}/alert/`, {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        })
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            setData(result);
            setLoading(false);
          })

          .catch((_error) => {});
      }
    } catch (error) {}
  };

  return (
    <Stack>
      <CustomTooltip title={"Show Alarm"}>
        <IconButton
          onClick={() => {
            getFilterData(id);
            setOpen(true);
          }}
        >
          <SlideshowIcon />
        </IconButton>
      </CustomTooltip>
      <Modal
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent width={"1400px"}>
          <Stack gap={"16px"}>
            <Stack>
              <Typography fontWeight={700} variant="h6">
                {t("filterManagement.filteredAlerts")}
                Filtered Alerts
              </Typography>
              <Typography variant="caption">
                {t("general.total")} {data.length}{" "}
                {t("filterManagement.alerts")}
              </Typography>
            </Stack>

            {data.length < 1 ? (
              <Stack padding={"24px"} width={"100%"} alignItems={"center"}>
                <Typography color={"warning.dark"} variant="subtitle2">
                  {t("filterManagement.emptyFilteredAlerts")}
                </Typography>
              </Stack>
            ) : (
              <FilterAlarmTable data={data} loading={loading} />
            )}
          </Stack>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default ShowAlarm;
