import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Stack } from "@mui/material";
import { useTheme } from "layouts/theme/ThemeContext";
import "./style.css";

const Logo = () => {
  const { theme } = useTheme();

  return (
    <>
      <svg
        width="200"
        height="173"
        viewBox="0 0 200 173"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M87.6018 35.9216L82.2564 7.77058C82.147 6.89783 82.6923 6.42738 83.5654 6.24838C92.0746 4.50287 109.551 0.990014 111.384 0.902739C113.217 0.815465 114.039 1.88458 114.221 2.43004L116.73 15.8485C116.73 15.8485 131.13 15.8485 139.639 20.3213C141.821 15.8485 146.948 9.30299 146.948 9.30299C146.948 9.30299 148.149 7.5575 150.003 8.64836C157.494 13.3394 172.239 23.6835 173.458 24.9032C174.331 25.7761 173.767 27.1038 173.458 27.4124C173.131 27.7397 166.585 38.5399 166.585 38.5399C166.585 38.5399 177.495 50.7584 178.804 57.6312C182.295 56.9766 190.717 55.5367 192.549 55.013C193.531 54.7325 194.306 55.5586 194.404 56.104C195.822 63.9587 199.64 81.959 199.968 84.5773C200.181 86.2835 199.308 86.4842 198.191 86.7412L198.113 86.7591C195.277 87.4137 184.784 88.941 184.913 89.3773C186.484 94.701 182.658 106.505 180.549 111.741C183.604 113.814 190.084 118.243 191.568 119.378C193.051 120.512 192.84 121.742 192.549 122.214C187.313 129.778 176.731 145.102 176.295 145.888C175.858 146.673 174.767 146.869 173.349 146.106C168.985 143.379 162.479 138.394 162.003 138.906C156.221 145.124 149.276 148.433 143.13 151.342C144.039 155.379 145.552 164.63 145.639 165.415C145.727 166.201 145.421 166.536 144.548 166.724C136.148 168.543 117.997 172.179 116.075 172.179C115.093 172.179 114.439 171.561 114.221 170.979C111.821 161.852 108.984 144.142 108.984 144.142C108.984 144.142 123.203 144.397 129.166 144.142C129.166 141.96 134.081 131.296 135.494 131.596C171.931 139.342 155.567 119.487 159.276 120.142C165.301 121.205 164.622 113.269 164.185 112.505C164.694 112.105 165.254 111.371 166.04 109.887C166.825 108.403 164.949 104.65 164.84 103.341C166.04 103.232 166.585 103.45 170.185 101.705C175.358 99.1968 174.331 93.8501 172.476 91.5592C170.622 89.2682 168.113 85.9955 166.258 82.8318C164.987 80.664 163.64 79.7772 163.64 72.3588C163.967 67.1224 165.582 59.6604 160.258 50.7584C153.603 39.6309 143.894 30.1397 125.675 27.4124C111.101 25.2305 94.2201 32.1761 87.6018 35.9216Z"
          fill="#DE481E"
          className="wheel"
        />
        <path
          d="M56.0739 39.844H40.8009L38.8373 33.9528H58.1468L70.147 44.8623H83.7837L84.7655 43.6621C92.7293 35.4801 106.802 32.2075 118.475 31.4439C130.148 30.6802 142.912 35.9167 151.421 44.8623C159.931 53.808 161.131 62.9718 160.149 68.8628C159.363 73.5756 160.476 78.6446 161.131 80.2083C161.785 82.3538 163.619 87.4079 167.676 91.8815C171.931 96.5723 169.279 99.2677 167.676 99.518C164.185 100.063 161.073 100.827 161.131 102.791C161.24 106.5 161.676 105.082 162.222 107.373C162.658 109.205 162.04 110.027 161.676 110.209L158.185 111.3L160.149 113.264C161.72 114.747 160.803 116.282 160.149 116.864L155.894 117.409C154.04 118.173 154.702 119.809 155.022 121.337C157.422 132.791 151.531 130.937 142.694 129.191C133.857 127.446 133.934 128.589 131.239 130.61C128.621 132.573 127.094 137.919 126.548 140.864C110.577 143.395 91.7475 136.755 84.3292 133.119L87.4929 125.919V120.791H110.184C113.85 118.26 111.712 115.518 110.184 114.464H80.62L63.6015 130.61H56.0739V124.719H61.3105L78.5472 108.682H83.7837C83.6091 105.976 81.02 100.427 79.7472 97.9907H103.53C108.417 95.2852 105.566 92.7906 103.53 91.8815H55.7467L43.8556 103.118H27.6007V97.118H41.7828L53.4558 85.8814H73.9653C72.9181 84.1359 72.0017 79.554 71.6743 77.4812H120.33C124.606 74.9502 122.112 72.0629 120.33 70.9356H53.4558V64.7173H72.438C72.9835 59.6988 74.4016 58.4626 75.2743 56.6444H104.075C108.439 54.2007 105.893 51.5534 104.075 50.5352H68.1833L56.0739 39.844Z"
          fill={theme.palette.primary.main}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.4914 50.6443C35.324 50.6443 41.6736 44.2947 41.6736 36.4621C41.6736 28.6296 35.324 22.28 27.4914 22.28C19.6589 22.28 13.3093 28.6296 13.3093 36.4621C13.3093 44.2947 19.6589 50.6443 27.4914 50.6443ZM27.4915 45.408C32.4321 45.408 36.4372 41.4029 36.4372 36.4624C36.4372 31.5218 32.4321 27.5167 27.4915 27.5167C22.551 27.5167 18.5459 31.5218 18.5459 36.4624C18.5459 41.4029 22.551 45.408 27.4915 45.408Z"
          fill={theme.palette.primary.main}
          className="point1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.6736 82.2812C49.5061 82.2812 55.8557 75.9317 55.8557 68.0991C55.8557 60.2665 49.5061 53.917 41.6736 53.917C33.841 53.917 27.4915 60.2665 27.4915 68.0991C27.4915 75.9317 33.841 82.2812 41.6736 82.2812ZM41.6736 77.0451C46.6142 77.0451 50.6193 73.04 50.6193 68.0995C50.6193 63.159 46.6142 59.1539 41.6736 59.1539C36.7331 59.1539 32.728 63.159 32.728 68.0995C32.728 73.04 36.7331 77.0451 41.6736 77.0451Z"
          fill={theme.palette.primary.main}
          className="point2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1821 113.918C22.0147 113.918 28.3642 107.569 28.3642 99.7361C28.3642 91.9035 22.0147 85.554 14.1821 85.554C6.34955 85.554 0 91.9035 0 99.7361C0 107.569 6.34955 113.918 14.1821 113.918ZM14.1821 108.682C19.1227 108.682 23.1278 104.677 23.1278 99.7362C23.1278 94.7956 19.1227 90.7905 14.1821 90.7905C9.2416 90.7905 5.23651 94.7956 5.23651 99.7362C5.23651 104.677 9.2416 108.682 14.1821 108.682Z"
          fill={theme.palette.primary.main}
          className="point3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.5464 141.409C50.3789 141.409 56.7285 135.06 56.7285 127.227C56.7285 119.395 50.3789 113.045 42.5464 113.045C34.7138 113.045 28.3643 119.395 28.3643 127.227C28.3643 135.06 34.7138 141.409 42.5464 141.409ZM42.5463 136.173C47.4869 136.173 51.492 132.168 51.492 127.227C51.492 122.287 47.4869 118.282 42.5463 118.282C37.6058 118.282 33.6007 122.287 33.6007 127.227C33.6007 132.168 37.6058 136.173 42.5463 136.173Z"
          fill={theme.palette.primary.main}
          className="point4"
        />
      </svg>
    </>
  );
};

export default function CustomLoading() {
  const [progress, setProgress] = React.useState(0);
  const { theme } = useTheme();

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 150);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Stack
      sx={{
        width: "100%",
        alignItems: "center",
        height: "70vh",
        justifyContent: "center",
      }}
    >
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <Stack>
          <CircularProgress
            size={"100px"}
            variant="determinate"
            sx={{
              color: theme.palette.primary.main,
            }}
            value={progress}
          />
        </Stack>
        <Box
          sx={{
            top: 0,
            left: 20,
            bottom: 0,
            right: 0,
            width: "60px",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Logo />
        </Box>
      </Box>
    </Stack>
  );
}
