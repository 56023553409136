import React from "react";

import VerticalTabs from "./components/Tabs";
import PageContainer from "page/general/PageContainer";

const AccountPage = () => {
  return (
    <PageContainer>
      <VerticalTabs />
    </PageContainer>
  );
};

export default AccountPage;
