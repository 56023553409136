import React from "react";
import { Stack, Typography } from "@mui/material";
import { IconLogoLarge } from "components/icons/Logos";
import { RowStack } from "components/stack/RowStack";
import { useTheme } from "layouts/theme/ThemeContext";

const AuthHeader = () => {
  const { theme } = useTheme();
  return (
    <Stack>
      <Typography
        fontWeight={700}
        align="center"
        sx={{
          [theme.breakpoints.down("md")]: {
            ...theme.typography.h2,
            fontWeight: 700,
          },
        }}
        variant="h1"
      >
        Hi there!
      </Typography>
      <RowStack gap={"16px"}>
        <Typography align="center" variant="caption">
          Welcome to Robenice.
        </Typography>
        <IconLogoLarge
          sx={{
            width: "80px",
            height: "80px",
          }}
          fill={theme.palette.secondary.dark}
        />
        <Typography align="center" variant="caption">
          Community Dashboard
        </Typography>
      </RowStack>
    </Stack>
  );
};

export default AuthHeader;
