import React, { useEffect, useState } from "react";
import { Button, Stack, Typography, styled } from "@mui/material";
import MetricsMiniDashboard from "../GeneralComponents/MetricsMiniDashboard";
import MetricsChartsGroups from "../GeneralComponents/MetricsChartsGroups";
import RightChartGroup from "./components/RightChartGroup";
import { IGraphifyOs } from "page/graphify/type";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { AppDispatch, RootState } from "redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { getAlertsList } from "redux/slices/AlertsSlice";
import { alarmData } from "page/graphify/alarmData";
import Alarms from "./components/Alarms";
import AlarmList from "./components/AlarmList";
import { useNavigate, useParams } from "react-router-dom";
import NetworkChartsGroups from "../GeneralComponents/NetworkChartsGroups";
import { useTranslation } from "react-i18next";

const Content = styled(Stack)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  boxSizing: "border-box",
}));
const BackButton = styled(Button)(({ theme }) => ({
  ...theme.typography.body1,
  padding: "0px",
  margin: "0px",
  gap: "0px",
  textAlign: "left",
  color: theme.palette.error.dark,
  textTransform: "none",
  opacity: 0.6,
  "&:hover": {
    fontWeight: 700,
    opacity: 1,
  },
}));

const TitleContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "24px",
  marginBottom: "24px",
}));

const OsHostDetail = () => {
  let { id } = useParams();
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [datas, setDatas] = useState<IGraphifyOs[]>([]);
  const [filterData, setFilterData] = useState<IGraphifyOs>({} as IGraphifyOs);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/data/graphifyOsData.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const rawData: IGraphifyOs[] = await response.json();
        const filteredData = rawData.filter((item) =>
          item.host_groups.some((group) => !group.name.includes("network"))
        );

        setDatas(filteredData);
        const data = filteredData.find((item) => item.host_id === id);
        if (data) {
          setFilterData(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, []);

  const alertData = alarmData;
  const filterAlarmData = alertData.filter((item) => item.other_id === id);

  return (
    <Stack>
      {datas.length !== 0 && (
        <Content>
          <TitleContainer>
            <BackButton
              disableRipple
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIosIcon fontSize="small" sx={{ fontSize: "16px" }} />
              {t("graphify.back")}
            </BackButton>
            <Typography variant="h6" fontWeight={700}>
              {filterData.host_name}
            </Typography>
          </TitleContainer>

          <MetricsMiniDashboard data={filterData} slice={3} />
          <MetricsChartsGroups data={filterData} />
          <NetworkChartsGroups data={filterData} />

          {filterAlarmData.length >= 1 && (
            <Stack
              sx={{
                gap: "16px",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <RightChartGroup data={filterAlarmData} />
              <Alarms filterData={filterAlarmData} />
            </Stack>
          )}
          {filterAlarmData.length >= 1 && (
            <AlarmList alerts={filterAlarmData} />
          )}
        </Content>
      )}
    </Stack>
  );
};

export default OsHostDetail;
