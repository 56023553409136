import { Stack, Typography, alpha, styled } from "@mui/material";
import { useTheme } from "layouts/theme/ThemeContext";
import { IGraphifyOs } from "page/graphify/type";
import React, { useEffect, useState } from "react";
import { SlimLineCharts } from "./charts/SlimLineCharts";
import { SlimAreaCharts } from "./charts/SlimAreaCharts";
import { useTranslation } from "react-i18next";

const Container = styled(Stack)(({ theme }) => ({
  alignItems: "flex-start",
  padding: "16px",
  borderRadius: "16px",
  gap: "8px",
  boxShadow: ` ${alpha(theme.palette.primary.dark, 0.25)}  0px 5px 15px `,
}));

const Content = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderRadius: "16px  ",
  gap: "8px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    gap: "16px",
  },
  /* flexDirection:"column-reverse" */
}));

interface IData {
  color: string;
  data: number[];
  title: string;
}

const MetricsChartsGroups = ({ data }: { data: IGraphifyOs }) => {
  const { t } = useTranslation();
  const time = data.metrics.map((item) => {
    return item.data;
  });
  const [select, setSlect] = useState<IData>({
    color: "#ED2438",
    data: [],
    title: "",
  });

  const calcData = (datas: IGraphifyOs, name: string) => {
    const items = datas.metrics.find((item) => item.name_x === name);
    return items && items.data.length > 1
      ? items?.data.map((item) => Number(item.lastvalue.toFixed(2)))
      : [];
  };

  const calcTime = (datas: IGraphifyOs, name: string) => {
    const items = datas.metrics.find((item) => item.name_x === name);
    return items && items.data.length > 1
      ? items?.data.map((item) => item.recivedTime)
      : [];
  };

  /*  Memory */
  const memoryDatas = calcData(data, "Linux: Memory utilization");
  const memoryTimes = calcTime(data, "Linux: Memory utilization");
  /*  Memory */

  const metricsData = [
    {
      data: calcData(data, "Linux: Memory utilization"),
      title: t("graphify.memoryUtilization"),
      color: "#ED2438",
    },
    {
      data: calcData(data, "Linux: CPU utilization"),
      title: t("graphify.cpuUtilization"),
      color: "#F06F05",
    },
    {
      data: calcData(data, "sda: Disk utilization"),
      title: t("graphify.diskUtilization"),
      color: "#51C1C0",
    },
  ];

  useEffect(() => {
    setSlect(metricsData[0]);
  }, []);

  return (
    <Container>
      <Stack
        sx={{
          marginBottom: { xs: "24px", md: "0px" },
        }}
      >
        <Typography variant="body1" fontWeight={700}>
          {select.title}
        </Typography>
        <Typography variant="caption">{t("graphify.charDesc")} </Typography>
      </Stack>

      <Content>
        <Stack flex={6}>
          {memoryTimes && (
            <SlimAreaCharts
              data={select.data}
              name={""}
              time={memoryTimes}
              color={select.color}
              tooltipYname={select.title}
            />
          )}
        </Stack>
        <Stack flex={2}>
          {metricsData.map((item, i) => {
            return (
              <ChartsItem
                key={`metricsData_${i}`}
                select={select}
                color={item.color}
                title={item.title}
                data={item.data}
                setSlect={setSlect}
              />
            );
          })}
        </Stack>
      </Content>
    </Container>
  );
};

export default MetricsChartsGroups;

const ChartsItem = ({
  title,
  data,
  color,
  setSlect,
  select,
}: {
  title: string;
  data: number[];
  color: string;
  select: IData;
  setSlect: React.Dispatch<React.SetStateAction<IData>>;
}) => {
  const { theme } = useTheme();

  return (
    <Stack
      sx={{
        cursor: "pointer",
        padding: "2px 8px",
        borderRadius: "8px",
        backgroundColor:
          select.title === title ? ` ${alpha(color, 0.05)}` : "none",
        "&:hover": {
          transition: "all 0.4s ease-in-out",
          transform: " scale(1.03); ",
          backgroundColor: ` ${alpha(color, 0.1)}`,
        },
      }}
      onClick={() => {
        setSlect({ data: data, color: color, title: title });
      }}
    >
      <Typography variant="subtitle2" marginBottom={"-20px"}>
        {title}
      </Typography>
      <SlimLineCharts data={data} color={color} />
    </Stack>
  );
};
