import React, { useEffect, useState } from "react";
import { Box, Stack, alpha, styled } from "@mui/material";
import TopTabMenu from "./TopTabMenu";
import { topTabBarArr } from "data/pathArr";
import { useSelector } from "react-redux";
import { RootState } from "redux/Store";
import { ITopTabBarItem } from "redux/slices/types";

const TopTabContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  height: "48px",
  borderRadius: "0px 0px 16px 16px",
  padding: "8px",
  top: "-15px",
  position: "absolute",
  backgroundColor: theme.palette.background.paper,
  boxShadow: ` ${alpha(
    theme.palette.primary.dark,
    0.25
  )}  0px 2px 5px -1px,${alpha(
    theme.palette.primary.contrastText,
    0.3
  )}  0px 1px 3px -1px `,
}));

const TopTabBarDesktop = () => {
  const layouts = useSelector((state: RootState) => state.layouts);
  const [tabTopBarTabs, setTabTopTabs] = useState<ITopTabBarItem[]>([]);

  useEffect(() => {
    const newObj = topTabBarArr.find(
      (item) => item.title.name === layouts.topbarMainTitle
    );
    if (newObj) {
      setTabTopTabs(newObj.tab);
    } else {
      setTabTopTabs([]);
    }
  }, [layouts.topbarMainTitle]);
  /* 
  useEffect(() => {
    var path = window.location.pathname;
    var match = path.match(/\/([^\/]+)\/([^\/]+)/);
    if (match) {
      var desiredValue = match[2];
      console.log(desiredValue);
    } else {
      console.log("Eşleşme bulunamadı.");
    }
  }, []); */

  return (
    <Stack
      sx={{
        display: { xs: "none ", md: "flex" },
        alignItems: "center",
      }}
    >
      {tabTopBarTabs.length > 0 && (
        <TopTabContent>
          <TopTabMenu />
        </TopTabContent>
      )}
    </Stack>
  );
};

export default TopTabBarDesktop;
