import React, { useState } from "react";
import { Stack, Modal, Typography, IconButton } from "@mui/material";
import { CustomStyleButton } from "components/buttons/CustomButton";
import { ModalContent } from "components/modal/ModalContent";
import {
  FormContainer,
  FormTextField,
  FormTitle,
  ItemContainer,
  TitleContainer,
} from "./Components";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CustomTooltip from "components/tooltip/tooltip";
import { CustomIconButton } from "components/buttons/CustomIconButton";
import { usePatchMutation } from "query/PatchQuery";
import { Notify } from "components/notify/notify";
import { useQueryClient } from "@tanstack/react-query";
import { usePostMutation } from "query/PostQuery";
import { useTranslation } from "react-i18next";

const AgentForm = ({ cname, id }: { cname?: string; id?: number }) => {
  const { t } = useTranslation();
  const editAgentManagement = usePatchMutation();
  const postAgentManagement = usePostMutation();
  const queryClient = useQueryClient();

  const [name, setName] = useState<string>(cname ? cname : "");
  const [isOpen, setOpen] = useState<boolean>(false);
  return (
    <Stack>
      {cname ? (
        <CustomTooltip title={t("general.edit")}>
          <CustomIconButton
            onClick={() => {
              setOpen(true);
            }}
          >
            <EditIcon />
          </CustomIconButton>
        </CustomTooltip>
      ) : (
        <CustomStyleButton
          onClick={() => {
            setOpen(true);
          }}
        >
          {t("agentManagement.newAgent")}
        </CustomStyleButton>
      )}

      <Modal
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent>
          <Stack gap={"16px"}>
            <TitleContainer>
              <Typography fontWeight={700} variant="body1">
                {cname
                  ? t("agentManagement.editAgent")
                  : t("agentManagement.newAgent")}
              </Typography>
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon sx={{ fontSize: "18px" }} />
              </IconButton>
            </TitleContainer>
            <FormContainer>
              <ItemContainer>
                <FormTitle> {t("agentManagement.agentName")}:</FormTitle>
                <FormTextField
                  id="from_host_name"
                  fullWidth
                  placeholder={t("agentManagement.savePlaceholder")}
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </ItemContainer>

              <ItemContainer marginTop={"24px"}>
                {cname && id ? (
                  <CustomStyleButton
                    onClick={() => {
                      editAgentManagement.mutate(
                        {
                          url: `/agent/${id}/`,
                          data: { name: name },
                        },
                        {
                          onSuccess() {
                            Notify.notifySuccess(
                              t("agentManagement.notifySuccessEdit")
                            );
                            queryClient.invalidateQueries({
                              queryKey: ["agentManagemenData"],
                            });
                            setOpen(false);
                          },
                          onError() {
                            Notify.notifyError(
                              t("agentManagement.notifyError")
                            );
                          },
                        }
                      );
                    }}
                  >
                    {t("general.edit")}
                  </CustomStyleButton>
                ) : (
                  <CustomStyleButton
                    onClick={() => {
                      postAgentManagement.mutate(
                        {
                          url: `/agent/`,
                          data: { name: name },
                        },
                        {
                          onSuccess() {
                            Notify.notifySuccess(
                              t("agentManagement.notifySuccessAdd")
                            );
                            queryClient.invalidateQueries({
                              queryKey: ["agentManagemenData"],
                            });
                            setOpen(false);
                            setName("");
                          },
                          onError() {
                            Notify.notifyError(
                              t("agentManagement.notifyError")
                            );
                          },
                        }
                      );
                    }}
                  >
                    {t("general.send")}
                  </CustomStyleButton>
                )}
              </ItemContainer>
            </FormContainer>
          </Stack>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default AgentForm;
