import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Stack, Typography } from "@mui/material";
import DeleteModal from "components/modal/DeleteModal";
import {
  CustomBoolCell,
  CustomTableCell,
  CustomTimeCell,
  TableContiner,
} from "components/tableCom/components";
import CustomLoading from "components/loading/CustomLoading";
import { IAgentDataProps } from "../type/Type";
import AgentForm from "./AgentForm";
import JobModal from "./job/JobModal";
import { useDeleteMutation } from "query/DeleteQuery";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface HeadCell {
  id: string;
  label: string;
  align: "right" | "left" | "center" | "inherit" | "justify" | undefined;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    label: "Name",
    align: "left",
  },
  {
    id: "is_active",
    label: "Active",
    align: "left",
  },
  {
    id: "licence_key",
    label: "Licence",
    align: "left",
  },
  {
    id: "created",
    label: "Created",
    align: "left",
  },
  {
    id: "action",
    label: "Actions",
    align: "center",
  },
];

const AgentTable = ({
  data,
  loading,
}: {
  data: IAgentDataProps[];
  loading: boolean;
}) => {
  const { t } = useTranslation();
  const deleteAgentManagement = useDeleteMutation();
  const queryClient = useQueryClient();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%", marginTop: "32px" }}>
      {loading ? (
        <CustomLoading />
      ) : (
        <TableContiner>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={`TableCell${headCell.id}`}
                      align={headCell.align}
                    >
                      <Typography variant="subtitle2">
                        {t(`agentManagement.table${headCell.label}`)}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={`agent_tableBody${index}`}
                        id={`host-table-checkbox-${index}`}
                        role="checkbox"
                        tabIndex={-1}
                        sx={{ cursor: "pointer" }}
                      >
                        <CustomTableCell text={row.name} />
                        <CustomBoolCell value={row.is_active} />
                        <CustomTableCell text={row.licence_key} />
                        <CustomTimeCell value={row.created} />
                        <TableCell align="center">
                          <Stack
                            width={"100%"}
                            justifyContent={"center"}
                            direction={"row"}
                          >
                            <AgentForm cname={row.name} id={row.agent_id} />
                            <JobModal
                              flowData={{
                                id: row.agent_id,
                                name: row.name,
                              }}
                            />
                            <DeleteModal
                              text={t("agentManagement.deleteAgentDesc")}
                              onClick={() => {
                                deleteAgentManagement.mutate(
                                  {
                                    url: `/agent/${row.agent_id}/`,
                                  },
                                  {
                                    onSuccess() {
                                      queryClient.invalidateQueries({
                                        queryKey: ["agentManagemenData"],
                                      });
                                    },
                                  }
                                );
                              }}
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContiner>
      )}
    </Box>
  );
};
export default AgentTable;
