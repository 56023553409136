import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGet } from "apis/Get";

export interface DashboardState {
  analyticsData: any[];
}

const initialState: DashboardState = {
  analyticsData: [],
};

export const getAnalyticsData = createAsyncThunk(
  "getAnalyticsData",
  async () => {
    const data = await fetchGet(`/alert/analytics/total_by_day/?day=10,1,5,30`);
    return data;
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAnalyticsData.fulfilled, (state, action) => {
      state.analyticsData = action.payload;
    });
  },
});

/* export const {} = dashboardSlice.actions;
 */
export default dashboardSlice.reducer;
