import { Stack } from "@mui/material";
import React from "react";
import AlarmChart from "./AlarmChart";
import { IAlertsModel, SeverityCount } from "page/graphify/type";

const Alarms = ({ filterData }: { filterData: IAlertsModel[] }) => {
  /* LINE CHART DATA */
  const calculateSeverityCountsLast7Days = (data: any[]): SeverityCount[] => {
    const today = new Date();
    const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000); // Son 7 günü hesapla
    // Son 7 günün verilerini filtrele
    const last7DaysData = data.filter(
      (item) => new Date(item.created) >= sevenDaysAgo
    );

    // Son 7 günün verilerini işle
    const severityCounts: { [key: string]: { [key: string]: number } } = {};

    last7DaysData.forEach((item) => {
      const createdDate = new Date(item.created).toLocaleDateString();
      const severity = item.severity.toLowerCase();
      if (!severityCounts[createdDate]) {
        severityCounts[createdDate] = {};
      }
      if (!severityCounts[createdDate][severity]) {
        severityCounts[createdDate][severity] = 0;
      }
      severityCounts[createdDate][severity]++;
    });

    const result: SeverityCount[] = [];

    for (const createdDate in severityCounts) {
      for (const severity in severityCounts[createdDate]) {
        result.push({
          name: severity,
          created: createdDate,
          number: severityCounts[createdDate][severity],
        });
      }
    }

    return result;
  };
  const lineChartData = calculateSeverityCountsLast7Days(filterData);

  /*   const calculateSeverityCounts = (data: any[]): SeverityCount[] => {
        const severityCounts: { [key: string]: { [key: string]: number } } = {};
    
        // Verileri tarihe göre gruplayın ve her bir tarih için severity değerlerini toplayın
        data.forEach((item) => {
          const createdDate = new Date(item.created).toLocaleDateString();
          const severity = item.severity.toLowerCase(); // Büyük-küçük harf duyarlı olmayacak şekilde
    
          if (!severityCounts[createdDate]) {
            severityCounts[createdDate] = {};
          }
    
          if (!severityCounts[createdDate][severity]) {
            severityCounts[createdDate][severity] = 0;
          }
    
          severityCounts[createdDate][severity]++;
        });
    
        // Elde edilen toplamları uygun formatta yeni bir diziye ekleyin
        const result: SeverityCount[] = [];
    
        for (const createdDate in severityCounts) {
          for (const severity in severityCounts[createdDate]) {
            result.push({
              name: severity,
              created: createdDate,
              number: severityCounts[createdDate][severity],
            });
          }
        }
    
        return result;
      };
    
      const newData = calculateSeverityCounts(data); */

  /* LINE CHART DATA */

  return (
    <Stack flex={1} gap={"16px"} direction={"row"}>
      {lineChartData.length >= 1 && (
        <AlarmChart lineChartData={lineChartData} />
      )}
    </Stack>
  );
};

export default Alarms;
