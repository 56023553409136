import { Stack, alpha, styled } from "@mui/material";

export const BorderContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "16px",
  padding: "24px",
  width: "100%",
  gap: "16px ",
  boxShadow: ` ${alpha(
    theme.palette.secondary.main,
    0.25
  )}  0px 2px 5px -1px,${alpha(
    theme.palette.primary.contrastText,
    0.3
  )}  0px 1px 3px -1px `,
}));
