import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import AlertTitle from "./components/AlertTitle";
import StatusView from "./components/StatusView";
import AlertTable from "./components/AlertTable";
import CustomLoading from "components/loading/CustomLoading";
import { AppDispatch, RootState } from "redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { getAlertsList, getSeverityList } from "redux/slices/AlertsSlice";
import PageContainer from "page/general/PageContainer";

const AlertsPage = () => {
  const [statusClose, setStatusClose] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("");
  const [searchValue, setSearch] = useState<string>("");
  const dispatch: AppDispatch = useDispatch();

  const url =
    selected !== ""
      ? `/alert/?q=${selected}`
      : searchValue !== ""
      ? `/alert/?q=${searchValue}`
      : `/customer/alert/`;

  useEffect(() => {
    const fetchData = () => {
      dispatch(getAlertsList(url));
      dispatch(getSeverityList());
    };

    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);

    return () => clearInterval(intervalId);
  }, [dispatch, url, selected, searchValue, statusClose]);

  const alerts = useSelector((state: RootState) => state.alerts);

  const alertsList =
    statusClose === false
      ? alerts.alertsList.filter((item) => item.status === 0)
      : alerts.alertsList;
  const severityList = alerts.severityList;

  return (
    <PageContainer>
      <Stack gap={"16px"}>
        <AlertTitle
          alerts={alertsList}
          statusClose={statusClose}
          setStatusClose={setStatusClose}
          onFunc={() => {}}
          searchValue={searchValue}
          setSearch={setSearch}
        />

        <StatusView
          severityList={severityList}
          selected={selected}
          setSelected={setSelected}
          alerts={alertsList}
          onChange={() => {
            dispatch(getSeverityList());
          }}
        />

        {alerts.alertsListLoading === true ? (
          <CustomLoading />
        ) : (
          <AlertTable severityList={severityList} alerts={alertsList} />
        )}
      </Stack>
    </PageContainer>
  );
};

export default AlertsPage;
