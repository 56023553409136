import React from "react";
import { Stack, Typography, styled } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useSelector } from "react-redux";
import { RootState } from "redux/Store";
import { useTranslation } from "react-i18next";

const HeadContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  gap: "6px",
  marginBottom: "18px",
}));
const DateContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "6px",
  alignItems: "center",
}));

const NameTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.primary.dark,
}));

const NameDesc = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const HeadTitle = () => {
  const { t } = useTranslation();
  const login = useSelector((state: RootState) => state.login);
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  /*   console.log(login.currentUser); */

  return (
    <HeadContainer>
      <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
        <Stack>
          <NameTitle variant="h4">
            {t("dashboard.hello")}, {login.currentUser.first_name}
          </NameTitle>
          <NameDesc variant="body2">{t("dashboard.titleDesc")}</NameDesc>
        </Stack>
        <DateContainer>
          <Typography>
            {day}.{month}.{year}
          </Typography>{" "}
          <CalendarMonthIcon />
        </DateContainer>
      </Stack>
    </HeadContainer>
  );
};

export default HeadTitle;
