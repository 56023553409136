import React, { useContext, useEffect, useState } from "react";
import { Stack, Typography, alpha, styled } from "@mui/material";
import { Handle, Position } from "reactflow";

import { FuncContext } from "page/workflow/context/FuncContext";

const NodesContainer = styled("div")(({ theme }) => ({
  width: "120px",
  /*   height: "36px", */
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid ${theme.palette.primary.main}`,
  padding: "4px 8px",
  borderRadius: "5px",
  color: theme.palette.primary.dark,
  backgroundColor: ` ${alpha(theme.palette.warning.main, 0.3)}`,
}));

const handleStyle = {
  height: "8px",
  borderRadius: "4px",
  border: "medium none",
  width: " 20px",
  backgroundColor: "#8E8E8E",
};

function BuiltinNode({ data, id }: any) {
  const [formData, setFormData] = useState<string>("");
  const { setNodes } = useContext(FuncContext);

  useEffect(() => {
    setFormData(data.data.d_parameter);
  }, []);

  /* const handleInputChange = () => {
    const nodeIndex = nodes.findIndex((item: any) => item.id === id);
    nodes[nodeIndex].data.data["d_parameter"] = formData;
    setNodes(nodes);
  }; */
  const handleInputChange = () => {
    setNodes((prevNodes) => {
      const nodeIndex = prevNodes.findIndex((item: any) => item.id === id);
      const updatedNodes = [...prevNodes];
      updatedNodes[nodeIndex] = {
        ...updatedNodes[nodeIndex],
        data: {
          ...updatedNodes[nodeIndex].data,
          data: {
            ...updatedNodes[nodeIndex].data.data,
            d_parameter: formData,
          },
        },
      };
      return updatedNodes;
    });
  };
  return (
    <NodesContainer key={id}>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable
        style={handleStyle}
      />
      <Stack gap={"4 px"}>
        <Typography
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          align="center"
          fontSize={"8px"}
          variant="subtitle2"
        >
          {data.data.display_name}
        </Typography>

        <input
          style={{ fontSize: "8px", marginBottom: "6px" }}
          placeholder={formData}
          value={formData}
          onChange={(event) => {
            setFormData(event.target.value);
          }}
          onBlur={(event) => {
            handleInputChange();
          }}
        />
      </Stack>

      <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable
        style={handleStyle}
      />
    </NodesContainer>
  );
}

export default BuiltinNode;
