import React, { useEffect } from "react";
import { Grid, styled, Typography, Box, Stack, alpha } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme } from "layouts/theme/ThemeContext";
import {
  IconFifteenDays,
  IconFiveDays,
  IconOneDay,
  IconThirtyDays,
} from "components/icons/Days";
import { AppDispatch, RootState } from "redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { getAnalyticsData } from "redux/slices/DashboardSlice";
import { useTranslation } from "react-i18next";

const Item = styled(Box)(({ theme }) => ({
  /*   ...theme.typography.body2, */
  width: "100%",
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  boxShadow: ` ${alpha(theme.palette.primary.dark, 0.2)} 0px 1px 4px`,
  borderRadius: "16px",
  gap: "8px",
  color: theme.palette.text.secondary,
  /*  borderRight: `1px solid ${theme.palette.primary.main} `, */
}));

const IconContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  borderRadius: "8px",
  backgroundColor: ` ${alpha(theme.palette.warning.dark, 0.8)} `,
}));

const SmallTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.primary.dark,
}));

const DescText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.primary.dark,
  fontWeight: 700,
}));

const HeadDash = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const dashboard = useSelector((state: RootState) => state.dashboard);
  useEffect(() => {
    dispatch(getAnalyticsData());
  }, []);

  const dataArr = dashboard.analyticsData.length && [
    {
      title: `${t("dashboard.analyticsDataTtile1")}`,
      value: dashboard.analyticsData[1].day_1,
      ratio: "increasing",
      ratioValue: `+8 ${t("dashboard.alert")}`,
      icon: (
        <IconOneDay fill={theme.palette.background.default} fontSize="medium" />
      ),
    },
    {
      title: `${t("dashboard.analyticsDataTtile2")}`,
      value: dashboard.analyticsData[2].day_5,
      ratio: "decreasing",
      ratioValue: `-6 ${t("dashboard.alert")}`,
      icon: (
        <IconFiveDays
          fill={theme.palette.background.default}
          fontSize="medium"
        />
      ),
    },
    {
      title: `${t("dashboard.analyticsDataTtile3")}`,
      value: dashboard.analyticsData[0].day_10,
      ratio: "decreasing",
      ratioValue: `+12 ${t("dashboard.alert")}`,
      icon: (
        <IconFifteenDays
          fill={theme.palette.background.default}
          fontSize="medium"
        />
      ),
    },
    {
      title: `${t("dashboard.analyticsDataTtile4")}`,
      value: dashboard.analyticsData[3].day_30,
      ratio: "increasing",
      ratioValue: `+40 ${t("dashboard.alert")}`,
      icon: (
        <IconThirtyDays
          fill={theme.palette.background.default}
          fontSize="medium"
        />
      ),
    },
  ];

  return (
    <Stack
      sx={{
        margin: "40px 0px",
      }}
    >
      <Grid container spacing={2}>
        {dataArr &&
          dataArr.map((item, index) => {
            return (
              <Grid
                key={`headDash${index}`}
                sx={{ backgroundColor: "transparent" }}
                item
                xs={12}
                md={3}
              >
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Item>
                    <IconContainer>{item.icon}</IconContainer>
                    <Stack>
                      <SmallTitle>{item.title}</SmallTitle>
                      <Stack
                        direction={"row"}
                        gap={"16px"}
                        alignItems={"center"}
                      >
                        <DescText> {item.value}</DescText>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          borderRadius={"8px"}
                          padding={"2px"}
                        >
                          {item.ratio === "increasing" && <ArrowDropDownIcon />}
                          {item.ratio === "decreasing" && <ArrowDropUpIcon />}
                          <Typography
                            fontWeight={700}
                            color={
                              item.ratio === "decreasing" ? "success" : "error"
                            }
                            variant="caption"
                          >
                            {item.ratioValue}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Item>
                </Stack>
              </Grid>
            );
          })}
      </Grid>
    </Stack>
  );
};

export default HeadDash;
