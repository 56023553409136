import { Stack, Typography } from "@mui/material";
import { useTheme } from "layouts/theme/ThemeContext";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import { RowGapStack } from "components/stack/RowStack";

export const ClosePort = () => {
  const { theme } = useTheme();
  return (
    <Stack
      sx={{
        clipPath:
          "path('M17.6792 24.2447H13.2929C11.4876 24.2447 10.0241 22.7812 10.0241 20.9759C10.0241 19.1706 8.5606 17.7071 6.7553 17.7071H5C2.79086 17.7071 1 15.9163 1 13.7071V5C1 2.79086 2.79086 1 5 1H26C28.2091 1 30 2.79086 30 5V13.7071C30 15.9163 28.2091 17.7071 26 17.7071H24.2168C22.4115 17.7071 20.948 19.1706 20.948 20.9759C20.948 22.7812 19.4845 24.2447 17.6792 24.2447Z')",
        width: "32px",
        height: "32px",
        borderRadius: "4px",
        backgroundColor: theme.palette.primary.light,
        border: `1px solid ${theme.palette.primary.main}`,
      }}
    />
  );
};

export const OpenPort = ({
  onClick,
  chartView,
  name,
}: {
  onClick: () => void;
  chartView: string[];
  name: string;
}) => {
  const { theme } = useTheme();
  return (
    <Stack
      onClick={onClick}
      sx={{
        clipPath:
          "path('M17.6792 24.2447H13.2929C11.4876 24.2447 10.0241 22.7812 10.0241 20.9759C10.0241 19.1706 8.5606 17.7071 6.7553 17.7071H5C2.79086 17.7071 1 15.9163 1 13.7071V5C1 2.79086 2.79086 1 5 1H26C28.2091 1 30 2.79086 30 5V13.7071C30 15.9163 28.2091 17.7071 26 17.7071H24.2168C22.4115 17.7071 20.948 19.1706 20.948 20.9759C20.948 22.7812 19.4845 24.2447 17.6792 24.2447Z')",
        width: "32px",
        height: "32px",
        alignItems: "center",
        justifyContent: "flex-start",
        borderRadius: "4px",
        backgroundColor: chartView.includes(name)
          ? theme.palette.info.main
          : theme.palette.success.main,
        border: `1px solid ${theme.palette.success.main}`,
        cursor: "pointer",
      }}
    >
      {chartView.includes(name) && (
        <CheckIcon
          sx={{
            fontSize: "18px",
            color: theme.palette.background.paper,
          }}
        />
      )}
    </Stack>
  );
};

export const SelectPort = ({ bol,size }: { bol: boolean,size:string }) => {
  const { theme } = useTheme();
  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        width: size,
        height:"16px",
        borderRadius: "4px",
        backgroundColor: bol
          ? theme.palette.info.main
          : theme.palette.success.main,
        border: `1px solid ${theme.palette.success.main}`,
      }}
    >
      {bol === true && (
        <CheckIcon
          sx={{
            fontSize: "14px",
            color: theme.palette.background.paper,
          }}
        />
      )}
    </Stack>
  );
};

export const ClosesPort = () => {
  const { theme } = useTheme();
  return (
    <Stack
      sx={{
        width: "16px",
        height: "16px",
        borderRadius: "4px",
        backgroundColor: theme.palette.primary.light,
        border: `1px solid ${theme.palette.primary.main}`,
      }}
    />
  );
};

export const PortDesc = () => {
  const { theme } = useTheme();
  return (
    <RowGapStack
      sx={{
        gap: "8px",
      }}
    >
      <RowGapStack gap={"8px"}>
        <ClosesPort />
        <Typography variant="caption">Close Port</Typography>
      </RowGapStack>
      <RowGapStack gap={"8px"}>
        <SelectPort size="16px" bol={false} />
        <Typography variant="caption">Open Port</Typography>
      </RowGapStack>
      <RowGapStack gap={"8px"}>
        <SelectPort  size="16px" bol={true} />
        <Typography variant="caption">Select Port</Typography>
      </RowGapStack>
    </RowGapStack>
  );
};

export const PortInDesc = () => {
  return (
    <Stack sx={{ gap: "8px" }}>
      <RowGapStack gap={"8px"}>
      <SelectPort  size="24px"  bol={false} />
        <Typography variant="caption">FAN</Typography>
      </RowGapStack>
      <RowGapStack gap={"8px"}>
        <SelectPort  size="24px"  bol={false} />
        <Typography variant="caption">POWER</Typography>
      </RowGapStack>
      <RowGapStack gap={"8px"}>
      <SelectPort  size="24px"  bol={false} />
        <Typography variant="caption">TEMPERATURE</Typography>
      </RowGapStack>
    </Stack>
  );
};
