import React from "react";
import { Stack } from "@mui/material";
import { useTheme } from "layouts/theme/ThemeContext";
import Limit from "./component/Limit";
import AverangeCosts from "./component/AverangeCosts";
import TopProducts from "./component/TopProducts";
import DashTitle from "../DashTitle";

const DashCustom = () => {
  const date = new Date();
  const { theme } = useTheme();
  return (
    <Stack gap={"24px"}>
      <DashTitle title={"Custom Dashboard"} />
      <Stack direction={"row"} gap={"24px"}>
        <Limit />
        <AverangeCosts />
      </Stack>
      {/* başka line  */}
      <Stack>
        <TopProducts />
      </Stack>
    </Stack>
  );
};

export default DashCustom;
