import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGet } from "apis/Get";
import { IAlertsModel } from "page/alert/type/type";
import { ISeveritylist } from "page/filterManagement/types/Type";

export interface AlertsState {
  alertsList: IAlertsModel[];
  alertsListLoading: boolean;
  severityList: ISeveritylist[];
}

const initialState: AlertsState = {
  alertsList: [],
  alertsListLoading: false,
  severityList: [],
};

export const getAlertsList = createAsyncThunk(
  "getAlertsList",
  async (url: string) => {
    const data = await fetchGet(url);
    return data;
  }
);

export const getSeverityList = createAsyncThunk("getSeverityList", async () => {
  const data = await fetchGet(`/customer/alert_severity/`);
  return data;
});

export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAlertsList.pending, (state, action) => {
      state.alertsListLoading = true;
    });
    builder.addCase(getAlertsList.fulfilled, (state, action) => {
      state.alertsList = action.payload;
      state.alertsListLoading = false;
    });
    builder.addCase(getSeverityList.fulfilled, (state, action) => {
      state.severityList = action.payload;
    });
  },
});

/* export const {} = alertsSlice.actions;
 */
export default alertsSlice.reducer;
