import { useMutation } from "@tanstack/react-query";
import { fetchDelete } from "apis/Delete";
import { Notify } from "components/notify/notify";

export const useDeleteMutation = function () {
  const mutate = useMutation({
    mutationFn: ({ url }: { url: string }) => fetchDelete(url),
    onSuccess: () => {
      Notify.notifySuccess("Successfully deleted");
    },
    onError() {
      Notify.notifyError("Error");
    },
  });
  return mutate;
};

/* How to use 
    const setDeletelist = useDeleteMutation();
     setDeletelist.mutate(
        {
          path: productNoteDbPath,
        },
        {
          onSuccess() {
            Notify.notifyWarning("Alarm filter is Successfully deleted.");
          },
          onError() {
            Notify.notifyError("Error");
          },
        }
      );
  */
