import { baseUrl } from "utils/helpers/BaseUrl";

export const fetchGet = async (url: string) => {
  const user: string | null = sessionStorage.getItem("user");
  try {
    if (user !== null) {
      const userData = JSON.parse(user);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Token ${userData.token}`);
      const data = await fetch(`${baseUrl}${url}`, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      })
        .then((response) => response.json())
        .catch((error) => {
          console.log(error);
        });

      return data;
    }
  } catch (error) {}
};
