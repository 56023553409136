import { useTheme } from "layouts/theme/ThemeContext";
import { INetworkInterfaces } from "page/graphify/type";
import ReactApexChart from "react-apexcharts";
import "../../../../../components/apexcharts/tooltipCss.scss";
import { Stack } from "@mui/material";
import { Size, useWindowSize } from "utils/hooks/use_window_size";

export const SlimAreasCharts = ({
  data,
  time,
}: {
  data: INetworkInterfaces[];
  time: string[];
}) => {
  const { theme } = useTheme();
  const windowsize: Size = useWindowSize();

  return (
    
      <ReactApexChart
    
        options={{
          chart: {
            type: "area",
            height: 300,
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
              offsetX: 0,
              offsetY: 0,
            },
          },
          stroke: {
            curve: "smooth",
            colors: ["#ED2438", "#51C1C0"],
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            cssClass: "tooltipCss",
            theme: theme.palette.primary.dark,
            x: {
              show: true,
              format: "dd MMM yyyy HH:mm",
              formatter: undefined,
            },

            y: {
              formatter: undefined,
              title: {
                formatter: (seriesName) => seriesName,
              },
            },
          },

          labels: time,
          xaxis: {
            type: "datetime",
            tickAmount: 12,

            labels: {
              rotate: -90,
              style: {
                colors: theme.palette.primary.main,
              },
            },
            axisBorder: {
              show: false, // x-ekseni çizgisini gizle
            },
          },
          yaxis: {
            opposite: true,
            labels: {
              style: {
                colors: theme.palette.primary.main,
              },
            },
          },

          legend: {
            markers: {
              fillColors: ["#ED2438", "#51C1C0"],
            },
            labels: {
              colors: theme.palette.primary.main,
            },
            horizontalAlign: "left",
          },
        }}
        series={data.map((item) => {
          return {
            name: item.name,
            data: item.data.map((k) => Number(k.lastvalue.toFixed(2))),
          };
        })}
        type="line"
       /*  width={windowsize?.width ? windowsize?.width - 300 : 400} */
        height={300}
      />

  );
};


export const SlimAreasChartsW = ({
  data,
  time,
}: {
  data: INetworkInterfaces[];
  time: string[];
}) => {
  const { theme } = useTheme();
  const windowsize: Size = useWindowSize();

  return (
    
      <ReactApexChart
    
        options={{
          chart: {
            type: "area",
            height: 250,
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
              offsetX: 0,
              offsetY: 0,
            },
          },
          stroke: {
            curve: "smooth",
            colors: ["#ED2438", "#51C1C0"],
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            cssClass: "tooltipCss",
            theme: theme.palette.primary.dark,
            x: {
              show: true,
              format: "dd MMM yyyy HH:mm",
              formatter: undefined,
            },

            y: {
              formatter: undefined,
              title: {
                formatter: (seriesName) => seriesName,
              },
            },
          },

          labels: time,
          xaxis: {
            type: "datetime",
            tickAmount: 12,

            labels: {
              rotate: -90,
              style: {
                colors: theme.palette.primary.main,
              },
            },
            axisBorder: {
              show: false, // x-ekseni çizgisini gizle
            },
          },
          yaxis: {
            opposite: true,
            labels: {
              style: {
                colors: theme.palette.primary.main,
              },
            },
          },

          legend: {
            markers: {
              fillColors: ["#ED2438", "#51C1C0"],
            },
            labels: {
              colors: theme.palette.primary.main,
            },
            horizontalAlign: "left",
          },
        }}
        series={data.map((item) => {
          return {
            name: item.name,
            data: item.data.map((k) => Number(k.lastvalue.toFixed(2))),
          };
        })}
        type="line"
        width={windowsize?.width ? windowsize?.width - 300 : 400}
        height={250}
      />

  );
};
