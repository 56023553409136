import React from "react";
import { Stack, Typography } from "@mui/material";
import { useTheme } from "layouts/theme/ThemeContext";
import { RowStack } from "components/stack/RowStack";
import AlarmModal from "./addAlarm/AlarmModal";
import { useTranslation } from "react-i18next";

const TopRow = ({ data }: { data: any }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  return (
    <Stack>
      <RowStack>
        <Stack>
          <Typography fontWeight={700} variant="body1">
            {t("filterManagement.filterManagement")}
          </Typography>
          <Typography
            sx={{ color: theme.palette.secondary.main }}
            fontWeight={700}
            variant="caption"
          >
            {t("general.total")} {data.length} {t("filterManagement.filter")}
          </Typography>
        </Stack>
        <AlarmModal />
      </RowStack>
    </Stack>
  );
};

export default TopRow;
