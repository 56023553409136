import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { CustomStyleButton } from "components/buttons/CustomButton";
import { useTheme } from "layouts/theme/ThemeContext";
import AutomationTable from "./components/AutomationTable";
import CustomLoading from "components/loading/CustomLoading";
import { useNavigate } from "react-router";
import { RowStack } from "components/stack/RowStack";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/Store";
import {
  getScenarioList,
  setAutomationFlowData,
} from "redux/slices/AutomationSlice";
import PageContainer from "page/general/PageContainer";
import { useTranslation } from "react-i18next";

const mockData = {
  screnario_id: 0,
  screnario_name: "",
  created: "",
  content: {
    nodes: [],
    edges: [],
  },
};
const AutomationPage = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const automation = useSelector((state: RootState) => state.automation);

  useEffect(() => {
    dispatch(getScenarioList());
  }, []);

  const data = automation.scenarioList;

  return (
    <PageContainer>
      <Stack gap={"16px"}>
        <RowStack>
          <Typography variant="caption">{t("automation.desc")}</Typography>
          <CustomStyleButton
            onClick={() => {
              navigate(`/workflow`);
              dispatch(setAutomationFlowData(mockData));
            }}
          >
            {t("automation.newScenario")}
          </CustomStyleButton>
        </RowStack>
        <Stack>
          <Typography fontWeight={700} variant="body1">
            {t("automation.automationHosts")}
          </Typography>
          <Typography
            sx={{ color: theme.palette.secondary.main }}
            fontWeight={700}
            variant="caption"
          >
            {t("automation.total")} {data && data.length} host
          </Typography>
        </Stack>
        <Stack>
          {automation.scenarioLoading ? (
            <CustomLoading />
          ) : (
            data && <AutomationTable scenarioList={data} />
          )}
        </Stack>
      </Stack>
    </PageContainer>
  );
};

export default AutomationPage;
