import {
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import React from "react";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useTheme } from "layouts/theme/ThemeContext";
import { BorderContainer } from "components/box/BorderContainer";

const ButtonContainer = styled(Button)<{ isSelect: boolean }>(
  ({ theme, isSelect }) => ({
    textTransform: "none",
    borderRadius: "8px",
    padding: "12px 12px",
    gap: "12px",
    color: isSelect ? theme.palette.success.main : theme.palette.primary.dark,
    border: isSelect ? "none" : ` 1px solid ${theme.palette.primary.main}`,

    "&:hover": {
      color: isSelect
        ? theme.palette.success.dark
        : theme.palette.primary.contrastText,
      backgroundColor: isSelect
        ? ""
        : ` ${alpha(theme.palette.primary.contrastText, 0.1)}  `,
    },
  })
);

const IntegrationList = () => {
  const { theme } = useTheme();
  const listArr = [
    {
      name: "Zabbix",
      subName: "Your subtitle comes here",
      img: "https://www.bilisimasistani.com/wp-content/uploads/2023/02/zabbix_logo_icon_167937.png",
      status: false,
    },
    {
      name: "Prtg",
      subName: "Your subtitle comes here",
      img: "https://play-lh.googleusercontent.com/JJ9nSAO70meVqN3wSbDwG0bhTF4lWFc-p5cdd4XtGAyePvvSVFQv8vDjDfT877xUiI8",
      status: false,
    },
    {
      name: "Solarwinds",
      subName: "Your subtitle comes here",
      img: "https://companieslogo.com/img/orig/SWI-35cf4004.png?t=1598831149",
      status: false,
    },
    {
      name: "Appdinamics",
      subName: "Your subtitle comes here",
      img: "https://cdn.icon-icons.com/icons2/2699/PNG/512/appdynamics_logo_icon_168593.png",
      status: false,
    },
    {
      name: "Newrelic",
      subName: "Your subtitle comes here",
      img: "https://companieslogo.com/img/orig/NEWR-de5fcb2e.png?t=1681801483",
      status: false,
    },
    {
      name: "Instana",
      subName: "Your subtitle comes here",
      img: "https://avatars.githubusercontent.com/u/5128994?s=280&v=4",
      status: false,
    },
    {
      name: "Prometheus",
      subName: "Your subtitle comes here",
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Prometheus_software_logo.svg/2066px-Prometheus_software_logo.svg.png",
      status: false,
    },
    {
      name: "Scada",
      subName: "Your subtitle comes here",
      img: "https://icons.veryicon.com/png/o/miscellaneous/tbk/scada-system.png",
      status: false,
    },
  ];
  return (
    <Grid container spacing={2}>
      {listArr.map((item) => {
        return (
          <Grid
            key={`integration${item.name}`}
            sx={{ backgroundColor: "transparent" }}
            item
            xs={12}
            md={4}
          >
            <BorderContainer>
              <Stack
                width={"100%"}
                justifyContent={"flex-end"}
                direction={"row"}
              >
                <IconButton>
                  <AutorenewIcon fontSize="small" />
                </IconButton>
                <IconButton>
                  <MoreHorizIcon fontSize="small" />
                </IconButton>
              </Stack>
              <Stack
                width={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
                direction={"row"}
              >
                <img src={item.img} alt="" width={"64px"} height={"64px"} />
                <Stack>
                  {!item.status && (
                    <ButtonContainer isSelect={false}>
                      + Install
                    </ButtonContainer>
                  )}
                  {item.status && (
                    <ButtonContainer isSelect={true}>
                      <TaskAltIcon /> Installed{" "}
                    </ButtonContainer>
                  )}
                </Stack>
              </Stack>
              <Stack alignItems={"flex-start"} width={"100%"}>
                <Typography variant="subtitle2">{item.name}</Typography>
                <Typography variant="caption">{item.subName}</Typography>
              </Stack>
            </BorderContainer>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default IntegrationList;
