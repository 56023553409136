import React from "react";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import PieChartIcon from "@mui/icons-material/PieChart";
import SchemaIcon from "@mui/icons-material/Schema";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Stack, Typography } from "@mui/material";
import { CustomStyleButton } from "components/buttons/CustomButton";
import { useTheme } from "layouts/theme/ThemeContext";

const listArr = [
  {
    icon: <PieChartIcon sx={{ fontSize: "36px" }} />,
    name: "Dashboard",
    add: "Dashboard added : 0",
    buttonName: "Add",
  },
  {
    icon: <SchemaIcon sx={{ fontSize: "36px" }} />,
    name: "Service Map",
    add: "Maps added : 1",
    buttonName: "View all",
  },
  {
    icon: <LineStyleIcon sx={{ fontSize: "36px" }} />,
    name: "Automated Task",
    add: "Configurations : 0",
    buttonName: "Configure",
  },
  {
    icon: <PermIdentityIcon sx={{ fontSize: "36px" }} />,
    name: "Users",
    add: "User count : 2 ",
    buttonName: "View all",
  },
];

const IntegrationList = () => {
  const { theme } = useTheme();

  return (
    <Stack
      direction={"row"}
      width={"100%"}
      justifyContent={"space-between"}
      alignItems={"center"}
      color={theme.palette.primary.main}
    >
      {listArr.map((item) => {
        return (
          <Stack gap={"8px"} key={`integrationList${item.name}`}>
            {item.icon}
            <Typography
              sx={{ color: theme.palette.primary.dark }}
              variant="subtitle2"
            >
              {item.name}
            </Typography>
            <Typography
              sx={{ color: theme.palette.primary.dark }}
              variant="caption"
            >
              {item.add}
            </Typography>
            <CustomStyleButton sx={{ marginTop: "8px" }}>
              {item.buttonName}
            </CustomStyleButton>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default IntegrationList;
