import React from "react";
import { Divider, Stack, Typography, styled } from "@mui/material";
import RoseTypeEChart from "page/graphify/components/GeneralComponents/charts/RoseTypeEChart";

interface ITotalData {
  name: string;
  data: {
    name: string;
    value: number;
    id: string;
  }[];
  desc: string;
}

const RowItem = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "48px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "12px",
  },
}));

interface IOsTopChartsData {
  name: string;
  data: {
    name: string;
    value: number;
    id: string;
  }[];
  desc: string;
}

const GeneralTopCharts = ({
  data,
  type,
  showDisk

}: {
  data: IOsTopChartsData[];
  type: string;
  showDisk:boolean
}) => {
  return (
    <Stack gap={"60px"}>
      <RowItem>
        <HostListRoseItem data={data[0]} type={type} />
        <Divider
          sx={{ display: { xs: "none", md: "flex" } }}
          variant="middle"
          orientation="vertical"
        />
        <HostListRoseItem data={data[1]} type={type} />
      </RowItem>
      {showDisk && <RowItem>
        <HostListRoseItem data={data[2]} type={type} />
      </RowItem> }
      <RowItem>
        <HostListRoseItem data={data[3]} type={type} />
        <Divider
          sx={{ display: { xs: "none", md: "flex" } }}
          variant="middle"
          orientation="vertical"
        />
        <HostListRoseItem data={data[4]} type={type} />
      </RowItem>
    </Stack>
  );
};

export default GeneralTopCharts;

export const HostListRoseItem = ({
  data,
  type,
}: {
  data: ITotalData;
  type: string;
}) => {
  return (
    <Stack width={"100%"} flex={1} gap={"24px"}>
      <Stack>
        <Typography width={"100%"} align="center" fontWeight={300} variant="h4">
          {data.name}{" "}
        </Typography>
        <Typography
          width={"100%"}
          align="center"
          variant="subtitle2"
          fontWeight={400}
        >
          {data.desc}{" "}
        </Typography>
      </Stack>

      <Stack
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
        width={"100%"}
        justifyContent={"space-between"}
      >
        <Stack flex={1}>
          <RoseTypeEChart pieData={data.data} type={type} />
        </Stack>
      </Stack>
    </Stack>
  );
};
