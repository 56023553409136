import ReactEcharts from "echarts-for-react";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router";
import { useTheme } from "layouts/theme/ThemeContext";

interface IType {
  name: string;
  value: number;
  id: string;
}

const RoseTypeEChart = ({
  pieData,
  type,
}: {
  pieData: IType[];
  type: string;
}) => {
  let navigate = useNavigate();
  const { theme } = useTheme();
  return (
    <Stack>
      <ReactEcharts
        style={{
          width: "100%",
          height: "420px",
          marginBottom: "0px",
          padding: "0px",
        }}
        onEvents={{
          click: (params: any) => {
            navigate(`/graphifyx/${type}/${params.data.id}`);
          },
        }}
        option={{
          legend: {
            top: "left",
            textStyle: {
              color: theme.palette.primary.dark,
            },
          },

          /*  toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              restore: { show: true },
              saveAsImage: { show: true },
            },
          }, */

          series: [
            {
              label: {
                alignTo: "edge",
                /* formatter: "{name|{b}}\n{time|{c} 小时}", */
                formatter: "{name|{b}}\n{c}",
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 15,
                color: theme.palette.primary.dark,
                rich: {
                  time: {
                    fontSize: 10,
                    color: "#999",
                  },
                },
              },
              labelLine: {
                length: 15,
                length2: 0,
                maxSurfaceAngle: 80,
              },
              name: "Nightingale Chart",
              type: "pie",
              radius: [35, 120],
              center: ["50%", "60%"],
              roseType: "area",
              itemStyle: {
                borderRadius: 8,
              },
              data: pieData,
            },
          ],
        }}
      />
    </Stack>
  );
};

export default RoseTypeEChart;

/* const pieData = [
  {
    name: "A-TARIM, ORMANCILIK VE BALIKÇILIK",
    value: 199200,
  },
  {
    name: "B-MADENCİLİK VE TAŞ OCAKÇILIĞI",
    value: 2771739,
  },
  {
    name: "C-İMALAT",
    value: 165084,
  },
  {
    name: "D-ELEKTRİK, GAZ, BUHAR VE İKLİMLENDİRME ÜRETİMİ VE DAĞITIMI",
    value: 1241,
  },
  {
    name: "E-Su Temini; Kanalizasyon, Atık Yönetimi ve İyileştirme Faaliyetleri",
    value: 1514508,
  },

  {
    name: "J-Bilgi ve İletişim",
    value: 349335,
  },
  {
    name: "M-MESLEKİ, BİLİMSEL VE TEKNİK FAALİYETLER",
    value: 0,
  },
  {
    name: "R-KÜLTÜR, SANAT, EĞLENCE, DİNLENCE VE SPOR",
    value: 24952,
  },
  {
    name: "S-DİĞER HİZMET FAALİYETLERİ",
    value: 0,
  },
];
 */
