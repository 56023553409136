import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Stack, Switch, Typography, styled } from "@mui/material";
import DeleteModal from "components/modal/DeleteModal";
import { Notify } from "components/notify/notify";
import {
  CustomTableCell,
  CustomTimeCell,
} from "components/tableCom/components";
import CustomLoading from "components/loading/CustomLoading";
import { IFilterList } from "../types/Type";
import { useTheme } from "layouts/theme/ThemeContext";
import { useState } from "react";
import ShowAlarm from "./ShowAlarm";
import EditAlarmModal from "./addAlarm/EditAlarmModal";
import { useDeleteMutation } from "query/DeleteQuery";
import { usePatchMutation } from "query/PatchQuery";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface HeadCell {
  id: string;
  label: string;
  align: "right" | "left" | "center" | "inherit" | "justify" | undefined;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    label: "Name",
    align: "left",
  },
  {
    id: "is_active",
    label: "Active",
    align: "left",
  },
  {
    id: "created",
    label: "Created",
    align: "left",
  },
  {
    id: "action",
    label: "Actions",
    align: "center",
  },
];

const TableContiner = styled(Stack)(({ theme }) => ({
  width: "100%",
  mb: 2,
  borderRadius: "16px",
  /*   boxShadow: ` ${alpha(
    theme.palette.primary.contrastText,
    0.05
  )}0px 0px 0px 1px`, */
}));

const FilterTable = ({
  data,
  loading,
}: {
  data: IFilterList[];
  loading: boolean;
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const queryClient = useQueryClient();
  const setDeletelist = useDeleteMutation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%", marginTop: "32px" }}>
      {loading ? (
        <CustomLoading />
      ) : (
        <TableContiner>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={`TableCell${headCell.id}`}
                      align={headCell.align}
                    >
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                        }}
                        variant="subtitle2"
                      >
                        {t(`filterManagement.table${headCell.label}`)}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={`host-table-checkbox-${index}`}
                        id={`host-table-checkbox-${index}`}
                        role="checkbox"
                        tabIndex={-1}
                        sx={{ cursor: "pointer" }}
                      >
                        <CustomTableCell text={row.filter_name} />

                        <CustomSwitchCell value={row} />
                        <CustomTimeCell value={row.created} />
                        <TableCell align="center">
                          <Stack
                            width={"100%"}
                            justifyContent={"center"}
                            direction={"row"}
                          >
                            <EditAlarmModal data={row} />
                            <ShowAlarm id={row.filter_id} />
                            <DeleteModal
                              text={t("filterManagement.deleteFilterDesc")}
                              onClick={() => {
                                setDeletelist.mutate(
                                  {
                                    url: `/filter/${row.filter_id}/`,
                                  },
                                  {
                                    onSuccess() {
                                      queryClient.invalidateQueries({
                                        queryKey: ["filterManagementData"],
                                      });
                                    },
                                  }
                                );
                              }}
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContiner>
      )}
    </Box>
  );
};
export default FilterTable;

const CustomSwitchCell = ({ value }: { value: IFilterList }) => {
  const setEdit = usePatchMutation();
  const [checked, setChecked] = useState<boolean>(value.is_active);
  return (
    <TableCell>
      <Stack alignItems={"center"} width={"80px"} direction={"row"} gap={"4px"}>
        <Typography fontWeight={700} variant="caption">
          false
        </Typography>
        <Switch
          checked={checked}
          onChange={(event) => {
            setChecked(event.target.checked);
            setEdit.mutate(
              {
                data: {
                  ...value,
                  is_active: event.target.checked,
                },
                url: `/filter/${value.filter_id}/`,
              },
              {
                onSuccess() {
                  Notify.notifySuccess("Filter was edited successfully");
                },
                onError() {
                  Notify.notifyError("Error");
                },
              }
            );
          }}
          color="warning"
        />
        <Typography fontWeight={700} variant="caption">
          true
        </Typography>
      </Stack>
    </TableCell>
  );
};
