import { useTheme } from "layouts/theme/ThemeContext";
import ReactApexChart from "react-apexcharts";
import "../../../../../components/apexcharts/tooltipCss.scss";

export const SlimAreaCharts = ({
  data,
  time,
  name,
  color,
  tooltipYname,
}: {
  data: number[];
  name: string;
  time: string[];
  color: string;
  tooltipYname: string;
}) => {
  const { theme } = useTheme();
  return (
    <ReactApexChart
      options={{
        chart: {
          type: "area",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
          },
        },
        tooltip: {
          cssClass: "tooltipCss",
          theme: theme.palette.primary.dark,
          x: {
            show: true,
            format: "dd MMM yyyy HH:mm",
            formatter: undefined,
          },

          y: {
            formatter: undefined,

            title: {
              formatter: (seriesName) => tooltipYname,
            },
          },
        },

        xaxis: {
          type: "datetime",
          tickAmount: 12,

          labels: {
            rotate: -90,
            style: {
              colors: theme.palette.primary.main,
            },
          },
          axisBorder: {
            show: false, // x-ekseni çizgisini gizle
          },
        },
        yaxis: {
          opposite: true,
          labels: {
            style: {
              colors: theme.palette.primary.main,
            },
          },
        },
        stroke: {
          curve: "smooth",
          colors: [color],
        },
        dataLabels: {
          enabled: false,
        },

        labels: time,

        legend: {
          horizontalAlign: "left",
        },
      }}
      series={[
        {
          data: data,
        },
      ]}
      type="line"
      height={350}
    />
  );
};
