import React from "react";

import { useSelector } from "react-redux";
import { RootState } from "redux/Store";
import CustomLoading from "components/loading/CustomLoading";
import { Outlet } from "react-router";
import PageContainer from "./PageContainer";

const GeneralView = () => {
  const layouts = useSelector((state: RootState) => state.layouts);
  return (
    <PageContainer>
      {layouts.topTabBarLoading ? <CustomLoading /> : <Outlet />}
    </PageContainer>
  );
};

export default GeneralView;
