import React from "react";
import { Stack, Typography, alpha, styled } from "@mui/material";
import { StyledTextField } from "components/textField/CustomTextField";
import { useTheme } from "layouts/theme/ThemeContext";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  fontWeight: 700,
  color: theme.palette.secondary.main,
}));

const ReturnInput = ({
  title,
  returnData,
  setReturnData,
}: {
  title: string;
  returnData: string;
  setReturnData: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { theme } = useTheme();

  return (
    <Stack gap={"6px"}>
      <TextFieldTitle>{title}:</TextFieldTitle>
      <StyledTextField
        sx={{ backgroundColor: `${alpha(theme.palette.warning.main, 0.2)}` }}
        id={title}
        name={title}
        fullWidth
        variant="outlined"
        size="small"
        placeholder={returnData}
        value={returnData}
        onChange={(event) => {
          setReturnData(event.target.value);
        }}
        /*    onBlur={(event) => {
             setTimeout(() => {
            onInputChange(parameter.parameter_name, value);
          }, 90); 
        }} */
      />
    </Stack>
  );
};

export default ReturnInput;
