import React from "react";
import { Stack, ToggleButton, Typography, alpha, styled } from "@mui/material";
import { ISeveritylist } from "page/filterManagement/types/Type";
import { IAlertsModel } from "../type/type";

export const StatusContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "24px",
  width: "100%",
  margin: "24px 0px",

  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
}));

const StyledToggleButton = styled(ToggleButton)<{ den: string }>(
  ({ theme, den }) => ({
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    justifyContent: "center",
    alignItems: "center",

    height: "42px",
    padding: "12px 36px",
    border: "none",
    borderRadius: "32px",
    /*     backgroundColor: ` ${alpha(den, 0.1)}`, */
    boxShadow: `${alpha(den, 0.3)}1px 1px 4px 0px`,
    "&:hover": {
      backgroundColor: ` ${alpha(den, 0.5)}`,
      boxShadow: `${alpha(den, 0.6)}0px 1px 10px 0px`,
    },

    "&.Mui-selected": {
      color: theme.palette.background.paper,
      backgroundColor: ` ${alpha(den, 0.9)}`,
      boxShadow: `${alpha(den, 0.5)}0px 1px 10px 0px`,
      "&:hover": {
        backgroundColor: ` ${alpha(den, 0.6)}`,
      },
    },
  })
);

const StatusView = ({
  severityList,
  alerts,
  onChange,
  selected,
  setSelected,
}: {
  severityList: ISeveritylist[];
  alerts: IAlertsModel[];
  onChange: () => void;
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <StatusContainer>
      {severityList &&
        severityList.map((item, i) => {
          const count = alerts.filter(
            (n) => n.severity.toLowerCase() === item.severity_name.toLowerCase()
          ).length;
          return (
            <Stack key={`customToggleButton_${i}`}>
              {count !== 0 && (
                <StyledToggleButton
                  key={`customToggleButton_${i}`}
                  den={item.severity_color}
                  value="check"
                  selected={Boolean(
                    selected == item.severity_name.toLowerCase()
                  )}
                  onChange={() => {
                    onChange();

                    if (!selected) {
                      setSelected(item.severity_name.toLowerCase());
                    } else {
                      setSelected("");
                    }
                  }}
                >
                  <Typography variant="body2">
                    {item.severity_name.toLowerCase()}:
                  </Typography>
                  <Typography variant="subtitle2">{count}</Typography>
                </StyledToggleButton>
              )}
            </Stack>
          );
        })}
    </StatusContainer>
  );
};

export default StatusView;
