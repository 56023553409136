import React from "react";
import {
  LinearProgress,
  Stack,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import ChartWrapper from "components/eCharts/ChartWrapper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.error.light,
  },
}));

const limitArr = [
  { name: "Credit Limit", firstData: 1500, secondDtata: 2000, value: 75 },
  { name: "Online Limit", firstData: 1280, secondDtata: 1500, value: 85 },
];

const Limit = () => {
  return (
    <Stack
      sx={{
        height: "340px",
        maxHeight: "340px",
        width: "40%",
        gap: "24px",
      }}
    >
      {limitArr.map((item) => {
        return (
          <ChartWrapper
            key={`limitArr${item.name}`}
            children={
              <Stack
                sx={{
                  padding: "12px",
                  gap: "16px",
                  height: "132px",
                  maxHeight: "132px",
                  width: "100%",
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="subtitle2">{item.name} </Typography>
                  <MoreHorizIcon />
                </Stack>

                <Stack direction={"row"} gap={"4px"} alignItems={"flex-end"}>
                  <Typography fontWeight={700} variant="body1">
                    {item.firstData}
                  </Typography>
                  {"/"}
                  <Typography variant="caption">{item.secondDtata}</Typography>
                </Stack>
                <Stack>
                  <BorderLinearProgress
                    variant="determinate"
                    value={item.value}
                  />
                </Stack>
              </Stack>
            }
          />
        );
      })}
    </Stack>
  );
};

export default Limit;
