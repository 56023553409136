import React from "react";
import {
  Typography,
  AccordionSummary,
  AccordionDetails,
  styled,
  AccordionProps,
  alpha,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "layouts/theme/ThemeContext";
import { IDataNode, IFlowArr } from "page/workflow/types/nodeTypes";

interface InfoItemProps extends AccordionProps {
  borderinfo: boolean;
}

const Accordion = styled((props: InfoItemProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, borderinfo }) => ({
  borderRadius: "16px",
  boxShadow:
    borderinfo === true
      ? ` ${alpha(theme.palette.primary.contrastText, 0.1)}0px 0px 0px 1px`
      : "none",
}));

const SummaryTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  width: "100%",
  padding: "0px 8px 0px 0px",
  boxSizing: "border-box",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "center",
  fontWeight: 700,

  [theme.breakpoints.up("md")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.only("sm")]: {
    fontSize: "9px",
  },
  [theme.breakpoints.only("xs")]: {
    fontSize: "7px",
  },
}));

const CustomDndnode = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DE481E40",
  /*   backgroundColor: "transparent", */
  borderRadius: "16px",
  cursor: "grab",
  fontSize: "12px",
  "&:hover": {
    backgroundColor: "#DE481E60",
    color: "white",
    fontWeight: 700,
    border: `none`,
    borderRadius: "16px",
  },
}));

const ItemAccordion = ({
  sidebarData,
  index,
}: {
  sidebarData: IFlowArr;
  index: number;
}) => {
  const { theme } = useTheme();

  /* Accordion */
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  /* Accordion */

  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    nodeType: string,
    nodeData: { data: IDataNode }
  ) => {
    const stringNode = JSON.stringify(nodeData);
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("application/reactflow1", stringNode);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <Stack gap={"8px"}>
      <Accordion
        borderinfo={Boolean(expanded === "panel1")}
        expanded={expanded === "panel1"}
        onChange={handleAccordionChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="small" />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            borderRadius: "16px",
            backgroundColor:
              expanded === "panel1"
                ? "none"
                : ` ${alpha(theme.palette.background.default, 0.8)}`,
          }}
        >
          <SummaryTitle>{sidebarData.category_name}</SummaryTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Stack gap={"4px"}>
            {sidebarData.components.map((item, i) => {
              return (
                <CustomDndnode
                  key={`nodesAllItem${i}`}
                  onDragStart={(event) => {
                    item.component_type &&
                      onDragStart(event, item.component_type, {
                        data: item,
                      });
                  }}
                  draggable
                >
                  <Typography
                    sx={{
                      width: "100%",
                      padding: "0px 8px",
                      boxSizing: "border-box",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: { xs: "7px", sm: "9px", md: "11px" },
                    }}
                    align="center"
                  >
                    {item.data?.display_name}
                  </Typography>
                </CustomDndnode>
              );
            })}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default ItemAccordion;
