import { baseUrl } from "utils/helpers/BaseUrl";

export const fetchPatch = async (datas: any, url: string) => {
  const user: string | null = sessionStorage.getItem("user");

  try {
    if (user !== null) {
      const userData = JSON.parse(user);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Token ${userData.token}`);
      const data = await fetch(`${baseUrl}${url}`, {
        method: "PATCH",
        headers: myHeaders,
        body: JSON.stringify(datas),
        redirect: "follow",
      })
        .then((response) => response.json())
        .catch((error) => {
          console.log(error);
        });
      return data;
    }
  } catch (error) {}
};
