import React from "react";
import { Stack, Typography, alpha, styled } from "@mui/material";
import { SeverityCount } from "page/graphify/type";
import ReactEcharts from "echarts-for-react";
import { useTranslation } from "react-i18next";
import { useTheme } from "layouts/theme/ThemeContext";

const Content = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px",
  borderRadius: "16px",
  gap: "16px",
  boxShadow: ` ${alpha(theme.palette.primary.dark, 0.25)}  0px 5px 15px `,
}));

type DataType = {
  name: string;
  value: number[];
};

type GroupedData = {
  date: string[];
  data: DataType[];
};

const AlarmChart = ({ lineChartData }: { lineChartData: SeverityCount[] }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const groupedData: GroupedData = {
    date: [],
    data: [],
  };

  // Verileri gruplayın
  lineChartData.forEach((item) => {
    const dataIndex = groupedData.data.findIndex((el) => el.name === item.name);
    const dateIndex = groupedData.date.indexOf(item.created);

    if (dataIndex === -1) {
      groupedData.data.push({ name: item.name, value: [] });
    }

    if (dateIndex === -1) {
      groupedData.date.push(item.created);
    }

    const dataIndexUpdated = groupedData.data.findIndex(
      (el) => el.name === item.name
    );
    const dateIndexUpdated = groupedData.date.indexOf(item.created);

    if (
      groupedData.data[dataIndexUpdated].value[dateIndexUpdated] === undefined
    ) {
      groupedData.data[dataIndexUpdated].value[dateIndexUpdated] = item.number;
    } else {
      groupedData.data[dataIndexUpdated].value[dateIndexUpdated] += item.number;
    }
  });

  // Eksik verileri 0 ile tamamlayın
  groupedData.data.forEach((item) => {
    for (let i = 0; i < groupedData.date.length; i++) {
      if (item.value[i] === undefined) {
        item.value[i] = 0;
      }
    }
  });

  return (
    <Content flex={1}>
      <Stack
        sx={{
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="body1" fontWeight={700}>
          {t("graphify.totalAlarm")}
        </Typography>
        <Typography variant="caption">
          {t("graphify.totalAlarmDesc")}
        </Typography>
      </Stack>

      <ReactEcharts
        style={{
          width: "100%",
          height: "350px",
          margin: "0",
          padding: "0",
        }}
        option={{
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            top: "bottom",
            data: groupedData.data.map((item) => item.name),
            textStyle: {
              color: theme.palette.primary.dark,
            },
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              data: groupedData.date,
              axisLabel: {
                formatter: "{value}",
                color: theme.palette.primary.dark,
              },
              axisLine: {
                lineStyle: {
                  color: theme.palette.primary.dark,
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: "{value}",
                color: theme.palette.primary.dark,
              },

              axisLine: {
                lineStyle: {
                  color: theme.palette.primary.dark,
                },
              },
            },
          ],
          series: groupedData.data.map((item) => {
            return {
              name: item.name,
              type: "bar",
              data: item.value,
            };
          }),
        }}
      />
    </Content>
  );
};

export default AlarmChart;
