import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useTheme } from "layouts/theme/ThemeContext";
import { ISeveritylist } from "page/filterManagement/types/Type";

const StatusStack = ({
  severity,
  severityList,
}: {
  severity: string;
  severityList: ISeveritylist[];
}) => {
  const { theme } = useTheme();
  const [filterColor, setColor] = useState<string>("#B80D0D");

  useEffect(() => {
    const findColor = severityList.filter(
      (item) => item.severity_name.toLowerCase() === severity.toLowerCase()
    );
    if (findColor.length !== 0) {
      setColor(findColor[0].severity_color);
    }
  }, []);
  return (
    <Stack
      sx={{
        ...theme.typography.overline,
        fontWeight: 700,
        padding: "2px",
        borderRadius: "10px",
        width: "80px",
        alignItems: "center",
        fontSize: 9,
        color: theme.palette.background.paper,
        backgroundColor: filterColor,
      }}
    >
      {severity}
    </Stack>
  );
};

export default StatusStack;
