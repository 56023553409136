import {
  Stack,
  styled,
  alpha,
  TableCell,
  Typography,
  Switch,
} from "@mui/material";
import { useTheme } from "layouts/theme/ThemeContext";
import { IFilterList } from "page/filterManagement/types/Type";
import { useState } from "react";
import { formatDateTime } from "utils/helpers/TimeFunc";

export const TableContiner = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100%",
  mb: 2,
  borderRadius: "16px",
  boxShadow: ` ${alpha(
    theme.palette.primary.contrastText,
    0.05
  )}0px 0px 0px 1px`,
}));

export const CustomArrTableCell = ({ arr }: { arr: string[] | undefined }) => {
  return (
    <TableCell>
      <Stack gap={"8px"}>
        {arr && arr.length < 1 ? (
          <Typography variant="body2">-</Typography>
        ) : (
          <Stack>
            {arr?.map((item) => {
              return <Typography variant="body2">{item}</Typography>;
            })}
          </Stack>
        )}
      </Stack>
    </TableCell>
  );
};

export const CustomTableCell = ({ text }: { text: string }) => {
  return (
    <TableCell>
      <Stack gap={"8px"}>
        {text === "" ? (
          <Typography variant="body2">-</Typography>
        ) : (
          <Typography variant="body2">{text}</Typography>
        )}
      </Stack>
    </TableCell>
  );
};

export const CustomBoolCell = ({ value }: { value: boolean }) => {
  const { theme } = useTheme();
  return (
    <TableCell>
      <Stack width={"80px"} gap={"8px"}>
        {value === true ? (
          <Stack
            sx={{
              padding: "4px",
              borderRadius: "32px",
              alignItems: "center",
              color: theme.palette.background.paper,
              backgroundColor: ` ${alpha(theme.palette.info.main, 0.8)}`,
            }}
          >
            <Typography fontWeight={700} variant="caption">
              True
            </Typography>
          </Stack>
        ) : (
          <Stack
            sx={{
              padding: "4px",
              borderRadius: "32px",
              alignItems: "center",
              color: theme.palette.background.paper,
              backgroundColor: ` ${alpha(theme.palette.error.main, 0.8)}`,
            }}
          >
            <Typography fontWeight={700} variant="caption">
              False
            </Typography>
          </Stack>
        )}
      </Stack>
    </TableCell>
  );
};

export const CustomTimeCell = ({ value }: { value: string }) => {
  return (
    <TableCell>
      <Typography variant="body2">{formatDateTime(value)}</Typography>
    </TableCell>
  );
};

export const CustomSwitchCell = ({ value }: { value: IFilterList }) => {
  const [checked, setChecked] = useState<boolean>(value.is_active);
  return (
    <TableCell>
      <Stack alignItems={"center"} width={"80px"} direction={"row"} gap={"4px"}>
        <Typography fontWeight={700} variant="caption">
          false
        </Typography>
        <Switch
          checked={checked}
          onChange={(event) => {
            setChecked(event.target.checked);
          }}
          color="warning"
        />
        <Typography fontWeight={700} variant="caption">
          true
        </Typography>
      </Stack>
    </TableCell>
  );
};
