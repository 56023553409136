import React, { useState } from "react";
import { Box, Modal, Stack, Typography, styled } from "@mui/material";
import { CustomStyleButton } from "components/buttons/CustomButton";
import { CancelButtonSyled } from "components/buttons/CancelButtonSyled";
import { DeleteButtonStyled } from "components/buttons/DeleteButtonStyled";
import { useTranslation } from "react-i18next";

const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "48px",
  borderRadius: "16px",
  backgroundColor: theme.palette.background.default,
}));

const ClearModal = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  const [openClearModal, setOpenClearModal] = useState(false);
  return (
    <Stack>
      <CustomStyleButton
        onClick={() => {
          setOpenClearModal(true);
        }}
      >
        {t("workflow.clearWorkflow")}
      </CustomStyleButton>
      <Modal
        open={openClearModal}
        onClose={() => {
          setOpenClearModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent>
          <Stack alignItems={"center"} gap={"16px"}>
            <Typography
              align="center"
              fontWeight={700}
              variant="body1"
              maxWidth={"170px"}
            >
              {t("workflow.clearWorkflowDesc")}
            </Typography>
            <Stack
              direction={"row"}
              width={"100%"}
              gap={"16px"}
              justifyContent={"flex-end"}
            >
              <CancelButtonSyled
                onClick={() => {
                  setOpenClearModal(false);
                }}
              >
                {t("general.cancel")}
              </CancelButtonSyled>
              <DeleteButtonStyled
                onClick={() => {
                  onClick();
                  setOpenClearModal(false);
                }}
              >
                {t("general.clear")}
              </DeleteButtonStyled>
            </Stack>
          </Stack>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default ClearModal;
