import ReactEcharts from "echarts-for-react";
import { useTheme } from "layouts/theme/ThemeContext";
import { Stack } from "@mui/material";

interface IType {
  name: string;
  value: number;
}

const PieLegenEChart = ({
  pieData,
  name,
  category,
}: {
  pieData: IType[];
  name: string;
  category: string[];
}) => {
  const { theme } = useTheme();

  return (
    <Stack sx={{ width: "100%" }}>
      <ReactEcharts
        style={{
          width: "100%",
          height: "390px",
          marginBottom: "0px",
          padding: "0px",
        }}
        option={{
          grid: {
            left: "3%",
            right: "7%",
            bottom: "7%",
            containLabel: true,
          },
          tooltip: {
            trigger: "item",
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          legend: {
            data: category,
            left: "center",
            bottom: "0px",
            textStyle: {
              color: theme.palette.primary.dark,
              fontSize: "11px",
            },
          },
          series: [
            {
              name: name,
              type: "pie",
              radius: [40, 115],
              center: ["50%", "50%"],
              itemStyle: {
                borderRadius: 8,
              },
              label: {
                color: theme.palette.primary.dark,
                show: true,
                fontSize: 11,
                formatter(param: { name: any }) {
                  return param.name;
                },
              },
              data: pieData,
            },
          ],
        }}
      />
    </Stack>
  );
};

export default PieLegenEChart;

/* const pieData = [
  {
    name: "A-TARIM, ORMANCILIK VE BALIKÇILIK",
    value: 199200,
  },
  {
    name: "B-MADENCİLİK VE TAŞ OCAKÇILIĞI",
    value: 2771739,
  },
  {
    name: "C-İMALAT",
    value: 165084,
  },
  {
    name: "D-ELEKTRİK, GAZ, BUHAR VE İKLİMLENDİRME ÜRETİMİ VE DAĞITIMI",
    value: 1241,
  },
  {
    name: "E-Su Temini; Kanalizasyon, Atık Yönetimi ve İyileştirme Faaliyetleri",
    value: 1514508,
  },

  {
    name: "J-Bilgi ve İletişim",
    value: 349335,
  },
  {
    name: "M-MESLEKİ, BİLİMSEL VE TEKNİK FAALİYETLER",
    value: 0,
  },
  {
    name: "R-KÜLTÜR, SANAT, EĞLENCE, DİNLENCE VE SPOR",
    value: 24952,
  },
  {
    name: "S-DİĞER HİZMET FAALİYETLERİ",
    value: 0,
  },
];
 */
