import React from "react";
import { Size, useWindowSize } from "utils/hooks/use_window_size";
import { Box, Stack, styled } from "@mui/material";
import { IDataNode, IFlowArr } from "page/workflow/types/nodeTypes";
import CustomTooltip from "components/tooltip/tooltip";
import LoopIcon from "@mui/icons-material/Loop";
import HardwareIcon from "@mui/icons-material/Hardware";
import UpdateIcon from "@mui/icons-material/Update";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import { useGetData } from "query/GetQuery";

const SidebarContent = styled(Box)<{ windowsize: Size }>(
  ({ theme, windowsize }) => ({
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    borderRadius: "8px",
  })
);

const CustomStack = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
}));

const CustomDndnode = styled(Stack)(({ theme }) => ({
  width: "36px",
  height: "36px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid ${theme.palette.primary.main} `,
  color: theme.palette.primary.dark,
  borderRadius: "8px",
  cursor: "grab",
  "&:hover": {
    backgroundColor: "#DE481E80",
    color: "white",
    fontWeight: 700,
    border: `none`,
    borderRadius: "8px",
  },
}));

const TopSidebar = () => {
  const windowsize: Size = useWindowSize();

  const { data } = useGetData<IFlowArr>({
    path: `/component_category/`,
    key: `getSidebarData`,
  });

  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    nodeType: string,
    nodeData: { data: IDataNode }
  ) => {
    const stringNode = JSON.stringify(nodeData);
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("application/reactflow1", stringNode);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <SidebarContent windowsize={windowsize}>
      {data &&
        data
          .filter((item) => item.category_name === "Built-In")
          .map((item, index) => {
            return (
              <CustomStack key={`topSidebar${index}`}>
                {item.components.map((item, index) => {
                  return (
                    <CustomTooltip
                      key={`topSidebarItem${index}`}
                      title={item.data?.display_name}
                    >
                      <CustomDndnode
                        onDragStart={(event) => {
                          item.component_type &&
                            onDragStart(event, item.component_type, {
                              data: item,
                            });
                        }}
                        draggable
                      >
                        {item.data?.display_name === "Loop" && <LoopIcon />}
                        {item.data?.display_name === "Break" && (
                          <HardwareIcon />
                        )}
                        {item.data?.display_name === "Condition" && (
                          <AssignmentOutlinedIcon />
                        )}
                        {item.data?.display_name === "Else" && (
                          <LanOutlinedIcon />
                        )}
                        {item.data?.display_name === "End Loop" && (
                          <UpdateIcon />
                        )}
                        {item.data?.display_name === "End Condition" && (
                          <AssignmentTurnedInOutlinedIcon />
                        )}
                      </CustomDndnode>
                    </CustomTooltip>
                  );
                })}
              </CustomStack>
            );
          })}
    </SidebarContent>
  );
};

export default TopSidebar;
