import React from "react";
import { Stack, Typography, alpha, styled } from "@mui/material";
import PieLegenEChart from "components/eCharts/PieLegenEChart";
import CustomLoading from "components/loading/CustomLoading";
import { IAlertsModel } from "page/graphify/type";
import { useTranslation } from "react-i18next";

const Content = styled(Stack)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "center",
  padding: "16px",
  borderRadius: "16px",
  gap: "8px",
  boxShadow: ` ${alpha(theme.palette.primary.dark, 0.25)}  0px 5px 15px `,
}));

const RightChartGroup = ({ data }: { data: IAlertsModel[] }) => {
  const { t } = useTranslation();
  const severityCounts: { [key: string]: number } = {};

  data.forEach((item) => {
    const severity = item.severity;
    if (!severityCounts[severity]) {
      severityCounts[severity] = 0;
    }
    severityCounts[severity]++;
  });
  const newData = Object.keys(severityCounts).map((key) => ({
    name: key,
    number: severityCounts[key],
  }));

  if (newData === undefined) {
    return <CustomLoading />;
  }

  return (
    <Content flex={1}>
      {newData && (
        <Stack width={"100%"}>
          <Stack>
            <Typography variant="body1" fontWeight={700}>
              {t("graphify.severityChart")}
            </Typography>
            <Typography variant="caption">
              {t("graphify.severityChartDesc")}
            </Typography>
          </Stack>

          <PieLegenEChart
            pieData={newData.map((item) => ({
              name: item.name,
              value: item.number,
            }))}
            name={""}
            category={newData.map((item) => item.name)}
          />
        </Stack>
      )}
    </Content>
  );
};

export default RightChartGroup;
