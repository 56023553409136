import { FC } from "react";
import { Link } from "react-router-dom";
import { toImageUrl } from "utils/helpers/AssetHelpers";
import { setTopbarMainTitle } from "redux/slices/LayoutsSlice";
import { useDispatch } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { CustomStyleButton } from "components/buttons/CustomButton";
import { useTheme } from "layouts/theme/ThemeContext";

const Error404 = () => {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  return (
    <Stack
      sx={{
        gap: "36px",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "100%",
        height: "100vh",

        backgroundColor: theme.palette.background.default,
      }}
    >
      <Stack alignItems={"center"} gap={"24px"}>
        <Stack alignItems={"center"} direction={"row"} gap={"16px"}>
          <Typography fontWeight={700} variant="h4">
            Oops...!
          </Typography>
          <Typography variant="h4">The page is no longer here</Typography>
        </Stack>
        <CustomStyleButton>
          <Link
            onClick={() => {
              dispatch(setTopbarMainTitle("Dashboard"));
            }}
            to="/dashboard"
            className="linkStyles"
            style={{ textDecoration: "none", color: `#FFFFFF` }}
          >
            Take me Back!
          </Link>
        </CustomStyleButton>
      </Stack>

      <img
        src={toImageUrl("/media/error/error-404.png")}
        alt=""
        style={{ width: "70%", height: "auto" }}
      />
    </Stack>
  );
};

export { Error404 };
