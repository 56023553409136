import React from "react";
import {
  Box,
  BoxProps,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import ThemeSwitcher from "layouts/theme/themeSwitcher";
import { IconLogoMedium } from "components/icons/Logos";
import AccountSettings from "./components/AccountSettings";
import NotificationsMenu from "./components/Notifications";
import TopTabBarDesktop from "./components/topTabBar/TopTabBarDesktop";
import TopTabBarMobil from "./components/topTabBar/TopTabBarMobil";
import CustomTooltip from "components/tooltip/tooltip";
import { setMobileOpen } from "redux/slices/LayoutsSlice";
import { RootState } from "redux/Store";
import { useDispatch, useSelector } from "react-redux";
import LanguageSwitcher from "layouts/lang/language_switcher";
import { useTranslation } from "react-i18next";
import { Size, useWindowSize } from "utils/hooks/use_window_size";

const TopbarContent = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "64px",
}));

const BoxContent = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  padding: "8px",
  height: "64px",
}));

const Topbar = () => {
  const windowsize: Size = useWindowSize();
  const dispatch = useDispatch();
  const layouts = useSelector((state: RootState) => state.layouts);

  return (
    <TopbarContent
      sx={{
        width: { xs: `calc(${windowsize?.width}px - 20px)`, md: "100%" },
      }}
    >
      <Stack sx={{ position: "relative", alignItems: "center" }}>
        <TopTabBarDesktop />
      </Stack>

      <BoxContent>
        <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
          <CustomTooltip title="Sidebar Menu">
            <IconButton
              sx={{
                alignItems: "center",
                display: { xs: "flex ", sm: "none" },
              }}
              onClick={() => {
                dispatch(setMobileOpen(true));
              }}
            >
              <IconLogoMedium sx={{ width: "32px" }} />
            </IconButton>
          </CustomTooltip>
          <Typography
            fontWeight={700}
            variant="h3"
            sx={{ fontSize: { xs: "16px ", sm: "24px", md: "24px" } }}
          >
            {layouts.topbarMainTitle}
          </Typography>
        </Stack>
        <Stack
          sx={{
            gap: { xs: "2px", md: "8px" },
          }}
          direction={"row"}
          alignItems={"center"}
        >
          <LanguageSwitcher />
          <TopTabBarMobil />
          <ThemeSwitcher />
          {/*  <NotificationsMenu /> */}
          <AccountSettings />
        </Stack>
      </BoxContent>
    </TopbarContent>
  );
};

export default Topbar;
