import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ITopTabBarType } from "./types";
import { topTabBarArr } from "data/pathArr";

export interface LayoutsState {
  topbarMainTitle: string;
  topTabBarName: string;
  topTabBarSelect: ITopTabBarType;
  topTabBarLoading: boolean;
  mobileIsOpen: boolean;
  sidebarIsOpen: boolean;
}

const initialState: LayoutsState = {
  topbarMainTitle: "Dashboard",
  topTabBarName: "",
  topTabBarSelect: topTabBarArr[0],
  topTabBarLoading: false,
  mobileIsOpen: false,
  sidebarIsOpen: false,
};

export const layoutsSlice = createSlice({
  name: "layouts",
  initialState,
  reducers: {
    setSidebarIsOpen: (state) => {
      state.sidebarIsOpen = !state.sidebarIsOpen;
    },
    setMobileOpen: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        mobileIsOpen: action.payload,
        sidebarIsOpen: true,
      };
    },
    setTopbarMainTitle: (state, action: PayloadAction<string>) => {
      state.topbarMainTitle = action.payload;
      sessionStorage.setItem("topbarMainTitle", action.payload);
    },
    setTopTabBarName: (state, action: PayloadAction<string>) => {
      state.topTabBarLoading = true;
      state.topTabBarName = action.payload;
      state.topTabBarLoading = false;
      /*  setTimeout(() => {
        state.topTabBarLoading = false;
      }, 300); */
    },
    setTopTabBarSelect: (state, action: PayloadAction<ITopTabBarType>) => {
      state.topTabBarLoading = true;
      state.topTabBarSelect = action.payload;
      state.topTabBarLoading = false;
    },
  },
});

export const {
  setSidebarIsOpen,
  setMobileOpen,
  setTopbarMainTitle,
  setTopTabBarName,
  setTopTabBarSelect,
} = layoutsSlice.actions;

export default layoutsSlice.reducer;
