import React from "react";
import { Stack, Modal, styled, Box, Typography } from "@mui/material";
import CustomTooltip from "components/tooltip/tooltip";
import { DeleteOutline } from "@mui/icons-material";
import { CustomIconButton } from "components/buttons/CustomIconButton";
import { CancelButtonSyled } from "components/buttons/CancelButtonSyled";
import { DeleteButtonStyled } from "components/buttons/DeleteButtonStyled";
import { useTranslation } from "react-i18next";

const ModalDeleteContent = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "48px",
  borderRadius: "16px",
  backgroundColor: theme.palette.background.default,
}));

const DeleteModal = ({
  onClick,
  text,
}: {
  onClick: () => void;
  text: string;
}) => {
  const { t } = useTranslation();
  const [openDelete, setOpenDelete] = React.useState(false);
  return (
    <Stack>
      <CustomTooltip title={"Delete"}>
        <CustomIconButton
          onClick={() => {
            setOpenDelete(true);
          }}
        >
          <DeleteOutline />
        </CustomIconButton>
      </CustomTooltip>

      <Modal
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalDeleteContent>
          <Typography align="center" fontWeight={700} variant="body1">
            {text} <br /> {t("general.continue")}?
          </Typography>
          <Stack
            direction={"row"}
            width={"100%"}
            gap={"16px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CancelButtonSyled
              onClick={() => {
                setOpenDelete(false);
              }}
            >
              {t("general.cancel")}
            </CancelButtonSyled>
            <DeleteButtonStyled
              onClick={() => {
                onClick();
                setOpenDelete(false);
              }}
            >
              {t("general.delete")}
            </DeleteButtonStyled>
          </Stack>
        </ModalDeleteContent>
      </Modal>
    </Stack>
  );
};

export default DeleteModal;
