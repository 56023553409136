import { IGraphifyOs } from "page/graphify/type";

export const createRoseDataFunc = (inputData: IGraphifyOs[]) => {
  const data = inputData.map((item) => {
    const filteredData = item.network_interfaces[0];
    const value = filteredData?.data
      ? filteredData.data[filteredData.data.length - 1].lastvalue
      : 0;
    const netValue: number = Number(value.toFixed(2));
    return {
      name: item.host_name,
      value: netValue,
      id: item.host_id,
    };
  });
  return data.sort((a, b) => b.value - a.value);
};

export const createNetworkFn = (datas: IGraphifyOs[], type: string) => {
  let filteredInterfaces = datas.map((fild) => {
    return {
      ...fild,
      network_interfaces: fild.network_interfaces.filter((iface) =>
        iface.name.includes(type)
      ),
    };
  });
  if (filteredInterfaces[0].network_interfaces.length !== 0) {
    return filteredInterfaces.map((sorted) => {
      return {
        ...sorted,
        network_interfaces: sorted.network_interfaces.sort((a, b) => {
          const aValue = a.data[a.data.length - 1].lastvalue;
          const bValue = b.data[b.data.length - 1].lastvalue;
          return bValue - aValue;
        }),
      };
    });
  } else {
    return { ...filteredInterfaces, network_interfaces: [] };
  }
};
