import { IGraphifyOs } from "page/graphify/type";

export const createMetricsRoseDataFunc = (
  data: IGraphifyOs[],
  name: string
) => {
  let roseMetricsData = data.map((item) => {
    const filteredData = item.metrics.find((item) => item.name_x === name);
    const value = filteredData?.data
      ? filteredData.data[filteredData.data.length - 1].lastvalue
      : 0;
    const netValue: number = Number(value.toFixed(2));
    return {
      name: item.host_name,
      value: netValue,
      id: item.host_id,
    };
  });

  return roseMetricsData.sort((a, b) => {
    return b.value - a.value;
  });
};

export const createMetricsFn = (datas: IGraphifyOs[]) => {
  return datas.map((sorted) => {
    return {
      ...sorted,
      network_interfaces: sorted.network_interfaces.sort((a, b) => {
        const aValue = a.data[a.data.length - 1].lastvalue;
        const bValue = b.data[b.data.length - 1].lastvalue;
        return bValue - aValue;
      }),
    };
  });
};
