import React, { useState } from "react";
import { Size, useWindowSize } from "utils/hooks/use_window_size";
import RandomEcharts from "components/eCharts/RandomEcharts";
import { Container, FormContainer, SubContainer } from "./AuthStyled";
import { useTheme } from "layouts/theme/ThemeContext";
import SignInPage from "./signIn/SignInPage";
import AuthHeader from "./AuthHeader";

const AuthView = () => {
  const { theme } = useTheme();
  const windowsize: Size = useWindowSize();

  return (
    <Container windowsize={windowsize}>
      <SubContainer>
        <FormContainer>
          <AuthHeader />
          <SignInPage />
        </FormContainer>
      </SubContainer>
      <SubContainer
        sx={{
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        }}
      >
        <RandomEcharts />
      </SubContainer>
    </Container>
  );
};

export default AuthView;
