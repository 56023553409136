import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Size, useWindowSize } from "utils/hooks/use_window_size";
import { useTheme } from "layouts/theme/ThemeContext";
import CustomTooltip from "components/tooltip/tooltip";
import { sidebarListArr } from "data/pathArr";
import { setTopbarMainTitle } from "redux/slices/LayoutsSlice";
import { RootState } from "redux/Store";
import { useTranslation } from "react-i18next";

const listItemButtonStyles = {
  "&:hover": {
    backgroundColor: "transparent",
    transition: "all 0.2s ease-in-out",
    transform: "scale(1.05)",
  },
};

const CustomListText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",

  "&:hover": {
    fontWeight: 700,
  },
}));

const CustomList = styled(List)<{ windowsize: Size }>(
  ({ theme, windowsize }) => ({
    display: "flex",
    flexDirection: "column",
    height: `calc(${windowsize?.height}px - 120px)`,
    justifyContent: "space-between",
    padding: "16px 0px 0px 4px",
    boxSizing: "border-box",
  })
);

const CustomMenuItem = ({ open }: { open: boolean }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const windowsize: Size = useWindowSize();
  const layouts = useSelector((state: RootState) => state.layouts);
  return (
    <div>
      <CustomList windowsize={windowsize}>
        <Stack>
          {sidebarListArr.map((text, index) => (
            <Stack key={text.path}>
              <ListItem
                onClick={() => {
                  dispatch(setTopbarMainTitle(text.title));
                }}
                disablePadding
              >
                <Link
                  style={{
                    width: "100%",
                    textDecoration: "none",
                    height: "40px",
                  }}
                  to={text.path}
                >
                  <ListItemButton sx={listItemButtonStyles} disableRipple>
                    <CustomTooltip title={text.title}>
                      <ListItemIcon
                        style={{
                          width: "36px",
                          minWidth: "40px",
                          color:
                            layouts.topbarMainTitle === text.title
                              ? "#DE481E"
                              : theme.palette.text.secondary,
                        }}
                      >
                        {text.icons}
                      </ListItemIcon>
                    </CustomTooltip>
                    {open === true && (
                      <Stack alignItems={"center"} direction={"row"}>
                        <CustomListText
                          sx={{
                            color: Boolean(
                              layouts.topbarMainTitle === text.title
                            )
                              ? "#DE481E"
                              : theme.palette.text.secondary,
                          }}
                        >
                          {t(`sidebar.${text.path}`)}

                          {/*        {text.title}{" "} */}
                        </CustomListText>
                      </Stack>
                    )}
                  </ListItemButton>
                </Link>
              </ListItem>
            </Stack>
          ))}
        </Stack>
      </CustomList>
    </div>
  );
};

export default CustomMenuItem;
