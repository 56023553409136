import { INetworkInterfaces } from "page/graphify/type";
import ReactApexChart from "react-apexcharts";

export const SlimLinesCharts = ({ data }: { data: INetworkInterfaces[] }) => {
  return (
    <ReactApexChart
      options={{
        legend: {
          show: false,
        },
        chart: {
          height: 80,
          type: "area",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
          },
        },
        title: {
          margin: 0,
        },
        stroke: {
          width: 3,
          curve: "smooth",
          colors: ["#ED2438", "#51C1C0"],
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
            offsetX: -10,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        xaxis: {
          labels: {
            show: false,
            offsetX: -10,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          enabled: false, // Tooltip'u devre dışı bırakır
        },
      }}
      series={data.map((item) => {
        return {
          name: item.name,
          data: item.data.map((k) => Number(k.lastvalue.toFixed(2))),
        };
      })}
      type="line"
      height={80}
    />
  );
};
