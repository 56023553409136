import React from "react";
import Chart from "react-apexcharts";

/* const data = [76, 67, 61, 90];
const labelsName = ["Vimeo", "Messenger", "Facebook", "LinkedIn"];
const circleColors = ["#1ab7ea", "#0084ff", "#39539E", "#0077B5"];
 */
const RadialBarChartsSimple = ({
  height,
  width,
  seriesData,
  labelsName,
  circleColors,
}: {
  width: string;
  height: string;
  seriesData: number[];
  labelsName: string[];
  circleColors: string[];
}) => {
  return (
    <Chart
      options={{
        colors: circleColors,
        plotOptions: {
          radialBar: {
            offsetX: 0,
            offsetY: 0,
            hollow: {
              size: "40%",
            },
            dataLabels: {
              name: {
                show: labelsName.length !== 0 ? true : false,
              },
              value: {
                color: circleColors[0],
                fontWeight: 700,
                fontSize: "12px",
                offsetY: 5,
              },
            },
          },
        },
        labels: labelsName,
      }}
      series={seriesData}
      type="radialBar"
      width={width}
      height={height}
    />
  );
};

export default RadialBarChartsSimple;
