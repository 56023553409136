import React from "react";
import SpaceDashboardRoundedIcon from "@mui/icons-material/SpaceDashboardRounded";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import DescriptionIcon from "@mui/icons-material/Description";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ErrorIcon from "@mui/icons-material/Error";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";

export const pathShortArr = [
  { path: "/dashboard", name: "Dashboard" },
  { path: "/alerts", name: "Alerts" },
  { path: "/graphifyx", name: "GraphifyX" },
  { path: "/automation", name: "Automation" },
  { path: "/workflow", name: "Workflow" },
  { path: "/agentManagement", name: "Agent Management" },
  { path: "/reports", name: "Reports" },
  { path: "/filterManagement", name: "Filter Management" },
  { path: "/integration", name: "Integration" },
  { path: "/marketPlace", name: "Market Place" },
  { path: "/settings", name: "Settings" },
  { path: "/account", name: "Account" },
];

export const sidebarListArr = [
  {
    title: "Dashboard",
    path: "dashboard",
    icons: <SpaceDashboardRoundedIcon sx={{ fontSize: "20px" }} />,
  },
  {
    title: "Alerts",
    path: "alerts",
    icons: <ErrorIcon sx={{ fontSize: "20px" }} />,
  },
  {
    title: "GraphifyX",
    path: "graphifyx",
    icons: <DeveloperBoardIcon sx={{ fontSize: "20px" }} />,
  },
  {
    title: "Automation",
    path: "automation",
    icons: <AutoFixHighIcon sx={{ fontSize: "20px" }} />,
  },
  {
    title: "Workflow",
    path: "workflow",
    icons: <AccountTreeIcon sx={{ fontSize: "20px" }} />,
  },
  {
    title: "Agent Management",
    path: "agentManagement",
    icons: <DisplaySettingsIcon sx={{ fontSize: "20px" }} />,
  },
  {
    title: "Filter Management",
    path: "filterManagement",
    icons: <NotificationsActiveIcon sx={{ fontSize: "20px" }} />,
  },
  {
    title: "Reports",
    path: "reports",
    icons: <DescriptionIcon sx={{ fontSize: "20px" }} />,
  },
  {
    title: "Integration",
    path: "integration",
    icons: <MiscellaneousServicesIcon sx={{ fontSize: "20px" }} />,
  },
  {
    title: "Market Place",
    path: "marketPlace",
    icons: <LocalGroceryStoreIcon sx={{ fontSize: "20px" }} />,
  },
];

export const topTabBarArr = [
  {
    title: { name: "Dashboard", path: "dashboard" },
    tab: [
      { name: "Alarm", path: "" },
      { name: "CRM/ERP", path: "crmerp" },
      { name: "Custom", path: "custom" },
      /* { name: "Customer", path: "customer" }, */
      { name: "Monitor", path: "monitor" },
    ],
  },
  {
    title: { name: "GraphifyX", path: "graphifyx" },
    tab: [
      { name: "OS", path: "" },
      { name: "Network", path: "network" },
      { name: "Physical Server", path: "physicalServer" },
      { name: "Virtual Server", path: "virtualServer" },
      { name: "Storage", path: "storage" },
      { name: "Web", path: "web" },
      { name: "Application", path: "application" },
    ],
  },
  {
    title: { name: "Reports", path: "reports" },
    tab: [
      { name: "Alarm", path: "" },
      { name: "Automation", path: "automation" },
    ],
  },
  {
    title: { name: "Integration", path: "integration" },
    tab: [
      { name: "CRM", path: "" },
      { name: "Monitor", path: "monitor" },
      /* { name: "IoT", path: "ioT" } ,    */
      { name: "Ticket", path: "ticket" },
    ],
  },
  {
    title: { name: "Market Place", path: "marketPlace" },
    tab: [
      { name: "Hotel", path: "" },
      { name: "Insurance", path: "insurance" },
      { name: "Analysis", path: "analysis" },
      { name: "AI", path: "ai" },
      { name: "Mail", path: "mail" },
      { name: "SAP", path: "sap" },
    ],
  },
  /*   { name: "Integration", tab: ["CRM", "Ticket", "Monitor"] }, */
];
