import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Stack, Typography } from "@mui/material";
import DeleteModal from "components/modal/DeleteModal";
import { TableContiner } from "components/tableCom/components";
import CustomLoading from "components/loading/CustomLoading";
import { formatDateTime } from "utils/helpers/TimeFunc";
import { useTheme } from "layouts/theme/ThemeContext";
import { useTranslation } from "react-i18next";

interface IData {
  created: string;
  agent?: string;
  scenario?: number;
  user?: string;
  extra_parameters?: any[];
  job_response?: string;
  status?: number;
}

interface HeadCell {
  id: string;
  label: string;
  align: "right" | "left" | "center" | "inherit" | "justify" | undefined;
}

const headCells: readonly HeadCell[] = [
  {
    id: "status",
    label: "Status",
    align: "left",
  },
  {
    id: "agent",
    label: "Agent",
    align: "left",
  },
  {
    id: "job_response",
    label: "Response",
    align: "left",
  },
  {
    id: "created",
    label: "Created",
    align: "center",
  },

  {
    id: "action",
    label: "Actions",
    align: "center",
  },
];

const JobTable = ({ data, loading }: { data: IData[]; loading: boolean }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%", marginTop: "32px" }}>
      {loading ? (
        <CustomLoading />
      ) : (
        <TableContiner>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={`TableCell${headCell.id}`}
                      align={headCell.align}
                    >
                      <Typography variant="subtitle2">
                        {t(`automation.table${headCell.label}`)}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={`host-table-${index}`}
                        id={`host-table-checkbox-${index}`}
                        role="checkbox"
                        tabIndex={-1}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell align="center">
                          <Stack
                            sx={{
                              ...theme.typography.overline,
                              fontWeight: 700,
                              padding: "2px",
                              borderRadius: "10px",
                              width: "80px",
                              alignItems: "center",
                              fontSize: 9,
                              color: theme.palette.background.paper,
                              backgroundColor:
                                row.status === 0
                                  ? "#F6B60D"
                                  : row.scenario === 1
                                  ? "#89ADCF"
                                  : row.status === 2
                                  ? "#B80D0D"
                                  : "#B80D0D",
                            }}
                          >
                            {row.status === 0
                              ? "Pending"
                              : row.scenario === 1
                              ? "Success"
                              : row.status === 2
                              ? "Fail"
                              : "Fail"}
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography variant="body2">
                            {row.agent?.toLowerCase()}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography variant="body2">
                            {row.job_response?.toLowerCase()}
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          {formatDateTime(row.created)}
                        </TableCell>
                        <TableCell align="center">
                          <Stack
                            width={"100%"}
                            justifyContent={"center"}
                            direction={"row"}
                          >
                            <DeleteModal
                              text={t("automation.deleteJopDesc")}
                              onClick={() => {
                                /*  mainStore.deleteAgentManagement({
                                  id: row.alert_id,
                                }); */
                              }}
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {/*    <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </TableContiner>
      )}
    </Box>
  );
};
export default JobTable;
