export const listArr = [
  {
    id: "marketCard1",
    title: "title1",
    desc: "desc1",
    what: "what1",
    howDoesItDo: "howDoesItDo1",
    img: "/media/marketPlace/iskur.png",
    status: true,
  },
  {
    id: "marketCard2",
    title: "title2",
    desc: "desc2",
    what: "what2",
    howDoesItDo: "howDoesItDo2",
    img: "/media/marketPlace/calisanHaftalikMesai.png",
    status: false,
  },

  {
    id: "marketCard3",
    title: "title3",
    desc: "desc3",
    what: "what3",
    howDoesItDo: "howDoesItDo3",
    img: "/media/marketPlace/faturaKayit.png",
    status: false,
  },
  {
    id: "marketCard4",
    title: "title4",
    desc: "desc4",
    what: "what4",
    howDoesItDo: "howDoesItDo4",
    img: "/media/marketPlace/urunFiyat.png",
    status: true,
  },
  {
    id: "marketCard5",
    title: "title5",
    desc: "desc5",
    what: "what5",
    howDoesItDo: "howDoesItDo5",
    img: "/media/marketPlace/kasko.png",
    status: false,
  },
  {
    id: "marketCard6",
    title: "title6",
    desc: "desc6",
    what: "what6",
    howDoesItDo: "howDoesItDo6",
    img: "/media/marketPlace/sgkIsKazasi.png",
    status: false,
  },
  {
    id: "marketCard7",
    title: "title7",
    desc: "desc7",
    what: "what7",
    howDoesItDo: "howDoesItDo7",
    img: "/media/marketPlace/in.png",
    status: false,
  },
];

/* export const listArr = [
  {
    id: "marketCard1",
    title: "İŞKUR Aylık Çizelge Oluşturma Robotu",
    desc: "Bu robot , işkur sitesinde aylık çizelge oluşturmasını sağlar",
    what: "İŞKUR sitesinde belirlediğiniz firma üzerinden giriş yapıp , excelde yazmış olduğunuz çalışan sayısına göre verileri güncelleyip aylık olarak işgücü çizelgesi oluşturmasını sağlar.",
    howDoesItDo:
      "Robot , dosyalar sekmesinden yüklemiş olduğunuz exceli kontrol eder , eğer excel var ise bilgisayara indirir.",
    img: "/media/marketPlace/iskur.png",
    status: true,
  },
  {
    id: "marketCard2",
    title: "Çalışan haftalık mesai özet tablosu hazırlama ",
    desc: "Çalışanların haftalık mesai saatlerinin özet tablosunun hazırlanması",
    what: "PDF formatında hazır oluşmuş çalışanın haftalık çalışma saatlerini gösteren listeyi isim bazında excele aktaran, haftada istediğiniz en fazla çalışma saatini girerek (örneğin 45 Saat), girdiğiniz haftalık çalışma saatini aşan çalışanların haftalık çalışma saatini en fazla çalışma saatiyle sınırlandıran, en fazla haftalık çalışma saatinden az çalışanların haftalık saatini mevcut çalıştığı haftalık çalışma saatinde bırakan bir excel tablosu. Bu robot sayesinde çalışan başına haftalık mesai saatlerini listelemiş oluyorsunuz ve tabloyu excel formatında mail ile belirlediğiniz kişilere  gönderebiliyorsunuz. ",
    howDoesItDo:
      "PDF dosyasını bulur ve acar. Parametrelerden pdf formatındaki dosyanın ismini ’pdf_dosya_yolu’ nu girmeniz gerekiyor. <br/> PDF dosyasındaki başlıklar Sütun 1: İsim Soyad ve Sütun 2: çalışanın haftada çalıştığı saat. <br/>  Excel dosyası oluşturur ve PDF’in içindeki tabloyu Excel’e aktarır. <br/> Tablonun satır sayılarını sayar ve satır sayılarına göre çalışma döngüsü oluşturur. <br/>  Çalışan isminin karşısındaki haftalık çalışma saatini okur. Haftalık en fazla çalışma saatini, ’en_fazla_haftalik_calisma_saati’ parametresinden girmeniz gerekmektedir. Çalışanın haftalık çalışma saati en fazla haftalık çalışma saatinin üzerindeyse tablodaki çalışanın haftalık çalışma saatini en fazla haftalık çalışma saatiyle değiştirir. Çalışanın haftalık çalışma saati, en fazla haftalık çalışma saatiyse veya altındaysa, o zaman çalışanın tabloda yer alan haftalık çalışma saatini değiştirmez. <br/>  Yeni oluşmuş tabloda hiç bir çalışanın haftalık çalışma saati en fazla haftalık çalışma saatini geçmiyor olacak. Tablo yeni Excel dosya ismi altında saklanacak. <br/> Yeni excel dosyasını parametrelerden gireceğiniz alıcının e-postasına ek olarak gönderiyor.",
    img: "/media/marketPlace/calisanHaftalikMesai.png",
    status: false,
  },

  {
    id: "marketCard3",
    title: "Invoice Processing",
    desc: "Create GIB E-Fatura invoices on your ERP system!",
    what: "GIB üzerinden gelen faturaların Satın Alma Sipariş (SAS) numarası  kontrollerini ve ERP giriş işlemlerini otomatik olarak yapmanızı sağlayan bir robottur. <br/> SAS numarası üzerinden fatura kontrol giriş ve irsaliye oluşturma işlemlerini yapar.",
    howDoesItDo: "",
    img: "/media/marketPlace/faturaKayit.png",
    status: false,
  },
  {
    id: "marketCard4",
    title: "Ürün Fiyat Karşılaştırma ",
    desc: "Marketlerden fiyatları sorgular, kendi fiyatlarınızla karşılaştırabilmenizi sağlar.",
    what: "Ürün Fiyat Karşılaştırma robotu rekabet avantajı sağlamak amacıyla tasarlandı. <br/> Rakip marketlerdeki belirlenen veya tüm ürünlerin, ürün ismi ve fiyat bilgilerini bir excele aktararak kendi ürünlerinizin fiyatlarıyla karşılaştırmasını sağlar. Sanal marketlerde, belirttiğiniz kategoride ve isimdeki ürünlerin fiyatlarını bulur, excele aktarır, ve size e-posta ile gönderir.",
    howDoesItDo:
      "Robot ürün listenizi kullanarak belirlediğiniz ürünlerin fiyatlarını diğer marketlerin web sitelerinde araştırır. <br/> Öncelikle belirlediğiniz market web sitesine giriş yapılır. Karşılaştırılma yapılmasını istenen ürün kategorileri; marka adı ve ürün adı önceden hazırlanmış Excel dosyasından alınarak ilgili market web sitesinde aranır. Kategoriler ile ayrıştırılarak tüm ürünlerin adı ve fiyatları excele aktarılır.Karşılaştırma sonucu bilgilendirme e-postası ile ilgili kişiye veya kişilere iletilir.",
    img: "/media/marketPlace/urunFiyat.png",
    status: true,
  },
  {
    id: "marketCard5",
    title: "Kasko İçin Teklif Oluşturma",
    desc: "Sigorta şirketlerinin web siteleri üzerinden kasko teklifi alır. ",
    what: "Sigorta şirketlerinin web sitesine giriş yaparak teklif istenecek araç bilgilerini excel, api üzerinden otomatik alarak doldurur ve kasko fiyat teklifi alarak size iletir. ",
    howDoesItDo: "",
    img: "/media/marketPlace/kasko.png",
    status: false,
  },
  {
    id: "marketCard6",
    title: "SGK İş Kazası Bildirim Robotu ",
    desc: "SGK portalı üzerinden iş kazası bildirimi yapar.",
    what: "SGK İş Kazası Bildirim işlemlerini otomatik olarak yapmanızı sağlayan bir robottur. <br/> Belirtilen personel listesi için SGK web sitesinden iş kazası bildirimi işlemini yapar ve size raporlar.",
    howDoesItDo: "",
    img: "/media/marketPlace/sgkIsKazasi.png",
    status: false,
  },
  {
    id: "marketCard7",
    title: "AI - Linkedin İçerik Robotu",
    desc: "İstenilen konuya ilişkin bir blog yazısı ve içeriğe uygun görsel hazırlar ve LinkedIn'de taslak olarak kayıt eder.",
    what: "LinkedIn için taslak içerik hazırlayan bir robot, kullanıcıların profesyonel ağlarını genişletmek, markalarını tanıtmak ve iş fırsatlarına erişmek için etkileyici içerik oluşturmalarına yardımcı olan bir otomatik sistemdir. Bu robot, kullanıcının sektör, beceri veya hedef kitle gibi belirli bilgilerini temel alarak, dikkat çekici başlıklar, makul metinler ve ilgili görsellerle zenginleştirilmiş içerikler üretebilir. <br/> LinkedIn'deki profesyonel profilinizi güçlendirmek için, bu robotla birlikte oluşturulan içerikler, bağlantılarınızla etkileşim kurmanızı ve profesyonel itibarınızı artırmanızı sağlar. Ayrıca, iş dünyasındaki son gelişmeleri paylaşmak, sektörünüzle ilgili bilgileri yaymak veya kendi uzmanlığınızı sergilemek için özelleştirilmiş içerikler üretebilirsiniz.",
    howDoesItDo:
      "<ul><li>Bir kasa oluşturulur ve kasa içerisinde Linkedin kullanıcı bilgileri girilir</li> <li>Yazılacak içerik konusu robota girilir</li><li>   İçeriğe uygun imaj için İngilizce bir tanım girilir</li><li>    Girilen bilgilere uygun bir İçerik ve İmaj oluşturulur.</li> <li>  Linkedin sayfası açılarak bir taslak olan içerik kaydedili</li></ul>",
    img: "/media/marketPlace/in.png",
    status: false,
  },
];
 */
export const listArrEn = [
  {
    id: "marketCard1",
    title: "İŞKUR Monthly Schedule Creation Robot",
    desc: "This robot enables the creation of a monthly schedule on the İŞKUR website.",
    what: "It allows you to log in through the İŞKUR website using the specified company, update the data based on the number of employees you have written in Excel, and create a monthly workforce schedule.",
    howDoesItDo:
      "<ul><li>The robot checks the uploaded Excel file from the Files tab; if an Excel file is present, it downloads it to the computer.</li><li>It opens the Excel file, retrieves and updates the information required on the İŞKUR website.</li><li>After the company process is completed; columns B and onwards will perform filling operations based on corresponding values in future pages.</li></ul>",
    img: "/media/marketPlace/iskur.png",
    status: true,
  },
  {
    id: "marketCard2",
    title: "Preparing a Weekly Overtime Summary Table for Employees",
    desc: "Preparation of a Summary Table for Employees' Weekly Working Hours",
    what: "Development of a PDF-formatted ready-made list displaying the employee's weekly working hours, which can be transferred to Excel on a name basis. The robot allows you to input the maximum weekly working hours you desire (for example, 45 hours) and limits the weekly working hours of employees exceeding the entered value to the specified maximum. It also maintains the weekly hours for employees working less than the maximum weekly working hours at their current weekly working hours. With this robot, you can list the weekly overtime hours per employee and send the table in Excel format via email to designated individuals.",
    howDoesItDo: "",
    img: "/media/marketPlace/calisanHaftalikMesai.png",
    status: false,
  },
  {
    id: "marketCard3",
    title: "Invoice Processing",
    desc: "Create GIB E-Fatura invoices on your ERP system!",
    what: "A robot that automates the Purchase Order (SAS) number checks of invoices received via GIB and performs ERP entry processes. <br/> It conducts invoice verification and generates delivery notes based on the SAS number.",
    howDoesItDo: "",
    img: "/media/marketPlace/faturaKayit.png",
    status: false,
  },
  {
    id: "marketCard4",
    title: "Product Price Comparison",
    desc: "Track your competitors' online prices and popularity!",
    what: "The Product Price Comparison robot is designed to provide a competitive advantage. It compares the prices of your products with those in rival markets by transferring the specified or all products' name and price information into an Excel sheet. It locates and retrieves the prices of products in the specified category and name on virtual markets, transfers them to an Excel sheet, and sends them to you via email.",
    howDoesItDo:
      "<ul> <li>Utilizing your robot product list, the prices of the selected products are researched on other retailers' websites. </li><li>Firstly, access is made to the chosen market's website. </li><li>The product categories for comparison are extracted from a pre-prepared Excel file containing brand names and product names, and they are searched on the relevant market website.</li><li>After categorization, the names and prices of all products are transferred to Excel. The comparison results are then communicated to the concerned individual(s) through an informative email.</li></ul>",

    img: "/media/marketPlace/urunFiyat.png",
    status: true,
  },
  {
    id: "marketCard5",
    title: "Traffic Insurance Policy ",
    desc: "Get a quote for a traffic insurance policy!",
    what: "Enables the automatic generation of comprehensive insurance quotes by logging into the websites of insurance companies with the details of the requested vehicle.<br/> Logs into insurance company websites, automatically retrieves and populates vehicle information from Excel or via API, obtains a comprehensive insurance quote, and forwards it to you.",
    howDoesItDo: "",
    img: "/media/marketPlace/kasko.png",
    status: false,
  },
  {
    id: "marketCard6",
    title: "SGK Occupational Accident Notification",
    desc: "Report occupational accidents to SGK automatically! ",
    what: "It is a robot that allows you to execute SGK Occupational Accident Notification transactions automatically. <br/> It makes the work accident notification process for the specified work accident report.",
    howDoesItDo:
      "<ul><li>Logs into https://uyg.sgk.gov.tr/IsvBildirimFormu/welcome.do with username, workplace password and security code. </li> <li>Click on Occupational Accident Notification Entry.</li> <li>The following parameters are filled with the information from the Occupational Accident Report; <br/> <ul><li>Country / City / Town</li> <li>Accident date / time </li> <li>Accident address</li> <li>The event that caused the accident</li>  <li>The sub event that caused the  accident</li> <li>Device / Tool / Vehicle that caused the accident</li> <li>Vehicle / equipment subgroup that caused the accident</li>  <li> The place / department where the Occupational Accident took place </li>  <li> Place / department subgroup where the Occupational Accident occurred </li>  <li> How accident occurred </li>  <li> Number of people injured </li>  <li>What did the insured person do after the accident? </li> <li>The place where the accident took place </li> <li>Incapacity for work as a result of accident </li> <li>Condition of the workplace after the accident </li>  </ul> </li></ul>",
    img: "/media/marketPlace/sgkIsKazasi.png",
    status: false,
  },
  {
    id: "marketCard7",
    title: "AI - LinkedIn Content Robot",
    desc: "Prepares a blog post on the requested topic, creates relevant content, and saves it as a draft on LinkedIn with appropriate visuals.",
    what: "A robot that prepares draft content for LinkedIn is an automated system designed to assist users in expanding their professional networks, promoting their brands, and accessing job opportunities by creating impressive content. Based on specific information such as industry, skills, or target audience, this robot can generate content enriched with attention-grabbing headlines, reasonable texts, and relevant visuals.<br/>To strengthen your professional profile on LinkedIn, the content created with this robot enables you to interact with your connections and enhance your professional reputation. Additionally, you can produce customized content to share recent developments in the business world, disseminate information related to your industry, or showcase your expertise.",
    howDoesItDo:
      "<ul><li>A vault is created, and LinkedIn user information is entered into the vault.</li> <li>The topic of the content to be written is input into the robot.</li> <li>An English description is provided for an image suitable for the content.</li> <li>Content and an image appropriate to the entered information are generated.</li> <li>A LinkedIn page is opened, and a draft of the content is saved.</li></ul>",
    img: "/media/marketPlace/in.png",
    status: false,
  },
];
