import React from "react";
import { Stack, Typography } from "@mui/material";
import { useTheme } from "layouts/theme/ThemeContext";
import AgentForm from "./AgentForm";
import { IAgentDataProps } from "../type/Type";
import { useTranslation } from "react-i18next";

const AgentManagementView = ({ data }: { data: IAgentDataProps[] }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  return (
    <Stack>
      <Stack
        width={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        direction={"row"}
      >
        <Stack>
          <Typography fontWeight={700} variant="body1">
            {t("agentManagement.automationAgent")}
          </Typography>
          <Typography
            sx={{ color: theme.palette.secondary.main }}
            fontWeight={700}
            variant="caption"
          >
            {t("general.total")} {data.length} {t("agentManagement.agent")}
          </Typography>
        </Stack>
        <AgentForm />
      </Stack>
    </Stack>
  );
};

export default AgentManagementView;
