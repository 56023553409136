import React, { ReactNode } from "react";
import { styled, Paper, Stack } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  /*   height: "400px",
  maxHeight: "400px", */
  width: "100%",
  borderRadius: "16px",
  color: theme.palette.text.secondary,
}));

const ChartWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Stack width={"100%"}>
      <Item sx={{ backgroundColor: "transparent" }}>
        <Stack
          sx={{
            /*        height: "340px",
            maxHeight: "340px", */
            padding: "10px",
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {children}
        </Stack>
      </Item>
    </Stack>
  );
};

export default ChartWrapper;
