import { useTheme } from "layouts/theme/ThemeContext";
import React from "react";
import ReactApexChart from "react-apexcharts";
import "./tooltipCss.scss";

const AreaChartsStacked = ({
  title,
  categories,
  series,
}: {
  title: string;
  categories: string[];
  series: any;
}) => {
  const { theme } = useTheme();

  return (
    <div style={{ width: "100%" }}>
      <ReactApexChart
        width={"100%"}
        maxWidth={"1400px"}
        height={400}
        options={{
          chart: {
            stacked: true,
            type: "area",
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [3, 3, 3, 3],
            curve: "smooth",
          },
          title: {
            text: title,
            align: "left",
            style: {
              color: theme.palette.primary.main,
            },
          },
          legend: {
            labels: {
              colors: theme.palette.primary.main,
            },
            tooltipHoverFormatter: function (
              val: string,
              opts: {
                w: {
                  globals: { series: { [x: string]: { [x: string]: string } } };
                };
                seriesIndex: string | number;
                dataPointIndex: string | number;
              }
            ) {
              return (
                val +
                " - " +
                opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                ""
              );
            },
          },

          markers: {
            size: 0,
            /* colors: theme.palette.primary.main, */
            hover: {
              sizeOffset: 6,
            },
          },
          xaxis: {
            categories: categories,
            labels: {
              style: {
                colors: theme.palette.primary.main,
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: theme.palette.primary.main,
              },
            },
          },
          tooltip: {
            theme: theme.palette.primary.dark,
            cssClass: "tooltipCss",
            y: [
              {
                title: {
                  formatter: function (val: any) {
                    return val;
                  },
                },
              },
            ],
          },

          grid: {
            show: false,
            borderColor: "#f1f1f1",
          },
          colors: ["#B80D0D", "#E65D4E", "#F78F4A", "#F7CD3B"], // Replace these colors with your desired colors
        }}
        series={series}
        type="area"
      />
    </div>
  );
};

export default AreaChartsStacked;
