import React, { useEffect, useState } from "react";
import AceEditor from "react-ace";
import { MenuItem, Stack, TextField, Typography, styled } from "@mui/material";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/theme-solarized_light";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/ext-language_tools";
import { INodeParameters } from "page/workflow/types/nodeTypes";

const ReactAgeCom = ({
  mode,
  parameter,
  onInputChange,
}: {
  mode: string;
  parameter: INodeParameters;
  onInputChange: (parameterName: string, value: any) => void;
}) => {
  const [value, setValue] = useState<string>("");
  useEffect(() => {
    if (parameter.parameter_value) {
      setValue(parameter.parameter_value);
    }
  }, []);

  const TextFieldTitle = styled(Typography)(({ theme }) => ({
    ...theme.typography.caption,
    fontWeight: 700,
    color: theme.palette.secondary.main,
  }));
  return (
    <Stack key={`strInput_${parameter.parameter_name}`} gap={"6px"}>
      <TextFieldTitle>{parameter.parameter_name}:</TextFieldTitle>
      <AceEditor
        mode={mode}
        theme={"solarized_light"}
        height="260px"
        width="400px"
        onChange={(event) => {
          setValue(event);
          onInputChange(parameter.parameter_name, event);
        }}
        /* onBlur={() => {
          setTimeout(() => {
            onInputChange(parameter.parameter_name, value);
          }, 90);
        }} */
        value={value}
        name="UNIQUE_ID_OF_DIV"
        editorProps={{ $blockScrolling: true }}
      />
    </Stack>
  );
};

export default ReactAgeCom;
