import React, { useEffect, useState } from "react";
import { Divider, Stack, Typography, alpha, styled } from "@mui/material";
import DashedLineChart from "components/apexcharts/DashedLineChart";
import CircularProgressWithLabel from "components/chartTypes/CircularProgressWithLabel";
import { useTheme } from "layouts/theme/ThemeContext";
import { useGetEventsTitles } from "page/dashboard/hooks/useResults";
import { baseUrl } from "utils/helpers/BaseUrl";
import { useTranslation } from "react-i18next";

const Container = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "48px",
  width: "100%",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

interface ITypeData {
  CRITICAL: number;
  MAJOR: number;
  MINOR: number;
  WARNING: number;
  date: string;
}

interface IData {
  date: {
    name: string;
    data: string[];
  };
  cusSeries: {
    name: string;
    data: number[];
  }[];
}
interface IDataAll {
  date: string[];
  data: { name: string; data: number[] }[];
}

const emptyData = {
  date: [],
  data: [],
};
const SeverityLineChart = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [data, setData] = useState<IDataAll>(emptyData);
  const url = `${baseUrl}/alert/analytics/grouped_by_values/?day=20&key=severity`;
  const arr = useGetEventsTitles({ key: "severityDash", url: url }).data;

  useEffect(() => {
    if (arr) {
      setData(arr);
    }
  }, [arr]);

  const sumOfAllNumbers = data.data.reduce((acc, item) => {
    const itemSum = item.data.reduce((itemAcc, value) => itemAcc + value, 0);
    return acc + itemSum;
  }, 0);

  return (
    <Container>
      <Stack
        sx={{
          flex: 4,
        }}
      >
        <DashedLineChart
          categories={data.date}
          series={data.data}
          title={t("dashboard.severitychartDesc")}
        />
      </Stack>

      <Stack
        sx={{
          flex: 2,
          backgroundColor: `${alpha(theme.palette.background.default, 0.4)}`,
          width: "100%",
          borderRadius: "16px",
          padding: "32px",
          boxSizing: "border-box",
          gap: "16px",
          justifyContent: "space-between",
          height: { xs: "100%", md: "420px" },
        }}
      >
        <Stack>
          <Typography variant="subtitle2">{t("dashboard.severity")}</Typography>
          <Typography variant="caption">
            {t("dashboard.severityDesc")}
          </Typography>
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "16px",
            height: "100%",
          }}
        >
          {data.data.map((item) => {
            return (
              <Stack width={"110px"} key={`${item.name}`} gap={"12px"}>
                <Stack>
                  <Typography
                    sx={{
                      color: theme.palette.secondary.main,
                    }}
                    variant="caption"
                  >
                    {item.name}
                  </Typography>
                  <Divider orientation="horizontal" flexItem />
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={"16px"}
                    marginTop={"8px"}
                  >
                    <CircularProgressWithLabel
                      value={
                        (item.data.reduce((acc, current) => acc + current, 0) *
                          100) /
                        sumOfAllNumbers
                      }
                      color={theme.palette.error.main}
                    />
                    <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
                      <Typography fontSize={"18px"} variant="subtitle2">
                        {item.data.reduce((acc, current) => acc + current, 0)}
                      </Typography>
                      <Typography variant="caption">
                        {t("dashboard.alert")}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Container>
  );
};

export default SeverityLineChart;
