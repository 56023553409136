import { Stack, Typography } from "@mui/material";
import { toImageUrl } from "utils/helpers/AssetHelpers";

const EmptyDataView = ({ text }: { text?: string }) => {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",

        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={toImageUrl("/media/general/emptyData.png")}
        alt="emptyData"
        style={{ width: "40%", height: "auto" }}
      />
      {text && <Typography variant="h3">{text}</Typography>}
    </Stack>
  );
};

export default EmptyDataView;
