import React from "react";
import { Stack, Typography, InputBase, styled, Checkbox } from "@mui/material";
import { IAlertsModel } from "../type/type";
import { useTranslation } from "react-i18next";

const Container = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "12px",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const SearchContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  maxWidth: "450px",
  padding: "0px 4px 0px 16px",
  borderRadius: "32px",
  border: `1px solid ${theme.palette.primary.main}`,

  [theme.breakpoints.down("sm")]: {},
}));

const TitleContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const AlertTitle = ({
  onFunc,
  alerts,
  statusClose,
  setStatusClose,
  searchValue,
  setSearch,
}: {
  onFunc: () => void;
  alerts: IAlertsModel[];
  searchValue: string;
  statusClose: boolean;
  setStatusClose: React.Dispatch<React.SetStateAction<boolean>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <TitleContainer>
        <Typography variant="subtitle2"> {t("alert.allAlerts")} </Typography>
        <Typography variant="caption">
          {" "}
          {t("alert.total")} {alerts.length}{" "}
        </Typography>
      </TitleContainer>
      <Stack
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            size="small"
            color="success"
            checked={statusClose}
            onChange={(event) => {
              onFunc();
              setStatusClose(event.target.checked);
            }}
          />
          <Typography width={"160px"} variant="body2">
            {t("alert.showCloseAlerts")}
          </Typography>
        </Stack>
        <SearchContainer>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={t("alert.searchAlerts")}
            value={searchValue}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            inputProps={{ "aria-label": t("alert.searchAlerts") }}
          />
        </SearchContainer>
      </Stack>
    </Container>
  );
};

export default AlertTitle;
