import React from "react";
import IntegrationList from "./components/IntegrationList";
import IntegrationTitle from "../IntegrationTitle";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const IntegrationCrm = () => {
  const { t } = useTranslation();
  return (
    <Stack gap={"24px"}>
      <IntegrationTitle title={t("integration.integrationCRM")} />
      <IntegrationList />
    </Stack>
  );
};

export default IntegrationCrm;
