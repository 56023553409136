import React, { useState } from "react";
import { Size, useWindowSize } from "utils/hooks/use_window_size";
import {
  Box,
  IconButton,
  InputBase,
  Stack,
  Typography,
  styled,
  alpha,
} from "@mui/material";
import ItemAccordion from "./ItemAccordion";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { IFlowArr } from "page/workflow/types/nodeTypes";
import FilterItemAccordion from "./ItemAccordionFiltered";
import { useGetData } from "query/GetQuery";
import { useTranslation } from "react-i18next";

const TopContent = styled(Box)<{ windowsize: Size }>(
  ({ theme, windowsize }) => ({
    width: "280px",
    boxShadow: `${alpha(theme.palette.primary.dark, 0.4)} 0px 2px 8px 0px`,
    boxSizing: "border-box",
    padding: "8px",
    borderRadius: "16px",
    height: `calc(${windowsize?.height}px - 200px)`,
  })
);
const SidebarContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  /*     border: `1px solid ${theme.palette.primary.main} `, */
  boxSizing: "border-box",
  padding: "16px",
  gap: "8px",
  borderRadius: "16px",
  height: "100%",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: 6,
  },

  "&::-webkit-scrollbar-thumb:vertical": {
    borderRadius: 4,
    background: theme.palette.primary.main,
  },

  "&::-webkit-scrollbar-track:vertical": {
    borderRadius: 4,
    background: "transparent",
  },
}));
const SearchContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  maxWidth: "450px",
  padding: "0px 4px 0px 16px",
  borderRadius: "32px",
  border: `1px solid ${theme.palette.primary.main}`,
  [theme.breakpoints.down("sm")]: {},
}));

function Sidebar() {
  const { t } = useTranslation();
  const windowsize: Size = useWindowSize();
  const [searchValue, setSearch] = useState<string>("");
  const [filteredData, setFilteredData] = useState<IFlowArr[]>([]);
  const { data } = useGetData<IFlowArr>({
    path: `/component_category/`,
    key: `getSidebarData`,
  });

  const handleFilterChange = () => {
    if (data) {
      const filterData = data.filter(
        (item) => item.category_name !== "Built-In"
      );
      // Filtreleme işlemini burada gerçekleştiriyoruz.
      const filteredItems = filterData.filter((item) =>
        item.components.some((component) =>
          component.data?.display_name
            ?.toLowerCase()
            .includes(searchValue.toLowerCase())
        )
      );
      setFilteredData(filteredItems);
    }
  };

  return (
    <TopContent windowsize={windowsize}>
      <SidebarContent>
        <Typography fontSize={"11px"} variant="caption" align="center">
          {t("workflow.rightDesc")}
        </Typography>
        <SearchContainer>
          <Stack width={"100%"} direction={"row"}>
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: "12px" }}
              placeholder="search"
              value={searchValue}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              inputProps={{ "aria-label": "search alarm" }}
            />
            {searchValue !== "" && (
              <IconButton
                onClick={() => {
                  setFilteredData([]);
                  setSearch("");
                }}
              >
                <CloseIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            )}
          </Stack>
          <IconButton
            onClick={() => {
              handleFilterChange();
            }}
            sx={{ p: "10px" }}
            aria-label="search"
          >
            <SearchIcon sx={{ fontSize: "16px" }} />
          </IconButton>
        </SearchContainer>
        {filteredData.length === 0
          ? data &&
            data
              .filter((item) => item.category_name !== "Built-In")
              .map((item, index) => {
                return (
                  <Stack key={`nodesAll${index}`}>
                    <ItemAccordion sidebarData={item} index={index} />
                  </Stack>
                );
              })
          : filteredData
              .filter((item) => item.category_name !== "Built-In")
              .map((item, index) => {
                return (
                  <Stack key={`nodesAll${index}`}>
                    <FilterItemAccordion sidebarData={item} index={index} />
                  </Stack>
                );
              })}
      </SidebarContent>
    </TopContent>
  );
}
export default Sidebar;
