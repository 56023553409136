import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "layouts/theme/ThemeContext";
import { MenuItem, Stack, Typography } from "@mui/material";
import HeadersMenu from "components/menu/HeaderMenu";
import PublicIcon from "@mui/icons-material/Public";
import "./config";

function LanguageSwitcher() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { i18n } = useTranslation("language");
  const [selectLang, setSelect] = useState<string>("tr");

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    lang && setSelect(lang);
    lang && i18n.changeLanguage(lang);
  }, []);

  const arrLang = [
    { name: "türkçe", label: "tr" },
    { name: "english ", label: "en" },
  ];

  return (
    <div>
      <HeadersMenu
        tooltipName={t("header.language")}
        buttonChild={
          <Stack alignItems={"center"} direction={"row"} gap={"4px"}>
            <PublicIcon sx={{ fontSize: "16px" }} />
            <Typography variant="subtitle2">{selectLang}</Typography>
          </Stack>
        }
        menuChild={
          <Stack
            sx={{
              width: "48px",
              padding: "4px",
            }}
          >
            {arrLang.map((lang) => {
              return (
                <MenuItem
                  disableRipple
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                  key={lang.name}
                  onClick={() => {
                    localStorage.setItem("lang", lang.label);
                    i18n.changeLanguage(lang.label);
                    setSelect(lang.label);
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: selectLang === lang.label ? 700 : 400,
                      color:
                        selectLang === lang.label
                          ? theme.palette.error.main
                          : "",
                      "&:hover": {
                        color: theme.palette.error.dark,
                      },
                    }}
                    variant="body2"
                  >
                    {lang.label}
                  </Typography>
                </MenuItem>
              );
            })}
          </Stack>
        }
      />
    </div>
  );
}
export default LanguageSwitcher;
