import React, { useEffect, useState } from "react";
import {
  Checkbox,
  MenuItem,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { RowStack } from "components/stack/RowStack";
import { IInfoAlarmTypes } from "page/filterManagement/types/Type";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { StyledTextField } from "components/textField/CustomTextField";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { IAgentDataProps } from "page/agentManagement/type/Type";
import { fetchGet } from "apis/Get";
import { IScenarioList } from "page/workflow/types/types";
import { useTranslation } from "react-i18next";

export const CheckboxContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "4px",
}));

export const TextFieldContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  gap: "4px",
  width: "100%",
  height: "120px",
}));

const ValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Required field"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Geçerli bir telefon numarası girin")
    .required("Telefon numarası zorunludur"),
});

interface IForm {
  email: string;
  phone: string;
}

const InfoValues = ({
  setInfoArr,

  infoValueArr,
}: {
  infoValueArr?: IInfoAlarmTypes;

  setInfoArr: React.Dispatch<React.SetStateAction<IInfoAlarmTypes>>;
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isPhone, setPhone] = useState<boolean>(false);
  const [isMail, setMail] = useState<boolean>(false);
  const [isAction, setAction] = useState<boolean>(false);
  const [actionValue, setActionValue] = useState<string>(`0`);
  const [agentValue, setAgentValue] = useState<string>(`0`);

  const agentManagemenData = useQuery({
    queryKey: ["agentManagemenData"],
    queryFn: async () => await fetchGet(`/agent/`),
    select: (data: IAgentDataProps[]) => data,
  });
  const scenarioList = useQuery({
    queryKey: ["scenarioList"],
    queryFn: async () => await fetchGet(`/scenario/`),
    select: (data: IScenarioList[]) => data,
  });

  useEffect(() => {
    if (infoValueArr) {
      setPhone(infoValueArr.phone !== "" ? true : false);
      setMail(infoValueArr.mail !== "" ? true : false);
      setAction(infoValueArr.action !== "0" ? true : false);
      setActionValue(infoValueArr.action);
      setAgentValue(infoValueArr.agent);
    }
  }, []);

  return (
    <Stack>
      <Formik
        initialValues={{
          email:
            infoValueArr && infoValueArr.mail !== "" ? infoValueArr.mail : "",
          phone:
            infoValueArr && infoValueArr.phone !== "" ? infoValueArr.phone : "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values: IForm, actions) => {}}
      >
        {(props: FormikProps<IForm>) => {
          const {
            values,
            touched,
            errors,
            dirty,
            handleBlur,
            handleChange,
            handleSubmit,
            setSubmitting,
          } = props;
          return (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
                setSubmitting(false);
              }}
            >
              <Stack marginTop={"16px"}>
                <RowStack>
                  <CheckboxContainer>
                    <Typography>{t("filterManagement.phone")}</Typography>
                    <Checkbox
                      size="small"
                      checked={isPhone}
                      onChange={(event) => {
                        setPhone(event.target.checked);
                      }}
                    />
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <Typography>{t("filterManagement.mail")}</Typography>
                    <Checkbox
                      size="small"
                      checked={isMail}
                      onChange={(event) => {
                        setMail(event.target.checked);
                      }}
                    />
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <Typography>{t("filterManagement.action")}</Typography>
                    <Checkbox
                      size="small"
                      checked={isAction}
                      onChange={(e) => {
                        queryClient.invalidateQueries({
                          queryKey: ["filterManagementData"],
                        });
                        setAction(e.target.checked);
                      }}
                    />
                  </CheckboxContainer>
                </RowStack>
                <RowStack gap={"8px"}>
                  {isPhone && (
                    <TextFieldContainer>
                      <Typography variant="subtitle2">
                        {t("filterManagement.phone")}{" "}
                      </Typography>
                      <StyledTextField
                        id="infoValues_isPhone"
                        name="phone"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={values.phone}
                        helperText={
                          errors.phone && touched.phone ? errors.phone : " "
                        }
                        error={errors.phone && touched.phone ? true : false}
                        onChange={(e) => {
                          setInfoArr({
                            mail: values.email,
                            phone: e.target.value,
                            action: actionValue,
                            agent: agentValue,
                          });
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                    </TextFieldContainer>
                  )}
                  {isMail && (
                    <TextFieldContainer>
                      <Typography variant="subtitle2">
                        {t("filterManagement.mail")}
                      </Typography>
                      <StyledTextField
                        id="mail"
                        name="email"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={values.email}
                        helperText={
                          errors.email && touched.email ? errors.email : " "
                        }
                        error={errors.email && touched.email ? true : false}
                        onChange={(e) => {
                          setInfoArr({
                            mail: e.target.value,
                            phone: values.phone,
                            action: actionValue,
                            agent: agentValue,
                          });
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                    </TextFieldContainer>
                  )}
                  {isAction && (
                    <TextFieldContainer gap={"4px"} width={"100%"}>
                      <Typography variant="subtitle2">
                        {t("filterManagement.action")}-{" "}
                        {t("filterManagement.agent")}
                      </Typography>
                      <TextField
                        id="isAction_textField"
                        placeholder="Select"
                        select
                        variant="outlined"
                        size="small"
                        value={actionValue}
                        onChange={(e) => {
                          setActionValue(`${e.target.value}`);
                          setInfoArr({
                            mail: values.email,
                            phone: values.phone,
                            action: `${e.target.value}`,
                            agent: agentValue,
                          });
                        }}
                      >
                        <MenuItem value={`0`}>
                          <Typography variant="caption">
                            {t("filterManagement.selectAction")}
                          </Typography>
                        </MenuItem>
                        {scenarioList.data &&
                          scenarioList.data.map((option, i) => (
                            <MenuItem
                              key={`operatorData${i}`}
                              value={option.screnario_id}
                            >
                              {option.screnario_name.toLowerCase()}
                            </MenuItem>
                          ))}
                      </TextField>
                      <TextField
                        id="isAction_textField"
                        placeholder="Select"
                        select
                        variant="outlined"
                        size="small"
                        value={agentValue}
                        onChange={(e) => {
                          setAgentValue(`${e.target.value}`);
                          setInfoArr({
                            mail: values.email,
                            phone: values.phone,
                            action: actionValue,
                            agent: `${e.target.value}`,
                          });
                        }}
                      >
                        <MenuItem value={`0`}>
                          <Typography variant="caption">
                            {t("filterManagement.selectAgent")}
                          </Typography>
                        </MenuItem>
                        {agentManagemenData.data &&
                          agentManagemenData.data.map((option, i) => (
                            <MenuItem
                              key={`operatorData${i}`}
                              value={`${option.agent_id}`}
                            >
                              {option.name.toLowerCase()}
                            </MenuItem>
                          ))}
                      </TextField>
                    </TextFieldContainer>
                  )}
                </RowStack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};

export default InfoValues;
