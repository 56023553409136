import React from "react";

import TopRow from "./components/TopRow";
import FilterTable from "./components/FilterTable";
import { useQuery } from "@tanstack/react-query";
import { IFilterList } from "./types/Type";
import { fetchGet } from "apis/Get";
import PageContainer from "page/general/PageContainer";

const FilterManagement = () => {
  const filterData = useQuery({
    queryKey: ["filterManagementData"],
    queryFn: async () => await fetchGet(`/filter/`),
    select: (data: IFilterList[]) => data,
  });

  return (
    <PageContainer>
      {filterData.data && <TopRow data={filterData.data} />}
      {filterData.data && (
        <FilterTable data={filterData.data} loading={false} />
      )}
    </PageContainer>
  );
};

export default FilterManagement;
