import { MenuItem, Stack, TextField, Typography, alpha } from "@mui/material";
import React, { useState } from "react";
import { AddSmallButton, FormContainer } from "../Components";
import { IOperator, IValueTypes } from "page/filterManagement/types/Type";
import AddIcon from "@mui/icons-material/Add";
import { RowStack } from "components/stack/RowStack";
import DeleteModal from "components/modal/DeleteModal";
import { Notify } from "components/notify/notify";
import { useTheme } from "layouts/theme/ThemeContext";
import { useQuery } from "@tanstack/react-query";
import { fetchGet } from "apis/Get";
import { useTranslation } from "react-i18next";

const FilterValues = ({
  filterValueArr,
  setValueArr,
}: {
  filterValueArr: IValueTypes[];
  setValueArr: React.Dispatch<React.SetStateAction<IValueTypes[]>>;
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const operatorData = useQuery({
    queryKey: ["operatorData"],
    queryFn: async () => await fetchGet(`/filter/operators/`),
    select: (data: IOperator[]) => data,
  });
  const fieldsData = useQuery({
    queryKey: ["fieldsData"],
    queryFn: async () => await fetchGet(`/filter/fields/`),
    select: (data: string[]) => data,
  });
  const [fieldValue, setFieldValue] = useState<string>("");
  const [operatorValue, setOperator] = useState<string>(
    `${operatorData.data && operatorData.data[0].value}`
  );
  const [fieldName, setField] = useState<string>(
    fieldsData.data ? fieldsData.data[0] : ""
  );

  const handleAddFilter = () => {
    setValueArr((prev: any) => [
      ...prev,
      {
        field_name: fieldName,
        operator: operatorValue,
        field_value: fieldValue,
      },
    ]);
    setFieldValue("");
  };

  return (
    <Stack gap={"16px"}>
      {" "}
      <FormContainer>
        <Stack gap={"4px"} width={"100%"}>
          <Typography variant="subtitle2">
            {" "}
            {t("filterManagement.field")}
          </Typography>
          <TextField
            id="filterType_textField"
            placeholder="Select"
            select
            fullWidth
            variant="outlined"
            size="small"
            value={fieldName}
            onChange={(event) => {
              setField(event.target.value);
            }}
          >
            {fieldsData.data &&
              fieldsData.data.map((option, i) => (
                <MenuItem key={`FilterValues${i}`} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
        </Stack>
        <Stack gap={"4px"} width={"100%"}>
          <Typography variant="subtitle2">
            {t("filterManagement.operator")}
          </Typography>
          <TextField
            id="operator_textField"
            placeholder="Select"
            select
            variant="outlined"
            size="small"
            value={operatorValue}
            onChange={(event) => {
              setOperator(event.target.value);
            }}
          >
            {operatorData.data &&
              operatorData.data.map((option, i) => (
                <MenuItem key={`operatorData${i}`} value={option.value}>
                  {option.label} ({option.value})
                </MenuItem>
              ))}
          </TextField>
        </Stack>
        <Stack gap={"4px"} width={"100%"}>
          <Typography variant="subtitle2">
            {t("filterManagement.value")}
          </Typography>
          <TextField
            id="filterValue_textField"
            fullWidth
            placeholder=""
            variant="outlined"
            size="small"
            value={fieldValue}
            onChange={(e) => {
              setFieldValue(e.target.value);
            }}
          />
        </Stack>

        <AddSmallButton
          disabled={
            fieldValue === "" || fieldName === "" || operatorValue === ""
          }
          onClick={() => {
            handleAddFilter();
          }}
        >
          <AddIcon />
        </AddSmallButton>
      </FormContainer>
      {filterValueArr.map((item, i) => {
        return (
          <RowStack
            key={`filterValueArr${i}`}
            sx={{
              backgroundColor: ` ${alpha(theme.palette.warning.main, 0.7)}`,
              padding: "4px",
              borderRadius: "32px",
            }}
            gap={"4px"}
          >
            <Typography
              marginLeft={"8px"}
              maxWidth={"200px"}
              width={"100%"}
              variant="body2"
            >
              {item.field_name}
            </Typography>
            <Typography width={"100%"} variant="body2">
              {item.operator}
            </Typography>
            <Typography width={"100%"} variant="body2">
              {item.field_value}
            </Typography>
            <DeleteModal
              text={t("filterManagement.deleteCreateFilterDesc")}
              onClick={() => {
                Notify.notifyWarning(t("filterManagement.notifyWarning"));
                setValueArr((prev: any) =>
                  prev.filter((e: any) => e.field_name !== item.field_name)
                );
              }}
            />
          </RowStack>
        );
      })}
    </Stack>
  );
};

export default FilterValues;
