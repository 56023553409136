import * as echarts from "echarts";
import ReactEcharts from "echarts-for-react";
import { Stack } from "@mui/material";
import { useTheme } from "layouts/theme/ThemeContext";

/* const legendData = ["Line 1", "Line 2", "Line 3", "Line 4", "Line 5"] */
/* const optionColors = ["#80FFA5", "#00DDFF", "#37A2FF", "#FF0087", "#FFBF00"] */
/* const xAxisData = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const   seriesData =  [140, 232, 101, 264, 90, 340, 250] */

const GradientStackedAreaChartEChart = ({
  legendData,
  seriesData,
  optionColors,
  xAxisData,
}: {
  legendData: string[];
  seriesData: number[];
  optionColors: string[];
  xAxisData: string[];
}) => {
  const { theme } = useTheme();

  return (
    <Stack marginTop={"-72px"} width={"100%"}>
      <ReactEcharts
        option={{
          color: optionColors,
          /*  title: {
            text: "Gradient Stacked Area Chart",
          }, */
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          legend: {
            data: legendData,
          },
          /*  toolbox: {
            feature: {
              saveAsImage: {},
            },
          }, */
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              show: false,
              type: "category",
              boundaryGap: false,
              data: xAxisData,
            },
          ],
          yAxis: [
            {
              show: false,
              type: "value",
            },
          ],
          series: [
            {
              name: "Line 1",
              type: "line",
              stack: "Total",
              smooth: true,
              lineStyle: {
                width: 0,
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: theme.palette.warning.main,
                  },
                  {
                    offset: 1,
                    color: theme.palette.background.paper,
                  },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: seriesData,
            },
          ],
        }}
      />
    </Stack>
  );
};

export default GradientStackedAreaChartEChart;
