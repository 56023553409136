import { configureStore } from "@reduxjs/toolkit";
import layoutsSlice from "./slices/LayoutsSlice";
import handleLoginSlice from "./slices/LoginSlice";
import automationSlice from "./slices/AutomationSlice";
import alertsSlice from "./slices/AlertsSlice";
import dashboardSlice from "./slices/DashboardSlice";

export const store = configureStore({
  reducer: {
    layouts: layoutsSlice,
    login: handleLoginSlice,
    automation: automationSlice,
    alerts: alertsSlice,
    dashboard: dashboardSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
