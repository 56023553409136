import React, { useState } from "react";
import {
  Stack,
  IconButton,
  Modal,
  styled,
  Box,
  Typography,
  MenuItem,
} from "@mui/material";
import CustomTooltip from "components/tooltip/tooltip";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { RowStack } from "components/stack/RowStack";
import { StyledTextField } from "components/textField/CustomTextField";
import { CustomIconButton } from "components/buttons/CustomIconButton";
import { CancelButtonSyled } from "components/buttons/CancelButtonSyled";
import { baseUrl } from "utils/helpers/BaseUrl";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/Store";
import { postJobData } from "redux/slices/AutomationSlice";
import { IAgentList } from "page/workflow/types/types";
import { useTranslation } from "react-i18next";

const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "48px",
  borderRadius: "16px",
  backgroundColor: theme.palette.background.default,
}));

const PlayButton = styled(IconButton)(({ theme }) => ({
  width: "88px",
  textTransform: "lowercase",
  borderRadius: "8px",
  ...theme.typography.subtitle2,
  marginTop: "36px",
  padding: "8px 16px",
  border: `1px solid ${theme.palette.error.main}`,
  "&:hover": {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.light,
  },
}));

const WorkFlowStartModal = ({
  flowData,
}: {
  flowData: { id: number; name: string };
}) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [agentList, setAgentList] = useState<IAgentList[]>([]);
  const [valueA, setValueA] = useState<number>(1);
  const getAgentList = async () => {
    const user: string | null = sessionStorage.getItem("user");
    try {
      if (user !== null) {
        const userData = JSON.parse(user);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Token ${userData.token}`);
        await fetch(`${baseUrl}/agent/`, {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        })
          .then((response) => response.json())
          .then((result) => {
            setAgentList(result);
            setValueA(result[0].agent_id);
          })
          .catch((_error) => {});
      }
    } catch (error) {}
  };

  return (
    <Stack>
      <CustomTooltip title={"Play"}>
        <CustomIconButton
          onClick={() => {
            setOpenModal(true);
            getAgentList();
          }}
        >
          <PlayArrowIcon />
        </CustomIconButton>
      </CustomTooltip>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent>
          <Stack width={"250px"} gap={"16px"}>
            <RowStack gap={"24px"}>
              <Typography align="center" fontWeight={700} variant="body1">
                {flowData.name.toLowerCase()}
              </Typography>
            </RowStack>
            <Stack>
              {agentList.length > 0 && (
                <StyledTextField
                  id="outlined-select-currency"
                  select
                  fullWidth
                  size="small"
                  value={valueA}
                  onChange={(event) => {
                    setValueA(Number(event.target.value));
                  }}
                >
                  {agentList.map((option: IAgentList) => (
                    <MenuItem
                      key={`agentList${option.agent_id}`}
                      value={option.agent_id}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </StyledTextField>
              )}
            </Stack>
          </Stack>

          <Stack
            direction={"row"}
            width={"100%"}
            gap={"16px"}
            justifyContent={"flex-end"}
          >
            <CancelButtonSyled
              onClick={() => {
                setOpenModal(false);
              }}
            >
              {t("general.cancel")}
            </CancelButtonSyled>
            <PlayButton
              onClick={() => {
                dispatch(
                  postJobData({
                    agent: valueA,
                    scenario: flowData.id,
                  })
                );
                /*    flowStore.postJobData(valueA, flowData.id); */
                setOpenModal(false);
              }}
            >
              {t("general.play")}
            </PlayButton>
          </Stack>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default WorkFlowStartModal;
